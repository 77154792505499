import { Box, Button, CircularProgress, Typography } from "@mui/material";
import vector from "assets/Vector2.png";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import * as Frigade from "@frigade/react";

const ZoomCenterModal = ({
  user,
  loading,
  modal,
  setOpen,
  collapsed,
  userData,
}) => {
  return (
    <>
      {modal}
      <GlobalPageComponent user={user} userData={userData}>
        <Box
          sx={{
            backgroundColor: "#EFF3F6",
            width: "100%",
            padding: {
              xs: "16px",
              md: "32px 40px",
            },
            display: {
              xs: !collapsed ? "none" : "block",
            },
            overflowY: "auto",
          }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"12px"}
            sx={{
              marginTop: {
                xs: "28px",
                md: "0px",
              },
            }}
            data-cy="dashboard-title"
          >
          </Box>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                position: "absolute",
                top: "50%",
                left: { xs: "50%", lg: "60%", xl: "58%" },
                transform: "translate(-50%, -50%)",
              }}
            >
              <Box
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                position="relative"
              >
                <CircularProgress />
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                position: "absolute",
                top: "50%",
                left: { xs: "50%", lg: "60%", xl: "58%" },
                transform: "translate(-50%, -50%)",
              }}
            >
              <Box
                sx={{
                  margin: "0 auto",
                  width: "840px",
                  maxWidth: {
                    xs: "calc(100% - 32px)",
                    md: "calc(100% - 40px)",
                  },
                  padding: "56px",
                  background:
                    "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
                  borderRadius: "40px",
                  display: "flex",
                  alignItems: {
                    xs: "flex-start",
                    md: "center",
                  },
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                  justifyContent: "space-between",
                  gap: {
                    xs: "40px",
                    md: "0",
                  },
                }}
              >
                {/* <Frigade.Banner flowId="flow_ZLBcEzYh" dismissible={true} /> */}
                {/* <Frigade.Banner flowId="flow_ZLBcEzYh" /> */}
                {/* <Frigade.Tour flowId="flow_vQzPsInI" /> */}
                {/* <Frigade.Tour
                  flowId="flow_bj0qKlvC"
                  defaultOpen={false}
                  sequential={false}
                  dismissible={true}
                /> */}
                <Box
                  sx={{
                    width: {
                      xs: "100%",
                      xl: "50%",
                    },
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontWeight: "300",
                      fontSize: "32px",
                      fontFamily: "Montserrat",
                      textTransform: "capitalize"
                    }}
                  >
                    Connect to Zoom
                  </Typography>
                  <Typography
                    sx={{
                      color: "white",
                      fontWeight: "400",
                      fontSize: "14px",
                      fontFamily: "Montserrat",
                      lineHeight: "1.5",
                      letterSpacing: "0.3px",
                    }}
                  >
                    By connecting to Zoom, you will be able to review, analyze,
                    and share your calls.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: {
                      xs: "100%",
                      xl: "50%",
                    },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: {
                      xs: "flex-start",
                      md: "flex-end",
                    },
                    zIndex: "1",
                  }}
                >
                  <Button
                    onClick={() => setOpen(true)}
                    sx={{
                      width: "218px",
                      padding: "10px 0",
                      background: "white",
                      border: "1px solid white",
                      borderRadius: "52px",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      transition: "all 0.3s",
                      "&:hover": {
                        background: "black",
                        "& *": {
                          color: "white",
                        },
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        color: "#262626",
                        fontSize: "10px",
                        fontWeight: "700",
                        lineHeight: "1.5",
                        letterSpacing: "2px",
                        textTransform: "uppercase",
                      }}
                    >
                      Connect
                    </Typography>
                    <span id="hint-anchor-1"></span>
                  </Button>
                </Box>
                <Box
                  component="img"
                  src={vector}
                  alt="vector"
                  width={{ xs: "395px" }}
                  sx={{
                    position: "absolute",
                    marginLeft: { md: "390px" },
                    zIndex: "0",
                    display: {
                      xs: "none",
                      md: "inline",
                    },
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </GlobalPageComponent>
    </>
  );
};

export default ZoomCenterModal;
