import React from "react";
import { SvgIcon, Checkbox } from "@mui/material";

// Custom Disabled Icon
const CustomDisabledIcon = () => (
  <SvgIcon
  style={{
    width: 24,
    height: 24,
    borderRadius: "6px", // Fixed border radius
  }}
>
  <rect
    x="2"
    y="2"
    width="20"
    height="20"
    rx="6" // Ensure consistent rounded corners
    fill="#FAFAFA"
    stroke="#D9E4EC"
    strokeWidth="1"
  />
</SvgIcon>
);

const CustomIndeterminateIcon = () => (
    <SvgIcon
      style={{
        width: 24,
        height: 24,
        borderRadius: "6px", // Keep consistent border radius
        backgroundColor: "white", // Background color
      }}
    >
      {/* Blue border */}
      <rect
        x="2"
        y="2"
        width="20"
        height="20"
        rx="6" // Border radius
        fill="transparent" // Transparent fill for border
        stroke="#0070FF" // Blue border color
        strokeWidth="2" // Thickness of the border
      />
      {/* Dash icon */}
      <path
        d="M17 11H7v2h10v-2z"
        fill="#568FF3" // Dash color
      />
    </SvgIcon>
  );
  

const CustomUncheckedIcon = () => (
    <SvgIcon
      style={{
        width: 24,
        height: 24,
        borderRadius: "6px", // Fixed border radius
      }}
    >
      <rect
        x="2"
        y="2"
        width="20"
        height="20"
        rx="6" // Ensure consistent rounded corners
        fill="transparent"
        stroke="#8093A1"
        strokeWidth="1"
      />
    </SvgIcon>
  );  

// Custom checked icon
const CustomCheckedIcon = () => (
    <SvgIcon
      style={{
        width: 24,
        height: 24,
      }}
    >
      <rect
        x="2"
        y="2"
        width="20"
        height="20"
        rx="6"
        fill="#0076BA" // Blue fill for the background
        stroke="#0076BA" // Blue stroke for the border
        strokeWidth="1"
      />
      <path
        d="M7 12l3 3 7-7" // Checkmark path
        stroke="#FFFFFF" // White checkmark
        strokeWidth="2"
        fill="none"
      />
    </SvgIcon>
  );

  const CustomCheckbox = ({ disabled, ...props }) => (
    <Checkbox
      {...props}
      disabled={disabled} // Ensure `disabled` is passed to the `Checkbox` component
      icon={disabled ? <CustomDisabledIcon /> : <CustomUncheckedIcon />} // Dynamically apply the disabled icon
      checkedIcon={<CustomCheckedIcon />}
      indeterminateIcon={<CustomIndeterminateIcon />}
      sx={{
        padding: 0, // Remove any extra padding around the checkbox
        "& .MuiSvgIcon-root": {
          borderRadius: "6px", // Maintain consistent border radius
        },
        "&:hover .MuiSvgIcon-root": {
          borderRadius: "6px", // Explicitly set border radius on hover
        },
      }}
    />
  );
  
  export default CustomCheckbox;
  
