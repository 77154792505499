import { useState, useEffect, useRef } from "react";
import { generateClient } from "aws-amplify/data";

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });

const useBigFiveAssessment = ({ user, assessmentId, teamId, coachId }) => {
    const [assessment, setAssessment] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const creatingRef = useRef(false); // Use ref to track creation status

    useEffect(() => {
        // Make sure we have a user and prevent double creation during assessment creation
        if (!user?.sub || creatingRef.current) return;

        const fetchAssessment = async () => {
            try {
                setLoading(true);
                
                let data, errors;
                
                if (assessmentId && assessmentId !== '-1') {
                    // If assessmentId exists, fetch it by id
                    const { data: assessmentData, errors: assessmentErrors } = await resourceClient.models.assessments.get({
                        id: assessmentId,
                    });
                    data = assessmentData;
                    errors = assessmentErrors;
                } else {
                    // If assessmentId is not available, list by user_id and test_type
                    const { data: assessmentListData, errors: assessmentListErrors } = await resourceClient.models.assessments.list({
                        filter: {
                            user_id: { eq: user.sub },
                            test_type: { eq: "The Big Five Personality Test" },
                        },
                    });
                    data = assessmentListData.length > 0 ? assessmentListData[0] : null;
                    errors = assessmentListErrors;
                }

                // Handle any errors from the GraphQL queries
                if (errors) {
                    setError(errors);
                    return;
                }

                if (data) {
                    // If assessment data is found, set it
                    setAssessment(data);
                    return;
                } else {
                    // If no data is found, create a new assessment
                    if (!creatingRef.current) {
                        creatingRef.current = true; // Set flag to prevent multiple creations
                        const { data: newAssessmentData, errors: createErrors } = await resourceClient.models.assessments.create({
                            user_id: user.sub,
                            test_type: "The Big Five Personality Test",
                            completed: false,
                            assigned: true,
                            team_id: teamId || null,
                            coach_id: coachId || null,
                        });

                        if (createErrors) {
                            setError(createErrors);
                        } else {
                            setAssessment(newAssessmentData);
                        }

                        creatingRef.current = false; // Reset flag after creation
                    }
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchAssessment();
    }, [user, assessmentId, teamId, coachId]);

    return { assessment, loading, error };
};

export default useBigFiveAssessment;
