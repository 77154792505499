import { useQuery } from "@tanstack/react-query";
import React, { useState, useEffect, Suspense } from "react";
import { Box, Modal, Typography, CircularProgress } from "@mui/material";
import Team from "scenes/SessionsList";
import { useDispatch, useSelector } from "react-redux";
import { getCallsByCoachID } from "slices/CoachSlice";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import WelcomeCard from "components/CoachDashboardCards/WelcomeCard";
import ClientCard from "components/CoachDashboardCards/ClientCard";
import ZoomCenterModal from "components/ClientDashboardCards/ZoomCenterModal";
import DeclineButton from "components/DeclineButton";
import ConfirmButton from "components/ConfirmButton";
import ClientLimitReached from "components/ClientLimitReachedModal";
import ReturnFromZoomModal from "components/CoachDashboardCards/ReturnFromZoomModal";
import axios from "axios";
import {
  handleStripeRedirect,
  updateUserStateAfterStripe,
} from "utils/stripeRedirect";
import { fetchAuthSession } from "aws-amplify/auth";
import * as Frigade from "@frigade/react";
import { checkUserGroupAlignment } from "helpers/userCognitoGroupCheck";
import { fetchAvatarUrl } from "utils/fetchAvatarUrl"

// Lazy load VirtualCoachCard
const VirtualCoachCard = React.lazy(() => import("components/CoachDashboardCards/VirtualCoachCard"));


const CoachDashboard = ({ user, userData }) => {
  const collapsed = useSelector((state) => state.layout.collapsed);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const coachCallData = useSelector((state) => state.coach.data);
  const [lastFileName, setLastFileName] = useState("");
  const dispatch = useDispatch();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [client, setClient] = useState([]);
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
  const clientID = process.env.REACT_APP_CLIENT_ID;
  const redirectURL = process.env.REACT_APP_ZOOM_API_ENDPOINT;

  const refreshUserSession = async () => {
    try {
      await fetchAuthSession({ forceRefresh: true });
      console.log("Session refreshed successfully");
      window.location.replace("/platform/coach");
    } catch (error) {
      console.error("Error refreshing session:", error);
    }
  };

  useEffect(() => {
    // Call the utility function to handle the Stripe redirect
    handleStripeRedirect(() => updateUserStateAfterStripe(refreshUserSession));
  }, []);

  const handleOAuthRedirect = async () => {
    try {
      const userId = user?.sub;
      const stateParam = encodeURIComponent(userId);
      const zoomOAuthURL = `https://zoom.us/oauth/authorize?response_type=code&client_id=${clientID}&redirect_uri=${encodeURIComponent(
        redirectURL
      )}&state=${stateParam}`;
      window.location.href = zoomOAuthURL;
    } catch (error) {
      console.error("Error fetching user ID or redirecting:", error);
    }
  };

  const fetchUserData = async (coach_id) => {
    try {
      // Fetch calls by coach ID
      let response;
      try {
        response = await dispatch(
          getCallsByCoachID({ coachId: coach_id || user?.sub }) // Use passed `coach_id`
        ).unwrap();
        response = response.data;
      } catch (error) {
        console.error("Error fetching calls:", error);
        response = []; // Fallback response
      }
  

      // Check Zoom status
      const { data: zoomCheckResponse } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/check-zoom`,
        { params: { coach_id } }
      );

      console.log("zoom res: ", zoomCheckResponse)
  
      let zoomStatus = false;
      if (zoomCheckResponse?.data?.length > 0) {
        zoomStatus = zoomCheckResponse?.data[0]?.zoom_connected;
        console.log("zoom status 111: ", zoomStatus)
      }

      if(response){
        setLastFileName(
          response?.find((row) => row.status === "ReadyToPublish" || row.status === "assigned")?.file_name || ""
        );
      }
      
  
      // Return combined data
      return { response, zoomStatus };
    } catch (error) {
      console.error("Error fetching user data:", error);
      return { response: [], zoomStatus: false }; // Fallback return structure
    }
  };

  // Use Query
  const { data: userDataResult, isLoading: isLoadingUserData } = useQuery({
    queryKey: ['userData', user?.sub],
    queryFn: () => fetchUserData(user?.sub),
    staleTime: 0,
    cacheTime: 0,
  });

  const zoomStatus = userDataResult?.zoomStatus

  const fetchSums = async (user_id) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-dashboard-metrics-sum`,
      { params: { user_id } }
    );
    console.log("sums: ", data?.data)
    return data?.data;
  };

  const { data: callSums, isLoading: isLoadingSum, refetch } = useQuery({
    queryKey: ['coachSums', user.sub],
    queryFn: () => fetchSums(user.sub),
    staleTime: 60000, // Use stale data for 1 minute
    cacheTime: 300000, // Keep cached data for 5 minutes
  });
  
  

  const fetchClientData = async () => {
    // If the user does not have access to the client portal, exit early
    if (userData.accessTo === "client_portal") return;

    try {
      const userId = user?.sub;
      let response = null;

      if (userData?.companyInformation) {
        await checkUserGroupAlignment(
          user?.sub,
          user?.role,
          userData?.companyInformation?.company_id
        );
        switch (user?.role) {
          case "OWNER":
            response = await axios.get(
              `${process.env.REACT_APP_API_ENDPOINT}/zoom/owner-fetch-company-information`,
              {
                params: { userId: userId, companyId: userData?.companyInformation?.company_id },
              }
            );
            break;
          case "SUPERADMINS":
            response = await axios.get(
              `${process.env.REACT_APP_API_ENDPOINT}/zoom/super-admin-fetch-company-members`,
              {
                params: { userId: userId, userGroup: user?.role, companyId: userData?.companyInformation?.company_id },
              }
            );
            break;
          case "ADMINS":
            response = await axios.get(
              `${process.env.REACT_APP_API_ENDPOINT}/zoom/admin-fetch-company-members`,
              {
                params: { userId: userId, userGroup: user?.role, companyId: userData?.companyInformation?.company_id },
              }
            );
            break;
          case "USERS":
            response = await axios.get(
              `${process.env.REACT_APP_API_ENDPOINT}/zoom/user-fetch-company-members`,
              {
                params: { userId: userId, userGroup: user?.role, companyId: userData?.companyInformation?.company_id },
              }
            );
            break;
          case "PRIVATE":
            response = await axios.get(
              `${process.env.REACT_APP_API_ENDPOINT}/zoom/private-fetch-company-members`,
              {
                params: {
                  userId: userId,
                  userGroup: user?.role,
                  companyId: userData?.companyInformation?.company_id,
                },
              }
            );
            break;
          default:
            break;
        }
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-clients`,
          {
            params: { coach_id: userId },
          }
        );
      }

      return response?.data?.members || [];  
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  useEffect(() => {
    const fetchClientDataAndProcess = async () => {
      setLoading(true);
      try {
        const clientResponse = await fetchClientData(user?.sub, user?.role, userData?.companyInformation);

        const processedClientData = await Promise.all(
          clientResponse
            .filter((item) => item.user_id !== user?.sub)
            .map(async (item) => ({
              ...item,
              avatar: item.avatar
                ? await fetchAvatarUrl(item.avatar)
                : null,
            }))
        );

        setClient(processedClientData);
      } catch (error) {
        console.error("Error fetching client data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchClientDataAndProcess();
  }, [user, userData]);
  
  
  
  

  const modal = (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: { xs: "50%", lg: "60%", xl: "58%" },
          transform: "translate(-50%, -50%)",
          width: 500,
          background: "white",
          border: "2px solid white",
          boxShadow: 24,
          paddingX: "50px",
          paddingY: "30px",
          borderRadius: "30px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "20px",
          margin: "0 auto",
          maxWidth: "calc(100% - 40px)",
        }}
      >
        <Box>
          <Typography color="black" fontWeight="400">
            {`You will be redirected to Zoom and asked to allow our app connection.`}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            gap: { xs: "8px", md: "15px" },
            flexDirection: { xs: "column-reverse", md: "row" },
          }}
        >
          <DeclineButton onClick={handleClose} label={"Cancel"} />
          <ConfirmButton onClick={handleOAuthRedirect} label={"Connect"} />
        </Box>
      </Box>
    </Modal>
  );

  if (
    (!userDataResult?.zoomStatus && !isLoadingUserData && userData?.companyInformation?.my_user_role?.role_name === 'owner')
  ) {
    return (
      <>
        <ClientLimitReached user={user} userData={userData} />
        <ZoomCenterModal
          user={user}
          loading={isLoadingUserData}
          modal={modal}
          setOpen={setOpen}
          collapsed={collapsed}
          userData={userData}
        />
        <Frigade.Checklist.Collapsible
          flowId="flow_HER5jgDw"
          style={{
            position: "fixed", // Makes the carousel fixed position relative to the viewport
            top: "50%", // Positions the top edge of the carousel at the center of the viewport
            left: "50%", // Positions the left edge of the carousel at the center of the viewport
            transform: "translate(-50%, -50%)", // Shifts the carousel back to truly center it
            maxWidth: "600px",
            maxHeight: "600px",
            width: "90%", // Responsive width, adjust as needed
            margin: "0", // Removes auto margin since it's now absolutely positioned
            boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)", // X=0, Y=5, Blur=15, and a subtle opacity for better effect
            border: "1px solid #ccc",
            borderRadius: "10px",
            overflow: "scroll",
            padding: "20px",
            zIndex: 1000, // High z-index to ensure it's on top of other content
            background: "white", // Ensures the content is readable by providing a solid background
            fontFamily: "Poppins",
          }}
        />
      </>
    );
  }

  return (
    <GlobalPageComponent user={user} userData={userData}>
      <ClientLimitReached user={user} userData={userData} />
      <Box
        sx={{
          backgroundColor: "#EFF3F6",
          width: "100%",
          padding: "16px",
          display: { xs: !collapsed ? "none" : "flex" },
          flexDirection: "column",
          overflow: "hidden",
          height: "100%",
        }}
      >
        {/* <Box display="flex" alignItems="center" gap="12px">
          <Typography sx={{ color: "#070728", fontSize: "36px", fontWeight: 300, paddingBottom: "16px" }}>
            Dashboard
          </Typography>
        </Box> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xs: "100%", md: "calc(100% / 3 * 2)" },
              gap: "8px",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                flexDirection: { xs: "column", md: "row" },
                height: "100%",
              }}
            >
              <>
                <WelcomeCard
                  loadingSum={isLoadingSum}
                  loadingUserData={isLoadingUserData}
                  zoomStatus={userDataResult?.zoomStatus}
                  user={user}
                  coachCallData={coachCallData}
                  lastFileName={lastFileName}
                  handleOpen={handleOpen}
                  modal={modal}
                  sums={callSums}
                />
                <ClientCard
                  loadingSum={isLoadingSum}
                  loadingUserData={isLoadingUserData}
                  zoomStatus={userDataResult?.zoomStatus}
                  handleOpen={handleOpen}
                  modal={modal}
                  client={client}
                  sums={callSums}
                  userData={userData}
                />
              </>
            </Box>
          </Box>
          <Box
      sx={{
        width: { xs: "100%", md: "calc(100% / 3)" },
        flexGrow: 1,
      }}
    >
      {/* Suspense ensures a fallback is displayed while loading the component */}
      <Suspense
        fallback={
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        }
      >
        <VirtualCoachCard user={user} />
      </Suspense>
    </Box>
        </Box>
        <Team
          user={user}
          userDataInfo={userData}
          fetchData={fetchUserData}
          isDashboard={true}
          isProfessionalTier={userData.accessTo === "platform_professional"}
          fetchSum={() => fetchSums(user?.sub)}
          accessTo={userData.accessTo}
          memberData={client}
        />
      </Box>
      <ReturnFromZoomModal
        open={openSuccessMessage}
        setOpen={setOpenSuccessMessage}
      />
    </GlobalPageComponent>
  );
};

export default CoachDashboard;

