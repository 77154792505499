import React, { useState } from "react";
import { Popover, Box, List, ListItem, ListItemButton, ListItemText, Typography, Divider } from "@mui/material";

const ToolbarPopover = ({ buttonLabel, buttonIcon, title, navItems, renderContent, buttonStyles = {} }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(navItems[0]?.label); // Default to the first item's label

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "toolbar-popover" : undefined;

  return (
    <>
      {/* Trigger Button */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          padding: "8px",
          borderRadius: "8px",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#F5F7FF",
          },
          ...buttonStyles, // Custom styles for the button
        }}
        onClick={handleClick}
      >
        {buttonIcon && <>{buttonIcon}</>}
        <Typography
          sx={{
            fontFamily: "Lexend",
            fontSize: "12px",
            lineHeight: "20px",
            color: "#2D2D2D",
          }}
        >
          {buttonLabel}
        </Typography>
      </Box>

      {/* Popover */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "left",
        }}
        PaperProps={{
            sx: {
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2) !important", // Strong shadow with custom RGBA
            borderRadius: "10px", // Rounded corners
            },
        }}
        >
        {/* Popover Content */}
        <Box
          sx={{
            display: "flex",
            width: "600px",
            height: "400px",
            backgroundColor: "white",
            borderRadius: "8px",
          }}
        >
          {/* Navigation Items (Left) */}
          <List
            sx={{
              width: "200px",
              backgroundColor: '#FAFCFE',
              padding: 0,
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    paddingY: "12px",
                    paddingX: "20px",
                    alignItems: "center"
                }}
            >
                <Typography
                    sx={{
                        color: "black",
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        lineHeight: "24px",
                        fontWeight: "500",
                        letterSpacing: "0.05em"

                    }}
                >
                    {title}
                </Typography>
            </Box>
            <Divider sx={{ marginBottom: "20px", backgroundColor: "#D9E4EC" }} />
            {navItems?.map(({ label, icon }) => (
              <ListItem
                key={label}
                disablePadding
                onClick={() => setSelectedItem(label)}
                sx={{
                  "&:hover": { backgroundColor: "#F5F7FF" },
                  backgroundColor: selectedItem === label ? "#F5F7FF" : "transparent",
                  color: "black",
                  letterSpacing: "0.05em"
                }}
              >
                <ListItemButton>
                  {icon && (
                    <Box
                      sx={{
                        marginRight: "8px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {icon}
                    </Box>
                  )}
                    <ListItemText
                    primary={label}
                    primaryTypographyProps={{
                        fontFamily: "Lexend",
                        fontSize: "14px",
                        lineHeight: "18px",
                        fontWeight: "500",
                        letterSpacing: "0.05em",
                    }}
                    />

                </ListItemButton>
              </ListItem>
            ))}
          </List>

          {/* Content (Right) */}
          <Box
            sx={{
              flex: 1,
              padding: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {renderContent(selectedItem)}
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default ToolbarPopover;
