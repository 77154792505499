import { Modal, Box, Typography, Button, CircularProgress } from "@mui/material";
import { generateClient } from "aws-amplify/data";
import { useEffect, useState } from "react";
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate } from "react-router-dom";


/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */

const resourceClient = generateClient({ authMode: "userPool" });

const TheBigFiveHistoryModal = ({ open, handleClose, userId, assessmentId, userName }) => {
    const [loading, setLoading] = useState(false)
    const [assessmentData, setAssessmentData] = useState([])
    const navigate = useNavigate();

    const fetchAssessmentData = async () => {
        if (!userId || !open) return;
      
        let hasNextPage = true;
        let nextToken = null;
        let assessments = [];
        let pageCount = 0;
      
        try {
          setLoading(true);
      
          while (hasNextPage) {
      
            const response = await resourceClient.models.assessments.list({
                filter: { user_id: { eq: userId }, completed: { eq: true } },
                limit: 300,
                nextToken, // Pass the token for pagination
              });
      
            if (response.errors) {
              console.error(`Errors for page ${pageCount + 1}:`, response.errors);
              break;
            }
      
            if (!response.data) {
              console.warn(`No data returned for page ${pageCount + 1}. Stopping pagination.`);
              break;
            }
      
            assessments = assessments.concat(response.data || []);
            nextToken = response.nextToken; // Extract nextToken from response
      
            hasNextPage = !!nextToken; // Continue fetching if nextToken is not null
            pageCount++;
          }
      
          const idSet = new Set();
          const uniqueAssessments = assessments
            .map((assessment) => {
              if (!assessment.id || idSet.has(assessment.id)) {
                console.warn("Duplicate or missing ID:", assessment.id);
                return null;
              }
              idSet.add(assessment.id);
              return {
                ...assessment,
                results: assessment.results ? JSON.parse(assessment.results) : {},
              };
            })
            .filter(Boolean);
      
          const sortedData = uniqueAssessments.sort(
            (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
          );
      
          setAssessmentData(sortedData);
        } catch (error) {
          console.error("Error fetching assessment data:", error);
        } finally {
          setLoading(false);
        }
      };      
             
        

    useEffect(() => {
        fetchAssessmentData();
    },[userId, open]);

    const domainColors = {
        A: '#F3AB54',
        C: '#49CA7A',
        E: '#337BE8',
        N: '#9865CF',
        O: '#F56565'
      };
      
    const domainTitles = {
        A: 'Agreeableness',
        C: 'Conscientiousness',
        E: 'Extraversion',
        N: 'Neuroticism',
        O: 'Openness'
    };

    const domainColorsHover = {
        A: '#F3AB54B3',  // 70% opacity
        C: '#49CA7AB3',  // 70% opacity
        E: '#337BE8B3',  // 70% opacity
        N: '#9865CFB3',  // 70% opacity
        O: '#F56565B3',  // 70% opacity
      }

    const domainOrder = ['O', 'C', 'E', 'A', 'N'];

    // Function to calculate the average score for each assessment
    const calculateAverageScore = (assessment) => {
        const totalScore = Object.keys(domainTitles).reduce((acc, domainKey) => {
            const score = Number(assessment.results?.[domainKey]?.score) || 0;
            return acc + score;
        }, 0);

        // Calculate average based on the number of domains (assuming 5 domains)
        return totalScore / Object.keys(domainTitles).length;
    };

    // Function to compare the average score and return the corresponding icon
    const getScoreComparisonIcon = (currentScore, previousScore) => {
        if (currentScore > previousScore) {
            return <NorthIcon sx={{ color: "#65CF7C" }} />;
        } else if (currentScore < previousScore) {
            return <SouthIcon sx={{ color: "#EC5D5D" }} />;
        } else {
            return <RemoveIcon sx={{ color: "gray" }} />;
        }
    };

  return (
    <Modal open={open} onClose={handleClose}>
    <Box
        sx={{
        position: "absolute",
        backgroundColor: "white",
        color: "black",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 1000,
        boxShadow: 24,
        padding: { xs: "24px", md: "36px" },
        gap: "24px",
        borderRadius: "30px",
        display: "flex",
        flexDirection: "column",
        margin: "20px auto",
        overflowY: "auto",
        height: 650,
        }}
    >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <Box
            sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            }}
        >
            <Typography sx={{ color: "black", fontSize: 36, fontWeight: 300 }}>
            Previous Tests | {userName}
            </Typography>
            <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
                width: "90px",
                height: "35px",
                borderRadius: "20px",
                backgroundColor: "white",
                color: "black",
                border: "1px solid black",
                textTransform: "capitalize",
                fontFamily: "Poppins",
                fontWeight: 500,
                lineHeight: "18px",
                letterSpacing: "0.05em",
            }}
            >
            Back
            </Button>
        </Box>
        <Typography sx={{ fontSize: 16, fontFamily: "Lexend", color: "#828282" }}>
            {`${userName?.split(" ")[0] || "User"}'s most recent assessment compared to their previous attempts`}
        </Typography>
        </Box>

        {loading ? (
        <Box
            sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
            }}
        >
            <CircularProgress sx={{ color: "black" }} />
        </Box>
        ) : (
        <>
            {assessmentData && assessmentData.length > 0 ? (
            <Box sx={{display: "flex", flexDirection: "column", width: "100%", height: "100%", flexGrow: 1, justifyContent: "space-between"}}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                    {/* Header Row for Assessment Index and Date */}
                    <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        marginBottom: "10px",
                    }}
                    >
                    {/* Empty space for Domain Title column */}
                    <Box sx={{ minWidth: "200px" }}></Box>
                    {/* Assessment Index and Date columns */}
                    {assessmentData.map((assessment, index) => {
                        const currentScore = calculateAverageScore(assessment);
                        const previousScore = index > 0 ? calculateAverageScore(assessmentData[index - 1]) : null;

                        return (
                        <Box key={index} sx={{ textAlign: "center", width: "170px" }}>
                            {/* Show comparison icon only if it's not the first assessment */}
                            {index > 0 ? getScoreComparisonIcon(currentScore, previousScore) : <RemoveIcon sx={{ color: "gray" }} />}

                            <Typography
                            sx={{
                                color: "black",
                                fontFamily: "Poppins",
                                fontSize: 24,
                                fontWeight: '300',
                            }}
                            >
                                {new Date(assessment.updatedAt).toLocaleDateString()}
                            </Typography>
                        </Box>
                        );
                    })}
                    </Box>

                    {/* Domain Rows with Scores */}
                    {domainOrder.map((domainKey) => (
                    <Box
                        key={domainKey}
                        sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        marginBottom: "10px",
                        }}
                    >
                        {/* Domain Title */}
                        <Typography
                        sx={{
                            color: domainColors[domainKey] || "#000",
                            fontFamily: "Lexend",
                            fontSize: 20,
                            fontWeight: 400,
                            minWidth: "200px", // Adjust width as needed
                        }}
                        >
                        {domainTitles[domainKey]}
                        </Typography>

                        {/* Scores for Each Assessment */}
                        {assessmentData.map((assessment, index) => {
                        // Extract and scale the score value
                        const scoreValue =
                            Number(assessment.results?.[domainKey]?.score) || 0; // Ensure score is valid
                        const scaledScore = Math.round(
                            Math.min(Math.max((scoreValue / 120) * 100, 0), 100)
                        ); // Scale to 0-100 and round

                        return (
                            <Box
                                sx={{
                                    display: "flex",
                                    width: "170px",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <Box
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "50%",
                                        backgroundColor: domainColors[domainKey] || "#000",
                                        width: { xs: "40px", md: "40px" },
                                        height: { xs: "40px", md: "40px" },
                                        textAlign: "center",
                                        "&:hover": {
                                            backgroundColor: assessment?.id ? domainColorsHover[domainKey] : domainColors[domainKey],
                                            fontWeight: assessment?.id ? 500 : 400, // Increase font weight only if id exists
                                            cursor: assessment?.id ? "pointer" : "default", // Show pointer only if clickable
                                        },
                                    }}
                                    onClick={() => {
                                        if (assessment?.id) {
                                          navigate(`/platform/coach/assessments/view/individual-breakdown/${assessment.id}/${assessment.user_id}/${domainTitles[domainKey]}`);
                                        }
                                      }}
                                >
                                    <Typography
                                        sx={{
                                        color: "white",
                                        fontFamily: "Lexend",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        }}
                                    >
                                        {scoreValue ? `${scaledScore}` : "N/A"} {/* Display scaled score out of 100 */}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                        })}
                    </Box>
                    ))}
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        flexDirection: "column"
                    }}
                >
                    <Typography sx={{color: "#93989A", textTransform: "uppercase", fontFamily: "Lexend", fontWeight: 600}}>
                        {/* {`Highlights about ${userName?.split(" ")[0] || "User"}`} */}
                    </Typography>
                </Box>
            </Box>
            ) : (
            <Box sx={{ width: "100%" }}>
                <Typography
                sx={{
                    fontSize: 16,
                    fontFamily: "Lexend",
                    color: "#828282",
                }}
                >
                    Assessment data is not available.
                </Typography>
            </Box>
            )}
        </>
        )}
    </Box>
    </Modal>
  );
};

export default TheBigFiveHistoryModal;
