import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import VideoPlayer from '../VideoPlayer';
import PdfDownloadIconForVC from 'components/Icons/PdfDownloadIconForVC';
import PdfFullscreenIcon from 'components/Icons/PdfFullscreenIcon';
import PdfIconForVC from '../../../components/Icons/PdfIconForVC.svg'

import ContentLibraryCloseIcon from 'components/Icons/ContentLibraryCloseIcon.svg';
import ContentLibraryOpenIcon from 'components/Icons/ContentLibraryOpenIcon.svg';
import { FilterContentCategory } from '../Filters/FilterContentCategory';
import { FilterContentFacilitator } from '../Filters/FilterContentFacilitator';
import { FilterContentFormat } from '../Filters/FilterContentFormat';
import PaginationForContentLibrary from '../PaginationForContentLibrary';
import ContentTitleSearch from '../Filters/ContentTitleSearch'
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { toast } from 'react-toastify';
import VirtualCoachSendIcon from 'components/Icons/VirtualCoachSendIcon.svg';


const ContentLibrary = ({ contentLibraryOpen, setPromptFromContentLibraryAsInput, setContentLibraryOpen, handleOpenPdfModal, setSelectedPdfUrl }) => {

    const [categoryOptions, setCategoryOptions] = useState([])
    const [facilitatorOptions, setFacilitatorOptions] = useState([]);
    const [formatOptions, setFormatOptions] = useState([])

    const [showPaginationBar, setShowPaginationBar] = useState(true);
    const [paginationSelectedPage, setPaginationSelectedPage] = useState(1);
    const [paginationCount, setPaginationCount] = useState(670);

    const [filteredContentLibraryData, setFilteredContentLibraryData] = useState([])
    const [selectedTitle, setSelectedTitle] = useState({}); // For title filter

    const [loadingContentLibrary, setLoadingContentLibrary] = useState(false);

    const [selectedCategory, setSelectedCategory] = useState('none');
    const [selectedFacilitator, setSelectedFacilitator] = useState('none');
    const [selectedFormat, setSelectedFormat] = useState('none');

    useEffect(() => {
        fetchContentFilterOption()
    }, [])

    useEffect(() => {
        setPaginationSelectedPage(1);
        fetchFilteredData();
    }, [selectedFormat, selectedFacilitator, selectedCategory]);

    useEffect(() => {

        const effectFunction = async () => {

            // console.log('selected title changed', selectedTitle);
            setShowPaginationBar(false);
            if (!selectedTitle.suggestion) {
                await fetchContentLibrary();
                return;
            }
            if (selectedTitle.type === 'title') {
                await fetchContentByTitle();
                return;
            }
            if (selectedTitle.type === 'keyword') {
                await fetchContentByKeyword();
                return;
            }
            if (selectedTitle.type === 'rawInput') {
                await fetchContentWithoutUsingSuggestions();
                return;
            }

        }
        effectFunction()

    }, [selectedTitle]);


    const fetchFilteredData = async (page = 1) => {
        setSelectedTitle('');
        setShowPaginationBar(true);
        try {
            if (selectedFormat === 'none' && selectedCategory === 'none' && selectedFacilitator === 'none') {
                await fetchContentLibrary(page);
                return;
            }
            setLoadingContentLibrary(true);
            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/get-filtered-content-library`, {
                params: {
                    format: selectedFormat,
                    facilitator: selectedFacilitator,
                    category: selectedCategory,
                    limit: 10,
                    page: page,
                    shouldGetCount: page === 1 ? true : false,
                },
            });
            setFilteredContentLibraryData(response.data.result);
            console.log('fetched CL data based on filtering -----------', response.data.result);
            console.log('Received search query', response.data);
            if (response.data.count !== 'null') setPaginationCount(response.data.count);
            setLoadingContentLibrary(false);
        } catch (error) {
            console.error('Error fetching filtered data:', error);
            toast.error('Oops! Something went wrong. Please Reload')
            setLoadingContentLibrary(false);
        }
    };

    const fetchContentByTitle = async () => {
        if (!selectedTitle) return;
        try {
            setLoadingContentLibrary(true);
            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/get-content-by-title`, {
                params: {
                    input: selectedTitle.suggestion,
                },
            });
            setFilteredContentLibraryData(response.data.result);
            console.log('fetched data -----------', response.data.result);
            setLoadingContentLibrary(false);
        } catch (error) {
            console.error('Error fetching filtered data:', error);
            toast.error('Oops! Something went wrong. Please Reload')
            setLoadingContentLibrary(false);
        }
    };

    const fetchContentByKeyword = async (page = 1) => {
        try {
            setLoadingContentLibrary(true);
            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/get-content-by-keyword`, {
                params: {
                    input: selectedTitle.suggestion,
                    limit: 10,
                    page: page,
                    shouldGetCount: page === 1 ? true : false,
                }
            })
            setFilteredContentLibraryData(response.data.result)
            console.log('fetched data -----------', response.data.result);
            if (response.data.count !== 'null') setPaginationCount(response.data.count);
            setLoadingContentLibrary(false);
            setShowPaginationBar(true);
        } catch (error) {
            console.error("Error fetching content library:", error);
            toast.error('Oops! Something went wrong. Please Reload')
            setLoadingContentLibrary(false);
        }
    };

    const fetchContentWithoutUsingSuggestions = async (page = 1) => {
        try {
            setLoadingContentLibrary(true);
            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/get-content-without-using-suggestions`, {
                params: {
                    input: selectedTitle.suggestion,
                    limit: 10,
                    page: page,
                    shouldGetCount: page === 1 ? true : false,

                }
            })
            console.log('fetched data -----------', response.data.result);
            setFilteredContentLibraryData(response.data.result)
            if (response.data.count !== 'null') setPaginationCount(response.data.count);
            setLoadingContentLibrary(false);
            setShowPaginationBar(true);
        } catch (error) {
            console.error("Error fetching content library:", error);
            toast.error('Oops! Something went wrong. Please Reload')
            setLoadingContentLibrary(false);
        }
    };


    const handlePromptSelection = (prompt, format) => {
        setPromptFromContentLibraryAsInput(prompt);
        if (format === 'Video') {
            setPromptFromContentLibraryAsInput(`${prompt} Provide the video.`);
        } else if (format === 'Download') {
            setPromptFromContentLibraryAsInput(`${prompt} Provide the PDF.`);
        } else {
            setPromptFromContentLibraryAsInput(prompt);
        }
    };

    const toggleContentLibrary = () => {
        setContentLibraryOpen((prevState => !prevState));
    }

    const fetchContentLibrary = async (page = 1) => {
        try {
            setLoadingContentLibrary(true);
            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/get-content-library-data`, {
                params: {
                    limit: 10,
                    page: page,
                    shouldGetCount: page === 1 ? true : false,

                }
            })
            setFilteredContentLibraryData(response.data.result)
            console.log('fetched CLLLL data -----------', response.data.result);
            if (response.data.count !== 'null') setPaginationCount(response.data.count);
            setLoadingContentLibrary(false);
            setShowPaginationBar(true);
        } catch (error) {
            console.error("Error fetching content library:", error);
            toast.error('Oops! Something went wrong. Please Reload')
            setLoadingContentLibrary(false);
        }
    };


    const fetchContentFilterOption = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/get-content-library-filter-options`);
            const fetchedData = response.data
            const formats = fetchedData.format.map((data) => data.format)
            const facilitator = fetchedData.facilitator.map((data) => data.facilitator)
            const categories = fetchedData.category.map((data) => data.sub_category)
            setFormatOptions(formats)
            setFacilitatorOptions(facilitator.sort())
            setCategoryOptions(categories.sort())
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong')
        }
    }

    const handleContentLibraryFilterOptionChange = async (field, value) => {
        if (field === 'category') setSelectedCategory(value);
        if (field === 'facilitator') setSelectedFacilitator(value);
        if (field === 'format') setSelectedFormat(value);

        // Build the query
        const query = new URLSearchParams({
            selectedFormat: field === 'format' ? value : selectedFormat === 'none' ? '' : selectedFormat,
            selectedFacilitator: field === 'facilitator' ? value : selectedFacilitator === 'none' ? '' : selectedFacilitator,
            selectedCategory: field === 'category' ? value : selectedCategory === 'none' ? '' : selectedCategory,
        }).toString();

        try {

            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/get-dynamic-content-library-filter-options?${query}`);
            const fetchedData = response.data
            // console.log('fetched data after filter change -----------', fetchedData);

            if (selectedCategory === 'none' && field !== 'category') {
                const categories = fetchedData.categoryOptions;
                setCategoryOptions(categories.sort())
            }

            if (selectedFacilitator === 'none' && field !== 'facilitator') {
                const facilitator = fetchedData.facilitatorOptions;
                setFacilitatorOptions(facilitator.sort())
            }

            if (selectedFormat === 'none' && field !== 'format') {
                const formats = fetchedData.formatOptions;
                setFormatOptions(formats)
            }



        } catch (error) {
            console.log(error);
            toast.error('Something went wrong')
        }


    }

    const handleFilterOptionCancel = async (field) => {
        if (field === 'category') setSelectedCategory('none');
        if (field === 'facilitator') setSelectedFacilitator('none');
        if (field === 'format') setSelectedFormat('none');

        // Build the query
        const query = new URLSearchParams({
            selectedFormat: field === 'format' ? '' : selectedFormat === 'none' ? '' : selectedFormat,
            selectedFacilitator: field === 'facilitator' ? '' : selectedFacilitator === 'none' ? '' : selectedFacilitator,
            selectedCategory: field === 'category' ? '' : selectedCategory === 'none' ? '' : selectedCategory,
        }).toString();

        try {

            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/get-dynamic-content-library-filter-options?${query}`);
            const fetchedData = response.data
            // console.log('fetched data after cancelling filter  -----------', fetchedData);

            if (selectedCategory === 'none' || field === 'category') {
                const categories = fetchedData.categoryOptions;
                setCategoryOptions(categories.sort())
            }

            if (selectedFacilitator === 'none' || field === 'facilitator') {
                const facilitator = fetchedData.facilitatorOptions;
                setFacilitatorOptions(facilitator.sort())
            }

            if (selectedFormat === 'none' || field === 'format') {
                const formats = fetchedData.formatOptions;
                setFormatOptions(formats)
            }


        } catch (error) {
            console.log(error);
            toast.error('Something went wrong')
        }
    }


    const handlePaginationChange = async (event, value) => {
        setPaginationSelectedPage(value);
        if (setCategoryOptions === 'none' && selectedFacilitator === 'none' && selectedFormat === 'none') {
            await fetchContentLibrary(value)
        } else {
            await fetchFilteredData(value)
        }

    }

    return (
        <>

            {/* Content Library toggler Button */}
            < Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '20px',
                    height: '6%',
                    position: 'absolute',
                    // border: '1px solid red',
                    top: '20px',
                    right: '25px',
                }}
            >
                <span id="hint-anchor-5">
                    <Box
                        sx={{
                            cursor: 'pointer',
                            borderRadius: '50%',
                            padding: '7px',
                            transition: 'background-color 0.6s ease',
                            '&:hover': {
                                backgroundColor: '#EFF3F6'
                            }

                        }}
                        onClick={toggleContentLibrary}
                    >
                        {contentLibraryOpen ? <ContentLibraryCloseIcon /> : <ContentLibraryOpenIcon />}
                    </Box>
                </span>
            </ Box>

            {contentLibraryOpen && (
                <Box
                    sx={{
                        width: {
                            xs: contentLibraryOpen ? '100%' : '0%',
                            sm: contentLibraryOpen ? '100%' : '0%',
                            md: contentLibraryOpen ? '40%' : '0%',
                        },
                        transition: "width 0.5s ease",
                        height: '100%',
                        backgroundColor: 'white',
                        borderTopRightRadius: '40px',
                        borderBottomRightRadius: '40px',
                        paddingTop: contentLibraryOpen ? '40px' : '0px',
                        paddingX: contentLibraryOpen ? '15px' : '0px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        boxSizing: 'border-box',
                        overflow: 'hidden',
                    }}>
                    <Typography
                        sx={{
                            color: 'black', fontSize: '18px', fontFamily: 'Poppins', fontWeight: '400', wordWrap: 'break-word'
                        }}
                    >
                        Content
                    </Typography>

                    {/* Content Library Filters and Search Bar */}
                    <Box
                        sx={{
                            display: contentLibraryOpen ? "flex" : 'none',
                            flexDirection: "column",
                            width: "100%"
                        }}
                    >
                        <ContentTitleSearch
                            // contentLibraryData={contentLibraryData}
                            setSelectedTitle={setSelectedTitle}
                            setLoadingContentLibrary={setLoadingContentLibrary}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "start",
                                flexDirection: 'column',

                                // display: 'grid',
                                // gridTemplateColumns: '1fr 1fr 1fr',

                                width: "100%",
                                marginTop: "20px",
                                gap: '8px'
                            }}
                        >
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'start',
                                gap: '10px',
                            }}>
                                <FilterContentFormat
                                    value={selectedFormat}
                                    items={formatOptions}
                                    handleContentLibraryFilterOptionChange={handleContentLibraryFilterOptionChange}
                                    handleFilterOptionCancel={handleFilterOptionCancel}
                                />

                                <FilterContentFacilitator
                                    value={selectedFacilitator}
                                    items={facilitatorOptions}
                                    handleContentLibraryFilterOptionChange={handleContentLibraryFilterOptionChange}
                                    handleFilterOptionCancel={handleFilterOptionCancel}
                                />
                            </Box>


                            <FilterContentCategory
                                value={selectedCategory}
                                items={categoryOptions}
                                handleContentLibraryFilterOptionChange={handleContentLibraryFilterOptionChange}
                                handleFilterOptionCancel={handleFilterOptionCancel}
                            />



                        </Box>
                    </Box>

                    <Box
                        sx={{
                            flexGrow: 1, // Takes up remaining space in the container
                            overflowY: 'auto', // Enables scrolling when content overflows
                            paddingY: '15px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                        }}
                    >

                        {
                            loadingContentLibrary && <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                                <CircularProgress sx={{
                                    color: 'black'
                                }} />
                            </Box>
                        }

                        {/* Render filtered content or recent uploads */}
                        {!loadingContentLibrary && filteredContentLibraryData && filteredContentLibraryData.length && filteredContentLibraryData?.map((item) => (
                            <Box
                                key={item.content_id}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    paddingY: '10px',
                                    borderRadius: '8px',
                                }}
                            >
                                <Typography sx={{ color: 'black', fontFamily: 'Poppins', fontSize: 16, fontWeight: 500 }}>
                                    {item.title}
                                </Typography>
                                <Typography sx={{ color: '#555', fontSize: 14, fontFamily: 'Lexend' }}>
                                    {item.facilitator} - {item.format === 'Download' ? 'PDF' : item.format}
                                </Typography>

                                {item.format === 'Video' ? (

                                    <VideoPlayer forContentLibrary={true} urlInfo={{
                                        url: item.s3_link,
                                        videoTimeStamp: item.time_stamp ? item.time_stamp : "null",
                                    }} />

                                ) : item.format === 'Download' ? (
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                        marginTop: '10px',

                                    }}>
                                        <Box
                                            onClick={() => {
                                                setSelectedPdfUrl(item.s3_link);
                                                handleOpenPdfModal();
                                            }}
                                            sx={{
                                                width: '100%',
                                                height: '200px',
                                                // border: '1px solid red',
                                                borderRadius: '8px 8px 0px 0px',
                                                backgroundColor: '#EFF3F6',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                position: 'relative'
                                            }}
                                        >
                                            <PdfIconForVC width='48' height='60' />
                                        </Box>

                                        <Box sx={{
                                            height: '15%',
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'end',
                                            gap: '10px',
                                            alignItems: 'center',
                                            paddingX: '10px',
                                            borderRadius: '0px 0px 8px 8px',
                                            backgroundColor: '#EFF3F6',

                                        }}>
                                            <Box
                                                onClick={() => {
                                                    window.open(item.s3_link, '_blank');
                                                }}
                                                sx={{ cursor: 'pointer', }}>
                                                <PdfDownloadIconForVC />
                                            </Box>
                                            <Box
                                                onClick={() => {
                                                    setSelectedPdfUrl(item.s3_link);
                                                    handleOpenPdfModal();
                                                }}
                                                sx={{ cursor: 'pointer', }}>
                                                <PdfFullscreenIcon />
                                            </Box>

                                        </Box>
                                    </Box>

                                ) : null}

                                <Typography sx={{ color: '#A1A1A1', fontSize: 10, fontFamily: 'Lexend', fontWeight: '500', marginTop: "10px" }}> Try these prompts</Typography>
                                <Box
                                    sx={{
                                        marginTop: '10px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '8px'
                                    }}
                                >
                                    {item.parsed_prompts.slice(0, 2).map((prompt, index) => (
                                        <Box
                                            key={index}
                                            onClick={() => handlePromptSelection(prompt, item.format)}
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                width: "100%",

                                                gap: "8px",
                                                justifyContent: 'space-between',
                                                cursor: 'pointer',
                                                px: '10px',
                                                py: '7px',
                                                borderRadius: '5px',
                                                borderBottom: '1px solid #D9E4EC',
                                                ':hover': {
                                                    backgroundColor: "#eaf0f5"
                                                }
                                            }}>
                                            <Typography
                                                key={index}
                                                sx={{
                                                    color: '#606060',
                                                    fontSize: 12,
                                                    fontFamily: 'Lexend',
                                                    fontWeight: '300',
                                                    letterSpacing: 0.40,
                                                    wordWrap: 'break-word'
                                                }}
                                            >
                                                {prompt}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    cursor: 'pointer',
                                                    padding: '5px',
                                                }}

                                            >
                                                <VirtualCoachSendIcon />
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>

                            </Box>


                        ))}
                        {
                            showPaginationBar && !loadingContentLibrary && <PaginationForContentLibrary paginationSelectedPage={paginationSelectedPage} paginationCount={paginationCount} handlePaginationChange={handlePaginationChange} />
                        }

                    </Box>


                </Box>
            )}
        </>
    );
};

export default ContentLibrary;