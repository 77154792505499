import { Check, KeyboardArrowDown } from "@mui/icons-material";
import { Box, Modal, Select, MenuItem, Typography } from "@mui/material";
import { useState } from "react"; // Import useState for managing selected client
import { generateClient } from "aws-amplify/data";
import ConfirmButton from "components/ConfirmButton";
import DeclineButton from "components/DeclineButton";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */

const resourceClient = generateClient({ authMode: "userPool" });

const AssignAssessmentModal = ({ user, userData, open, clientData, assessment, fetchData, setOpenAssignToStudent, refetch }) => {
  // State to hold the selected client
  const [selectedClient, setSelectedClient] = useState("");
  const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient(); // Access the query client

  // Function to handle client selection from the dropdown
  const handleClientChange = (event) => {
    setSelectedClient(event.target.value);
  };

  const handleClose = () => {
    setOpenAssignToStudent(false);
    setSelectedClient("")
  };

  // Function to create the assessment and send the selected client user ID
  const assignAssessment = async () => {
    if (!selectedClient) {
      console.log("No client selected");
      return;
    }

    try {
      setLoading(true)
      const { data, errors } = await resourceClient.models.assessments.create({
        user_id: selectedClient,  // Use selected client ID
        test_type: assessment,    // Assessment type passed in props
        coach_id: user?.sub,
        assigned: true
        // team_id: 
      });

      if (errors) {
        console.error("Errors from GraphQL create assessment: ", errors);
      }
      toast(`Assessment assigned successfully.`, {
        hideProgressBar: true,
        style: {
          background: "white",
          boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
          borderRadius: "8px",
          padding: "16px 28px",
          color: "#160042",
        },
        icon: () => <Check color="success" />,
      });

      refetch();
    } catch (error) {
      console.error("Error creating assessment: ", error);
    }finally{
      setLoading(false);
      handleClose();
    }
  };

  const CustomIcon = (props) => (
    <KeyboardArrowDown {...props} style={{ color: 'black' }} />
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "white",
          color: "black",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          boxShadow: 24,
          padding: {
            xs: "24px",
            lg: "64px",
          },
          gap: "24px",
          borderRadius: "30px",
          display: "flex",
          flexDirection: "column",  // Make the container a column flexbox
          maxWidth: "40%",
          margin: "20px auto",
          overflowY: "auto",
          height: "450px",
        }}
      >
        {/* Modal Content */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            width: "100%",
            flexGrow: 1,  // Allow content to grow and push buttons down
          }}
        >
          <Typography
            sx={{
              color: "black",
              fontFamily: "Lexend",
              fontWeight: 300,
              fontSize: "36px"
            }}
          >
            Send Assessment
          </Typography>
          <Typography
            sx={{
              color: "#828282",
              fontSize: "16px",
              fontWeight: 500,
              fontFamily: "Lexend"
            }}
          >
            Please select a team or individual.
          </Typography>
          <Box
            sx={{
              marginTop: "20px",
              width: "90%",
              gap: "4px"
            }}
          >
            <Typography
              sx={{
                color: "black",
                textTransform: "Capitalize",
                fontFamily: "Lexend",
                fontSize: "12px",
                fontWeight: 700,
                letterSpacing: 2
              }}
            >
              Individual
            </Typography>
            <Select
              fullWidth
              required
              value={selectedClient}  // Set the selected client in the dropdown
              onChange={handleClientChange}  // Handle client selection
              IconComponent={CustomIcon}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: 'white',
                    maxHeight: 300,
                    overflow: 'auto',
                    maxWidth: '20%',
                    width: '20%',
                  },
                },
              }}
              sx={{
                color: "black",
                background: "white",
                borderRadius: "16px",
                border: "1px solid #C4CAD4",
                "&:before, &:after": {
                  display: "none",
                },
                "& fieldset": {
                  border: "none !important",
                },
              }}
            >
              {clientData?.length > 0 && clientData.map((client) => (
                <MenuItem
                  key={client.client_id}
                  value={client.user_id}  // Set value as user_id
                  sx={{
                    background: "white",
                    color: "black",
                    "&:hover": {
                      background: "#F7F7F7",
                      cursor: "pointer"
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "12px",
                      whiteSpace: "normal",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      wordBreak: "break-word"
                    }}
                  >
                    {client.full_name}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </Box>

          {/* Spacer to push the buttons to the bottom */}
          <Box sx={{ flexGrow: 1 }} />
          
          {/* Button Section */}
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              gap: { xs: "8px", md: "15px" },
              flexDirection: { xs: "column-reverse", md: "row" },
              alignItems: "center",  // Ensure buttons are centered horizontally
            }}
          >
            <DeclineButton
              onClick={handleClose}
              label={"Cancel"}
            />
            <ConfirmButton
              onClick={assignAssessment}
              loading={loading}
              disabled={!selectedClient || !assessment}  // Disable confirm button if no client selected
              label={"Confirm"}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AssignAssessmentModal;
