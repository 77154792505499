import React from "react";

// If you already have this in a separate utility, you can import it instead.
const getPieChartColor = (score) => {
    if (score >= 1 && score <= 20) return "#DA0000";  // Red
    if (score >= 21 && score <= 40) return "#F90";    // Orange
    if (score >= 41 && score <= 60) return "#FFD600"; // Yellow
    if (score >= 61 && score <= 70) return "#A2D831"; // Light Blue
    if (score >= 71 && score <= 80) return "#70CD27"; // Green
    if (score >= 81 && score <= 95) return "#4BC500"; // Green
    if (score >= 95 && score <= 100) return "#37AB00"; // Green
    return "#D3D3D3"; // Grey for undefined or zero
};

/**
 * Renders a "protractor"-style half-circle from left to right.
 * - A light grey arc for the full 180°
 * - A colored arc representing the score portion
 * - A small indicator dot at the arc’s end
 * - Score text in the center, "Overall Score" below it
 */
const OverallScoreRing = ({ score = 0 }) => {
    // Clamp score between 0 and 100
    const normalizedScore = Math.round(Math.max(0, Math.min(100, score)));
    const color = getPieChartColor(normalizedScore);

    // Arc dimensions
    const width = 200;
    const height = 120;
    const radius = 90;          // how large the half circle is
    const centerX = width / 2;
    const centerY = height;     // place the "center" at the bottom of the SVG
    const strokeWidth = 14;

    // We draw a 180° arc from angle 180° (left) to 0° (right) overhead.
    const startAngleDeg = 180;
    const endAngleDeg = 0;
    const totalArcDeg = startAngleDeg - endAngleDeg; // 180 degrees

    // How much of that arc do we fill?
    const fillAngleDeg = totalArcDeg * (normalizedScore / 100);
    // If score=50, fillAngleDeg=90 => arc goes from 180° to 90°.
    const currentAngleDeg = startAngleDeg - fillAngleDeg;

    // Convert degrees to radians
    const toRad = (deg) => (deg * Math.PI) / 180;

    // Compute positions for the arcs
    // Start of the arc (left side, 180°)
    const arcStartX = centerX + radius * Math.cos(toRad(startAngleDeg));
    const arcStartY = centerY - radius * Math.sin(toRad(startAngleDeg));

    // End of the arc (right side, 0°)
    const arcEndX = centerX + radius * Math.cos(toRad(endAngleDeg));
    const arcEndY = centerY - radius * Math.sin(toRad(endAngleDeg));

    // Where the "filled" portion ends
    const fillEndX = centerX + radius * Math.cos(toRad(currentAngleDeg));
    const fillEndY = centerY - radius * Math.sin(toRad(currentAngleDeg));

    // If you never exceed 180° of arc, largeArcFlag is 0 for normal usage.
    const largeArcFlag = fillAngleDeg > 180 ? 1 : 0;
    // We want the arc to go "clockwise" from 180° to 0°, so set sweepFlag=1.
    const sweepFlag = 1;

    // Path for the background arc (full 180°)
    const backgroundArcPath = `
        M ${arcStartX} ${arcStartY}
        A ${radius} ${radius} 0 0 ${sweepFlag} ${arcEndX} ${arcEndY}
    `;

    // Path for the colored arc portion
    const filledArcPath = `
        M ${arcStartX} ${arcStartY}
        A ${radius} ${radius} 0 ${largeArcFlag} ${sweepFlag} ${fillEndX} ${fillEndY}
    `;

    // The indicator dot goes at (fillEndX, fillEndY)
    const indicatorDotRadius = 8;

    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} style={{ overflow: "visible" }}>
            {/* Background arc (light grey) */}
            <path
                d={backgroundArcPath}
                fill="none"
                stroke="#E5E5E5"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />

            {/* Colored arc portion */}
            <path
                d={filledArcPath}
                fill="none"
                stroke={color}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
            />

            {/* Conditionally render the indicator dot */}
            {score > 0 && (
                <circle
                    cx={fillEndX}
                    cy={fillEndY}
                    r={indicatorDotRadius}
                    fill="white"
                    stroke={color}
                    strokeWidth="3"
                />
            )}

            {/* Score text in the middle of the arc—roughly center of the SVG */}
            <text
                x={centerX}
                y={centerY - radius * 0.15} 
                textAnchor="middle"
                fontSize="60"
                fill="#333"
                alignmentBaseline="middle"
                fontWeight="300"
                fontFamily="Poppins, sans-serif"
            >
                {score > 0 ? normalizedScore : "N/A"}
            </text>

            {/* "Overall Score" below that—slightly lower */}
            <text
                x={centerX}
                y={centerY - radius * 0.00000000000000000001 + 40}
                textAnchor="middle"
                fontSize="21"
                fill="#606060"
                fontFamily="Poppins, sans-serif"
            >
                Overall Score
            </text>
        </svg>
    );
};

export default OverallScoreRing;
