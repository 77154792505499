import { useState } from "react";
import { Box, Typography, Popover } from "@mui/material";
import {
    LocalizationProvider,
    StaticDatePicker,
  } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import EditIcon from "components/Icons/Edit.svg";
import MarkAsCompletedIcon from "components/Icons/MarkAsCompleted.svg";
import ActionCalendarIcon from "components/Icons/actioncalender.svg";
import ActionDeleteIcon from "components/Icons/ActionDelete.svg";
import {
  Undo,
} from "@mui/icons-material";

export const TodoActionsToolbar = ({selectedActions, selectedActionObjects, setInitialAction, setUpdatedAction, setOpenEditActionModal, setOpenCompleteActionModal, setOpenDueDateSetModal, setOpenDeleteActionModal, selectedDate, setSelectedDate }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const handleIconClick = (event) => {
    if (selectedActions.length > 0) {
      setAnchorEl(event.currentTarget);
      setOpenDatePicker(true);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenDatePicker(false);
  };

    return(
        <Box
            sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
            }}
        >
                    {/* EDIT */}
                    <Box
                        sx={{
                            display: "flex",
                            gap: "8px",
                            padding: "8px",
                            borderRadius: "8px",
                            alignItems: "center",
                            "&:hover": {
                            background: "#F5F7FF",
                            cursor:
                                selectedActions.length === 1
                                ? "pointer"
                                : "not-allowed",
                            },
                        }}
                        onClick={() => {
                            if (selectedActions.length === 1) {
                            setInitialAction(selectedActions[0]); // Set the initial action
                            setUpdatedAction(selectedActions[0]); // Set the updated action
                            setOpenEditActionModal(true); // Open the modal
                            }
                        }}
                    >
                        <EditIcon
                            color={selectedActions.length === 1 ? "black" : "#B6B6B6"} 
                            height={20}
                            width={20}
                        />
                        <Typography
                        sx={{
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            color:
                            selectedActions.length === 1
                                ? "black"
                                : "#B6B6B6",
                        }}
                        data-cy="edit-action-button"
                        >
                        Edit
                        </Typography>
                    </Box>

                    {/* MARK AS COMPLETED */}
                    <Box
                        sx={{
                          display: "flex",
                          gap: "8px",
                          padding: "8px",
                          borderRadius: "8px",
                          alignItems: "center",
                          "&:hover": {
                            background: "#F5F7FF",
                            cursor:
                              selectedActions.length > 0
                                ? "pointer"
                                : "not-allowed",
                          },
                        }}
                        onClick={() =>
                          selectedActions.length > 0 &&
                          setOpenCompleteActionModal(true)
                        }
                    >
                        <MarkAsCompletedIcon
                            color={selectedActions.length > 0 ? "black" : "#B6B6B6"} 
                            height={20}
                            width={20}
                        />
                        <Typography
                            sx={{
                                fontFamily: "Lexend",
                                fontSize: "12px",
                                color: (selectedActions.length > 0 ? "#000000" : "#B6B6B6"),
                            }}
                          data-cy="mark-as-completed-button"
                        >
                          Mark as Completed
                        </Typography>
                    </Box>

                    {/* DELETE */}
                    <Box
                        sx={{
                          display: "flex",
                          gap: "8px",
                          padding: "8px",
                          borderRadius: "8px",
                          alignItems: "center",
                          "&:hover": {
                            background: "#F5F7FF",
                            cursor:
                              selectedActions.length > 0
                                ? "pointer"
                                : "not-allowed",
                          },
                        }}
                        onClick={() =>
                          selectedActions.length > 0 &&
                          setOpenDeleteActionModal(true)
                        }
                    >
                        <ActionDeleteIcon
                          fill={selectedActions.length > 0 ? "black" : "#B6B6B6"}
                          height={20}
                          width={20}
                        />

                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            color:
                              selectedActions.length > 0 ? "black" : "#B6B6B6",
                          }}
                          data-cy="delete-action-button"
                        >
                          Delete
                        </Typography>
                    </Box>

                    {/* ASSIGN DUE DATE */}
                    <Box
                        sx={{
                          display: "flex",
                          gap: "8px",
                          padding: "8px",
                          borderRadius: "8px",
                          alignItems: "center",
                          "&:hover": {
                            background: "#F5F7FF",
                            cursor:
                              selectedActions.length > 0
                                ? "pointer"
                                : "not-allowed",
                          },
                        }}
                        onClick={handleIconClick}
                    >
                        <ActionCalendarIcon
                          color={selectedActions.length > 0 ? "black" : "#B6B6B6"}
                          height={20}
                          width={20}
                        />
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            color:
                              selectedActions.length > 0 ? "black" : "#B6B6B6",
                          }}
                        >
                          Assign Due Date
                        </Typography>
                    </Box>
                      {/* DATE PICKER POPOVER */}
                      <Popover
                        open={openDatePicker}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <Box sx={{ zIndex: 999 }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <StaticDatePicker
                              defaultValue={null}
                              onChange={(newValue) => {
                                setSelectedDate(newValue);
                                handleClose();
                                setOpenDueDateSetModal(true);
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                      </Popover>
        </Box>
    )
}


export const CompletedActionsToolbar = ({ selectedCompletedActions, selectedActionObjects, setInitialAction, setUpdatedAction, setOpenEditActionModal, setOpenUndoActionModal, setOpenDueDateSetModal, setOpenDeleteActionModal, selectedDate, setSelectedDate }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const handleIconClick = (event) => {
    if (selectedCompletedActions.length > 0) {
      setAnchorEl(event.currentTarget);
      setOpenDatePicker(true);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenDatePicker(false);
  };
  return(
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
            display: "flex",
            gap: "8px",
            padding: "8px",
            borderRadius: "8px",
            alignItems: "center",
            "&:hover": {
            background: "#F5F7FF",
            cursor:
            selectedCompletedActions.length === 1
                ? "pointer"
                : "not-allowed",
            },
        }}
        onClick={() => {
            if (selectedCompletedActions.length === 1) {
            setInitialAction(selectedCompletedActions[0]); // Set the initial action
            setUpdatedAction(selectedCompletedActions[0]); // Set the updated action
            setOpenEditActionModal(true); // Open the modal
            }
        }}
      >
          <EditIcon
              color={selectedCompletedActions.length === 1 ? "black" : "#B6B6B6"} 
              height={20}
              width={20}
          />
          <Typography
          sx={{
              fontFamily: "Lexend",
              fontSize: "12px",
              color:
              selectedCompletedActions.length === 1
                  ? "black"
                  : "#B6B6B6",
          }}
          data-cy="edit-action-button"
          >
          Edit
          </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "8px",
          padding: "8px",
          borderRadius: "8px",
          alignItems: "center",
          "&:hover": {
            background: "#F5F7FF",
            cursor:
              selectedCompletedActions.length > 0
                ? "pointer"
                : "not-allowed",
          },
        }}
        onClick={() =>
          selectedCompletedActions.length > 0 &&
          setOpenUndoActionModal(true)
        }
      >
        <Undo
          sx={{
            fill:
              selectedCompletedActions.length > 0
                ? "black"
                : "#B6B6B6",
          }}
          height={20}
          width={20}
        />
          <Typography
            sx={{
              fontFamily: "Lexend",
              fontSize: "12px",
              color:
                selectedCompletedActions.length > 0
                  ? "black"
                  : "#B6B6B6",
            }}
          >
            Mark as To-Do
          </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          padding: "8px",
          borderRadius: "8px",
          alignItems: "center",
          "&:hover": {
            background: "#F5F7FF",
            cursor:
              selectedCompletedActions.length > 0
                ? "pointer"
                : "not-allowed",
          },
        }}
        onClick={() =>
          selectedCompletedActions.length > 0 &&
          setOpenDeleteActionModal(true)
        }
      >
        <ActionDeleteIcon
          fill={
            selectedCompletedActions.length > 0
              ? "black"
              : "#B6B6B6"
          }
          height={20}
          width={20}
        />

        <Typography
          sx={{
            fontFamily: "Lexend",
            fontSize: "12px",
            color:
              selectedCompletedActions.length > 0
                ? "black"
                : "#B6B6B6",
          }}
        >
          Delete
        </Typography>
      </Box>

      {/* Assign Due Date*/}
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            padding: "8px",
            borderRadius: "8px",
            alignItems: "center",
            "&:hover": {
              background: "#F5F7FF",
              cursor:
                selectedCompletedActions.length > 0
                  ? "pointer"
                  : "not-allowed",
            },
          }}
          onClick={handleIconClick}
        >
          <ActionCalendarIcon
            color={
              selectedCompletedActions.length > 0
                ? "black"
                : "#B6B6B6"
            }
            height={20}
            width={20}
          />
          <Typography
            sx={{
              fontFamily: "Lexend",
              fontSize: "12px",
              color:
                selectedCompletedActions.length > 0
                  ? "black"
                  : "#B6B6B6",
            }}
          >
            Assign Due Date
          </Typography>
        </Box>
        {/* DATE PICKER POPOVER */}
        <Popover
          open={openDatePicker}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box sx={{ zIndex: 999 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDatePicker
                defaultValue={null}
                onChange={(newValue) => {
                  setSelectedDate(newValue);
                  handleClose();
                  setOpenDueDateSetModal(true);
                }}
              />
            </LocalizationProvider>
          </Box>
        </Popover>
    </Box>
  )
}