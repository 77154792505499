import React from 'react';

const AltercallLogoHorizontal = () => (
    <svg width="270" height="100" viewBox="0 0 1103 179" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M135.453 8.96326C167.425 40.8029 160.584 89.3572 132.166 112.693C116.26 85.3216 100.359 57.9601 84.3202 30.3613L83.8879 29.6174L83.4556 30.3613C75.4915 44.0648 67.5658 57.7032 59.6579 71.3113C51.6229 85.1379 43.6061 98.9331 35.5858 112.733C6.11475 88.1676 1.16607 39.0973 32.0622 9.1673L32.4219 8.81886L32.0729 8.45972C29.9666 6.29229 27.8576 4.12487 25.7513 1.96013L25.4508 1.65131L25.1172 1.30843L24.7599 1.62661C11.9384 13.0455 2.21829 33.1053 0.768371 52.4879L1.2339 52.5227L0.768369 52.4879C-1.32412 80.4812 8.85658 103.676 30.0212 122.305C20.4048 138.852 10.8113 155.362 1.15025 171.991L0.895474 172.43L1.33759 172.678C4.19881 174.287 6.93798 175.827 9.7903 177.433L10.22 177.675L10.4678 177.248C13.822 171.478 17.1703 165.718 20.5107 159.972C26.5959 149.503 32.6552 139.079 38.6769 128.722C68.9074 145.892 98.9119 145.892 129.096 128.72C138.444 144.802 147.858 160.997 157.308 177.251L157.556 177.678L157.985 177.436C159.452 176.611 160.883 175.805 162.303 175.005C163.683 174.228 165.053 173.456 166.435 172.678L166.877 172.43L166.623 171.991C162.219 164.413 157.834 156.866 153.46 149.338C148.218 140.317 142.992 131.322 137.768 122.331C178.596 89.8973 174.497 29.9622 142.939 1.74736L142.595 1.43974L142.261 1.75814C139.989 3.9232 137.723 6.08532 135.46 8.24745L135.089 8.60155L135.453 8.96326ZM83.8879 50.9189L90.346 62.0316C101.471 81.1752 112.514 100.176 123.551 119.172C102.709 133.635 69.4376 135.936 44.1754 119.255C49.9688 109.286 55.7551 99.3292 61.55 89.3573C68.9711 76.587 76.4062 63.7926 83.8879 50.9189Z" fill="black" stroke="black"/>
        <path d="M322.1 105.207L277.61 105.207L268.029 126.475L259.244 126.475L295.75 46.8557L304.075 46.8557L340.581 126.475L331.682 126.475L322.1 105.207ZM319.019 98.3814L299.855 55.6149L280.692 98.3814L319.019 98.3814Z" fill="black"/>
        <path d="M376.628 46.8557L385.072 46.8557L385.072 119.195L429.789 119.195L429.789 126.475L376.632 126.475L376.632 46.8557L376.628 46.8557Z" fill="black"/>
        <path d="M474.73 54.1355L446.668 54.1355L446.668 46.8557L511.236 46.8557L511.236 54.1355L483.174 54.1355L483.174 126.475L474.73 126.475L474.73 54.1355Z" fill="black"/>
        <path d="M603.977 119.195L603.977 126.475L547.511 126.475L547.511 46.8557L602.265 46.8557L602.265 54.1355L555.95 54.1355L555.95 82.4559L597.245 82.4559L597.245 89.6221L555.95 89.6221L555.95 119.195L603.977 119.195Z" fill="black"/>
        <path d="M701.166 126.475L682.913 100.882C680.86 101.109 678.807 101.223 676.526 101.223L655.082 101.223L655.082 126.475L646.639 126.475L646.639 46.8557L676.526 46.8557C696.832 46.8557 709.15 57.0902 709.15 74.1548C709.15 86.6674 702.531 95.4224 690.897 99.1757L710.402 126.475L701.161 126.475L701.166 126.475ZM700.71 74.1548C700.71 61.4152 692.267 54.1355 676.299 54.1355L655.082 54.1355L655.082 94.0564L676.299 94.0564C692.271 94.0564 700.71 86.6632 700.71 74.1506V74.1548Z" fill="black"/>
        <path d="M744.852 86.6664C744.852 63.3518 762.65 46.1738 786.716 46.1738C798.35 46.1738 808.847 50.0407 815.92 57.7744L810.558 63.1207C804.058 56.5218 796.07 53.6805 786.943 53.6805C767.666 53.6805 753.177 67.7861 753.177 86.6664C753.177 105.547 767.666 119.652 786.943 119.652C796.07 119.652 804.054 116.807 810.558 110.099L815.92 115.445C808.847 123.179 798.354 127.159 786.602 127.159C762.645 127.159 744.852 109.985 744.852 86.6664Z" fill="black"/>
        <path d="M904.788 105.207L860.298 105.207L850.717 126.475L841.932 126.475L878.438 46.8557L886.763 46.8557L923.269 126.475L914.37 126.475L904.788 105.207ZM901.707 98.3814L882.543 55.6149L863.38 98.3814L901.707 98.3814Z" fill="black"/>
        <path d="M959.316 46.8557L967.759 46.8557L967.759 119.195L1012.48 119.195L1012.48 126.475L959.32 126.475L959.32 46.8557L959.316 46.8557Z" fill="black"/>
        <path d="M1049.32 46.8557L1057.76 46.8557L1057.76 119.195L1102.48 119.195L1102.48 126.475L1049.32 126.475L1049.32 46.8557L1049.32 46.8557Z" fill="black"/>
    </svg>
);

export default AltercallLogoHorizontal;
