import axios from "axios";

/**
 * Fetch assessments data for the given user and company.
 * @param {Object} params - Parameters for the fetch request.
 * @param {Object} params.user - The user object containing user details.
 * @returns {Promise<Object>} - 
 */
export const fetchRecurringMeetings = async ({ user }) => {
  if (!user ) {
    console.warn("Missing user or userData for fetch recurring meetings");
    return null;
  }

  try {
    let response = [];
        // Fetch data for company context
        response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-recurring-meetings`,
          {
            params: {
              user_id: user?.sub,
            },
          }
        );
        console.log("res fetch rec: ", response)
        const recurring_meeting_data = response?.data?.recurring_meeting_data;
        console.log("res fetch recurring meetings: ", recurring_meeting_data)

    return {
        recurring_meeting_data
    };
  } catch (error) {
    console.error("Error fetching recurring meetings:", error);
    throw error; // Re-throw the error for handling in the calling function
  }
};
