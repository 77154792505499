import React, { memo } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import * as Frigade from "@frigade/react";

const WelcomeCard = ({
  loadingSum,
  loadingUserData,
  zoomStatus,
  loading,
  user,
  coachCallData,
  lastFileName,
  handleOpen,
  modal,
  sums,
}) => {
  const navigate = useNavigate();

  console.log("zoom status: ", zoomStatus);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: "40px",
        padding: "30px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        position: "relative",
        minHeight: "320px",
        background:
          "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
      }}
    >
      <Frigade.Checklist.Collapsible
        flowId="flow_HER5jgDw"
        style={{
          position: "fixed", // Makes the carousel fixed position relative to the viewport
          top: "50%", // Positions the top edge of the carousel at the center of the viewport
          left: "50%", // Positions the left edge of the carousel at the center of the viewport
          transform: "translate(-50%, -50%)", // Shifts the carousel back to truly center it
          maxWidth: "600px",
          maxHeight: "600px",
          width: "90%", // Responsive width, adjust as needed
          margin: "0", // Removes auto margin since it's now absolutely positioned
          boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)", // X=0, Y=5, Blur=15, and a subtle opacity for better effect
          border: "1px solid #ccc",
          borderRadius: "10px",
          overflow: "scroll",
          padding: "20px",
          zIndex: 1000, // High z-index to ensure it's on top of other content
          background: "white", // Ensures the content is readable by providing a solid background
          fontFamily: "Poppins",
        }}
      />
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      {!loading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            zIndex: 1,
            width: {
              xs: "100%",
              md: "100%",
            },
          }}
        >
        {!loading && (
          <>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: {
                  xs: "24px",
                },
                fontWeight: 300,
                color: "white",
                marginBottom: "8px",
                lineHeight: "1.2",
              }}
            >
              {(zoomStatus || loadingUserData) ? `Welcome back ${user?.given_name}!` : `Welcome ${user?.given_name}!`}
            </Typography>
            {(zoomStatus) && (
              <Typography
                sx={{
                  color: "white",
                  fontFamily: "Lexend",
                  fontSize: "14px",
                  fontWeight: 400,
                  marginBottom: "8px",
                  lineHeight: "1.2",
                }}
              >
                {loadingUserData ? "\u200B" : `Here’s what has happened in your recent activity!`}
              </Typography>
            )}
            {(!zoomStatus) && (
              <Typography
                sx={{
                  color: "white",
                  fontFamily: "Lexend",
                  fontSize: "14px",
                  fontWeight: 400,
                  marginBottom: "8px",
                  lineHeight: "1.2",
                }}
              >
                {loadingUserData ? "\u200B"  : `Finish setting up your account by logging into Zoom using the button below.`}
              </Typography>
            )}
          </>
        )}
          <Box
            sx={{
              display: "flex",
              gap: "30px",
              paddingY: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "2px",
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontFamily: "Poppins",
                  fontSize: "56px",
                  fontWeight: 300,
                  lineHeight: "normal",
                  padding: "0px",
                }}
              >
                {sums?.unprocessed_sum ?? 0}
              </Typography>
              <Box display="flex">
                <Typography
                  sx={{
                    color: "white",
                    fontFamily: "Lexend",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  Available to Generate
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontFamily: "Poppins",
                  fontSize: "56px",
                  fontWeight: 300,
                  lineHeight: "normal",
                  padding: "0px",
                }}
              >
                {sums?.unassigned_sum ?? 0}
              </Typography>
              <Box display="flex">
                <Typography
                  sx={{
                    color: "white",
                    fontFamily: "Lexend",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  Available to Share
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
                {(zoomStatus && !loadingUserData) && (
            <Box
              sx={{
                display: "flex",
                marginTop: "auto",
              }}
            >
              <Tooltip
                title={
                  !lastFileName ? "Please process a call to review it" : ""
                }
              >
                <Button
                  sx={{
                    display: " flex",
                    width: {
                      xs: "100%",
                      md: "220px",
                    },
                    padding: "10px 8px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: " 10px",
                    transition: "all 0.3s",
                    borderRadius: "52px",
                    border: "1px solid black",
                    marginBottom: "-15px",
                    borderColor:
                      (lastFileName === "" || loadingUserData)
                        ? "rgba(255, 255, 255, 0.18)"
                        : "#578FF2",
                    background: "white",
                    opacity: (!lastFileName === "" || loadingUserData) ? 0.3 : 1,
                    "&:hover": {
                      cursor: (!lastFileName  === "" || loadingUserData) ? "not-allowed" : "pointer",
                      background:
                        (!lastFileName === "" || loadingUserData)
                          ? "rgba(255, 255, 255, 0.18)"
                          : "black",
                      borderColor: "black",
                    },
                    "&:hover *": {
                      color: "white",
                    },
                  }}
                  disabled={loadingUserData}
                  onClick={() => {
                    if (lastFileName !== "")
                      navigate("/platform/coach/session/" + lastFileName);
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      color: "black",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      letterSpacing: "0.05em",
                      lineHeight: "18px",
                      textTransform: "capitalize",
                    }}
                  >
                    Review Latest Call
                  </Typography>
                </Button>
              </Tooltip>
            </Box>
          )}
          {(!zoomStatus && !loadingUserData) && (
            <Box
              sx={{
                display: "flex",
                marginTop: "auto",
              }}
            >
              <Button
                sx={{
                  display: " flex",
                  width: {
                    xs: "100%",
                    md: "220px",
                  },
                  padding: "10px 8px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: " 10px",
                  borderRadius: "52px",
                  border: "1px solid #578FF2",
                  borderColor: "#578FF2",
                  background: "white",
                  marginTop: "15px",
                  opacity: 1,
                  transition: "all 0.3s",
                  marginBottom: "-15px",
                  "&:hover": {
                    cursor: "pointer",
                    background: "black",
                    borderColor: "black",
                  },
                  "&:hover *": {
                    color: "white",
                  },
                }}
                onClick={handleOpen}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    color: "black",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    letterSpacing: "0.05em",
                    lineHeight: "18px",
                    textTransform: "capitalize",
                  }}
                >
                  Connect to Zoom
                </Typography>
              </Button>
            </Box>
          )}
      {modal}

    </Box>
  );
};

// Memoize the component
export default memo(WelcomeCard, (prevProps, nextProps) => {
  // Custom comparison logic for deep equality
  return (
    prevProps.loadingSum === nextProps.loadingSum &&
    prevProps.loading === nextProps.loading &&
    prevProps.zoomStatus === nextProps.zoomStatus &&
    prevProps.unprocessedCallsSum === nextProps.unprocessedCallsSum &&
    prevProps.unassignedCallsSum === nextProps.unassignedCallsSum &&
    prevProps.totalActionsSum === nextProps.totalActionsSum &&
    prevProps.lastFileName === nextProps.lastFileName &&
    prevProps.user?.given_name === nextProps.user?.given_name
  );
});