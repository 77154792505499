import axios from "axios";

/**
 * Fetch assessments data for the given user and company.
 * @param {Object} params - Parameters for the fetch request.
 * @param {Object} params.user - The user object containing user details.
 * @param {Object} params.userData - The user data object containing company information.
 * @returns {Promise<Object>} - The fetched assessments data including members, teams, and assessments.
 */
export const fetchLara = async ({ user, userData }) => {
  if (!user || !userData) {
    console.warn("Missing user or userData for fetchLara");
    return null;
  }

  try {
    let response = [];
    if(userData?.companyInformation) {
        // Fetch data for company context
        const apiResponse = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-data-for-lara-in-company-test`,
          {
            params: {
              user_id: user?.sub,
              company_id: userData?.companyInformation?.company_id
            },
          }
        );
  
        response = apiResponse.data.data;
      } else {
        // Fetch general LARA data
        const apiResponse = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-data-for-lara`,
          {
            params: {
              user_id: user?.sub,
            },
          }
        );
  
        response = apiResponse.data.data;
      }

    return {
        data: response
    };
  } catch (error) {
    console.error("Error fetching lara data:", error);
    throw error; // Re-throw the error for handling in the calling function
  }
};
