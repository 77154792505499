import React from "react";

function HelpCenter({ color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0005 10.5009L12.0005 14.5009M11.9925 6.99988L12.0025 6.99988M16.5745 18.2069C16.1009 18.2506 15.6609 18.4705 15.3415 18.8229C15.0075 19.1929 14.7315 19.6249 14.4315 20.0229C13.6075 21.1229 13.1945 21.6739 12.6885 21.8709C12.2456 22.0431 11.7543 22.0431 11.3115 21.8709C10.8055 21.6739 10.3925 21.1239 9.56749 20.0229C9.26949 19.6249 8.99249 19.1929 8.65749 18.8229C8.33857 18.4709 7.89939 18.2511 7.42649 18.2069C6.85549 18.1539 6.27649 18.2419 5.70649 18.1519C4.91381 18.0264 4.18122 17.6531 3.61373 17.0856C3.04624 16.5181 2.67299 15.7856 2.54749 14.9929C2.50049 14.6979 2.50049 14.3449 2.50049 13.6389L2.50049 9.07988C2.50049 6.95188 2.50049 5.88788 2.91449 5.07588C3.27901 4.36106 3.86045 3.77997 4.57549 3.41588C5.38849 2.99988 6.45249 2.99988 8.58049 2.99988L15.4205 2.99988C17.5475 2.99988 18.6115 2.99988 19.4245 3.41388C20.1392 3.77814 20.7202 4.35921 21.0845 5.07388C21.4995 5.88688 21.4995 6.95088 21.4995 9.07988L21.4995 13.6399C21.4995 14.3449 21.4995 14.6979 21.4525 14.9929C21.3272 15.7857 20.954 16.5186 20.3865 17.0862C19.819 17.6539 19.0863 18.0273 18.2935 18.1529C17.7235 18.2429 17.1455 18.1529 16.5735 18.2069L16.5745 18.2069Z"
        stroke="#111111"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default HelpCenter;