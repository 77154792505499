import React, { Suspense } from "react"
import {
  Box,
  Button,
  Divider,
  Typography,
  CircularProgress,
} from "@mui/material";
import ClientLimitReached from "components/ClientLimitReachedModal";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import { useSelector } from "react-redux";
import {
  CoachLaraFilter,
  CoachLaraFilterForClient,
} from "components/CoachLaraAnalytics/CoachLaraFilter";
import { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import * as Frigade from "@frigade/react";
import { checkUserGroupAlignment } from "helpers/userCognitoGroupCheck";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useQuery } from '@tanstack/react-query';
import { fetchLara } from "utils/fetchLara"

const CoachLaraPieCharts = React.lazy(() =>
  import("components/CoachLaraAnalytics/CoachLaraPieCharts")
);
const CoachLaraLineChart = React.lazy(() =>
  import("components/CoachLaraAnalytics/CoachLaraLineChart")
);

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */

const useUrlQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CoachLaraAnalytics = ({ user, userData }) => {
  const query = useUrlQuery();
  const queryKey = ["laraData", { userId: user?.sub, companyId: userData?.companyInformation?.company_id }];
  const clientNameQuery = query.get("clientName");
  const [laraChartFileData, setLaraChartFileData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);



  // Filters for LaraPies
  const [filterCoachLaraPiesItems, setFilterCoachLaraPiesItems] = useState([
    { key: "Coach", value: "none" },
  ]);
  const [filterCoachLaraPies, setFilterCoachLaraPies] = useState("none");
  const [filterClientLaraPiesItems, setFilterClientLaraPiesItems] = useState([
    { key: "Client", value: "none" },
  ]);
  const [filterClientLaraPies, setFilterClientLaraPies] = useState("none");

  // Filters for LaraLineChart
  const [filterCoachLaraLineItems, setFilterCoachLaraLineItems] = useState([
    { key: "Coach", value: "none" },
  ]);
  const [filterCoachLaraLine, setFilterCoachLaraLine] = useState("none");
  const [filterClientLaraLineItems, setFilterClientLaraLineItems] = useState([
    { key: "Client", value: "none" },
  ]);
  const [filterClientLaraLine, setFilterClientLaraLine] = useState("none");
  const navigate = useNavigate();

  // Use the query to fetch or retrieve cached assessments
  const { data, isLoading, error, refetch } = useQuery({
    queryKey,
    queryFn: () => fetchLara({ user, userData }),
    enabled: !!user && !!userData?.companyInformation?.company_id,
  });

  const laraData = data?.data?.call_data;

  const fetchCallData = async () => {
    setLoading(true);
  
    try {
      let response = [];
  
      // Check if user has company information
      if (userData?.companyInformation) {
        await checkUserGroupAlignment(
          user?.sub,
          user?.role,
          userData?.companyInformation?.company_id
        );
  
        // Fetch data for company context
        const apiResponse = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-data-for-lara-in-company-test`,
          {
            params: {
              user_id: user?.sub,
              company_id: userData?.companyInformation?.company_id,
            },
          }
        );
  
        response = apiResponse.data.data;
      } else {
        // Fetch general LARA data
        const apiResponse = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-data-for-lara`,
          {
            params: {
              user_id: user?.sub,
            },
          }
        );
  
        response = apiResponse.data.data;
      }
  
      // Update the chart data state
      setLaraChartFileData(
        response.map((item) => ({
          ...item,
          lara: {
            coach: item.coachScores, // Use new response structure directly
            client: item.clientScores,
          },
        }))
      );
  
      // Process and set unique coach names for filtering
      const coachItems = response
        .filter((row) => row.coach_name !== null)
        .map((row) => row.coach_name);
  
      const uniqueCoaches = [...new Set(coachItems)].sort(); // Sort alphabetically
      const formattedCoaches = uniqueCoaches.map((name) => ({
        key: name,
        value: name,
      }));
      setFilterCoachLaraPiesItems([
        { key: "Coach", value: "none" },
        ...formattedCoaches,
      ]);
      setFilterCoachLaraLineItems([
        { key: "Coach", value: "none" },
        ...formattedCoaches,
      ]);
  
      // Process and set unique client names for filtering
      const clientItems = response
        .filter((row) => row.client_name !== null) // Use 'client_name' field
        .map((row) => row.client_name);
  
      const uniqueClients = [...new Set(clientItems)].sort(); // Sort alphabetically
      const formattedClients = uniqueClients.map((name) => ({
        key: name,
        value: name,
      }));
  
      setFilterClientLaraPiesItems([
        { key: "Client", value: "none" },
        ...formattedClients,
      ]);
      setFilterClientLaraLineItems([
        { key: "Client", value: "none" },
        ...formattedClients,
      ]);
    } catch (error) {
      console.error("Error fetching LARA data:", error);
      toast.error("Failed to load LARA data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  
  
  // useEffect(() => {
  //   fetchCallData();
  // }, []);

// Memoize unique coaches and clients outside of useEffect
const coachItems = useMemo(() => {
  if (laraData)
    return [...new Set(laraData?.map((item) => item.coach_name).filter(Boolean))].sort();
}, [laraData]);

const participantItems = useMemo(() => {
  if (laraData) {
    // Extract participant names from participant_lara_scores
    const participantNames = laraData
      .flatMap((item) => item.participant_lara_scores.map((p) => p.participant_name))
      .filter(Boolean);
    return [...new Set(participantNames)].sort();
  }
}, [laraData]);


useEffect(() => {
  if (!laraData) return;

  // Set filters based on memoized data
  setFilterCoachLaraPiesItems([
    { key: 'Coach', value: 'none' },
    ...coachItems.map((name) => ({ key: name, value: name })),
  ]);

  setFilterClientLaraPiesItems([
    { key: 'Participant', value: 'none' },
    ...participantItems.map((name) => ({ key: name, value: name })),
  ]);

  setFilterCoachLaraLineItems([
    { key: 'Coach', value: 'none' },
    ...coachItems.map((name) => ({ key: name, value: name })),
  ]);

  setFilterClientLaraLineItems([
    { key: 'Participant', value: 'none' },
    ...participantItems.map((name) => ({ key: name, value: name })),
  ]);
}, [laraData, coachItems, participantItems]);



// Memoized filtered data for pies
const filteredLaraPiesFileData = useMemo(() => {
  if (!laraData) return [];

  let data = laraData;

  // Filter by coach name
  if (filterCoachLaraPies !== 'none') {
    data = data.filter(
      (item) => item.coach_name?.toLowerCase() === filterCoachLaraPies.toLowerCase()
    );
  }

  // Filter by participant name (from participant_lara_scores)
  if (filterClientLaraPies !== 'none') {
    data = data.filter((item) =>
      item.participant_lara_scores.some(
        (participant) =>
          participant.participant_name?.toLowerCase() === filterClientLaraPies.toLowerCase()
      )
    );
  }

  return data;
}, [laraData, filterCoachLaraPies, filterClientLaraPies]);


// Update the pies chart count
const piesChartLaraCount = useMemo(() => filteredLaraPiesFileData.length, [filteredLaraPiesFileData]);


// Memoized filtered data for line charts
const filteredLaraLineChartFileData = useMemo(() => {
  if (!laraData) return [];

  let data = laraData;

  // Filter by coach name
  if (filterCoachLaraLine !== 'none') {
    data = data.filter((item) => item.coach_name === filterCoachLaraLine);
  }

  // Filter by participant name (from participant_lara_scores)
  if (filterClientLaraLine !== 'none') {
    data = data.filter((item) =>
      item.participant_lara_scores.some(
        (participant) =>
          participant.participant_name === filterClientLaraLine
      )
    );
  }

  return data;
}, [laraData, filterCoachLaraLine, filterClientLaraLine]);


// Update the line chart count
const lineChartLaraCount = useMemo(() => filteredLaraLineChartFileData.length, [filteredLaraLineChartFileData]);


  const handleResetLaraPiesFilters = () => {
    setFilterCoachLaraPies("none");
    setFilterClientLaraPies("none");
  };
  
  const handleResetLaraChartFilters = () => {
    setFilterCoachLaraLine("none");
    setFilterClientLaraLine("none");
  };  

  const handleCoachLaraPiesFilterChange = (value) => {
    setFilterCoachLaraPies(value);
  };

  const handleClientLaraPiesFilterChange = (value) => {
    if (value === "by client") {
      setFilterClientLaraPies("by client");
    } else {
      setFilterClientLaraPies(value);
    }
  };

  const handleCoachLaraLineFilterChange = (value) => {
    setFilterCoachLaraLine(value);
  };

  const handleClientLaraLineFilterChange = (value) => {
    if (value === "by client") {
      setFilterClientLaraLine("by client");
    } else {
      setFilterClientLaraLine(value);
    }
  };

  const collapsed = useSelector((state) => state.layout.collapsed);

  const handleClick = () => {
    window.open(
      "/blog?title=Enhancing%20Communication%20and%20Coaching%20Through%20the%20Power%20of%20the%20LARA%20Framework&id=21#:~:text=The%20LARA%20score%20is%20calculated%20based",
      "_blank"
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDataLoaded(true);
    }, 250); 
  
    return () => clearTimeout(timer);
  }, []);
  

  return (
    <GlobalPageComponent user={user} userData={userData}>
      <ClientLimitReached user={user} userData={userData} />
      <Box
        sx={{
          backgroundColor: "#EFF3F6",
          width: "100%",
          height: "100%",
          padding: "16px",
          display: {
            xs: !collapsed ? "none" : "block",
          },
          overflowY: "auto",
        }}
      >
        <Frigade.Tour
          flowId="flow_OwRgEcEK"
          defaultOpen={true}
          sequential={true}
          dismissible={true}
          css={{
            ".fr-card": {
              backgroundColor: "#EFF3F6",
            },
            ".fr-button-primary": {
              backgroundColor: "white",
              color: "black",
              borderColor: "black",
              borderRadius: "50px",
              borderWidth: "1px",
              borderStyle: "solid",
              transition: "background-color 0.3s, color 0.3s",
              "&:hover": {
                backgroundColor: "black",
                color: "white",
              },
            },
          }}
        />
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "black",
              fontFamily: "Poppins",
              fontSize: "36px",
              fontWeight: 300,
              marginBottom: "16px",
            }}
            data-cy="lara-analytics-title"
          >
            LARA Analytics
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end", // Changed to place at extreme right
              marginRight: {
                xs: "unset",
                md: "200px",
              },
              zIndex: 1100,
              position: "relative",
              paddingBottom: "24px",
              alignSelf: {
                xs: "center",
                md: "unset",
              },
            }}
          >
            <Button
              sx={{
                display: "flex",
                width: {
                  xs: "100%",
                  lg: "220px",
                },
                padding: "10px 8px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                borderRadius: "52px",
                border: "1px solid black",
                borderColor: "black",
                background: "white",
                opacity: 1,
                transition: "all 0.3s",
                "&:hover": {
                  cursor: "pointer",
                  background: "black",
                  borderColor: "black",
                  color: "white",
                },
                "&:hover *": {
                  color: "white",
                },
                "&:hover svg *": {
                  stroke: "white",
                },
              }}
              onClick={handleClick}
              data-cy="learn-more-button"
            >
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontSize: "10px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  letterSpacing: "2px",
                  lineHeight: "1.5",
                  textTransform: "uppercase",
                }}
              >
                Learn More
              </Typography>
              {ArrowForwardBlack()}
            </Button>
          </Box>
        </Box> */}
        <Box
          sx={{
            background: "white",
            borderRadius: "30px",
            paddingX: {
              xs: "30px",
              md: "50px",
            },
            paddingY: {
              xs: "30px",
              md: "30px",
            },
            height: "100%",
            overflow: "auto",
            width: "100%",
            boxSizing: "border-box",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgb(207, 207, 207)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "rgb(207, 207, 207)",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: "28px",
                  fontFamily: "Poppins",
                  fontWeight: 300,
                }}
              >
                <span id="hint-anchor-0">Overall LARA Score</span>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontFamily: "Lexend",
                    fontSize: "16px",
                    marginRight: { xs: "0", md: "10px" },
                  }}
                >
                  Sessions: {piesChartLaraCount}
                </Typography>
                <span id="hint-anchor-1">
                  <CoachLaraFilter
                    value={filterCoachLaraPies}
                    items={filterCoachLaraPiesItems}
                    handleChange={(e) => handleCoachLaraPiesFilterChange(e)}
                  />
                </span>
                <CoachLaraFilterForClient
                  value={filterClientLaraPies}
                  items={filterClientLaraPiesItems}
                  handleChange={(e) => handleClientLaraPiesFilterChange(e)}
                  type="client"
                />

                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    color: "#FF5E00",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    borderRadius: "45px",
                    border: "1px solid black",
                    paddingY: "4px",
                    paddingX: "20px",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                    letterSpacing: "-0.32px",
                    lineHeight: "24px",
                    "&:hover": {
                      cursor: "pointer",
                      background: "#FF5E00",
                      borderColor: "#FF5E00",
                      color: "white",
                    },
                    "&:hover *": {
                      color: "white",
                    },
                    "&:hover svg *": {
                      stroke: "white",
                    },
                  }}
                  data-cy="reset-filters-button"
                  onClick={handleResetLaraPiesFilters}
                >
                  Reset Filters
                </Typography>
              </Box>
            </Box>
            <Suspense fallback={<CircularProgress />}>
              <CoachLaraPieCharts
              laraData={filteredLaraPiesFileData}
              loading={isLoading}
              filterCoachLaraPies={filterCoachLaraPies}
              filterClientLaraPies={filterClientLaraPies}
              />
            </Suspense>
            <Divider color={"#DDE5ED"} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: "28px",
                  fontFamily: "Poppins",
                  fontWeight: 300,
                }}
              >
                <span id="hint-anchor-2">Score Comparison</span>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontFamily: "Lexend",
                    fontSize: "16px",
                    marginRight: { xs: "0", md: "10px" },
                  }}
                >
                  <span id="hint-anchor-3">Sessions: {lineChartLaraCount}</span>
                </Typography>
                <CoachLaraFilter
                  value={filterCoachLaraLine}
                  items={filterCoachLaraLineItems}
                  handleChange={(e) => handleCoachLaraLineFilterChange(e)}
                />
                <CoachLaraFilterForClient
                  value={filterClientLaraLine}
                  items={filterClientLaraLineItems}
                  handleChange={(e) => handleClientLaraLineFilterChange(e)}
                  type="client"
                />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    color: "#FF5E00",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    borderRadius: "45px",
                    border: "1px solid black",
                    paddingY: "4px",
                    paddingX: "20px",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                    letterSpacing: "-0.32px",
                    lineHeight: "24px",
                    "&:hover": {
                      cursor: "pointer",
                      background: "#FF5E00",
                      borderColor: "#FF5E00",
                      color: "white",
                    },
                    "&:hover *": {
                      color: "white",
                    },
                    "&:hover svg *": {
                      stroke: "white",
                    },
                  }}
                  onClick={handleResetLaraChartFilters}
                >
                  Reset Filters
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "calc(100vh - 420px)",
                  }}
                >
                  <CircularProgress
                    sx={{
                      color: "black",
                    }}
                  />
                </Box>
              ) : isDataLoaded && filteredLaraLineChartFileData &&
                filteredLaraLineChartFileData.length > 0 ? (


                <Suspense fallback={<CircularProgress />}>
                <CoachLaraLineChart
                  laraData={filteredLaraLineChartFileData}
                  loading={isLoading}
                  filterCoachLaraLine={filterCoachLaraLine}
                  filterClientLaraLine={filterClientLaraLine}
                />
              </Suspense>
              ) : isDataLoaded ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    textAlign: "center",
                    gap: "20px",
                    margin: "auto",
                    padding: "90px",
                    minHeight: "calc(100vh - 620px)",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "21px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "32px", 
                    }}
                  >
                    No Data Available |{" "}
                    <span style={{ color: "var(--AlterCall-Blue, #0076BA)" }}>
                      Process a Session to Sync
                    </span>
                  </Typography>

                  <Typography
                    sx={{
                      color: "var(--Darkest-gray, #828282)",
                      fontFamily: "Lexend",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "18px", 
                      textAlign: "left",
                      textUnderlinePosition: "from-font",
                      textDecorationSkipInk: "none",
                    }}
                  >
                    Process a call by{" "}
                    <span
                      style={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        fontWeight: 600,
                        lineHeight: "18px",
                        textAlign: "left",
                        textUnderlinePosition: "from-font",
                        textDecorationSkipInk: "none",
                        color: "var(--Darkest-gray, #828282)",
                      }}
                    >
                      Loading
                    </span>{" "}
                    or{" "}
                    <span
                      style={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        fontWeight: 600,
                        lineHeight: "18px",
                        textAlign: "left",
                        textUnderlinePosition: "from-font",
                        textDecorationSkipInk: "none",
                        color: "var(--Darkest-gray, #828282)",
                      }}
                    >
                      Generating Insights
                    </span>{" "}
                    from the session list on your dashboard
                  </Typography>

                  <Button
                    sx={{
                      display: "flex",
                      width: {
                        xs: "100%",
                        lg: "209px",
                      },
                      height: "34px",
                      padding: "10px 15px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      borderRadius: "50px",
                      border: "1px solid #D9E4EC",
                      background: "white",
                      opacity: 1,
                      transition: "all 0.3s",
                      "&:hover": {
                        cursor: "pointer",
                        background: "black",
                        borderColor: "black",
                        color: "white",
                      },
                      "&:hover *": {
                        color: "white",
                      },
                      "&:hover svg *": {
                        stroke: "white",
                      },
                    }}
                    onClick={() => {
                      navigate("/platform/coach");
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        color: "black",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        letterSpacing: "0.05em",
                        lineHeight: "18px",
                        textTransform: "capitalize",
                      }}
                    >
                      return to Dashboard
                    </Typography>
                  </Button>
                </Box>
              ) : null}
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end", // Changed to place at extreme right
                marginRight: {
                  xs: "unset",
                  md: "0px",
                },
                zIndex: 1100,
                position: "relative",
                paddingBottom: "24px",
                alignSelf: {
                  xs: "center",
                  md: "unset",
                },
              }}
            >
              <Button
                sx={{
                  display: "flex",
                  width: {
                    xs: "100%",
                    lg: "220px",
                  },
                  padding: "10px 8px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  borderRadius: "52px",
                  border: "1px solid black",
                  borderColor: "black",
                  background: "white",
                  opacity: 1,
                  transition: "all 0.3s",
                  "&:hover": {
                    cursor: "pointer",
                    background: "black",
                    borderColor: "black",
                    color: "white",
                  },
                  "&:hover *": {
                    color: "white",
                  },
                  "&:hover svg *": {
                    stroke: "white",
                  },
                }}
                onClick={handleClick}
                data-cy="learn-more-button"
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    color: "black",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    letterSpacing: "0.05em",
                    lineHeight: "18px",
                    textTransform: "capitalize",
                  }}
                >
                  Learn More
                </Typography>
                {/* {ArrowForwardBlack()} */}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </GlobalPageComponent>
  );
};

export default CoachLaraAnalytics;
