import React, { useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Typography,
  Collapse,
  IconButton,
} from "@mui/material";

import ClientLimitReached from "components/ClientLimitReachedModal";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { generateClient } from "aws-amplify/data";
/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
import domains from "components/Assessments/TheBigFive/en/index";
import { toast } from "react-toastify";
import { PDFDocument, StandardFonts, rgb, degrees, PDFName, PageSizes } from "pdf-lib";
import { saveAs } from "file-saver";
import avatar from "assets/avatar.png";
import dayjs from "dayjs";
import SectionDropDownIcon from "components/Icons/SectionDropDownIcon.svg";
import { fetchAvatarUrl } from "utils/fetchAvatarUrl";
import {
  verticalLineSvg,
  backgroundSvg,
  logoSvg3,
  gradientEllipseSvg,
  learnMoreSvg,
  vectorFooterwhiteSvg,
  page2BackgroundSvg,
  Namesvg,
  vectorFooterBlackSvg,
  newSvg,
  generatePieCircleSvg,
  gradientSvg,
  logoSvg,
} from "components/Icons/AssessmentReportSvg.svg";
import {
  personalityGuidelines,
  personalityLowsGuidelines,
} from "pages/personalityGuidelines";

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });

const CoachIndividualBreakdown = ({ user, userData }) => {
  const navigate = useNavigate();
  const collapsed = useSelector((state) => state.layout.collapsed);
  const tagNames = [
    "General",
    "Openness",
    "Conscientiousness",
    "Extraversion",
    "Agreeableness",
    "Neuroticism",
  ];
  const [selectedTag, setSelectedTag] = useState(tagNames[0]);
  const [loading, setLoading] = useState(false);
  const [coacheeData, setCoacheeData] = useState([]);
  const [assessmentData, setAssessmentData] = useState([]);
  const [loadingPDF, setLoadingPDF] = useState(false);

  const [openSections, setOpenSections] = useState(null);

  const { testId, userId, teamId, tab } = useParams();

  // Function to handle opening/closing sections
  const handleToggle = (section) => {
    setOpenSections((prevSection) =>
      prevSection === section ? null : section
    );
  };

  useEffect(() => {
    if (tab) {
      setSelectedTag(tab);
    }
  }, [tab]);

  const fetchCoacheeData = async () => {
    setLoading(true);
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-client-data`,
        {
          params: {
            client_id: userId,
            coach_id: user?.sub,
          },
        }
      );

      const { data: assessmentRes, errors } =
        await resourceClient.models.assessments.get({
          id: testId,
        });

      if (!response.data || !assessmentRes) {
        setLoading(false);
        return;
      }

      // Parse the results field
      const results = JSON.parse(assessmentRes.results);
      setAssessmentData({ ...assessmentRes, results });

      const data = response.data.client_data;
      // Fetch and assign the avatar URL if it exists
      const avatarUrl = data.avatar ? await fetchAvatarUrl(data.avatar) : null;

      data.avatar = avatarUrl;
      setCoacheeData(data);
    } catch (error) {
      console.error("Error fetching client data:", error);
    } finally {
      setLoading(false);
    }
  };

  const [assessmentReady, setAssessmentReady] = useState(false);

  useEffect(() => {
    fetchCoacheeData();
  }, []);

  useEffect(() => {
    const updateSub = resourceClient.models.assessments
      .onUpdate({
        filter: {
          user_id: {
            contains: `${user?.sub}`,
          },
        },
      })
      .subscribe({
        authMode: "userPool",
        next: (data) => {

          // Check if the required fields are updated
          const updatedFields = data.element.updatedFields || [];
          const requiredFields = [
            "summary",
            "communication_style",
            "communication_tips",
          ];

          if (requiredFields.every((field) => updatedFields.includes(field))) {
            toast.success("Assessment Analysis ready", {
              duration: 3000,
              position: "top-center",
            });
            setAssessmentReady(true);
          }
        },
        error: (error) => console.warn("Subscription Error:", error),
      });

    // Clean up on component unmount
    return () => updateSub.unsubscribe();
  }, [user?.sub]);

  useEffect(() => {
    if (assessmentReady) {
      fetchCoacheeData();
      setAssessmentReady(false);
    }
  }, [assessmentReady]);

  // Find the domain based on the selectedTag
  const selectedDomain = domains.find((domain) => domain.title === selectedTag);

  // Get the result for the selected domain
  const domainResult = assessmentData?.results?.[selectedDomain?.domain];

  const scaledScore = (facetScore, count) => {
    const maxScore = count * 5;
    return (facetScore / maxScore) * 10;
  };

  const domainColors = {
    G: "#000000", //General
    N: "#9865CF", // Neuroticism
    O: "#EF406E", // Openness to Experience
    A: "#F3AB54", // Agreeableness
    E: "#337BE8", // Extraversion
    C: "#49CA7A", // Conscientiousness
  };

  const facetColors = {
    0: "#9865CF",
    1: "#337BE8",
    2: "#EF406E",
    3: "#F3AB54",
    4: "#49CA7A",
    5: "#000000",
  };

  const domainOrder = ["O", "C", "E", "A", "N"];
  // Generate the PDF report
  const downloadPDF = async () => {
    console.log("Generating PDF report...");
    let name = coacheeData?.full_name || "Coachee";
    console.log("Generating PDF report for:", name);
    const pdfDoc = await PDFDocument.create();
    try {
      console.log("user:", user);
      console.log("domaindata:", domains);
      console.log("assessmentData:", assessmentData);
      console.log("coacheeData:", coacheeData);
      console.log("domainResult:", domainResult);
      console.log("selectedDomain:", selectedDomain);
      console.log("selectedTag:", selectedTag);
      console.log("scaledScore:", scaledScore);

      //page1
      const page1 = pdfDoc.addPage([1500, 1974]); // Custom size
      const svgToPng = async (svg) => {
        const svgBlob = new Blob([svg], { type: "image/svg+xml" });
        const url = URL.createObjectURL(svgBlob);
        const img = new Image();
        img.src = url;
        await new Promise((resolve) => (img.onload = resolve));
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        URL.revokeObjectURL(url);
        return canvas.toDataURL("image/png");
      };

      //page 1 background color:
      const backgroundPng = await svgToPng(backgroundSvg);
      const backgroundImage = await pdfDoc.embedPng(backgroundPng);

      page1.drawImage(backgroundImage, {
        x: 0,
        y: 0,
        width: 1500,
        height: 1974,
      });

      const logoPng = await svgToPng(logoSvg3);
      const logoImage3 = await pdfDoc.embedPng(logoPng);

      page1.drawImage(logoImage3, {
        x: 191,
        y: page1.getHeight() - 150 - 75, // Adjusted for top position
        width: 527,
        height: 101,
        opacity: 1,
      });
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
      const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
      const gradientEllipsePng = await svgToPng(gradientEllipseSvg);
      const gradientEllipseImage = await pdfDoc.embedPng(gradientEllipsePng);

      page1.drawImage(gradientEllipseImage, {
        x: 5,
        y: page1.getHeight() - 404 - 1750, // Adjusted for top position
        width: 1495,
        height: 1474,
        opacity: 1,
      });
      const customdate = assessmentData?.updatedAt
        ? dayjs(assessmentData?.updatedAt).format("MMMM D, YYYY")
        : "";
      console.log("customdate:", customdate);
      page1.drawText(`${customdate}`, {
        x: 194,
        y: page1.getHeight() - 440,
        size: 32,
        color: rgb(1, 1, 1),
        fontFamily: "lexend",
        width: 247,
        height: 30,
        lineHeight: 35, // Updated line height
        fontWeight: 300,
        textAlign: "left",
        opacity: 1, // Updated opacity to 1
      });

      page1.drawText(`${name}`, {
        x: 194, // Adjusted to match the left position
        y: page1.getHeight() - 525, // Adjusted for top position
        size: 68,
        fontFamily: "lexend",
        color: rgb(1, 1, 1),
        width: 170, // Adjusted to match the width
        height: 30, // Adjusted to match the height
        lineHeight: 30,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1, // Adjusted to match the opacity
      });
      // Draw the text "from" in white color
      page1.drawText(`The O.C.E.A.N.`, {
        x: 194,
        y: page1.getHeight() - 750, // Adjusted for top position
        size: 120,
        color: rgb(1, 1, 1), // White color
        fontFamily: "Poppins",
        width: 1066,
        height: 300,
        lineHeight: 127, // Updated line height
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });

      // Draw the text "callName" in orange color
      page1.drawText(`Assessment`, {
        x: 194,
        y: page1.getHeight() - 750 - 120, // Adjusted for top position
        size: 120,
        color: rgb(1, 94 / 255, 0), // Orange color
        fontFamily: "Poppins",
        lineHeight: 127, // Updated line height
        fontWeight: 300, // Updated font weight
        textAlign: "left",
        opacity: 1,
      });
      page1.drawText(`Report`, {
        x: 194,
        y: page1.getHeight() - 750 - 240, // Adjusted for top position
        size: 120,
        color: rgb(1, 94 / 255, 0), // Orange color
        fontFamily: "Poppins",
        lineHeight: 127, // Updated line height
        fontWeight: 300, // Updated font weight
        textAlign: "left",
        opacity: 1,
      });

      const clientAvatarUrl = coacheeData.avatar || avatar;
      console.log("Client avatar URL:", clientAvatarUrl);

      // Draw the "Learn More Here" rectangle

      const learnMorePng = await svgToPng(learnMoreSvg);
      const learnMoreImage = await pdfDoc.embedPng(learnMorePng);
      page1.drawImage(learnMoreImage, {
        x: 190,
        y: page1.getHeight() - 1492,
        width: 273,
        height: 58,
        opacity: 1,
        flexShrink: 0,
      });

      // Draw the "Learn More Here" text
      page1.drawText("Learn More Here", {
        x: 230,
        y: page1.getHeight() - 1470, // Adjusted to center the text vertically
        size: 24,
        color: rgb(1, 1, 1), // White color
        width: 273,
        height: 58,
        lineHeight: 35,
        fontWeight: 500,
        textAlign: "center",
        opacity: 1,
        fontFamily: "Poppins",
      });

      // Add a hyperlink to the "Learn More Here" text
      const linkAnnotation = pdfDoc.context.obj({
        Type: "Annot",
        Subtype: "Link",
        Rect: [
          190,
          page1.getHeight() - 1492,
          190 + 273,
          page1.getHeight() - 1492 + 58,
        ],
        Border: [0, 0, 0],
        A: {
          Type: "Action",
          S: "URI",
          URI: "https://altercall.com/aai",
        },
      });

      // Add the annotation to the page's annotations array
      page1.node.set(
        PDFName.of("Annots"),
        pdfDoc.context.obj([linkAnnotation])
      );

      const vectorFooterwhitePng = await svgToPng(vectorFooterwhiteSvg);
      const vectorFooterwhiteImage = await pdfDoc.embedPng(
        vectorFooterwhitePng
      );

      const page2 = pdfDoc.addPage([1500, 1974]); // Custom size
      page2.drawRectangle({
        x: 0,
        y: 0,
        width: page2.getWidth(),
        height: page2.getHeight(),
        color: rgb(0, 0, 0),
        opacity: 1,
      });

      const page2BackgroundPng = await svgToPng(page2BackgroundSvg);
      const page2BackgroundImage = await pdfDoc.embedPng(page2BackgroundPng);

      // Draw the gradient background from y = 0 to y = 1861
      page2.drawImage(page2BackgroundImage, {
        x: 0,
        y: page2.getHeight() - 1861, // Start from the top
        width: page2.getWidth(), // Full width of the page
        height: 1861, // Height of 1861
        opacity: 1, // Set opacity to 1
      });
      

      // Draw a white rectangle for the rest of the page
      page2.drawRectangle({
        x: 0,
        y: 0, // Start from the bottom
        width: page2.getWidth(), // Full width of the page
        height: page2.getHeight() - 1863, // Height from y = 0 to y = 1040
        color: rgb(1, 1, 1), // White color
      });
      page2.drawImage(logoImage3, {
        x: 80,
        y: page2.getHeight() - 33 - 44, // Adjusted for top position
        width: 229,
        height: 44,
        opacity: 1, // Adjusted opacity
      });

      const png = await svgToPng(Namesvg);
      const image = await pdfDoc.embedPng(png);

      page2.drawImage(image, {
        x: 392,
        y: page2.getHeight() - 33 - 44,
        width: 192,
        height: 40,
        flexShrink: 0,
      });

      // Draw client name
      page2.drawText(`${name}`, {
        x: 430, // Adjusted to match the left position
        y: page2.getHeight() - 33 - 30, // Adjusted for top position
        size: 18,
        font: helveticaFont, // Assuming Lexend is not available in PDFKit, using helveticaFont
        color: rgb(0, 0, 0), // var(--Colors-Grey-Black, #000)
        width: 170, // Adjusted to match the width
        height: 30, // Adjusted to match the height
        lineHeight: 30, // normal
        fontWeight: 300,
        textAlign: "left",
        opacity: 1, // Adjusted to match the opacity
      });
      // Draw the custom date with the specified layout
      page2.drawText(`${customdate}`, {
        x: 1273,
        y: page2.getHeight() - 45 - 19, // Adjusted for top position
        size: 15,
        color: rgb(1, 1, 1),
        width: 155,
        height: 19,
        lineHeight: 19, // Updated line height
        fontWeight: 300,
        textAlign: "left",
        opacity: 1, // Updated opacity to 1
        fontFamily: "Lexend",
      });
      // Draw the "Report Overview" text
      // Define the spacing between each heading
      // Text wrap function
      const wrapText = (text, maxWidth, fontSize, font) => {
        const words = text.split(" ");
        let lines = [];
        let currentLine = words[0];

        for (let i = 1; i < words.length; i++) {
          const word = words[i];
          const width = font.widthOfTextAtSize(
            currentLine + " " + word,
            fontSize
          );
          if (width < maxWidth) {
            currentLine += " " + word;
          } else {
            lines.push(currentLine);
            currentLine = word;
          }
        }
        lines.push(currentLine);
        return lines;
      };

      // Draw the "Assessment Summary" text
      page2.drawText("Assessment Summary", {
        x: 115,
        y: page2.getHeight() - 150 - 60, // Adjusted for top position
        size: 52,
        color: rgb(1, 1, 1), // White color
        fontFamily: "Poppins",
        width: 540,
        height: 75,
        lineHeight: 75,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });

      const reportoverview =
        assessmentData?.summary || "The summary is being generated.";
      const reportFontSize = 20;
      const reportMaxWidth = 1200; // Updated width according to layout
      let reportLines = wrapText(
        reportoverview,
        reportMaxWidth,
        reportFontSize,
        helveticaFont
      );

      // Calculate the total height needed for the report overview text
      let reportTotalHeight = reportLines.length * reportFontSize * 1.2;
      // Adjust the y position dynamically based on the total height
      let reportCurrentY = page2.getHeight() - 150 - 110; // Updated top position according to layout

      reportLines.forEach((line, index) => {
        page2.drawText(line, {
          x: 115, // Updated left position according to layout
          y: reportCurrentY - index * reportFontSize * 1.2,
          size: 20,
          fontFamily: "lexend",
          color: rgb(1, 1, 1),
          width: reportMaxWidth,
          height: 97, // Updated height according to layout
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        });
      });

      // Draw the "Communication Report" text
      page2.drawText("Communication Report", {
        x: 115,
        y: reportCurrentY - reportTotalHeight - 100, // Adjusted for equal spacing
        size: 52,
        color: rgb(1, 1, 1), // White color
        fontFamily: "Poppins",
        width: 490,
        height: 75,
        lineHeight: 75,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });

      const summary =
        assessmentData?.communication_tips ||
        "The Communication Report is being generated.";
      const summaryFontSize = 20;
      const summaryMaxWidth = 1200;
      let summaryLines = wrapText(
        summary,
        summaryMaxWidth,
        summaryFontSize,
        helveticaFont
      );

      let summaryCurrentY = reportCurrentY - reportTotalHeight - 160; // Adjusted top position according to layout
      let summaryTotalHeight = summaryLines.length * summaryFontSize * 1.2;

      summaryLines.forEach((line, index) => {
        page2.drawText(line, {
          x: 115, // Updated left position according to layout
          y: summaryCurrentY - index * summaryFontSize * 1.2,
          size: summaryFontSize,
          fontFamily: "lexend",
          color: rgb(1, 1, 1),
          width: summaryMaxWidth,
          height: 409, // Updated height according to layout
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        });
      });

      let comm_style =
        assessmentData?.communication_style ||
        "The communication style is being generated.";
      page2.drawText("Communication Style", {
        x: 115,
        y: summaryCurrentY - summaryTotalHeight - 100, // Adjusted for equal spacing
        size: 52,
        color: rgb(1, 1, 1), // White color
        fontFamily: "Poppins",
        width: 490,
        height: 75,
        lineHeight: 75,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });

      const comm_styleFontSize = 20;
      const commStyleMaxWidth = 1200;
      let commStyleLines = wrapText(
        comm_style,
        commStyleMaxWidth,
        comm_styleFontSize,
        helveticaFont
      );

      let commStyleCurrentY = summaryCurrentY - summaryTotalHeight - 160; // Adjusted top position according to layout
      let commStyleTotalHeight =
        commStyleLines.length * comm_styleFontSize * 1.2;

      commStyleLines.forEach((line, index) => {
        page2.drawText(line, {
          x: 115, // Updated left position according to layout
          y: commStyleCurrentY - index * comm_styleFontSize * 1.2,
          size: comm_styleFontSize,
          fontFamily: "lexend",
          color: rgb(1, 1, 1),
          width: commStyleMaxWidth,
          height: 409, // Updated height according to layout
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        });
      });

      // Check if the communication preference is available
      let comm_pref =
        assessmentData?.communication_preference ||
        "The communication preference is being generated.";

      // Draw "Communication Preference" heading
      page2.drawText("Communication Preference", {
        x: 115,
        y: commStyleCurrentY - commStyleTotalHeight - 100, // Adjusted for equal spacing
        size: 52,
        color: rgb(1, 1, 1), // Black color
        fontFamily: "Poppins",
        width: 490,
        height: 75,
        lineHeight: 75,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });

      // Wrap the communication preference text
      const commPrefFontSize = 20;
      const commPrefMaxWidth = 1200;
      let commPrefLines = wrapText(
        comm_pref,
        commPrefMaxWidth,
        commPrefFontSize,
        helveticaFont
      );

      // Adjust top position according to layout
      let commPrefCurrentY = commStyleCurrentY - commStyleTotalHeight - 160;
      let commPrefTotalHeight = commPrefLines.length * commPrefFontSize * 1.2;

      // Draw the communication preference text
      commPrefLines.forEach((line, index) => {
        page2.drawText(line, {
          x: 115, // Updated left position according to layout
          y: commPrefCurrentY - index * commPrefFontSize * 1.2,
          size: commPrefFontSize,
          fontFamily: "Lexend",
          color: rgb(1, 1, 1),
          width: commPrefMaxWidth,
          height: 409, // Updated height according to layout
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        });
      });

      // Draw the text "Learn more about L.A.R.A at www.altercall.com/aai"
      page2.drawText("Learn more about at", {
        x: 630,
        y: page2.getHeight() - 1933,
        size: 20,
        color: rgb(0, 0, 0),
        fontFamily: "lexend",
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
      });

      // Draw the clickable link "www.altercall.com/aai"
      page2.drawText("www.altercall.com/aai", {
        x: 825,
        y: page2.getHeight() - 1933,
        size: 20,
        color: rgb(0, 118 / 255, 186 / 255), // #0076BA color
        fontFamily: "lexend",
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
        underline: true,
      });

      page2.drawText("02", {
        x: 1437,
        y: page2.getHeight() - 1933,
        size: 18,
        color: rgb(1, 94 / 255, 0), // Orange color (#FF5E00)
        width: 18,
        height: 19,
        opacity: 1,
      });

      const vectorFooterBlackPng = await svgToPng(vectorFooterBlackSvg);
      const vectorFooterBlackImage = await pdfDoc.embedPng(
        vectorFooterBlackPng
      );

      page2.drawImage(vectorFooterBlackImage, {
        x: 54,
        y: page2.getHeight() - 1933,
        width: 199,
        height: 38,
        opacity: 1,
      });

      //new page
      const page3 = pdfDoc.addPage([1500, 1974]); // Custom size

      const verticalLinePng = await svgToPng(verticalLineSvg);
      const verticalLineImage = await pdfDoc.embedPng(verticalLinePng);

      page3.drawImage(verticalLineImage, {
        x: 0,
        y: page3.getHeight() - 1863, // Start from the top
        width: 340,
        height: 1863,
        opacity: 1,
      });
      //logo
      page3.drawImage(logoImage3, {
        x: 53,
        y: page3.getHeight() - 33 - 44, // Adjusted for top position
        width: 229,
        height: 44,
        opacity: 1, // Adjusted opacity
      });

      const newImagePng = await svgToPng(newSvg);
      const newImage = await pdfDoc.embedPng(newImagePng);

      page3.drawImage(newImage, {
        x: 392,
        y: page3.getHeight() - 33 - 44,
        width: 192,
        height: 40,
        flexShrink: 0,
      });

      // Draw client name
      page3.drawText(`${name}`, {
        x: 430, // Adjusted to match the left position
        y: page3.getHeight() - 33 - 30, // Adjusted for top position
        size: 18,
        font: helveticaFont, // Assuming Lexend is not available in PDFKit, using helveticaFont
        color: rgb(0, 0, 0), // var(--Colors-Grey-Black, #000)
        width: 170, // Adjusted to match the width
        height: 30, // Adjusted to match the height
        lineHeight: 30, // normal
        fontWeight: 300,
        textAlign: "left",
        opacity: 1, // Adjusted to match the opacity
      });
      // Draw the custom date with the specified layout
      page3.drawText(`${customdate}`, {
        x: 1273,
        y: page3.getHeight() - 45 - 19, // Adjusted for top position
        size: 15,
        color: rgb(0, 0, 0),
        width: 155,
        height: 19,
        lineHeight: 19, // Updated line height
        fontWeight: 300,
        textAlign: "left",
        opacity: 1, // Updated opacity to 1
        fontFamily: "Lexend",
      });
      // Draw the text "O.C.E.A.N. Traits" with the specified styles
      page3.drawText("O.C.E.A.N. Traits", {
        x: 417,
        y: page3.getHeight() - 185, // Adjusted for top position and line height
        size: 67,
        color: rgb(0, 0, 0),
        fontFamily: "Poppins",
        lineHeight: 75,
        fontWeight: 300,
        textAlign: "left",
        opacity: 0.8,
      });
      const text1 =
        "This page offers a detailed explanation of these sub-scores, shedding light on how they shape your behaviors, preferences, & interactions in various settings:";
      const text2 =
        " Openness, Conscientiousness, Extraversion, Agreeableness, and Neuroticism.";

      // Define the layout properties
      const layout = {
        width: 916, // Total width for the text block
        height: 201, // Height of the block
        top: 250, // Starting position from the top
        left: 417, // Left margin for alignment
        gap: 0, // No gap between text1 and text2
        opacity: 0.7, // Text opacity
        fontFamily: "Lexend", // Font family
        fontSize: 32, // Font size for both texts
        fontWeight: 300, // Regular font weight for text1
        lineHeight: 42, // Line height for both texts
        letterSpacing: -0.96, // Letter spacing for both texts
        textAlign: "left", // Left aligned
      };

      // Wrap the text using the wrapText function
      const text1Lines = wrapText(
        text1,
        layout.width,
        layout.fontSize,
        helveticaFont
      );
      const text2Lines = wrapText(
        text2,
        layout.width,
        layout.fontSize,
        helveticaFont
      );

      // Draw text1 with black color
      let text1Y = page3.getHeight() - layout.top;
      text1Lines.forEach((line, index) => {
        page3.drawText(line, {
          x: layout.left,
          y: text1Y - index * layout.lineHeight,
          size: layout.fontSize,
          frontfamily: "Lexend",
          color: rgb(0, 0, 0), // Black color for text1
          lineHeight: layout.lineHeight,
          fontWeight: layout.fontWeight, // Regular weight
          textAlign: layout.textAlign,
          opacity: layout.opacity,
          letterSpacing: layout.letterSpacing,
        });
      });

      // Get the width of the last line of text1
      const lastLine = text1Lines[text1Lines.length - 1];
      let lastLineWidth = helveticaFont.widthOfTextAtSize(
        lastLine,
        layout.fontSize
      ); // Width of the last line of text1

      // Calculate how much of text2 can fit on the same line
      let remainingWidth = layout.width - lastLineWidth; // Remaining width on the last line of text1
      let currentText = ""; // To store the portion of text2 that fits
      let index = 0; // Index for characters in text2

      // Loop to calculate the portion of text2 that fits on the same line
      while (
        index < text2.length &&
        helveticaFont.widthOfTextAtSize(
          currentText + text2[index],
          layout.fontSize
        ) < remainingWidth
      ) {
        currentText += text2[index];
        index++;
      }

      // Draw the portion of text2 that fits on the same line
      if (currentText) {
        page3.drawText(currentText, {
          x: layout.left + lastLineWidth,
          y: text1Y - (text1Lines.length - 1) * layout.lineHeight, // Same line as the last line of text1
          size: layout.fontSize,
          fontFamily: "Lexend",
          color: rgb(0, 0, 0), // Blue color for text2
          lineHeight: layout.lineHeight,
          fontWeight: "700px", // Bold font for text2
          textAlign: layout.textAlign,
          opacity: 1,
          letterSpacing: layout.letterSpacing,
        });
        text2Lines.shift(); // Remove the first line as it is already drawn
      }

      // Draw the remaining part of text2 on the next line with consistent line height
      let remainingText = text2.substring(index); // Get the remaining part of text2 that doesn't fit

      if (remainingText) {
        page3.drawText(remainingText, {
          x: layout.left, // Start from the left
          y: text1Y - text1Lines.length * layout.lineHeight, // Same spacing as other lines
          size: layout.fontSize,
          fontFamily: "Lexend",
          color: rgb(0, 0, 0), // Blue color for text2
          lineHeight: layout.lineHeight,
          fontWeight: 700, // Bold font for text2
          textAlign: layout.textAlign,
          opacity: 1,
          letterSpacing: layout.letterSpacing,
        });
      }
      // Draw the horizontal line with the specified properties
      page3.drawRectangle({
        x: 340,
        y: page3.getHeight() - 438, // Adjusted for top position
        width: 1160,
        height: 0,
        color: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
        borderWidth: 2,
        borderColor: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
        opacity: 1,
      });
      const domainColors = {
        Openness: rgb(239 / 255, 64 / 255, 110 / 255), // Openness to Experience
        Conscientiousness: rgb(73 / 255, 202 / 255, 122 / 255), // Conscientiousness
        Extraversion: rgb(51 / 255, 123 / 255, 232 / 255), // Extraversion
        Agreeableness: rgb(243 / 255, 171 / 255, 84 / 255), // Agreeableness
        Neuroticism: rgb(152 / 255, 101 / 255, 207 / 255), // Neuroticism
      };

      // Define domain order
      const domainOrder = [
        "Openness",
        "Conscientiousness",
        "Extraversion",
        "Agreeableness",
        "Neuroticism",
      ];

      // Mapping between full trait names and their corresponding keys in `assessmentData.results`
      const domainMapping = {
        Openness: "O",
        Conscientiousness: "C",
        Extraversion: "E",
        Agreeableness: "A",
        Neuroticism: "N",
      };
      const traitDefinitions = {
        Openness:
          "This trait reflects how open-minded, imaginative, and curious someone is. People who score high in Openness are typically creative, curious, and interested in novel experiences, while those with low scores may prefer routine, structure, and familiarity.",
        Conscientiousness:
          "This trait reflects an individual’s level of organization, reliability, and work ethic. High Conscientiousness individuals are disciplined, detail-oriented, and goal-focused. Low Conscientiousness individuals may be more spontaneous, flexible, and less detail-oriented.",
        Extraversion:
          "This trait reflects the degree to which a person is outgoing, energetic, and sociable. High Extraversion individuals are sociable, talkative, and energetic. Low Extraversion individuals are more reserved, introverted, and may prefer solitude.",
        Agreeableness:
          "This trait reflects an individual’s level of empathy, cooperativeness, and social harmony. High Agreeableness individuals are kind, empathetic, and eager to avoid conflict. Low Agreeableness individuals tend to be more competitive, critical, or skeptical.",
        Neuroticism:
          "Neuroticism refers to an individual’s emotional stability and susceptibility to stress. High Neuroticism individuals are more prone to anxiety, mood swings, and negative emotions, while low Neuroticism individuals tend to be calm, resilient, and emotionally stable.",
      };

      // Starting point for drawing the Score Overview section
      let startX = 417;
      let startY = page3.getHeight() - 530; // Adjust the Y position as needed based on page layout
      const circleRadius = 15.7956; // Radius for the filled and empty circles
      const circleGap = 12; // Gap between circles
      const textSpacing = 20; // Space between the trait title, circles, and score text
      // Draw each trait
      domainOrder.forEach((domainTitle) => {
        const domainKey = domainMapping[domainTitle]; // Map full trait name to corresponding key ('A', 'C', etc.)
        const domainColor = domainColors[domainTitle] || rgb(0, 0, 0);
        const domainData = assessmentData.results[domainKey] || {}; // Use the mapped key to access the score
        console.log("domainData: get from assessment data", domainData);

        const scoreValue = Number(domainData.score) || 0;
        console.log("scoreValue:", scoreValue);

        const scaledScore = Math.round((scoreValue / 120) * 100);
        const filledCircles = Math.floor(scaledScore / 10);
        const emptyCircles = 10 - filledCircles;

        // Draw Trait Title
        page3.drawText(domainTitle, {
          x: startX,
          y: startY,
          size: 30.67,
          color: domainColor, // Use domainColor
          fontFamily: "Lexend",
          lineHeight: 49.07,
          fontWeight: 400,
        });

        // Draw Circles
        let currentX = startX + 425; // Position for first circle
        for (let i = 0; i < filledCircles; i++) {
          page3.drawCircle({
            x: currentX,
            y: startY + circleRadius / 2,
            size: circleRadius,
            color: domainColor,
            fill: domainColor,
          });
          currentX += circleRadius * 2 + circleGap;
        }
        for (let i = 0; i < emptyCircles; i++) {
          page3.drawCircle({
            x: currentX,
            y: startY + circleRadius / 2,
            size: circleRadius,
            color: rgb(239 / 255, 243 / 255, 246 / 255), // #EFF3F6 color
            fill: rgb(239 / 255, 243 / 255, 246 / 255), // #EFF3F6 color
          });
          currentX += circleRadius * 2 + circleGap;
        }

        // Draw Score
        page3.drawText(`${scaledScore} / 100`, {
          x: currentX - 10,
          y: startY,
          size: 20.643,
          color: rgb(143 / 255, 143 / 255, 143 / 255), // #8F8F8F color
          lineHeight: 33.029,
          fontWeight: 400,
          fontFamily: "Lexend",
        });

        // Draw Short Description
        // Render Trait Definition
        const traitDefinition = traitDefinitions[domainTitle];
        if (traitDefinition) {
          const definitionLines = wrapText(
            traitDefinition,
            925,
            20,
            helveticaFont
          ); // Wrap text for fitting into the layout
          let definitionY = startY - 50; // Adjust Y position for definition
          definitionLines.forEach((line, lineIndex) => {
            page3.drawText(line, {
              x: startX,
              y: definitionY - lineIndex * 28,
              size: 20,
              color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
              fontFamily: "Lexend",
              fontWeight: 400,
              letterSpacing: -0.6,
            });
          });
          startY -= definitionLines.length * 20 + 10; // Adjust spacing after the definition
        }

        console.log("domaindata know", domains);
        // Move Y position for the next trait
        startY -= 150; // Adjust spacing between traits
      });

      // Draw the vertical line with the specified properties
      page3.drawRectangle({
        x: 24,
        y: page3.getHeight() - 150 - 44, // Adjusted for top position
        width: 8,
        height: 44,
        color: rgb(86 / 255, 143 / 255, 243 / 255), // #568FF3 color
        opacity: 1,
      });
      // Draw the "About OCEAN" text with the specified styles
      page3.drawText("About OCEAN:", {
        x: 52,
        y: page3.getHeight() - 159 - 20,
        size: 20,
        fontFamily: "Lexend",
        fontWeight: 900, // Increased font weight for boldness
        lineHeight: 24,
        letterSpacing: -0.03,
        textAlign: "left",
        color: rgb(1, 1, 1), // White color
        opacity: 1,
        underline: true,
        textUnderlinePosition: "from-font",
        textDecorationSkipInk: "none",
      });
      // Draw the text block with the specified properties
      const textBlock =
        "The Big 5 Personality Traits provide a comprehensive framework for understanding human behavior. These traits are commonly referred to by their acronym OCEAN, which stands for Openness, Conscientiousness, Extraversion, Agreeableness, and Neuroticism.";
      const textBlockFontSize = 20;
      const textBlockMaxWidth = 252;
      const textBlockLines = wrapText(
        textBlock,
        textBlockMaxWidth,
        textBlockFontSize,
        helveticaFont
      );

      let textBlockCurrentY = page3.getHeight() - 250; // Adjusted top position according to layout
      textBlockLines.forEach((line, index) => {
        page3.drawText(line, {
          x: 52, // Adjusted X position based on layout
          y: textBlockCurrentY - index * textBlockFontSize * 1.2,
          size: textBlockFontSize,
          fontFamily: "Lexend",
          color: rgb(1, 1, 1), // Black color
          width: textBlockMaxWidth,
          height: 24,
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
          textUnderlinePosition: "from-font",
          textDecorationSkipInk: "none",
        });
      });
      // Draw the explanation text for each trait
      const explanationText =
        "Below will explain each trait in detail, how to communicate effectively with someone who has a low or high score in that trait, and the best ways to address key workplace interactions.";
      const explanationLines = wrapText(
        explanationText,
        252,
        20,
        helveticaFont
      );
      let explanationY = page3.getHeight() - 580; // Adjust the Y position as needed

      explanationLines.forEach((line, index) => {
        page3.drawText(line, {
          x: 52, // Adjust the X position as needed
          y: explanationY - index * 24, // Adjust the line height as needed
          size: 20,
          fontFamily: "Lexend",
          color: rgb(1, 1, 1),
          lineHeight: 24,
          fontWeight: 400,
          letterSpacing: -0.6,
          opacity: 1, // Set opacity to 0
        });
      });
      // Draw the first line of text
      page3.drawText(
        "Continue reading the report to learn about each of your scores, our",
        {
          x: 417,
          y: page3.getHeight() - 1687 - 24, // Adjusted for top position
          size: 20,
          color: rgb(160 / 255, 160 / 255, 160 / 255), // #A0A0A0 color
          lineHeight: 24,
          fontFamily: "Lexend",
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        }
      );

      // Draw the second line of text
      page3.drawText(
        "suggested improvements to your communication style and ways of",
        {
          x: 417,
          y: page3.getHeight() - 1711 - 24,
          size: 20,
          color: rgb(160 / 255, 160 / 255, 160 / 255),
          lineHeight: 24,
          fontFamily: "Lexend",
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        }
      );

      // Draw the third line of text
      page3.drawText("communicating with others based on their scores.", {
        x: 417,
        y: page3.getHeight() - 1735 - 24, // Adjusted for top position
        size: 20,
        color: rgb(160 / 255, 160 / 255, 160 / 255), // #A0A0A0 color
        fontFamily: "Lexend",
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
      });

      // Draw the text "Learn more about L.A.R.A at www.altercall.com/aai"
      page3.drawText("Learn more about at", {
        x: 630,
        y: page3.getHeight() - 1933,
        size: 20,
        color: rgb(0, 0, 0),
        fontFamily: "lexend",
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
      });

      // Draw the clickable link "www.altercall.com/aai"
      page3.drawText("www.altercall.com/aai", {
        x: 825,
        y: page3.getHeight() - 1933,
        size: 20,
        color: rgb(0, 118 / 255, 186 / 255), // #0076BA color
        fontFamily: "lexend",
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
        underline: true,
      });

      page3.drawText("03", {
        x: 1437,
        y: page3.getHeight() - 1933,
        size: 18,
        color: rgb(1, 94 / 255, 0), // Orange color (#FF5E00)
        width: 18,
        height: 19,
        opacity: 1,
      });

      page3.drawImage(vectorFooterBlackImage, {
        x: 54,
        y: page3.getHeight() - 1933,
        width: 199,
        height: 38,
        opacity: 1,
      });

      //new page for all domains making

      const domainSections = [
        {
          title: "Openness",
          score: assessmentData?.results?.O?.score ?? 0,
          communicationStyle: assessmentData?.communication_style ?? "",
          page: "04", // Page number where this domain appears in the PDF
        },
        {
          title: "Conscientiousness",
          score: assessmentData?.results?.C?.score ?? 0,
          communicationStyle: assessmentData?.communication_style ?? "",
          page: "06",
        },
        {
          title: "Extraversion",
          score: assessmentData?.results?.E?.score ?? 0,
          communicationStyle: assessmentData?.communication_style ?? "",
          page: "08",
        },
        {
          title: "Agreeableness",
          score: assessmentData?.results?.A?.score ?? 0,
          communicationStyle: assessmentData?.communication_style ?? "",
          page: "10",
        },
        {
          title: "Neuroticism",
          score: assessmentData?.results?.N?.score ?? 0,
          communicationStyle: assessmentData?.communication_style ?? "",
          page: "12",
        },
      ];

      const domainData = domainSections.map((section) => {
        const domainKey = domainMapping[section.title];
        const selectedDomain =
          domains.find((domain) => domain.domain === domainKey) || {};
        const domainInfo = {
          title: section.title,
          shortDescription:
            selectedDomain.shortDescription ||
            "No description available for this domain.",
          description:
            selectedDomain.description ||
            "No description available for this result.",
          results: selectedDomain.results || [],
          facets:
            selectedDomain.facets.map((facet, index) => {
              const facetData =
                assessmentData?.results[domainKey]?.facet[index + 1] || {};
              const scoreValue = Number(facetData.score) || 0;
              const countValue = Number(facetData.count) || 1;
              const scaledFacetScore = Math.min(
                Math.max(
                  Math.round(((scoreValue / (countValue * 5)) * 100) / 10),
                  0
                ),
                10
              );
              return {
                title: facet.title,
                text: facet.text,
                score: scaledFacetScore,
              };
            }) || [],
        };
        return domainInfo;
      });

      console.log("domainData:", domainData);
      console.log("domainSections:", domainSections);
      for (const [index, domainSec] of domainSections.entries()) {
        const page = pdfDoc.addPage([1500, 1974]); // Custom size
        // Draw the background for the domain page
        page.drawImage(verticalLineImage, {
          x: 0,
          y: page.getHeight() - 1863, // Start from the top
          width: 340,
          height: 1863,
          opacity: 1,
        });
        // Draw the logo image
        page.drawImage(logoImage3, {
          x: 53,
          y: page.getHeight() - 33 - 44, // Adjusted for top position
          width: 229,
          height: 44,
          opacity: 1, // Adjusted opacity
        });
        //outer container
        page.drawImage(newImage, {
          x: 392,
          y: page.getHeight() - 33 - 44,
          width: 192,
          height: 40,
          flexShrink: 0,
        });

        // Draw client name
        page.drawText(`${name}`, {
          x: 430, // Adjusted to match the left position
          y: page.getHeight() - 33 - 30, // Adjusted for top position
          size: 18,
          fontFamily: "Lexend",
          color: rgb(0, 0, 0), // var(--Colors-Grey-Black, #000)
          width: 170, // Adjusted to match the width
          height: 30, // Adjusted to match the height
          lineHeight: 30, // normal
          fontWeight: 300,
          textAlign: "left",
          opacity: 1, // Adjusted to match the opacity
        });
        // Draw the custom date with the specified layout
        page.drawText(`${customdate}`, {
          x: 1271,
          y: page.getHeight() - 45 - 19, // Adjusted for top position
          size: 15,
          color: rgb(0, 0, 0),
          width: 155,
          height: 19,
          lineHeight: 19, // Updated line height
          fontWeight: 300,
          textAlign: "left",
          opacity: 1, // Updated opacity to 1
          fontFamily: "Lexend",
        });

        // Draw the domain title
        page.drawText(domainSec.title, {
          x: 417,
          y: page.getHeight() - 128 - 75,
          size: 67,
          color: rgb(0, 0, 0), // #000
          fontFamily: "Poppins",
          fontWeight: 300,
          lineHeight: 75, // 111.94%
          opacity: 0.8,
        });

        // Draw the domain score
        const domainScore = Number(domainSec.score) || 0;
        console.log("domainScore:", domainScore);
        let domainscore1 = Math.round((domainScore / 120) * 100);
        let colorPie = domainColors[domainSec.title];
        console.log("colorPie:", colorPie);

        // Ensure colorPie is a valid RGB object
        let colorPieHex = "000000"; // Default to black if invalid
        if (colorPie.type === "RGB") {
          const r = Math.round(colorPie.red * 255);
          const g = Math.round(colorPie.green * 255);
          const b = Math.round(colorPie.blue * 255);
          colorPieHex = [r, g, b]
            .map((x) => x.toString(16).padStart(2, "0"))
            .join("");
        }
        console.log("colorPieHex:", colorPieHex);

        const pieCircleSvg = generatePieCircleSvg(colorPieHex, domainscore1);
        const pieCirclePng = await svgToPng(pieCircleSvg);
        const pieCircleImage = await pdfDoc.embedPng(pieCirclePng);

        page.drawImage(pieCircleImage, {
          x: 67,
          y: page.getHeight() - 151 - 190.25,
          width: 191,
          height: 191,
          opacity: 1,
        });
        //about your score text
        page.drawText("Your Category Score", {
          x: 57,
          y: page.getHeight() - 366 - 32, // Adjusted for top position
          size: 20,
          color: rgb(1, 1, 1), // White color
          fontFamily: "Lexend",
          width: 225,
          height: 41,
          lineHeight: 24,
          fontWeight: 900, // Increased font weight for boldness
          textAlign: "center",
          opacity: 1,
          letterSpacing: -0.6,
          flexShrink: 0,
        });
        // Draw the short description
        const shortDescriptionLines = wrapText(
          domainData[index].shortDescription,
          980,
          32,
          helveticaFont
        );
        let shortDescriptionY = page.getHeight() - 260;

        shortDescriptionLines.forEach((line, lineIndex) => {
          page.drawText(line, {
            x: 417,
            y: shortDescriptionY - lineIndex * 42,
            size: 32,
            color: rgb(0, 0, 0),
            fontWeight: 300,
            fontFamily: "Lexend",
            lineHeight: 42,
            letterSpacing: -0.96,
            textAlign: "left",
            opacity: 0.7,
          });
        });
        // Draw the horizontal line with the specified properties
        page.drawRectangle({
          x: 340,
          y: page.getHeight() - 438, // Adjusted for top position
          width: 1160,
          height: 0,
          color: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
          borderWidth: 2,
          borderColor: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
          opacity: 1,
        });
        //about you text
        // Draw the "About You" text
        page.drawText("About You:", {
          x: 55,
          y: page.getHeight() - 484,
          size: 20,
          color: rgb(1, 1, 1), // White color
          fontFamily: "Lexend",
          width: 237,
          height: 290,
          lineHeight: 24, // 120%
          fontWeight: 700,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.6,
          flexShrink: 0,
        });
        page.drawRectangle({
          x: 27,
          y: page.getHeight() - 500, // Adjusted for top position
          width: 8,
          height: 44,
          color: rgb(86 / 255, 143 / 255, 243 / 255), // Blue color (#568FF3)
          opacity: 1,
          flexShrink: 0,
        });

        //draw result low text
        // Determine the score category and corresponding text
        let resultText = "";
        if (domainscore1 >= 0 && domainscore1 <= 40) {
          resultText =
            domainData[index].results.find((result) => result.score === "low")
              ?.text || "";
        } else if (domainscore1 > 40 && domainscore1 <= 70) {
          resultText =
            domainData[index].results.find(
              (result) => result.score === "neutral"
            )?.text || "";
        } else if (domainscore1 > 70 && domainscore1 <= 100) {
          resultText =
            domainData[index].results.find((result) => result.score === "high")
              ?.text || "";
        }
        // Remove newline characters from resultText
        resultText = resultText.replace(/\n/g, " ");
        // Draw the result text with height limit
        const resultTextLines = wrapText(resultText, 238, 20, helveticaFont);
        let resultTextY = page.getHeight() - 540;
        const maxYY = page.getHeight() - 775; // Maximum Y position

        for (
          let lineIndex = 0;
          lineIndex < resultTextLines.length;
          lineIndex++
        ) {
          const line = resultTextLines[lineIndex];
          if (resultTextY - lineIndex * 28 < maxYY) {
            // Stop rendering if the next line exceeds the maxY
            const truncatedLine = line.substring(0, line.lastIndexOf(".") + 1);
            page.drawText(truncatedLine, {
              x: 55,
              y: resultTextY - lineIndex * 28,
              size: 20,
              color: rgb(1, 1, 1), // White color
              fontFamily: "Lexend",
              lineHeight: 28,
              fontWeight: 700,
              letterSpacing: -0.6,
              textAlign: "left",
              opacity: 1,
            });
            break;
          }
          page.drawText(line, {
            x: 55,
            y: resultTextY - lineIndex * 28,
            size: 20,
            color: rgb(1, 1, 1), // White color
            fontFamily: "Lexend",
            lineHeight: 28,
            fontWeight: 700,
            letterSpacing: -0.6,
            textAlign: "left",
            opacity: 1,
          });
        }

        // Draw the facets
        let facetY = page.getHeight() - 520; // Starting y position for the first facet
        const maxY = page.getHeight() - 1600; // Maximum y position for the facets
        const gapBetweenFacets =
          (facetY - maxY) / (domainData[index].facets.length - 1); // Calculate the gap between facets

        domainData[index].facets.forEach((facet, facetIndex) => {
          // Draw the facet title
          page.drawText(`${facet.title}`, {
            x: 417, // Adjusted X position based on CSS (left: 78px)
            y: facetY,
            size: 30.67, // Font size based on CSS
            color: domainColors[domainSec.title],
            fontWeight: 700, // Bold
            fontFamily: "lexend",
            lineHeight: 49.07, // Line height from your CSS
            letterSpacing: 2, // Letter spacing from your CSS
            textAlign: "left",
            opacity: 1,
            width: 693, // Width from CSS (width: 693px)
            height: 18,
            textTransform: "capitalize",
          });

          // Draw the circles representing the facet score
          let newscore = facet.score * 10;
          const filledCircles = Math.floor(newscore / 10);
          const emptyCircles = 10 - filledCircles;
          const circleRadius = 12; // Radius for circle
          const circleGap = 10; // Gap between circles
          let currentX = 947; // Adjust X position based on CSS (left: 627px)
          console.log("currentX:", currentX);
          // Filled circles
          for (let i = 0; i < filledCircles; i++) {
            page.drawCircle({
              x: currentX,
              y: facetY,
              size: circleRadius,
              color: domainColors[domainSec.title], // Use domain color
              fill: domainColors[domainSec.title],
            });
            currentX += circleRadius * 2 + circleGap;
          }

          // Empty circles
          for (let i = 0; i < emptyCircles; i++) {
            page.drawCircle({
              x: currentX,
              y: facetY,
              size: circleRadius,
              color: rgb(239 / 255, 243 / 255, 246 / 255),
              fill: rgb(239 / 255, 243 / 255, 246 / 255),
            });
            currentX += circleRadius * 2 + circleGap;
          }
          page.drawText(`Score:`, {
            x: 1300, // Positioned based on your layout (left: 1002px)
            y: facetY - 5,
            size: 16, // Font size
            color: rgb(0, 0, 0),
            fontFamily: "Lexend",
            fontWeight: 700,
            lineHeight: 25.6, // 160% of 16px
            textAlign: "right",
            opacity: 0.8,
          });
          // Draw the facet score
          page.drawText(`${newscore}/100`, {
            x: 1350, // Positioned based on your layout (left: 1002px)
            y: facetY - 5,
            size: 16, // Font size
            color: rgb(0, 0, 0),
            fontfamily: "Lexend",
            fontWeight: 900,
            lineHeight: 25.6, // 160% of 16px
            textAlign: "right",
            opacity: 1,
          });

          const facetTextLines = wrapText(
            facet.text.replace(/\n/g, " "),
            880, // Adjust width for text wrapping after the bullet
            18,
            helveticaFont
          );

          let facetTextY = facetY - 60;

          facetTextLines.forEach((line, lineIndex) => {
            if (lineIndex === 0) {
              // Draw the bullet separately for the first line
              page.drawText("•", {
                x: 425,
                y: facetTextY,
                size: 18,
                color: rgb(0, 0, 0),
                fontFamily: "Lexend",
                fontWeight: 400,
                letterSpacing: -0.54,
                textAlign: "left",
                opacity: 1,
              });

              // Draw the first line of text aligned with the wrapped lines
              page.drawText(line, {
                x: 445, // Align the text after the bullet
                y: facetTextY,
                size: 18,
                color: rgb(0, 0, 0),
                fontFamily: "Lexend",
                fontWeight: 400,
                letterSpacing: -0.54,
                textAlign: "left",
                opacity: 1,
              });
            } else {
              // Draw wrapped lines
              page.drawText(line, {
                x: 445, // Ensure wrapped lines align with the first line
                y: facetTextY - lineIndex * 22,
                size: 18,
                color: rgb(0, 0, 0),
                fontFamily: "Lexend",
                fontWeight: 400,
                letterSpacing: -0.54,
                textAlign: "left",
                opacity: 1,
              });
            }
          });

          // Draw the horizontal line with the specified properties if not the last iteration
          if (facetIndex < domainData[index].facets.length - 1) {
            page.drawRectangle({
              x: 340,
              // Adjusted for top position
              width: 1160,
              height: 7,
              color: rgb(1, 1, 1), // White color
              opacity: 1,
            });

            // Update the y position for the next facet, adding 50 units after the line
            facetY = facetTextY - facetTextLines.length * 22 - 20 - 50;
          } else {
            // Update the y position for the next facet without adding the line
            facetY -= gapBetweenFacets;
          }
        });
        // Draw a vertical line with the specified properties
        page.drawRectangle({
          x: 27,
          y: page.getHeight() - 891,
          width: 8,
          height: 44,
          color: rgb(255 / 255, 94 / 255, 0 / 255),
          opacity: 1,
        });

        // Draw the "Key Scoring Criteria" title
        page.drawText("Key Scoring Criteria:", {
          x: 55,
          y: page.getHeight() - 878,
          size: 20,
          fontFamily: "Lexend",
          color: rgb(1, 1, 1), // Changed to #A0A0A0
          width: 225,
          height: 24,
          lineHeight: 24,
          fontWeight: 700,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.6,
        });
        const domainSections = [
          {
            title: "Openness",
            contentText:
              "Measures creativity, curiosity, and openness to new experiences.",
            high: "High: Imaginative, open-minded, enjoys novelty.",
            low: "Low: Prefers routine, practical, less open to change.",
          },
          {
            title: "Conscientiousness",
            contentText: "Measures organization, reliability, and work ethic.",
            high: "High: Disciplined, detail-oriented, goal-focused.",
            low: "Low: Spontaneous, flexible, less detail-oriented.",
          },
          {
            title: "Extraversion",
            contentText: "Measures outgoingness, energy, and sociability.",
            high: "High: Sociable, talkative, energetic.",
            low: "Low: Reserved, introverted, prefers solitude.",
          },
          {
            title: "Agreeableness",
            contentText:
              "Measures empathy, cooperativeness, and social harmony.",
            high: "High: Kind, empathetic, eager to avoid conflict.",
            low: "Low: Competitive, critical, skeptical.",
          },
          {
            title: "Neuroticism",
            contentText:
              "Measures emotional stability and susceptibility to stress.",
            high: "High: Prone to anxiety, mood swings, negative emotions.",
            low: "Low: Calm, resilient, emotionally stable.",
          },
        ];
        // Find the matching content from the domainSections array
        function renderDomainContent(page, domainSec, helveticaFont) {
          const matchedDomain = domainSections.find(
            (domain) => domain.title === domainSec.title
          );

          if (matchedDomain) {
            const { contentText, high, low } = matchedDomain;

            // Draw the content text
            const contentFontSize = 20;
            const contentMaxWidth = 225;
            const contentLines = wrapText(
              contentText,
              contentMaxWidth,
              contentFontSize,
              helveticaFont
            );

            let contentCurrentY = page.getHeight() - 935;
            contentLines.forEach((line, index) => {
              page.drawText(line, {
                x: 55,
                y: contentCurrentY - index * contentFontSize * 1.2,
                size: contentFontSize,
                color: rgb(1, 1, 1), // White color
                fontFamily: "Lexend",
                fontWeight: 700,
                lineHeight: 24,
                letterSpacing: -0.6,
                textAlign: "left",
                opacity: 1,
              });
            });

            // Draw the high text
            const highFontSize = 20;
            const highMaxWidth = 200; // Adjusted width to account for indentation
            const highBulletX = 55; // X position for the bullet
            const highTextX = 75; // Indentation for text after the bullet
            const highLines = wrapText(
              high,
              highMaxWidth,
              highFontSize,
              helveticaFont
            );

            let highCurrentY =
              contentCurrentY -
              contentLines.length * contentFontSize * 1.2 -
              30;
            page.drawText("•", {
              // Draw the bullet separately
              x: highBulletX,
              y: highCurrentY,
              size: highFontSize,
              color: rgb(1, 1, 1), // White color
              fontfamily: "Lexend",
            });

            highLines.forEach((line, index) => {
              page.drawText(line, {
                x: highTextX, // Indentation for all lines
                y: highCurrentY - index * highFontSize * 1.2,
                size: highFontSize,
                color: rgb(1, 1, 1), // White color
                fontfamily: "Lexend",
                letterSpacing: -0.6,
                lineHeight: 24,
                textAlign: "left",
                opacity: 1,
              });
            });

            // Draw the low text with bullet
            const lowFontSize = 20;
            const lowMaxWidth = 200;
            const lowBulletX = 55; // X position for the bullet
            const lowTextX = 75; // Indentation for text after the bullet
            const lowLines = wrapText(
              low,
              lowMaxWidth,
              lowFontSize,
              helveticaFont
            );

            let lowCurrentY =
              highCurrentY - highLines.length * highFontSize * 1.2 - 30;
            page.drawText("•", {
              // Draw the bullet separately
              x: lowBulletX,
              y: lowCurrentY,
              size: lowFontSize,
              color: rgb(1, 1, 1), // White color
              fontfamily: "Lexend",
              letterSpacing: -0.6,
              lineHeight: 24,
            });

            lowLines.forEach((line, index) => {
              page.drawText(line, {
                x: lowTextX, // Indentation for all lines
                y: lowCurrentY - index * lowFontSize * 1.2,
                size: lowFontSize,
                color: rgb(1, 1, 1), // White color
                fontfamily: "Lexend",
                letterSpacing: -0.6,
                lineHeight: 24,
                textAlign: "left",
                opacity: 1,
              });
            });
          } else {
            console.error(
              `No matching domain found for title: ${domainSec.title}`
            );
          }
        }
        renderDomainContent(page, domainSec, helveticaFont);
        // Draw the horizontal line with the specified properties
        page.drawRectangle({
          x: 340,
          y: page.getHeight() - 1863, // Adjusted for top position
          width: 1200,
          height: 0,
          color: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
          borderWidth: 2,
          borderColor: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
          opacity: 1,
          rotate: degrees(90),
        });
        // Draw the verticle line with the specified properties
        page.drawRectangle({
          x: -38,
          y: page.getHeight() - 1863, // Adjusted for top position
          width: 1606,
          height: 0,
          color: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
          borderWidth: 2,
          borderColor: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
          opacity: 1,
        });
        // Draw the text "Learn more about L.A.R.A at www.altercall.com/aai"
        page.drawText("Learn more about at", {
          x: 630,
          y: page.getHeight() - 1933,
          size: 20,
          color: rgb(0, 0, 0),
          fontFamily: "lexend",
          width: 894,
          height: 41,
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        });

        // Draw the clickable link "www.altercall.com/aai"
        page.drawText("www.altercall.com/aai", {
          x: 825,
          y: page.getHeight() - 1933,
          size: 20,
          color: rgb(0, 118 / 255, 186 / 255), // #0076BA color
          fontFamily: "lexend",
          width: 894,
          height: 41,
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
          underline: true,
        });

        page.drawImage(vectorFooterBlackImage, {
          x: 54,
          y: page.getHeight() - 1933,
          width: 199,
          height: 38,
          opacity: 1,
        });

        // Draw the page number
        page.drawText(domainSec.page, {
          x: 1400,
          y: 50,
          size: 18,
          color: rgb(1, 94 / 255, 0), // Orange color (#FF5E00)
          fontFamily: "Lexend",
        });
        if (domainSec.title) {
          const ex_page = pdfDoc.addPage([1500, 1974]); // Custom size
          // Draw the background for the domain page
          ex_page.drawImage(verticalLineImage, {
            x: 0,
            y: ex_page.getHeight() - 1863, // Start from the top
            width: 340,
            height: 1863,
            opacity: 1,
          });
          ex_page.drawImage(logoImage3, {
            x: 53,
            y: ex_page.getHeight() - 33 - 44, // Adjusted for top position
            width: 229,
            height: 44,
            opacity: 1, // Adjusted opacity
          });
          //outer container
          ex_page.drawImage(newImage, {
            x: 392,
            y: ex_page.getHeight() - 33 - 44,
            width: 192,
            height: 40,
            flexShrink: 0,
          });

          // Draw client name
          ex_page.drawText(`${name}`, {
            x: 430, // Adjusted to match the left position
            y: ex_page.getHeight() - 33 - 30, // Adjusted for top position
            size: 18,
            fontFamily: "Lexend",
            color: rgb(0, 0, 0), // var(--Colors-Grey-Black, #000)
            width: 170, // Adjusted to match the width
            height: 30, // Adjusted to match the height
            lineHeight: 30, // normal
            fontWeight: 300,
            textAlign: "left",
            opacity: 1, // Adjusted to match the opacity
          });
          // Draw the custom date with the specified layout
          ex_page.drawText(`${customdate}`, {
            x: 1271,
            y: ex_page.getHeight() - 45 - 19, // Adjusted for top position
            size: 15,
            color: rgb(0, 0, 0),
            width: 155,
            height: 19,
            lineHeight: 19, // Updated line height
            fontWeight: 300,
            textAlign: "left",
            opacity: 1, // Updated opacity to 1
            fontFamily: "Lexend",
          });

          // Draw the domain title
          ex_page.drawText(domainSec.title, {
            x: 417,
            y: ex_page.getHeight() - 128 - 75,
            size: 67,
            color: rgb(0, 0, 0), // #000
            fontFamily: "Poppins",
            fontWeight: 300,
            lineHeight: 75, // 111.94%
            opacity: 0.8,
          });
          ex_page.drawImage(pieCircleImage, {
            x: 67,
            y: ex_page.getHeight() - 151 - 190.25,
            width: 191,
            height: 191,
            opacity: 1,
          });
          //about your score text
          ex_page.drawText("Your Category Score", {
            x: 57,
            y: ex_page.getHeight() - 366 - 32, // Adjusted for top position
            size: 20,
            color: rgb(1, 1, 1), // White color
            fontFamily: "Lexend",
            width: 225,
            height: 41,
            lineHeight: 24,
            fontWeight: 900, // Increased font weight for boldness
            textAlign: "center",
            opacity: 1,
            letterSpacing: -0.6,
            flexShrink: 0,
          });

          // Draw the communication tips based on high or low scores
          const communicationTips = `How to communicate with someone with high or low ${domainSec.title} scores can depend on their score.`;
          const communicationTipsLines = wrapText(
            communicationTips,
            967,
            32,
            helveticaFont
          );
          let communicationTipsY = ex_page.getHeight() - 260;

          communicationTipsLines.forEach((line, index) => {
            ex_page.drawText(line, {
              x: 417,
              y: communicationTipsY - index * 42,
              size: 32,
              color: rgb(0, 0, 0),
              fontFamily: "Lexend",
              fontWeight: 300,
              lineHeight: 42,
              letterSpacing: -0.96,
              textAlign: "left",
              opacity: 0.7,
            });
          });
          // Draw the "If someone has..." text
          const ifSomeoneHasText = "If someone has...";
          ex_page.drawText(ifSomeoneHasText, {
            x: 417,
            y: ex_page.getHeight() - 360 - 10, // Adjusted for top position
            size: 20,
            fontFamily: "Helvetica",
            color: rgb(0, 0, 0), // Black color
            width: 198,
            height: 28,
            lineHeight: 28, // 140%
            fontWeight: 300,
            letterSpacing: -0.6,
            textAlign: "left",
            opacity: 1,
          });
          // Draw the "High" text with the specified styles
          ex_page.drawText(`High ${domainSec.title}`, {
            x: 417,
            y: ex_page.getHeight() - 390 - 30, // Adjusted for top position
            size: 30.67,
            fontFamily: "Lexend",
            color: domainColors[domainSec.title],
            lineHeight: 49.072,
            fontWeight: 400,
            textAlign: "left",
            opacity: 1,
          });
          const guidelines = personalityGuidelines.find(
            (guideline) => guideline.trait === domainSec.title
          );

          if (guidelines && guidelines.guidelines) {
            let yPosition = ex_page.getHeight() - 485 ;
            const maxWidth = 967;
            const fontSize = 20;

            guidelines.guidelines.forEach((guideline, guidelineIndex) => {
              const questionText = guideline.question; // Question text
              const answerText = guideline.answer || ""; // Handle missing answers
              const combinedText = `${questionText}: ${answerText}`; // Combine question and answer

              const questionEndIndex = questionText.length + 1; // Position after the colon
              const wrappedLines = wrapText(
                combinedText,
                maxWidth ,
                fontSize,
                helveticaFont
              ); // Adjust maxWidth for bullet

              // Render the bullet separately
              ex_page.drawText("•", {
                x: 417, // Left margin for the bullet
                y: yPosition,
                size: fontSize,
                color: rgb(0, 0, 0),
                fontfamily: "Lexend",
                letterSpacing: -0.6,
                lineHeight: 24,
              });

              // Render the wrapped question and answer text
              wrappedLines.forEach((line, lineIndex) => {
                const isBold = lineIndex === 0 && line.indexOf(":") > -1;
                const splitIndex = isBold ? line.indexOf(":") + 1 : -1;

                if (isBold && splitIndex !== -1) {
                  // Draw the bold part
                  ex_page.drawText(line.substring(0, splitIndex), {
                    x: 440,
                    y: yPosition - lineIndex * 22,
                    size: fontSize,
                    color: rgb(0, 0, 0),
                    font: boldFont,
                  });

                  // Draw the normal part
                  ex_page.drawText(line.substring(splitIndex), {
                    x:
                      440 +
                      boldFont.widthOfTextAtSize(
                        line.substring(0, splitIndex),
                        fontSize
                      ),
                    y: yPosition - lineIndex * 22,
                    size: fontSize,
                    color: rgb(0, 0, 0),
                    fontfamily: "Lexend",
                  });
                } else {
                  // Render normal text for other lines
                  ex_page.drawText(line, {
                    x: 440,
                    y: yPosition - lineIndex * 22,
                    size: fontSize,
                    color: rgb(0, 0, 0),
                    fontfamily: "Lexend",
                  });
                }
              });

              // Update yPosition for the next question-answer pair
              yPosition -= wrappedLines.length * 25 + 25; // Add spacing between entries
            });
          } else {
            console.error("Guidelines not found or invalid structure.");
          }

          // Draw the "About You" text
          ex_page.drawText("About You:", {
            x: 55,
            y: ex_page.getHeight() - 484,
            size: 20,
            color: rgb(1, 1, 1), // White color
            fontFamily: "Lexend",
            width: 237,
            height: 290,
            lineHeight: 24, // 120%
            fontWeight: 700,
            textAlign: "left",
            opacity: 1,
            letterSpacing: -0.6,
            flexShrink: 0,
          });
          ex_page.drawRectangle({
            x: 27,
            y: ex_page.getHeight() - 500, // Adjusted for top position
            width: 8,
            height: 44,
            color: rgb(86 / 255, 143 / 255, 243 / 255), // Blue color (#568FF3)
            opacity: 1,
            flexShrink: 0,
          });
          // Remove newline characters from resultText
          resultText = resultText.replace(/\n/g, " ");
          // Draw the result text with height limit
          const resultTextLines = wrapText(resultText, 238, 20, helveticaFont);
          let resultTextY = ex_page.getHeight() - 540;
          const maxYY = ex_page.getHeight() - 775; // Maximum Y position

          for (
            let lineIndex = 0;
            lineIndex < resultTextLines.length;
            lineIndex++
          ) {
            const line = resultTextLines[lineIndex];
            if (resultTextY - lineIndex * 28 < maxYY) {
              // Stop rendering if the next line exceeds the maxY
              const truncatedLine = line.substring(
                0,
                line.lastIndexOf(".") + 1
              );
              ex_page.drawText(truncatedLine, {
                x: 55,
                y: resultTextY - lineIndex * 28,
                size: 20,
                color: rgb(1, 1, 1), // White color
                fontFamily: "Lexend",
                lineHeight: 28,
                fontWeight: 700,
                letterSpacing: -0.6,
                textAlign: "left",
                opacity: 1,
              });
              break;
            }
            ex_page.drawText(line, {
              x: 55,
              y: resultTextY - lineIndex * 28,
              size: 20,
              color: rgb(1, 1, 1), // White color
              fontFamily: "Lexend",
              lineHeight: 28,
              fontWeight: 700,
              letterSpacing: -0.6,
              textAlign: "left",
              opacity: 1,
            });
          }
          ex_page.drawRectangle({
            x: 27,
            y: ex_page.getHeight() - 891,
            width: 8,
            height: 44,
            color: rgb(255 / 255, 94 / 255, 0 / 255),
            opacity: 1,
          });
          ex_page.drawText("Key Scoring Criteria:", {
            x: 55,
            y: ex_page.getHeight() - 878,
            size: 20,
            fontFamily: "Lexend",
            color: rgb(1, 1, 1), // Changed to #A0A0A0
            width: 225,
            height: 24,
            lineHeight: 24,
            fontWeight: 700,
            textAlign: "left",
            opacity: 1,
            letterSpacing: -0.6,
          });
          renderDomainContent(ex_page, domainSec, helveticaFont);
          // Draw the "If someone has..." text
          ex_page.drawText("If someone has...", {
            x: 417,
            y: ex_page.getHeight() - 1070 - 10, // Adjusted for top position
            size: 20,
            fontFamily: "Helvetica",
            color: rgb(0, 0, 0), // Black color
            width: 198,
            height: 28,
            lineHeight: 28, // 140%
            fontWeight: 300,
            letterSpacing: -0.6,
            textAlign: "left",
            opacity: 1,
          });
          // Draw the "Low" text with the specified styles
          ex_page.drawText(`Low ${domainSec.title}`, {
            x: 417,
            y: ex_page.getHeight() - 1100 - 30, // Adjusted for top position
            size: 30.67,
            fontFamily: "Lexend",
            color: domainColors[domainSec.title],
            lineHeight: 49.072,
            fontWeight: 400,
            textAlign: "left",
            opacity: 1,
          });
          const guide = personalityLowsGuidelines.find(
            (guideline) => guideline.trait === domainSec.title
          );

          if (guide && guide.guidelines) {
            let yPosition = ex_page.getHeight() - 1200;
            const maxWidth = 967;
            const fontSize = 20;

            guide.guidelines.forEach((guideline, guidelineIndex) => {
              const questionText = guideline.question; // Question text
              const answerText = guideline.answer || ""; // Handle missing answers
              const combinedText = `${questionText}: ${answerText}`; // Combine question and answer

              const questionEndIndex = questionText.length + 1; // Position after the colon
              const wrappedLines = wrapText(
                combinedText,
                maxWidth - 25,
                fontSize,
                helveticaFont
              ); // Adjust maxWidth for bullet

              // Render the bullet separately
              ex_page.drawText("•", {
                x: 417, // Left margin for the bullet
                y: yPosition,
                size: fontSize,
                color: rgb(0, 0, 0),
                fontfamily: "Lexend",
                letterSpacing: -0.6,
                lineHeight: 24,
              });

              // Render the wrapped question and answer text
              wrappedLines.forEach((line, lineIndex) => {
                const isBold = lineIndex === 0 && line.indexOf(":") > -1;
                const splitIndex = isBold ? line.indexOf(":") + 1 : -1;

                if (isBold && splitIndex !== -1) {
                  // Draw the bold part
                  ex_page.drawText(line.substring(0, splitIndex), {
                    x: 440,
                    y: yPosition - lineIndex * 22,
                    size: fontSize,
                    color: rgb(0, 0, 0),
                    font: boldFont,
                  });

                  // Draw the normal part
                  ex_page.drawText(line.substring(splitIndex), {
                    x:
                      440 +
                      boldFont.widthOfTextAtSize(
                        line.substring(0, splitIndex),
                        fontSize
                      ),
                    y: yPosition - lineIndex * 22,
                    size: fontSize,
                    color: rgb(0, 0, 0),
                    fontfamily: "Lexend",
                  });
                } else {
                  // Render normal text for other lines
                  ex_page.drawText(line, {
                    x: 440,
                    y: yPosition - lineIndex * 22,
                    size: fontSize,
                    color: rgb(0, 0, 0),
                    fontfamily: "Lexend",
                  });
                }
              });

              // Update yPosition for the next question-answer pair
              yPosition -= wrappedLines.length * 25 + 25; // Add spacing between entries
            });
          } else {
            console.error("Guidelines not found or invalid structure.");
          }
          // Draw the verticle line with the specified properties
          ex_page.drawRectangle({
            x: -38,
            y: ex_page.getHeight() - 1863, // Adjusted for top position
            width: 1606,
            height: 0,
            color: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
            borderWidth: 2,
            borderColor: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
            opacity: 1,
          });
          // Draw the text "Learn more about L.A.R.A at www.altercall.com/aai"
          ex_page.drawText("Learn more about at", {
            x: 630,
            y: ex_page.getHeight() - 1933,
            size: 20,
            color: rgb(0, 0, 0),
            fontFamily: "lexend",
            width: 894,
            height: 41,
            lineHeight: 24,
            fontWeight: 400,
            textAlign: "left",
            opacity: 1,
            letterSpacing: -0.03,
          });

          // Draw the clickable link "www.altercall.com/aai"
          ex_page.drawText("www.altercall.com/aai", {
            x: 825,
            y: ex_page.getHeight() - 1933,
            size: 20,
            color: rgb(0, 118 / 255, 186 / 255), // #0076BA color
            fontFamily: "lexend",
            width: 894,
            height: 41,
            lineHeight: 24,
            fontWeight: 400,
            textAlign: "left",
            opacity: 1,
            letterSpacing: -0.03,
            underline: true,
          });

          ex_page.drawImage(vectorFooterBlackImage, {
            x: 54,
            y: ex_page.getHeight() - 1933,
            width: 199,
            height: 38,
            opacity: 1,
          });
          const pages_no = [
            {
              title: "Openness",
              page: "05",
            },
            {
              title: "Conscientiousness",
              page: "07",
            },
            {
              title: "Extraversion",
              page: "09",
            },
            {
              title: "Agreeableness",
              page: "11",
            },
            {
              title: "Neuroticism",
              page: "13",
            },
          ];
            const pageNo = pages_no.find((page) => page.title === domainSec.title);
            if (pageNo) {
            ex_page.drawText(pageNo.page, {
              x: 1400,
              y: 50,
              size: 18,
              color: rgb(1, 94 / 255, 0), // Orange color (#FF5E00)
              fontFamily: "Lexend",
            });
            }
        }
      }

      //new page
      const page4 = pdfDoc.addPage([1500, 1974]); // Custom size
      page4.drawRectangle({
        x: 0,
        y: 0,
        width: page4.getWidth(),
        height: page4.getHeight(),
        color: rgb(1, 1, 1),
        opacity: 1,
      });

      // Draw the gradient overlay
      const gradientPng = await svgToPng(gradientSvg);
      const gradientImage = await pdfDoc.embedPng(gradientPng);

      page4.drawImage(gradientImage, {
        x: 0,
        y: page4.getHeight() - 1863, // Adjusted for top position
        width: page4.getWidth(),
        height: 1863,
        opacity: 1,
      });

      const logoImagePng = await svgToPng(logoSvg);
      const logoImageEmbedded = await pdfDoc.embedPng(logoImagePng);

      page4.drawImage(logoImageEmbedded, {
        x: 612,
        y: page4.getHeight() - 112 - 156,
        width: 275.58,
        height: 156,
        opacity: 1,
      });
      // Draw the first text block

      const text11 = "AlterCall AI is a";
      const text22 = "revolutionary";
      const ltext3 = "Leadership Platform";
      const text4 = "we measure the";
      const text5 = "previously";
      const text6 = "unmeasurable.";

      const pageWidth = page4.getWidth();
      const pageHeight = page4.getHeight();

      // Text Configuration
      const lfontSize = 78;
      const lineHeight = 88;
      const left = 136;
      const top = pageHeight - 987;
      const colorWhite = rgb(1, 1, 1); // White color
      const colorOrange = rgb(1, 94 / 255, 0); // Orange color

      // Draw Text 1
      page4.drawText(text11, {
        x: left,
        y: top,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorWhite,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 2
      page4.drawText(text22, {
        x: left,
        y: top - lineHeight,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorWhite,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 3
      page4.drawText(ltext3, {
        x: left,
        y: top - 2 * lineHeight,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorWhite,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 4
      page4.drawText(text4, {
        x: left,
        y: top - 3 * lineHeight,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorOrange,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 5
      page4.drawText(text5, {
        x: left,
        y: top - 4 * lineHeight,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorOrange,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 6
      page4.drawText(text6, {
        x: left,
        y: top - 5 * lineHeight,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorOrange,
        lineHeight: lineHeight,
        textAlign: "left",
      });
      // Draw the new text block
      const newText1 =
        "We are pioneering the coaching industry with a specialized";
      const newText2 =
        "AI platform that doesn't just speculate about your potential;";
      const newText3 = "it knows it,";
      const newText4 = "and more importantly, it's here to unlock it.";

      const newFontSize = 24;
      const newLineHeight = 35;
      const newLeft = 136;
      const newTop = pageHeight - 1544;
      const newColor = rgb(1, 1, 1); // White color

      // Draw new Text 1
      page4.drawText(newText1, {
        x: newLeft,
        y: newTop,
        size: newFontSize,
        fontFamily: "Lexend",
        color: newColor,
        lineHeight: newLineHeight,
        textAlign: "left",
        fontWeight: 300,
      });

      // Draw new Text 2
      page4.drawText(newText2, {
        x: newLeft,
        y: newTop - newLineHeight,
        size: newFontSize,
        fontFamily: "Lexend",
        color: newColor,
        lineHeight: newLineHeight,
        textAlign: "left",
        fontWeight: 300,
      });

      // Draw new Text 3
      // Draw new Text 3
      page4.drawText(newText3, {
        x: newLeft,
        y: newTop - 2 * newLineHeight,
        size: newFontSize,
        fontFamily: "Lexend",
        color: newColor,
        lineHeight: newLineHeight,
        textAlign: "left",
        fontWeight: 700,
      });

      // Draw new Text 4
      page4.drawText(newText4, {
        x: newLeft + 115,
        y: newTop - 2 * newLineHeight,
        size: newFontSize,
        fontFamily: "Lexend",
        color: newColor,
        lineHeight: newLineHeight,
        textAlign: "left",
        fontWeight: 1400,
      });

      // Draw a horizontal line with the specified properties
      page4.drawRectangle({
        x: -38,
        y: page4.getHeight() - 1863,
        width: 1606,
        height: 0,
        borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
        borderWidth: 2,
        opacity: 1,
        rotate: degrees(0),
      });
      page4.drawText("Learn more about at", {
        x: 630,
        y: page4.getHeight() - 1933,
        size: 20,
        color: rgb(0, 0, 0),
        fontFamily: "Lexend",
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
      });

      // Draw the clickable link "www.altercall.com/aai"
      page4.drawText("www.altercall.com/aai", {
        x: 825,
        y: page4.getHeight() - 1933,
        size: 20,
        fontfamily: "Lexend",
        color: rgb(0, 118 / 255, 186 / 255), // #0076BA color
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
        underline: true,
      });

      page4.drawText("14", {
        x: 1437,
        y: page4.getHeight() - 1933,
        size: 18,
        color: rgb(1, 94 / 255, 0), // Orange color (#FF5E00)
        width: 18,
        height: 19,
        opacity: 1,
      });



      page4.drawImage(vectorFooterBlackImage, {
        x: 54,
        y: page4.getHeight() - 1933,
        width: 199,
        height: 38,
        opacity: 1,
      });

      // Serialize the PDF to Uint8Array and start the download
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      saveAs(blob, `${name} Assessment.pdf`);
    } catch (error) {
      console.error("Error generating PDF report: ", error);
    }
  };

  const downloadPDFButton = async () => {
    setLoadingPDF(true);

    // Create a delay of 3 seconds
    const delay = new Promise((resolve) => setTimeout(resolve, 3000));

    try {
      // Run both the PDF download and the 3-second delay simultaneously
      await Promise.all([downloadPDF(), delay]);

      setLoadingPDF(false);
    } catch (error) {
      console.error("Error generating PDF report: ", error);
      setLoadingPDF(false);
    }
  };

  return (
    <GlobalPageComponent user={user} userData={userData}>
      <ClientLimitReached user={user} userData={userData} />

      <Box
        sx={{
          backgroundColor: "#EFF3F6",
          width: "100%",
          height: "auto",
          padding: "16px",
          display: {
            xs: !collapsed ? "none" : "block",
          },
          overflowY: "auto",
        }}
      >
        <Box sx={{ height: "100%" }}>
          {/* <Typography
                        sx={{
                            color: "black",
                            fontFamily: "Poppins",
                            fontSize: "36px",
                            fontWeight: 300,
                            marginBottom: "16px",
                        }}
                    >
                        Assessments
                    </Typography> */}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              height: "100%",
            }}
          >
            <Box
              sx={{
                background: "white",
                borderRadius: "40px",
                padding: "30px",
                width: "100%",
                height: "auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    md: "space-between",
                  },
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                  gap: {
                    xs: "20px",
                    md: "0px",
                  },
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontFamily: "Poppins",
                    fontSize: "24px",
                    fontWeight: 300,
                  }}
                >
                  Individual Breakdown
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: {
                      xs: "start",
                      md: "center",
                    },
                    justifyContent: "space-between",
                    border: "1px solid #D9D9D9",
                    height: {
                      xs: "auto",
                      md: "45px",
                    },
                    paddingX: "10px",
                    paddingY: "10px",
                    borderRadius: "20px",
                    flexDirection: {
                      xs: "column",
                      md: "row",
                    },
                    gap: {
                      xs: "20px",
                      md: "8px",
                    },
                  }}
                >
                  {teamId && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "#E3E3E3",
                          borderRadius: "50%",
                          width: "28px",
                          height: "28px",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "black",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontWeight: 300,
                        }}
                      >
                        {"N/A"}
                      </Typography>
                    </Box>
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <Avatar
                      sx={{ width: "30px", height: "30px" }}
                      src={coacheeData?.avatar || avatar}
                      onError={(e) => {
                        e.target.src = avatar; // Fallback to default avatar
                        console.error(
                          "Error loading avatar:",
                          coacheeData.avatar
                        );
                      }}
                    />
                    <Typography
                      sx={{
                        color: "black",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: 300,
                      }}
                    >
                      {loading
                        ? ""
                        : coacheeData?.full_name
                        ? coacheeData?.full_name
                        : ""}
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", gap: "8px" }}>
                    <Box sx={{ display: "flex", gap: "6px" }}>
                      <Typography
                        sx={{
                          color: "#B9B9B9",
                          font: "10px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        Date:
                      </Typography>
                      <Typography
                        sx={{
                          color: "#000000",
                          font: "10px",
                          fontWeight: "500",
                        }}
                      >
                        {loading
                          ? ""
                          : assessmentData?.updatedAt
                          ? ` ${new Date(
                              assessmentData?.updatedAt
                            ).toLocaleDateString()}`
                          : ""}
                      </Typography>
                    </Box>

                    <Typography
                      sx={{ color: "#000000", font: "10px", fontWeight: "500" }}
                    >
                      |
                    </Typography>

                    <Box sx={{ display: "flex", gap: "6px" }}>
                      <Typography
                        sx={{
                          color: "#B9B9B9",
                          font: "10px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        Time:
                      </Typography>
                      <Typography
                        sx={{
                          color: "#000000",
                          font: "10px",
                          fontWeight: "500",
                        }}
                      >
                        {loading
                          ? ""
                          : assessmentData?.updatedAt
                          ? new Date(
                              assessmentData?.updatedAt
                            ).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          : ""}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Button
                  onClick={downloadPDFButton}
                  variant="outlined"
                  sx={{
                    width: "160px",
                    height: "45px",
                    borderRadius: "22px",
                    backgroundColor: loadingPDF ? "black" : "white", // Change background based on loading state
                    color: loadingPDF ? "white" : "black", // Change text color based on loading state
                    border: "1px solid black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0 16px",
                    outline: "none",
                    "&:focus": {
                      outline: "none",
                    },
                    "&:hover": {
                      cursor: "pointer",
                      background: loadingPDF ? "black" : "black", // Keep background black if loading
                      borderColor: "black",
                    },
                    "&:hover *": {
                      color: loadingPDF ? "white" : "white", // Keep text color white if loading
                    },
                    "&:hover svg *": {
                      stroke: loadingPDF ? "white" : "white", // Keep SVG stroke color white if loading
                    },
                  }}
                >
                  {loadingPDF ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        animation: "spin 1s linear infinite", // Apply spinning animation
                      }}
                    >
                      <path
                        d="M12 2V6M12 18V22M4.93 4.93L7.76 7.76M16.24 16.24L19.07 19.07M2 12H6M18 12H22M4.93 19.07L7.76 16.24M16.24 7.76L19.07 4.93"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <>
                      {/* Your Provided Download Icon */}
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginRight: "8px" }}
                      >
                        <path
                          d="M3 15.5C3 18.2614 5.23858 20.5 8 20.5H16C18.7614 20.5 21 18.2614 21 15.5M9 12.6885C9.74024 13.6755 10.599 14.5668 11.5564 15.3426C11.6859 15.4475 11.843 15.5 12 15.5M15 12.6885C14.2598 13.6755 13.401 14.5668 12.4436 15.3426C12.3141 15.4475 12.157 15.5 12 15.5M12 15.5V4.5"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span
                        style={{
                          textTransform: "none",
                          fontSize: "12px",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          letterSpacing: "0.05em",
                          lineHeight: "18px",
                        }}
                      >
                        Download
                      </span>
                    </>
                  )}
                </Button>

                <Button
                  onClick={() => {
                    navigate(-1);
                  }}
                  variant="outlined"
                  sx={{
                    width: "90px",
                    height: "35px",
                    borderRadius: "20px",
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid black",
                    textTransform: "none",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    letterSpacing: "0.05em",
                    lineHeight: "18px",
                    "&:hover": {
                      cursor: "pointer",
                      background: "black",
                      borderColor: "black",
                      color: "white",
                    },
                    "&:hover *": {
                      color: "white",
                    },
                    "&:hover svg *": {
                      stroke: "white",
                    },
                  }}
                >
                  Back
                </Button>
              </Box>
            </Box>

            <Box
              sx={{
                background: "white",
                borderRadius: "40px",
                padding: "30px",
                width: "100%",
                height: "100%",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "rgb(207, 207, 207)",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "rgb(207, 207, 207)",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Box sx={{ paddingX: "0" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "40px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "24px",
                      fontFamily: "Poppins",
                      fontWeight: 300,
                      wordWrap: "break-word",
                    }}
                  >
                    Results
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "20px",
                      flexDirection: {
                        xs: "column",
                        md: "row",
                      },
                    }}
                  >
                    {tagNames.map((name, index) => (
                      <Box
                        key={index}
                        onClick={() => setSelectedTag(name)}
                        sx={{
                          width: "160px",
                          height: "35px",
                          borderRadius: "60px",
                          border:
                            selectedTag === name
                              ? `2px solid ${
                                  domainColors[
                                    domains.find((d) => d.title === name)
                                      ?.domain
                                  ]
                                }`
                              : "2px solid #8F8F8F",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          "&:hover": {
                            borderColor:
                              domainColors[
                                domains.find((d) => d.title === name)?.domain
                              ],
                            backgroundColor:
                              domainColors[
                                domains.find((d) => d.title === name)?.domain
                              ],
                            "& *": {
                              color: "white", // Ensure all child elements change color to white
                            },
                            "& svg *": {
                              stroke: "white", // Ensure SVG elements change stroke color to white
                            },
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            color:
                              selectedTag === name
                                ? domainColors[
                                    domains.find((d) => d.title === name)
                                      ?.domain
                                  ]
                                : "#8F8F8F",
                            fontFamily: "Poppins",
                            fontSize: "12px",
                            fontWeight: 500,
                            letterSpacing: "0.05em",
                            lineHeight: "18px",
                            textTransform: "capitalize",
                            paddingX: "10px",
                            "&:hover": {
                              color: "white",
                            },
                          }}
                        >
                          {name}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>

                {assessmentData && assessmentData?.results && (
                  <>
                    {selectedDomain?.domain &&
                    selectedDomain?.domain === "G" ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "40px",
                        }}
                      >
                        {/* Section to align Object.keys mapping and Assessment Summary */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            gap: "15px",
                            alignItems: "stretch",
                          }}
                        >
                          {/* Left side: Assessment Summary */}
                          <Box
                            sx={{
                              flex: 1, // Ensure the box grows equally with the right side
                              display: "flex",
                              flexDirection: "column",
                              maxHeight: "300px", // Set the maximum height
                              gap: "15px",
                              paddingRight: "8px",
                              overflowY: "auto", // Enable scrolling on overflow
                              "&::-webkit-scrollbar": {
                                width: "8px", // Custom scrollbar width
                              },
                              "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "rgb(207, 207, 207)", // Custom scrollbar thumb color
                                borderRadius: "4px", // Rounded scrollbar thumb
                              },
                              "&::-webkit-scrollbar-thumb:hover": {
                                backgroundColor: "rgb(170, 170, 170)", // Thumb color on hover
                              },
                              "&::-webkit-scrollbar-track": {
                                backgroundColor: "transparent", // Custom scrollbar track
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#93989A",
                                fontSize: 15.9,
                                fontFamily: "Lexend",
                                fontWeight: "600",
                                wordWrap: "break-word",
                                textTransform: "capitalize",
                              }}
                            >
                              Assessment Summary
                            </Typography>
                            <Typography
                              sx={{
                                color: "#373737",
                                fontSize: 18,
                                fontFamily: "Lexend",
                                fontWeight: "400",
                                wordWrap: "break-word",
                              }}
                            >
                              {assessmentData?.summary ??
                                "The summary is being generated."}
                            </Typography>
                          </Box>

                          {/* Right side: Object.keys mapping */}
                          <Box
                            sx={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              gap: "20px",
                            }}
                          >
                            {domainOrder.map((domainKey) => {
                              const domainData =
                                assessmentData.results[domainKey] || {};
                              const scoreValue = Number(domainData.score) || 0;
                              const scaledScore = Math.round(
                                (scoreValue / 120) * 100
                              );
                              const filledCircles = Math.max(
                                Math.round(scaledScore / 10),
                                0
                              );
                              const emptyCircles = Math.max(
                                10 - filledCircles,
                                0
                              );

                              return (
                                <Box
                                  key={domainKey}
                                  sx={{
                                    marginBottom: "20px",
                                    display: "flex",
                                    gap: "20px",
                                    textAlign: "left",
                                  }}
                                >
                                  {!loading && (
                                    <>
                                      <Typography
                                        sx={{
                                          color:
                                            domainColors[domainKey] || "#000",
                                          fontFamily: "Lexend",
                                          fontSize: "20px",
                                          fontWeight: 400,
                                          minWidth: "200px",
                                        }}
                                      >
                                        {domains.find(
                                          (d) => d.domain === domainKey
                                        )?.title || domainKey}
                                      </Typography>

                                      {/* Circles for scores */}
                                      <Box sx={{ display: "flex", gap: "5px" }}>
                                        {Array.from(Array(filledCircles)).map(
                                          (_, subIndex) => (
                                            <Box
                                              key={subIndex}
                                              sx={{
                                                width: {
                                                  xs: "18px",
                                                  md: "27px",
                                                },
                                                height: {
                                                  xs: "18px",
                                                  md: "27px",
                                                },
                                                borderRadius: "50%",
                                                backgroundColor:
                                                  domainColors[domainKey],
                                              }}
                                            />
                                          )
                                        )}
                                        {Array.from(Array(emptyCircles)).map(
                                          (_, subIndex) => (
                                            <Box
                                              key={subIndex}
                                              sx={{
                                                width: {
                                                  xs: "18px",
                                                  md: "27px",
                                                },
                                                height: {
                                                  xs: "18px",
                                                  md: "27px",
                                                },
                                                borderRadius: "50%",
                                                backgroundColor: "#EFF3F6",
                                              }}
                                            />
                                          )
                                        )}
                                      </Box>

                                      <Typography
                                        sx={{
                                          color: "#8F8F8F",
                                          fontFamily: "Lexend",
                                          fontSize: "16px",
                                        }}
                                      >
                                        {scaledScore} / 100
                                      </Typography>
                                    </>
                                  )}
                                </Box>
                              );
                            })}
                          </Box>
                        </Box>

                        {/* Section for the dropdowns taking the remaining space */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          {[
                            {
                              key: "communication_tips",
                              label: "Communication Tips",
                            },
                            {
                              key: "communication_style",
                              label: "Communication Style",
                            },
                            {
                              key: "communication_preference",
                              label: "Communication Preference",
                            },
                          ].map((section, index) => (
                            <Box
                              key={section.key}
                              sx={{ maxWidth: "100%", marginBottom: "10px" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#160042",
                                      fontSize: 30,
                                      fontFamily: "Poppins",
                                      fontWeight: 300,
                                      lineHeight: "48px",
                                      wordWrap: "break-word",
                                      marginLeft: "15px",
                                    }}
                                  >
                                    {section.label}
                                  </Typography>
                                </Box>

                                {/* IconButton with SectionDropDownIcon */}
                                <IconButton
                                  sx={{
                                    width: "71",
                                    height: "71",
                                    padding: 0,
                                    flexShrink: 0,
                                    borderRadius: "50%",
                                    backgroundColor:
                                      openSections === section.key
                                        ? "#0076BA"
                                        : "transparent", // Blue background when open
                                    transition: "background-color 0.3s ease",
                                    "& svg": {
                                      transform:
                                        openSections === section.key
                                          ? "rotate(180deg)"
                                          : "rotate(0deg)", // Rotate icon when open
                                      transition: "transform 0.3s ease", // Smooth transition for rotation
                                      "&:hover": {
                                        backgroundColor:
                                          openSections === section.key
                                            ? "#0076BA"
                                            : "transparent", // Maintain color on hover
                                        borderRadius: "50%",
                                      },
                                    },
                                  }}
                                  onClick={() => handleToggle(section.key)}
                                >
                                  <SectionDropDownIcon
                                    color={
                                      openSections === section.key
                                        ? "#FFFFFF"
                                        : "#383838"
                                    } // White icon when open, black when closed
                                  />
                                </IconButton>
                              </Box>

                              <Collapse in={openSections === section.key}>
                                <Box sx={{}}>
                                  <Typography
                                    sx={{
                                      color: "#292929",
                                      fontSize: 20,
                                      fontFamily: "Lexend",
                                      fontWeight: 400,
                                      wordWrap: "break-word",
                                      padding: "16px",
                                    }}
                                  >
                                    {assessmentData?.[section.key] ||
                                      "Your analysis is being generated and in process."}
                                  </Typography>
                                </Box>
                              </Collapse>

                              <Box
                                sx={{
                                  borderTop: "2px solid #CED9E0",
                                  marginY: "10px",
                                }}
                              />
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    ) : (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            height: {
                              xs: "auto",
                              md: "300px",
                            },
                            flexDirection: {
                              xs: "column",
                              md: "row",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              flex: 2,
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Box>
                              <Typography
                                sx={{
                                  color:
                                    domainColors[selectedDomain?.domain] ||
                                    "#000000",
                                  fontFamily: "Lexend",
                                  fontSize: {
                                    xs: "35px",
                                    md: "44px",
                                  },
                                  fontWeight: 400,
                                  lineHeight: "160%",
                                }}
                              >
                                {selectedDomain?.title || selectedTag}
                              </Typography>
                            </Box>

                            {loading || !selectedDomain?.domain ? (
                              <>
                                <CircularProgress sx={{ color: "black" }} />
                              </>
                            ) : (
                              <Box>
                                <Typography
                                  sx={{
                                    color: "#0C0C0C",
                                    fontFamily: "Lexend",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "160%",
                                  }}
                                >
                                  {selectedDomain?.shortDescription ||
                                    "No description available for this domain."}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#0C0C0C",
                                    fontFamily: "Lexend",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "160%",
                                  }}
                                >
                                  {selectedDomain?.results?.find(
                                    (result) =>
                                      result?.score ===
                                      assessmentData?.results[
                                        selectedDomain?.domain
                                      ]?.result
                                  )?.text ||
                                    "No description available for this result."}
                                </Typography>
                              </Box>
                            )}
                          </Box>

                          <Box
                            sx={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                                marginY: {
                                  xs: "20px",
                                  md: "0px",
                                },
                                width: {
                                  xs: "80px",
                                  md: "142px",
                                },
                                height: {
                                  xs: "80px",
                                  md: "142px",
                                },
                                backgroundColor:
                                  domainColors[selectedDomain?.domain] || "000",
                              }}
                            >
                              <Typography
                                sx={{
                                  margin: 0,
                                  padding: 0,
                                  color: "FFF" || "000",
                                  fontFamily: "Lexend",
                                  fontSize: "44px",
                                  fontWeight: 400,
                                  lineHeight: "160%",
                                }}
                              >
                                {selectedDomain?.domain
                                  ? Math.round(
                                      (assessmentData?.results[
                                        selectedDomain?.domain
                                      ]?.score /
                                        120) *
                                        100
                                    )
                                  : ""}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            backgroundColor: "#EFF3F6",
                            width: "100%",
                            height: "7px",
                            borderRadius: "10px",
                            marginY: {
                              xs: "30px",
                            },
                          }}
                        ></Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: {
                              xs: "40px",
                              md: "20px",
                            },
                            marginTop: "30px",
                          }}
                        >
                          {selectedDomain?.facets?.map((facet, index) => {
                            const facetData =
                              assessmentData?.results[selectedDomain.domain]
                                ?.facet[index + 1] || {}; // Default to empty object if no data
                            const scoreValue = Number(facetData.score) || 0; // Ensure score is a valid number, default to 0
                            const countValue = Number(facetData.count) || 1; // Default to 1 to avoid division by 0
                            const scaledFacetScore = Math.min(
                              Math.max(
                                Math.round(
                                  ((scoreValue / (countValue * 5)) * 100) / 10
                                ),
                                0
                              ),
                              10
                            ); // Adjust to scale 0-10

                            return (
                              <Box
                                key={index}
                                sx={{
                                  display: "flex",
                                  width: "100%",
                                  flexDirection: { xs: "column", md: "row" },
                                  gap: { xs: "20px", md: "30px" },
                                  marginBottom: "20px", // Add spacing between each facet
                                  flexWrap: "wrap", // Allow wrapping on small screens
                                }}
                              >
                                {/* Facet Title and Description */}
                                <Box
                                  sx={{
                                    flex: 2,
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    gap: "13px",
                                    minWidth: "250px", // Ensure a minimum width to avoid shrinkage
                                    padding: "10px", // Add some padding for better spacing
                                  }}
                                >
                                  <Box>
                                    <Typography
                                      sx={{
                                        color: "#222222", // Color based on the domain
                                        fontFamily: "Lexend",
                                        fontSize: { xs: "18px", md: "24px" }, // Responsive font size
                                        fontWeight: 400,
                                        lineHeight: "160%",
                                        marginBottom: "8px", // Avoid text overlapping
                                      }}
                                    >
                                      {facet.title}
                                    </Typography>
                                  </Box>

                                  <Box>
                                    <Typography
                                      sx={{
                                        color: "#0C0C0C",
                                        fontFamily: "Lexend",
                                        fontSize: { xs: "14px", md: "16px" }, // Responsive font size
                                        fontWeight: 400,
                                        lineHeight: "160%",
                                        overflowWrap: "break-word", // Handle long text wrapping
                                      }}
                                    >
                                      {facet.text}
                                    </Typography>
                                  </Box>
                                </Box>

                                {/* Scoring Circles */}
                                <Box
                                  sx={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    paddingX: "20px",
                                    rowGap: "10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "5px",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {" "}
                                    {/* Add flexWrap to handle overflow */}
                                    {/* Render the filled circles */}
                                    {Array.from(Array(scaledFacetScore)).map(
                                      (item, subIndex) => (
                                        <Box
                                          key={subIndex}
                                          sx={{
                                            width: { xs: "18px", md: "27px" },
                                            height: { xs: "18px", md: "27px" },
                                            borderRadius: "50%",
                                            backgroundColor:
                                              domainColors[
                                                selectedDomain?.domain
                                              ] || "000", // Circle color
                                          }}
                                        />
                                      )
                                    )}
                                    {/* Render the empty circles */}
                                    {Array.from(
                                      Array(10 - scaledFacetScore)
                                    ).map((item, subIndex) => (
                                      <Box
                                        key={subIndex}
                                        sx={{
                                          width: { xs: "18px", md: "27px" },
                                          height: { xs: "18px", md: "27px" },
                                          borderRadius: "50%",
                                          backgroundColor: "#EFF3F6", // Light gray for empty circles
                                        }}
                                      />
                                    ))}
                                  </Box>
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                      </>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </GlobalPageComponent>
  );
};
const styles = document.createElement("style");
styles.innerHTML = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
document.head.appendChild(styles);
export default CoachIndividualBreakdown;
