// NotificationContext.js
import React, {
    createContext,
    useRef,
    useState,
    useEffect,
    useContext,
  } from "react";
  import { toast } from "react-toastify";
  import axios from "axios";
  import { v4 as uuidv4 } from "uuid";
  import { generateClient } from "aws-amplify/data";
  import { getCurrentUser } from "aws-amplify/auth";
  /**
   * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
   */
  /**
   * NotificationContext:
   * This context provides global functions and states for handling:
   * - Processing calls (handleProcessCall)
   * - Receiving real-time notifications (handleNotificationUpdate)
   * - Displaying toast notifications globally.
   *
   * NOTE: The functions `reFetchUserData`, `closeProcessModal`, and `updateStatusToProcessing`
   * are implemented here as no-ops. You must replace them with your actual implementations or
   * import them from their respective files.
   */
  
  // Create a context
  export const NotificationContext = createContext();
  
  export const NotificationProvider = ({
    fetchData,
    fetchSum,
    children,
    user,
  }) => {
    const [loading, setLoading] = useState(true);
    const [processModal, setProcessModal] = useState(false);
    const [filteredUserData, setFilteredUserData] = useState([]);
  
    const reFetchUserData = async () => {
      setLoading(true);
      const data = await fetchData();
      console.log("fetch data from notification provider : data", data)
      await fetchSum();
      setLoading(false);
    };
    
    const closeProcessModal = () => setProcessModal(false);
  
    const updateStatusToProcessing = (ids) => {
      const updatedData = filteredUserData.map((row) => {
        if (ids.includes(row.id)) {
          return { ...row, status: "processing" };
        }
        return row;
      });
      setFilteredUserData(updatedData);
    };
    const toastIds = useRef({});
    const batchProgress = useRef({});
    const subscriptionRef = useRef(null);
  
    // States referenced by handleProcessCall and related logic
    const [loadingProcessing, setLoadingProcessing] = useState(false);
    const [disabled, setDisabled] = useState(true);
  
    // If handleProcessCall uses `selected` as a fallback, we define it here.
    // In your actual code, you may manage `selected` elsewhere or pass `rows` directly.
    const [selected] = useState([]);
  
    const dataClient = generateClient({ authMode: "userPool" });
    const [userfetch, setUserfetch] = useState({});
  
    const fetchUser = async () => {
      try {
        const userObj = await getCurrentUser();
        console.log(userObj);
        setUserfetch(userObj);
      } catch (error) {
        console.log("Error fetching current user:", error);
      }
    };
    console.log("userfetch", userfetch);
  
  
    useEffect(() => {
      if (!userfetch || !userfetch.userId) {
        fetchUser();
      }
    }, [userfetch]);
  
  
    // handleProcessCall copied from your provided code snippet
    const handleProcessCall = async ({ rows }) => {
      try {
        setLoadingProcessing(true);
        setDisabled(true);
        closeProcessModal();
        const selectedRows = rows || selected;
        const selectedIds = selectedRows.map((row) => row.id);
        updateStatusToProcessing(selectedIds);
  
        const groupCount = selectedRows.length;
        const messageGroupId =
          selectedRows[0].coach_name.replace(/\W/g, "") + uuidv4();
  
        const body = selectedRows.map((data) => ({
          coach_id: data.coach_id,
          call_id: data.call_id,
          call_name: data.call_name,
          coach_name: data.coach_name,
          client_name: data.client_name,
          zoom_call_date: data.zoom_call_date,
          duration: data.duration_minutes || 0,
          messageGroupId,
          group_count: groupCount,
        }));
  
        if (groupCount > 1) {
          // Batch scenario
          batchProgress.current[messageGroupId] = {
            total: groupCount,
            completed: 0,
          };
          toastIds.current[messageGroupId] = toast(
            `Generating Insights: 0/${groupCount} Completed`,
            {
              hideProgressBar: false,
              isLoading: true,
              closeButton: false,
              autoClose: false,
              style: {
                background: "white",
                boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
                borderRadius: "8px",
                padding: "16px 28px",
                color: "#160042",
              },
            }
          );
        } else {
          // Single scenario
          const messages = [
            "Pulling Session",
            "Generating Insights",
            "Calculating LARA",
          ];
          let messageIndex = 0;
          let progress = 0.2;
  
          toastIds.current[messageGroupId] = toast(messages[messageIndex], {
            progress,
            hideProgressBar: false,
            isLoading: true,
            closeButton: false,
            autoClose: false,
            style: {
              background: "white",
              boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
              borderRadius: "8px",
              padding: "16px 28px",
              color: "#160042",
            },
          });
  
          // Set an interval to update toast progress and message
          const interval = setInterval(() => {
            messageIndex = (messageIndex + 1) % messages.length;
            progress += 0.2;
            toast.update(toastIds.current[messageGroupId], {
              render: messages[messageIndex],
              progress: progress >= 0.9 ? 0.9 : progress,
              hideProgressBar: false,
              isLoading: true,
              closeButton: false,
              autoClose: false,
              style: {
                background: "white",
                boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
                borderRadius: "8px",
                padding: "16px 28px",
                color: "#160042",
              },
            });
          }, 20000);
  
          // For the single scenario, store the interval handle directly
          // because 'process_complete' will try to clear it.
          batchProgress.current[messageGroupId] = interval;
        }
  
        await axios.put(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/handleProcessCall`,
          body
        );
      } catch (err) {
        console.error("Error while sending data", err);
      } finally {
        setLoadingProcessing(false);
      }
    };
  
    const updateStatusToReadyToPublish = async (ids) => {
      try {
        const selectedRows = filteredUserData.filter((row) => ids.includes(row.id));
    
        // Prepare the body for the API request
        const body = selectedRows.map((data) => ({
          call_id: data.call_id,
        }));
    
        // API call to update the status to 'ReadyToPublish'
        await axios.put(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/updateCallStatus`,
          body
        );
    
        // Update the local state
        const updatedData = filteredUserData.map((row) => {
          if (ids.includes(row.id)) {
            return { ...row, status: "ReadyToPublish" };
          }
          return row;
        });
        setFilteredUserData(updatedData);
    
        console.log(`Status updated to ReadyToPublish for call IDs: ${ids}`);
      } catch (error) {
        console.error("Error updating status to ReadyToPublish:", error);
      }
    };
  
  
    // handleNotificationUpdate copied from your provided code snippet
    const handleNotificationUpdate = async (notificationData) => {
      const { group_id, update_type, group_count } = notificationData;
  
      if (update_type === "batch_call_complete") {
        if (!batchProgress.current[group_id]) {
          batchProgress.current[group_id] = { total: group_count, completed: 0 };
          toastIds.current[group_id] = toast.loading(
            `Insights: 0/${group_count} Generated`
          );
        }
        batchProgress.current[group_id].completed++;
  
        const progressData = batchProgress.current[group_id];
        const progressPercentage =
          Math.round((progressData.completed / progressData.total) * 100) / 100;
  
        if (toastIds.current[group_id]) {
          toast.update(toastIds.current[group_id], {
            render: `Generated ${progressData.completed}/${progressData.total} Insights`,
            progress: progressPercentage,
            hideProgressBar: false,
            closeButton: true,
            closeOnClick: true,
            isLoading: true,
          });
        }
  
        if (progressData.completed === progressData.total) {
          if (toastIds.current[group_id]) {
            toast.update(toastIds.current[group_id], {
              render: "Generating Insights Complete!",
              type: toast.TYPE.SUCCESS,
              progress: 1,
              autoClose: 5000,
              isLoading: false,
            });
            reFetchUserData();
          }
        }
      } else if (
        update_type === "batch_call_failed" &&
        toastIds.current[group_id]
      ) {
        toast.update(toastIds.current[group_id], {
          render: `Batch call failed. ${group_id} could not be processed.`,
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          isLoading: false,
        });
      } else if (update_type === "zoom_calls_loaded") {
        toast.success("Your Calls Are Loaded!", {
          autoClose: 5000,
          closeButton: true,
          style: {
            background: "white",
            boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
            borderRadius: "8px",
            padding: "16px 28px",
            color: "#160042",
          },
        });
        reFetchUserData();
      } else if (update_type === "webhook_call") {
        toast.success("You Have a New Call", {
          autoClose: 5000,
          closeButton: true,
          style: {
            background: "white",
            boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
            borderRadius: "8px",
            padding: "16px 28px",
            color: "#160042",
          },
        });
        reFetchUserData();
      } else if (update_type === "process_complete") {
        if (toastIds.current[group_id]) {
          toast.update(toastIds.current[group_id], {
            render: "Insights Generated",
            type: toast.TYPE.SUCCESS,
            progress: 1,
            autoClose: 5000,
            isLoading: false,
          });
          reFetchUserData();
          const groupIds = filteredUserData
          .filter((row) => row.group_id === group_id)
          .map((row) => row.id);
          await updateStatusToReadyToPublish(groupIds);
  
  
          // Clear interval if it's running
          if (batchProgress.current[group_id]) {
            clearInterval(batchProgress.current[group_id]);
            delete batchProgress.current[group_id];
          }
        }
      }
    };
    useEffect(() => {
      console.log(
        `[Subscription Init]: Attempting to subscribe for user with ID: ${userfetch.username}`
      );
      if (!subscriptionRef.current && userfetch.username) {
        try {
          const subscription = dataClient.models.callNotification
            .onCreate({
              filter: {
                coach_id: {
                  contains: `${userfetch.username}`,
                },
              },
            })
            .subscribe({
              authMode: "userPool",
              next: (data) => {
                console.log(
                  `[Subscription Update]: Received new notification data for user: ${userfetch.username}`,
                  data
                );
                handleNotificationUpdate(data);
              },
              error: (error) => {
                console.error(
                  `[Subscription Error]: Error encountered for user: ${userfetch.username}`,
                  error
                );
              },
            });
  
          subscriptionRef.current = subscription;
          console.log(
            `[Subscription Success]: Successfully set up subscription for user: ${userfetch.username}`
          );
        } catch (e) {
          console.error(
            `[Subscription Setup Failed]: Failed to set up subscription for user: ${userfetch.username}`,
            e
          );
        }
      }
  
      return () => {
        if (subscriptionRef.current) {
          console.log(
            `[Subscription Cleanup]: Unsubscribing for user: ${userfetch.username}`
          );
          subscriptionRef.current.unsubscribe();
          subscriptionRef.current = null;
          console.log(
            `[Subscription Cleanup Success]: Unsubscription successful for user: ${userfetch.username}`
          );
        }
      };
    }, [userfetch.username]);
  
    return (
      <NotificationContext.Provider
        value={{
          handleProcessCall,
          loadingProcessing,
          disabled,
        }}
      >
        {children}
      </NotificationContext.Provider>
    );
  };
  
  export const useNotification = () => useContext(NotificationContext);