import React from "react";
import { Switch, styled } from "@mui/material";

// Styled Switch Component
const StyledSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 32, // Adjust width to make it thinner horizontally
  height: 16, // Reduce height for a thinner look
  padding: 0,
  display: "flex",
  alignItems: "center", // Ensure alignment
  "& .MuiSwitch-switchBase": {
    padding: 1, // Reduce padding to fit the thinner track
    margin: 1,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)", // Adjust based on width
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#0076BA" : "#1890ff",
        opacity: 1,
        border: 0,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgba(0,35,11,.2)",
    width: 12, // Smaller circle
    height: 12, // Smaller circle
    borderRadius: 7, // Perfectly circular
    color: "#fff",
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 9, // Match track height for a rounder look
    backgroundColor: theme.palette.mode === "dark" ? "#D9E4EC" : "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

// Toggle Component
const Toggle = ({ checked, onChange, disabled }) => {
  return (
    <StyledSwitch
      checked={checked}
      onChange={onChange}
      disabled={disabled} // Forwarding the "disabled" prop
      inputProps={{ "aria-label": "custom toggle" }}
    />
  );
};

export default Toggle;
