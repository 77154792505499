import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import NewThreadIcon from '../Icons/NewThreadIcon.svg';
import ToggleThreadSidebarIcon from 'components/Icons/ToggleThreadSidebarIcon.svg';
import UserGuideIcon from 'components/Icons/UserGuideIcon.svg';


const TopFloatingButtons = ({ contentLibraryOpen, handleClickNewThread, sidebarOpen, setSidebarOpen }) => {
    const [isNewThreadBtnHovered, setIsNewThreadBtnHovered] = useState(false);
    const [isUserGuideBtnHovered, setIsUserGuideBtnHovered] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen((prevState) => !prevState);
    }
    return (
        <Box
            sx={{
                alignItems: 'center',
                justifyContent: 'center',
                gap: '20px',
                height: '6%',
                position: 'absolute',
                top: '20px',
                left: '25px',
                // border: '2px solid black',
                display: {
                    xs: contentLibraryOpen ? 'none' : 'flex',
                    sm: contentLibraryOpen ? 'none' : 'flex',
                    md: 'flex',
                },
            }}
        >
            {/* Sidebar Toggler */}
            <span id="hint-anchor-3">
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",   // Center vertically,
                        textAlign: "center",
                        cursor: 'pointer',
                        borderRadius: '50%',
                        width: '52px',          // Match the icon size
                        height: '52px',         // Match the icon size
                        transition: 'background-color 0.6s ease',
                        '&:hover': {
                            backgroundColor: '#EFF3F6'
                        }
                    }}
                    onClick={toggleSidebar}
                >
                    <ToggleThreadSidebarIcon />
                </Box>
            </span>
            {/*  New Thread For Mobile */}
            <Box sx={{
                padding: '8px',
                cursor: 'pointer',
                // border: '2px solid black',
                backgroundColor: 'black',
                borderRadius: '50%',
                justifyContent: 'center',
                alignItems: 'center',
                display: {
                    xs: 'flex',
                    sm: 'flex',
                    md: 'none',
                },
            }}
                onClick={handleClickNewThread}
            >
                <NewThreadIcon color={"#fff"} />
            </Box>


            {/*  New Thread For Dekstop */}
            <Box

                onMouseEnter={() => setIsNewThreadBtnHovered(true)}
                onMouseLeave={() => setIsNewThreadBtnHovered(false)}
                onClick={handleClickNewThread}
                sx={{
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'flex',
                    },
                    alignItems: 'center',
                    gap: '10px',
                    backgroundColor: isNewThreadBtnHovered ? 'black' : 'white',
                    border: '2px solid black',
                    ml: sidebarOpen ? "250px" : "0px",
                    borderRadius: '50px',
                    paddingX: '20px',
                    paddingY: '8px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease, margin-left 0.4s ease',
                }}>
                <NewThreadIcon
                    color={isNewThreadBtnHovered ? "#FFFFFF" : "#111111"}
                />

                <Typography
                    sx={{
                        font: '10px',
                        fontWeight: 500,
                        color: isNewThreadBtnHovered ? "#FFFFFF" : "#111111"
                    }}> <span id="hint-anchor-4" >New Thread</span>
                </Typography>
            </Box>

            {/* Download user guide for mobile */}
            <Box sx={{
                display: {
                    xs: 'block',
                    sm: 'block',
                    md: 'none',
                },
            }}>
                <a href="https://ac-media-files.s3.amazonaws.com/guides/Virtual+Coach+Guide+V2.pdf"
                    download
                    target="_blank"
                    rel='noreferrer'
                    style={{ textDecoration: "none" }}>
                    <Box sx={{
                        padding: '7px',
                        cursor: 'pointer',
                        border: '2px solid black',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}

                    >
                        <UserGuideIcon />
                    </Box>
                </a>
            </Box>

            {/* Download user guide for desktop */}
            <Box sx={{
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'block',
                },

            }}>
                <a href="https://ac-media-files.s3.amazonaws.com/guides/Virtual+Coach+Guide+V2.pdf"
                    download
                    target="_blank"
                    rel='noreferrer'
                    style={{ textDecoration: "none" }}>


                    <Box
                        onMouseEnter={() => setIsUserGuideBtnHovered(true)}
                        onMouseLeave={() => setIsUserGuideBtnHovered(false)}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            backgroundColor: isUserGuideBtnHovered ? 'black' : 'white',
                            border: '2px solid black',
                            borderRadius: '50px',
                            paddingX: '20px',
                            paddingY: '8px',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s ease',


                        }}>
                        <UserGuideIcon color={isUserGuideBtnHovered ? '#FFFFFF' : '#111111'} />
                        <Typography
                            sx={{
                                font: '10px',
                                fontWeight: 500,
                                color: isUserGuideBtnHovered ? 'white' : 'black'
                            }}>User Guides</Typography>
                    </Box>
                </a>
            </Box>
        </Box>
    );
};

export default TopFloatingButtons;