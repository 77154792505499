import { Box, Typography, TextField, useTheme, useMediaQuery, Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from "uuid";
import TextToSpeech from '../VoiceComponents/TextToSpeech';
import VcProfilePicture from 'components/Icons/VcProfilePicture';
import SpeechToText from '../VoiceComponents/SpeechToText';
import VoiceToChatSvg from 'components/Icons/VoiceToChat.svg';
import LoadingSkeleton from '../LoadingSkeleton';
import VideoPlayer from '../VideoPlayer';
import StartingPrompts from '../StartingPrompts';
import remarkGfm from 'remark-gfm';
import TypingLottie from '../TypingLottie';
import ReactMarkdown from 'react-markdown'
import VirtualCoachSendIcon from '../../Icons/VirtualCoachSendIcon.svg';
import EditIcon from '@mui/icons-material/Edit';
import LoopIcon from '@mui/icons-material/Loop';
import Tooltip from '@mui/material/Tooltip';

import VideoIconForVC from 'components/Icons/VideoIconForVC.svg';
import PdfIconForVC from 'components/Icons/PdfIconForVC.svg'

import * as Frigade from "@frigade/react";
import axios from 'axios';
import { toast } from 'react-toastify';


const ConvoArea = ({
    contentLibraryOpen,
    sidebarOpen,
    promptFromContentLibraryAsInput, setPromptFromContentLibraryAsInput,
    selectedThread,
    selectedThreadMessages, setSelectedThreadMessages,
    baseApiUrl,
    userId,
    setSelectedPdfUrl,
    handleOpenPdfModal,
    threadNames, setThreadNames, setSelectedThread
}) => {

    const messageRef = useRef(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Mobile screens
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md')); // Tablet screens
    const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // Desktop and larger screens

    const [loadingForAiResponse, setLoadingForAiResponse] = useState(false);
    const [selectedVideoInfo, setSelectedVideoInfo] = useState({});
    const [messageIdForVisibleEditIcon, setMessageIdForVisibleEditIcon] = useState(null);
    const [messageIdForVisibleInputFieldForEdit, setMessageIdForVisibleInputFieldForEdit] = useState(null);
    const [editUserInputValue, setEditUserInputValue] = useState('');
    const [showAudioRecordingModal, setShowAudioRecordingModal] = useState(false);
    const [s3UrToken, setS3UrToken] = useState('');


    const [loading, setLoading] = useState(false);
    const [showLoadingSkeleton, setShowLoadingSkeleton] = useState(false);

    const [currentlyIncomingAiResponseStream, setCurrentlyIncomingAiResponseStream] = useState('');
    const [haveNewThreadToSet, setHaveNewThreadToSet] = useState('');

    useEffect(() => {
        getTokenForUrlPrefix()
    }, [])


    useEffect(() => {
        const effectFunction = async () => {
            if (selectedThread?.startsWith('new-thread') || !selectedThread) {
                setLoading(false);
                const newThreadMessage = {
                    id: uuid(),
                    role: 'assistant',
                    content: 'What would you like to learn?',
                    newThread: true
                }
                setSelectedThreadMessages([newThreadMessage])
                return;
            }
            // if (haveNewThreadToSet && haveNewThreadToSet === selectedThread) {
            //     return;
            // }
            await getSingleThreadMessages(selectedThread)

        }

        effectFunction();
    }, [selectedThread])


    useEffect(() => () => {
        scrollToBottom();
    }, [loadingForAiResponse])

    useEffect(() => {
        if (loading === false) scrollToBottom();
    }, [loading])

    const scrollToBottom = () => {
        messageRef.current?.scrollIntoView({ behavior: "smooth" });
    }


    const getSingleThreadMessages = async (threadId) => {
        try {
            // console.log('Thread ID ------------:', threadId);
            setLoading(true);
            setSelectedThreadMessages([])
            setShowLoadingSkeleton(true);
            const response = await axios.post(`${baseApiUrl}/get-single-thread-messages`, { userId, threadId })
            const responseDataThreadMessages = response.data.threadWithMessages?.messages;
            const redoneThreadMessages = await redoThreadMessages(responseDataThreadMessages)
            // console.log('Redone Thread Messages:', redoneThreadMessages);
            setSelectedThreadMessages(redoneThreadMessages)
            setLoading(false);
            setShowLoadingSkeleton(false)
        } catch (error) {
            console.log(error);
            setLoading(false);
            setShowLoadingSkeleton(false)
        }
    }


    const sendMessageToBackend = async (inputText = '') => {
        const newMessageFromInputVar = inputText ? inputText.trim() : promptFromContentLibraryAsInput.trim();
        if (!newMessageFromInputVar) return;
        setPromptFromContentLibraryAsInput('');
        setLoadingForAiResponse(true);
        console.log('Message sent to backend', newMessageFromInputVar);
        const inputTextId = uuid();

        const inputMessageObject = {
            id: uuid(),
            role: 'user',
            content: newMessageFromInputVar
        }

        setSelectedThreadMessages((prevState) => [...prevState, inputMessageObject])
        const isNewAndEmptyThread = selectedThreadMessages[selectedThreadMessages.length - 1].newThread || selectedThreadMessages[selectedThreadMessages.length - 2].newThread ? true : false
        try {
            const response = await fetch(`${baseApiUrl}/get-ai-response`, {
                method: "POST",
                body: JSON.stringify({
                    userId,
                    threadId: isNewAndEmptyThread && selectedThread?.startsWith('new-thread') ? selectedThread.slice(10) : selectedThread,
                    inputText: newMessageFromInputVar,
                    inputTextId,
                    shouldCreateNewThread: isNewAndEmptyThread
                }),
                headers: { "Content-Type": "application/json" },
            });

            const reader = response.body.getReader();
            const decoder = new TextDecoder("utf-8");
            let buffer = "";
            let fullResponse = '';
            let aiMessageId = '';
            let newThreadName = '';

            while (true) {
                const { value, done } = await reader.read();
                if (done) {
                    setLoading(false);
                    break; // Stream has finished
                }
                buffer += decoder.decode(value, { stream: true });
                const lines = buffer.split("\n");

                for (const line of lines.slice(0, -1)) {
                    try {
                        const parsed = JSON.parse(line);
                        // console.log('WHAT CAME FROM BACKEND ------', parsed);
                        if (!aiMessageId) aiMessageId = parsed.id;
                        if (parsed.threadName && !newThreadName) newThreadName = parsed.threadName;
                        if (parsed.content) {
                            setCurrentlyIncomingAiResponseStream(prevMessages => prevMessages + parsed.content);
                            fullResponse += parsed.content;
                        }
                    } catch (error) {
                        console.error("Error parsing chunk:", error);
                    }
                }

                // Retain incomplete lines in the buffer
                buffer = lines[lines.length - 1];
                setLoadingForAiResponse(false);
            }


            const redoneThreadMessagesArray = await redoThreadMessages([{
                id: aiMessageId,
                role: 'assistant',
                content: fullResponse
            }])
            const redoneThreadMessages = redoneThreadMessagesArray[0]
            setSelectedThreadMessages((prevState) => [...prevState, redoneThreadMessages])
            setCurrentlyIncomingAiResponseStream('');

            // Handle the case where the thread name is changed here 
            if (newThreadName) {
                changeThreadNameAndIdOfNewThread(newThreadName, selectedThread, selectedThread.slice(10))
            } else {
                setHaveNewThreadToSet('');

            }

        } catch (error) {
            console.log(error);
            setLoadingForAiResponse(false);
            toast.error('Oops! Something went wrong. Please Reload!')
        }

    }

    const getTokenForUrlPrefix = async () => {
        try {
            const timestamp = Math.floor(Date.now() / 1000 + 604799);
            // const proxyApiKey = process.env.PROXY_API_KEY
            const proxyApiKey = "CNhriIPmW41qPSnVeg1xr1qWvxagEjAe9XUxuWQX"

            const response = await fetch(`https://9msizvyhec.execute-api.us-east-1.amazonaws.com/default/token_generator?apiKey=${proxyApiKey}&timestamp=${timestamp}`)

            const responseJson = await response.json();
            const token = responseJson.token;
            setS3UrToken(token)
        } catch (error) {
            console.log('error while getting token ', error);
            toast.error('Oops! Something went wrong. Please Reload!')
        }

    }

    const generateProxyUrl = async (url) => {
        const videoTimeStamp = url.split("#####").pop();
        const urlWithoutTimestamp = url.split("#####")[0];
        let prefix = '';
        let type = '';
        if (url.includes('https://video.altercall.com/')) {
            const base64 = urlWithoutTimestamp.split("https://video.altercall.com/").pop();
            prefix = atob(base64);
            type = 'video';
        } else {
            const base64 = urlWithoutTimestamp.split("https://download.altercall.com/").pop();
            prefix = atob(base64);
            type = 'pdf';
        }

        try {
            const proxyGenUrl = new URL(
                `https://jd6b6g80f6.execute-api.us-east-1.amazonaws.com/default/proxy_gen`,
            );

            proxyGenUrl.searchParams.set("keyPrefix", prefix);
            proxyGenUrl.searchParams.set("token", s3UrToken);

            const response = await fetch(proxyGenUrl)
            const proxyUrl = await response.json();
            // console.log('proxy url -------', proxyUrl);
            return {
                url: proxyUrl,
                type,
                videoTimeStamp: type === "video" && videoTimeStamp ? videoTimeStamp : "null"
            }
        } catch (error) {
            console.log('Error in getting proxy url', error);
            toast.error('Oops! Something went wrong. Please Reload!')
        }

    }

    const getProxyUrls = async (urlArray) => {
        if (!urlArray?.length) return
        const validUrlsArray = await Promise.all(
            urlArray?.map(async (url) => {
                const validUrl = await generateProxyUrl(url);
                return validUrl;
            })
        )
        return validUrlsArray;
    }

    const extractUrlFromMessageContent = (content) => {
        const urlRegex = /(?<=\()\bhttps?:\/\/[^\s)]+(?=\))/g;
        const urlArray = content.match(urlRegex);
        return urlArray;
    }

    const redoThreadMessages = async (messagesArrayParam) => {
        if (!messagesArrayParam) return;
        if (messagesArrayParam[0]?.newThread) return messagesArrayParam;

        let newThreadObject = []

        // Create an array of promises for processing each message
        const promises = messagesArrayParam.map(async (message) => {
            const urlArray = extractUrlFromMessageContent(message.content);

            if (urlArray) {
                const validUrlsArray = await getProxyUrls(urlArray); // url, type, videoTimeStamp
                let newMessageContent = message.content;

                // Replace each URL with its corresponding proxy URL
                urlArray.forEach((url, index) => {
                    const newUrl = validUrlsArray[index].url + '#####' + validUrlsArray[index].type + '-videoTimeStamp=' + validUrlsArray[index].videoTimeStamp;
                    newMessageContent = newMessageContent.replace(url, newUrl);
                });

                // // Remove everything between parentheses (globally) (Remove Links)
                // const linksRemovedContent = newMessageContent.replace(/\(.*?\)/g, '');

                // // Replace [ and ] with ** (Make the media titles bold)
                // const mediaTitlesBoldContent = linksRemovedContent.replace(/\[|\]/g, '**');

                // If needed filter out only the video urls in the validUrlsArray
                const videoUrlsArray = validUrlsArray.filter(url => url.type === 'video');

                return {
                    ...message,
                    content: newMessageContent,
                    metadata: videoUrlsArray
                };
            }

            return message;
        });

        newThreadObject = await Promise.all(promises);

        return newThreadObject;

    }

    const regenerateMessage = async (messageId, indexInSelectedThreadMessageArray) => {
        /* 
         1. Remove the message and the later messages from ui. Basically remove them from the selectedThreadMesaages array
         2. capture the previous message content from the selectedThreadMessages array.
         3. send the idForMessageToRegenerate, threadId, userInputMessage, userId, to the backend and get id and content for new message
         4. redo the incoming message and add it to the selectedThreadMessages array
         5. make sure the thinking lottie is shown while the message is being regenerated
        */

        const selectedThreadMessagesArrayVar = [...selectedThreadMessages];
        const messageArrayExcludingSelectedAndLaterMessages = selectedThreadMessagesArrayVar.slice(0, indexInSelectedThreadMessageArray);
        const lastUserMessageContent = messageArrayExcludingSelectedAndLaterMessages[messageArrayExcludingSelectedAndLaterMessages.length - 1].content;
        setSelectedThreadMessages(messageArrayExcludingSelectedAndLaterMessages);
        setLoadingForAiResponse(true);

        try {

            const response = await fetch(`${baseApiUrl}/regenerate-ai-response`, {
                method: "POST",
                body: JSON.stringify({
                    idForMessageToRegenerate: messageId,
                    threadId: selectedThread,
                    userInputMessage: lastUserMessageContent,
                    userId,
                }),
                headers: { "Content-Type": "application/json" },
            });

            const reader = response.body.getReader();
            const decoder = new TextDecoder("utf-8");
            let buffer = "";
            let fullResponse = '';
            let aiMessageId = '';

            while (true) {
                const { value, done } = await reader.read();
                if (done) {
                    break; // Stream has finished
                }

                buffer += decoder.decode(value, { stream: true });
                const lines = buffer.split("\n");

                for (const line of lines.slice(0, -1)) {
                    try {
                        const parsed = JSON.parse(line);
                        // console.log('WHAT CAME FROM BACKEND ------', parsed);
                        if (!aiMessageId) aiMessageId = parsed.id;
                        if (parsed.content) {
                            setCurrentlyIncomingAiResponseStream(prevMessages => prevMessages + parsed.content);
                            fullResponse += parsed.content;
                            // scrollToBottom();
                        }
                    } catch (error) {
                        console.error("Error parsing chunk:", error);
                    }
                }

                // Retain incomplete lines in the buffer
                buffer = lines[lines.length - 1];

                setLoadingForAiResponse(false);
                setLoading(false);
            }

            const redoneThreadMessagesArray = await redoThreadMessages([{
                id: aiMessageId,
                role: 'assistant',
                content: fullResponse
            }])
            const redoneThreadMessages = redoneThreadMessagesArray[0]
            setSelectedThreadMessages((prevState) => [...prevState, redoneThreadMessages])
            setCurrentlyIncomingAiResponseStream('');

        } catch (error) {
            toast.error('Oops! Something went wrong. Please Reload!')
            console.log(error);
            setLoadingForAiResponse(false);
        }


    }

    const handleEditUserInput = async (newMessageContent, messageId) => {
        const selectedThreadMessagesArrayVar = [...selectedThreadMessages];
        const indexOfMessageToEdit = selectedThreadMessagesArrayVar.findIndex(message => message.id === messageId);
        selectedThreadMessagesArrayVar[indexOfMessageToEdit].content = newMessageContent;
        const stateToSet = selectedThreadMessagesArrayVar.slice(0, indexOfMessageToEdit + 1);
        setSelectedThreadMessages(stateToSet);
        setMessageIdForVisibleInputFieldForEdit(null);

        try {
            setLoadingForAiResponse(true);
            const response = await fetch(`${baseApiUrl}/edit-query-and-regenerate-response`, {
                method: "POST",
                body: JSON.stringify({
                    threadId: selectedThread,
                    userId,
                    newMesageContent: newMessageContent,
                    messageId,
                }),
                headers: { "Content-Type": "application/json" },
            });

            const reader = response.body.getReader();
            const decoder = new TextDecoder("utf-8");
            let buffer = "";
            let fullResponse = '';
            let aiMessageId = '';
            let newThreadName = '';

            while (true) {
                const { value, done } = await reader.read();
                if (done) {
                    setLoading(false);
                    break; // Stream has finished
                }
                buffer += decoder.decode(value, { stream: true });
                const lines = buffer.split("\n");

                for (const line of lines.slice(0, -1)) {
                    try {
                        const parsed = JSON.parse(line);
                        // console.log('WHAT CAME FROM BACKEND ------', parsed);
                        if (!aiMessageId) aiMessageId = parsed.id;
                        if (parsed.threadName && !newThreadName) newThreadName = parsed.threadName;
                        if (parsed.content) {
                            setCurrentlyIncomingAiResponseStream(prevMessages => prevMessages + parsed.content);
                            fullResponse += parsed.content;
                            // scrollToBottom();
                        }
                    } catch (error) {
                        console.error("Error parsing chunk:", error);
                    }
                }

                // Retain incomplete lines in the buffer
                buffer = lines[lines.length - 1];
                setLoadingForAiResponse(false);
            }


            const redoneThreadMessagesArray = await redoThreadMessages([{
                id: aiMessageId,
                role: 'assistant',
                content: fullResponse
            }])
            const redoneThreadMessages = redoneThreadMessagesArray[0]
            setSelectedThreadMessages((prevState) => [...prevState, redoneThreadMessages])
            setCurrentlyIncomingAiResponseStream('');
        } catch (error) {
            console.log(error);
            toast.error('Oops! Something went wrong. Please Reload!')
            setLoadingForAiResponse(false);

        }

    }

    const changeThreadNameAndIdOfNewThread = (threadName, oldThreadId, newThreadId) => {
        const threadNamesArray = [...threadNames];
        const indexOfSelectedThread = threadNamesArray.findIndex(thread => thread.id === oldThreadId);
        threadNamesArray[indexOfSelectedThread].name = threadName;
        threadNamesArray[indexOfSelectedThread].id = newThreadId;
        setThreadNames(threadNamesArray);
        setHaveNewThreadToSet(newThreadId);
        setSelectedThread(newThreadId);

    }

    const getConvoAreaWidth = () => {
        // Base logic for sidebar and content library open/closed
        if (!sidebarOpen && !contentLibraryOpen) {
            if (isMobile) return '100%';
            if (isTablet) return '100%';
            if (isDesktop) return '100%';
        }

        if (sidebarOpen && !contentLibraryOpen) {
            if (isMobile) return '0%';
            if (isTablet) return '0%';
            if (isDesktop) return '78%';
        }

        if (contentLibraryOpen && !sidebarOpen) {
            if (isMobile) return '0%';
            if (isTablet) return '0%';
            if (isDesktop) return '60%';
        }

        // if (contentLibraryOpen && sidebarOpen) {
        //     if (isMobile) return '0%';
        //     if (isTablet) return '0%';
        //     if (isDesktop) return '40%';
        // }

    };

    const handleMarkdownLinkClick = (event, props, messageId) => {
        event.preventDefault(); // Prevent default link behavior
        const rawUrl = props.href;
        const url = rawUrl.split('#####')[0];
        const typeAndTimeStamp = rawUrl.split('#####').pop();
        const typeAndTimeStampArray = typeAndTimeStamp.split('-videoTimeStamp=')
        const type = typeAndTimeStampArray[0];
        const videoTimeStamp = typeAndTimeStampArray[1];
        if (type === 'pdf') {
            // window.open(url, '_blank');
            // return;
            setSelectedPdfUrl(url);
            handleOpenPdfModal();
            return;
        }
        if (type === 'video') {
            setSelectedVideoInfo((prevState) => ({ ...prevState, [messageId]: { url, type, videoTimeStamp } }))
        }
    };

    const getUrlInfo = (props) => {
        const rawUrl = props.href;
        const url = rawUrl.split('#####')[0];
        const typeAndTimeStamp = rawUrl.split('#####').pop();
        const typeAndTimeStampArray = typeAndTimeStamp.split('-videoTimeStamp=')
        const type = typeAndTimeStampArray[0];
        const videoTimeStamp = typeAndTimeStampArray[1];
        return { url, type, videoTimeStamp }
    }

    const handleStartAudioRecording = () => {
        setShowAudioRecordingModal(true);

    }
    const sendAudioTranscriptToBackendForAiResponse = async (inputText) => {
        await sendMessageToBackend(inputText)
    }
    return (
        <Box
            sx={{
                width: getConvoAreaWidth(),
                transition: 'width 0.5s ease',

                boxSizing: 'border-box',
                backgroundColor: 'white',
                display: {
                    xs: contentLibraryOpen ? 'none' : sidebarOpen ? 'none' : 'block',
                    sm: contentLibraryOpen ? 'none' : sidebarOpen ? 'none' : 'block',
                    md: 'block',
                },
                borderTopRightRadius: {
                    xs: contentLibraryOpen ? '0px' : '0px',
                    sm: contentLibraryOpen ? '0px' : '0px',
                    md: contentLibraryOpen ? '0px' : '40px',
                },
                borderBottomRightRadius: {
                    xs: contentLibraryOpen ? '0px' : '0px',
                    sm: contentLibraryOpen ? '0px' : '0px',
                    md: contentLibraryOpen ? '0px' : '40px',
                },
                borderTopLeftRadius: {
                    xs: sidebarOpen ? '0px' : '0px',
                    sm: sidebarOpen ? '0px' : '0px',
                    md: sidebarOpen ? '0px' : '40px',
                },
                borderBottomLeftRadius: {
                    xs: sidebarOpen ? '0px' : '0px',
                    sm: sidebarOpen ? '0px' : '0px',
                    md: sidebarOpen ? '0px' : '40px',
                },
                paddingTop: '100px',
            }}>
            {/* Messages Container */}
            <Box
                sx={{
                    height: '90%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    gap: '30px',
                    position: 'relative',
                }}>
                {/* Frigade */}
                <Frigade.Card
                    flowId="flow_hYmZL54G"
                    dismissible={true}
                    style={{
                        position: "fixed", // Makes the carousel fixed position relative to the viewport
                        top: "50%", // Positions the top edge of the carousel at the center of the viewport
                        left: "50%", // Positions the left edge of the carousel at the center of the viewport
                        transform: "translate(-50%, -50%)", // Shifts the carousel back to truly center it
                        maxWidth: "600px",
                        maxHeight: "600px",
                        width: "90%", // Responsive width, adjust as needed
                        margin: "0", // Removes auto margin since it's now absolutely positioned
                        boxShadow: "0 50px 100vw rgba(0, 0, 0, 0.8)", // X=0, Y=5, Blur=15, and a subtle opacity for better effect
                        border: "1px solid #ccc",
                        borderRadius: "10px",
                        overflow: "scroll",
                        padding: "20px",
                        zIndex: 1000, // High z-index to ensure it's on top of other content
                        background: "white", // Ensures the content is readable by providing a solid background
                        fontFamily: "Poppins",
                    }}
                />
                <Frigade.Tour
                    flowId="flow_TYcGa40F"
                    defaultOpen={true}
                    sequential={true}
                    dismissible={true}
                    css={{
                        ".fr-card": {
                            backgroundColor: "#EFF3F6",
                        },
                        ".fr-button-primary": {
                            backgroundColor: "white",
                            color: "black",
                            borderColor: "black",
                            borderRadius: "50px",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            transition: "background-color 0.3s, color 0.3s",
                            "&:hover": {
                                backgroundColor: "black",
                                color: "white",
                            },
                        },
                    }}
                />
                {
                    !showLoadingSkeleton ?

                        // Messages display Area
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '30px',
                            height: 'auto',
                            width: '100%',
                            overflowY: 'scroll',
                            my: 'end',
                        }}>
                            {selectedThreadMessages &&
                                selectedThreadMessages?.map((message, index) => {
                                    return message?.role === 'assistant' ?
                                        // Assistant Messages
                                        <Box key={message?.id}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                width: {
                                                    xs: '85%',
                                                    sm: '80%',
                                                    md: '80%',
                                                },
                                                mx: 'auto',
                                            }}
                                        >
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                maxWidth: "560px",
                                            }}>
                                                <Box sx={{
                                                    height: 'auto',
                                                    paddingTop: '10px',
                                                }}>
                                                    <VcProfilePicture />

                                                </Box>
                                                <Box
                                                    sx={{
                                                        paddingX: '10px',
                                                        color: 'black',
                                                        maxWidth: '100%',
                                                        wordWrap: 'break-word',
                                                        // border: '1px solid green',
                                                    }}
                                                >
                                                    <ReactMarkdown
                                                        remarkPlugins={[remarkGfm]} // Enables table support
                                                        components={{
                                                            a: ({ node, ...props }) => {
                                                                return (
                                                                    <a
                                                                        href={props.href}
                                                                        onClick={(e) => handleMarkdownLinkClick(e, props, message.id)}
                                                                        style={{ color: '#4A90E2', cursor: 'pointer', fontWeight: 'bold', textDecoration: 'none' }}
                                                                    >
                                                                        {
                                                                            getUrlInfo(props).type === 'video' &&
                                                                            <Box component='span' sx={{
                                                                                // border: '1px solid red',
                                                                                display: 'inline-flex',
                                                                                alignItems: 'center',
                                                                                paddingY: '0',
                                                                                paddingRight: '2px',


                                                                            }}>
                                                                                <VideoIconForVC color='#4A90E2' />
                                                                            </Box>

                                                                        }
                                                                        {
                                                                            getUrlInfo(props).type === 'pdf' &&
                                                                            <Box component='span' sx={{
                                                                                display: 'inline-flex',
                                                                                alignItems: 'center',
                                                                                paddingY: '0',
                                                                                paddingRight: '2px',


                                                                            }}>
                                                                                <PdfIconForVC color='#4A90E2' />
                                                                            </Box>
                                                                        }

                                                                        {props.children}
                                                                    </a>
                                                                );
                                                            },
                                                            table: ({ node, ...props }) => (
                                                                <table style={{ width: '100%', borderCollapse: 'collapse', }} {...props} />
                                                            ),
                                                            th: ({ node, ...props }) => (
                                                                <th style={{ border: '1px solid #ddd', padding: '10px', backgroundColor: '#EFF3F6' }} {...props} />
                                                            ),
                                                            td: ({ node, ...props }) => (
                                                                <td style={{ border: '1px solid #ddd', padding: '10px' }} {...props} />
                                                            ),
                                                        }}
                                                    >
                                                        {message?.content}
                                                    </ReactMarkdown>
                                                    {/* Video Player */}
                                                    {
                                                        message.metadata && Array.isArray(message?.metadata) &&
                                                        message?.metadata[0]?.type === 'video' && message?.metadata[0]?.url && (
                                                            <VideoPlayer urlInfo={selectedVideoInfo[message.id] || message?.metadata[0]} />
                                                        )
                                                    }

                                                    {/* Regenerate AND Voice */}
                                                    <Box
                                                        sx={{
                                                            // border: '1px solid red',
                                                            marginTop: '10px',
                                                            display: message.newThread ? 'none' : 'flex',
                                                            gap: '10px',
                                                            justifyContent: 'flex-start',

                                                        }}
                                                    >
                                                        {
                                                            !message.newThread && <TextToSpeech
                                                                text={message.content}
                                                                id={message.id}
                                                            />
                                                        }

                                                        <Tooltip title="Think Again">
                                                            <LoopIcon sx={{
                                                                color: 'black',
                                                                cursor: 'pointer',
                                                                fontSize: '18px',
                                                            }}
                                                                onClick={() => regenerateMessage(message.id, index)}
                                                            />
                                                        </Tooltip>

                                                    </Box>


                                                </Box>
                                            </Box>
                                        </Box>
                                        :
                                        // User Messages
                                        <Box
                                            key={message?.id}
                                            sx={{
                                                display: 'flex',

                                                justifyContent: 'flex-end',

                                                width: {
                                                    xs: '85%',
                                                    sm: '80%',
                                                    md: '80%',
                                                },
                                                mx: 'auto',
                                                // border: '1px solid green',
                                            }}>
                                            {
                                                messageIdForVisibleInputFieldForEdit === message.id ?

                                                    <Box sx={{
                                                        width: '100%',
                                                        // border: '1px solid lightgray',
                                                        borderRadius: '10px',
                                                        position: 'relative',
                                                        paddingBottom: '50px',
                                                        backgroundColor: "#EFF3F6",

                                                        // height: 'auto'
                                                    }}>
                                                        <TextField

                                                            multiline
                                                            value={editUserInputValue}
                                                            onChange={(e) => setEditUserInputValue(e.target.value)}
                                                            variant="outlined"
                                                            fullWidth
                                                            sx={{
                                                                '& .MuiInputBase-input': {
                                                                    color: 'black', // Set text color here
                                                                    border: 0,
                                                                },
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        border: '0',
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        // borderColor: 'black', // Border color on hover
                                                                        // border: '1px solid black'
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        // borderColor: 'black', // Border color when focused
                                                                        // border: '1px solid black'

                                                                    },
                                                                },
                                                            }}
                                                        />

                                                        <Box
                                                            sx={{
                                                                position: 'absolute',
                                                                bottom: '10px',
                                                                right: '10px',
                                                                // border: '1px solid red',
                                                                marginTop: '10px',
                                                                display: 'flex',
                                                                justifyContent: 'flex-end',
                                                                gap: '10px',
                                                            }}
                                                        >
                                                            <Button
                                                                variant='outlined'
                                                                sx={{
                                                                    borderRadius: '50px',
                                                                    width: '100px'
                                                                }}
                                                                onClick={() => setMessageIdForVisibleInputFieldForEdit(null)}
                                                            >
                                                                Cancel
                                                            </Button>
                                                            <Button
                                                                variant='contained'
                                                                sx={{
                                                                    borderRadius: '50px',
                                                                    width: '100px'
                                                                }}
                                                                onClick={() => handleEditUserInput(editUserInputValue, message.id)}
                                                            >
                                                                Save
                                                            </Button>
                                                        </Box>


                                                    </Box>

                                                    :
                                                    <Box sx={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                        gap: '30px',
                                                        alignItems: 'top',
                                                        // border: '1px solid red',

                                                    }}
                                                        onMouseEnter={() => setMessageIdForVisibleEditIcon(message.id)}
                                                        onMouseLeave={() => setMessageIdForVisibleEditIcon(null)}
                                                    >
                                                        <Tooltip title="Edit Message">

                                                            <EditIcon sx={{
                                                                display: messageIdForVisibleEditIcon === message.id ? 'block' : 'none',
                                                                marginTop: '10px',
                                                                color: 'black',
                                                                cursor: 'pointer',
                                                                fontSize: '18px',
                                                            }}
                                                                onClick={() => {
                                                                    setMessageIdForVisibleInputFieldForEdit(message.id)
                                                                    setEditUserInputValue(message.content)
                                                                }}
                                                            />
                                                        </Tooltip>
                                                        <Box sx={{
                                                            padding: '15px',
                                                            borderRadius: '10px',
                                                            backgroundColor: '#EFF3F6',
                                                            color: 'black',
                                                            maxWidth: "560px",
                                                            wordWrap: 'break-word',
                                                        }}

                                                        >
                                                            <Typography sx={{
                                                                color: 'black',
                                                                fontFamily: "Lexend",
                                                                fontSize: '15px',
                                                            }}>{message.content}</Typography>
                                                        </Box>
                                                    </Box>

                                            }

                                        </Box>
                                })
                            }

                            {
                                currentlyIncomingAiResponseStream &&
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        width: {
                                            xs: '85%',
                                            sm: '80%',
                                            md: '80%',
                                        },
                                        mx: 'auto',
                                    }}
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        maxWidth: "560px",
                                    }}>
                                        <Box sx={{
                                            height: 'auto',
                                            paddingTop: '10px',
                                        }}>
                                            <VcProfilePicture />

                                        </Box>
                                        <Box
                                            sx={{
                                                paddingX: '10px',
                                                color: 'black',
                                                maxWidth: '100%',
                                                wordWrap: 'break-word',
                                                // border: '1px solid green',
                                            }}
                                        >
                                            <ReactMarkdown
                                                remarkPlugins={[remarkGfm]} // Enables table support
                                                components={{
                                                    a: ({ node, ...props }) => {
                                                        return (
                                                            <a
                                                                href={props.href}
                                                                // onClick={(e) => handleMarkdownLinkClick(e, props, message.id)}
                                                                style={{ color: '#4A90E2', cursor: 'pointer', fontWeight: 'bold', textDecoration: 'none' }}
                                                            >
                                                                {
                                                                    getUrlInfo(props).type === 'video' &&
                                                                    <Box component='span' sx={{
                                                                        // border: '1px solid red',
                                                                        display: 'inline-flex',
                                                                        alignItems: 'center',
                                                                        paddingY: '0',
                                                                        paddingRight: '2px',


                                                                    }}>
                                                                        <VideoIconForVC color='#4A90E2' />
                                                                    </Box>

                                                                }
                                                                {
                                                                    getUrlInfo(props).type === 'pdf' &&
                                                                    <Box component='span' sx={{
                                                                        display: 'inline-flex',
                                                                        alignItems: 'center',
                                                                        paddingY: '0',
                                                                        paddingRight: '2px',


                                                                    }}>
                                                                        <PdfIconForVC color='#4A90E2' />
                                                                    </Box>
                                                                }

                                                                {props.children}
                                                            </a>
                                                        );
                                                    },
                                                    table: ({ node, ...props }) => (
                                                        <table style={{ width: '100%', borderCollapse: 'collapse', }} {...props} />
                                                    ),
                                                    th: ({ node, ...props }) => (
                                                        <th style={{ border: '1px solid #ddd', padding: '10px', backgroundColor: '#EFF3F6' }} {...props} />
                                                    ),
                                                    td: ({ node, ...props }) => (
                                                        <td style={{ border: '1px solid #ddd', padding: '10px' }} {...props} />
                                                    ),
                                                }}
                                            >
                                                {currentlyIncomingAiResponseStream}
                                            </ReactMarkdown>





                                        </Box>
                                    </Box>
                                </Box>
                            }
                            <TypingLottie loadingForAiResponse={loadingForAiResponse} />

                            {/* Invisible box to scroll to it  */}
                            <Box
                                sx={{
                                    height: '1px',
                                    width: '100%',
                                    color: 'black',
                                }}
                                ref={messageRef}
                            />
                        </Box>

                        :
                        <LoadingSkeleton />
                }

                {/* Starting FLoating Prompts */}
                {
                    selectedThreadMessages && selectedThreadMessages?.length === 1 && selectedThreadMessages[0]?.newThread &&
                    <StartingPrompts sidebarOpen={sidebarOpen} contentLibraryOpen={contentLibraryOpen} setPromptFromContentLibraryAsInput={setPromptFromContentLibraryAsInput} />
                }

            </Box>



            {/* Text sending Input area */}
            <Box sx={{
                width: '95%',
                // border: '2px solid red',
                minHeight: '8%',
                color: 'black',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: "#EFF3F6",
                borderRadius: '40px',
                paddingX: '10px',
                mx: 'auto',
            }}>

                {

                    showAudioRecordingModal ?
                        <SpeechToText
                            setShowAudioRecordingModal={setShowAudioRecordingModal}
                            // showAudioRecordingModal={showAudioRecordingModal}
                            sendAudioTranscriptToBackendForAiResponse={sendAudioTranscriptToBackendForAiResponse}
                        />
                        :
                        <>
                            <Box
                                sx={{
                                    // width: '93%',
                                    width: '95%',
                                    // border: '1px solid green',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: loadingForAiResponse ? "not-allowed" : "text",
                                }}>
                                <TextField
                                    disabled={loadingForAiResponse}

                                    value={promptFromContentLibraryAsInput}
                                    onChange={(e) => setPromptFromContentLibraryAsInput(e.target.value)}
                                    onKeyDown={(e) => e.key === 'Enter' && sendMessageToBackend()}
                                    id="outlined-basic"

                                    variant="standard"
                                    placeholder="Type your message here ...."
                                    maxRows={2}
                                    multiline
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    sx={{
                                        color: 'black',
                                        '& .MuiInputBase-input': {
                                            color: 'black',
                                        },
                                        paddingX: '10px',

                                        fontFamily: 'Lexend',
                                    }}
                                    style={{
                                        backgroundColor: 'transparent',
                                        cursor: loadingForAiResponse ? "not-allowed" : "text"
                                    }}
                                />
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    // border: '1px solid red',
                                    gap: '10px',
                                }}
                            >
                                <Button
                                    onClick={() => !loadingForAiResponse && sendMessageToBackend()}
                                    sx={{

                                        borderRadius: '50%',
                                        backgroundColor: loadingForAiResponse ? '' : '#E4E8EC', // Default background
                                        cursor: loadingForAiResponse ? "not-allowed" : "pointer",
                                        '&:hover': {
                                            backgroundColor: !loadingForAiResponse ? '#337BE8' : '', // Hover background when not loading
                                            '& svg path': {
                                                stroke: !loadingForAiResponse ? '#FFFFFF' : '#111111', // Change icon color on hover
                                            },
                                        },
                                        width: "40px",
                                        height: "40px",
                                        minWidth: "40px",
                                        minHeight: "40px",
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <span id="hint-anchor-1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <VirtualCoachSendIcon
                                            color='#111111'
                                        />
                                    </span>
                                </Button>

                                {/* // Voice to Chat */}
                                <Tooltip title='Use voice to chat'>
                                    <Box onClick={() => !loadingForAiResponse && handleStartAudioRecording()}
                                        sx={{
                                            cursor: loadingForAiResponse ? "not-allowed" : "pointer",
                                            // border: '1px solid black',
                                            backgroundColor: loadingForAiResponse ? '' : '#E4E8EC', // Default background
                                            width: "40px",
                                            height: "40px",
                                            minWidth: "40px",
                                            minHeight: "40px",
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderRadius: '50%',
                                            padding: '7px',
                                            transition: 'background-color 0.6s ease',
                                            '&:hover': {
                                                backgroundColor: !loadingForAiResponse ? '#337BE8' : '#E4E8EC', // Hover background when not loading
                                                '& svg path': {
                                                    stroke: !loadingForAiResponse ? 'white' : '#111111', // Change icon color on hover
                                                },
                                            },


                                        }}
                                    >

                                        <VoiceToChatSvg color={loadingForAiResponse ? 'black' : '#FF5E00'} />
                                    </Box>
                                </Tooltip>


                            </Box>
                        </>
                }
            </Box>


        </Box >
    );
};

export default ConvoArea;