import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import NewThreadIcon from "../Icons/NewThreadIcon.svg";
import * as Frigade from "@frigade/react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import VCPromptPreviousBtnVC from "components/Icons/VCPromptPreviousBtnVC.svg";
import VCPromptCarouselNext from "components/Icons/VCPromptCarouselNext.svg";
import { theme } from "scenes/ClientSignup";

const StartingPrompts = ({
  setPromptFromContentLibraryAsInput,
  contentLibraryOpen,
  sidebarOpen,
}) => {

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg')); // Check for desktop view
  const isTablet = useMediaQuery(theme.breakpoints.up('md')); // Check for tablet view
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check for mobile view

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: contentLibraryOpen && !sidebarOpen ? 2 : !contentLibraryOpen && sidebarOpen ? 2 : 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };


  const dataForStartLearningSection = [
    {
      id: 1,
      title: "Innovation",
      description: "Create a table of innovative strategies that I can implement to disrupt my industry and create long-term growth for my business."
    },
    {
      id: 2,
      title: "Leadership",
      description: "How can I cultivate a leadership style that inspires my team to connect with a deeper purpose, beyond just results?"
    },
    {
      id: 3,
      title: "Branding",
      description: "Create a 2-day course for branding, include time slots and resources."
    },
    {
      id: 4,
      title: "Team Building",
      description: "What are the key traits I need to foster in my team to create a culture of excellence and peak performance?"
    },
    {
      id: 5,
      title: "Vision",
      description: "How can I develop the mindset of a visionary leader to drive my organization forward in uncertain times?"
    },
    {
      id: 6,
      title: "Personal Growth",
      description: "How can I align my personal growth journey with the growth of my business to create a harmonious path to success?"
    },
    {
      id: 7,
      title: "Conflict Resolution",
      description: "How can I turn moments of conflict within my team into opportunities for deeper collaboration and trust-building?"
    },
    {
      id: 8,
      title: "Communication",
      description: "What strategies can I use to approach difficult conversations with empathy while maintaining clear boundaries and objectives?"
    },
    {
      id: 9,
      title: "Leadership",
      description: "What tools can I use to lead effectively and make confident decisions when the future of my industry is uncertain?"
    },
    {
      id: 10,
      title: "Resilience",
      description: "What practices can I adopt to stay grounded and resilient while facing the intense challenges of entrepreneurship?"
    },
    {
      id: 11,
      title: "Balance",
      description: "How can I achieve a meaningful balance between growing my career and living a fulfilling personal life?"
    },
    {
      id: 12,
      title: "Influence",
      description: "How can I enhance my influence in business and life by leading with authenticity and integrity?"
    }
  ];

  return (
    <Box
      sx={{
        position: "absolute",
        display: {
          xs: contentLibraryOpen ? "none" : sidebarOpen ? "none" : "block",
          sm: contentLibraryOpen ? "none" : sidebarOpen ? "none" : "block",
          md: "block",
        },
        top: 0,
        width: "100%",
      }}
    >
      <Frigade.Tour
        flowId="flow_TYcGa40F"
        defaultOpen={true}
        sequential={true}
        dismissible={true}
        css={{
          ".fr-card": {
            backgroundColor: "#EFF3F6",
          },
          ".fr-button-primary": {
            backgroundColor: "white",
            color: "black",
            borderColor: "black",
            borderRadius: "50px",
            borderWidth: "1px",
            borderStyle: "solid",
            transition: "background-color 0.3s, color 0.3s",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },

          },
        }}
      />
      <Box>
        <Box
          sx={{
            border: '1px solid #568FF3', // blue
            padding: '30px',
            borderRadius: '20px',
            position: 'relative',
            width: '75%',
            maxWidth: sidebarOpen && contentLibraryOpen ? '350px' : '900px',
            mx: 'auto',
            height: {
              xs: '300px',
              sm: '300px',
              md: sidebarOpen && contentLibraryOpen ? '300px' : '250px',
            },
            display: 'flex',
            flexDirection: 'column',
            gap: '30px',
          }}
        >
          <Box sx={{
            position: 'relative',
          }}>
            <Typography
              sx={{
                color: "black", fontSize: "24px", fontFamily: "Poppins", textAlign: {
                  xs: 'center',
                  sm: 'center',
                  md: 'start'
                },
              }}
              id="hint-anchor-2"
            >
              Start Learning
            </Typography>



          </Box>


          <Carousel
            key={`${contentLibraryOpen}-${sidebarOpen}`}
            arrows={false} customButtonGroup={isDesktop && <ButtonGroup />}
            responsive={responsive}
            // slidesToSlide={isDesktop ? 3 : isTablet ? 2 : 1}
            slidesToSlide={1}
            renderButtonGroupOutside={isDesktop && true}
            infinite={true}
            showDots={false}

          >
            {dataForStartLearningSection?.map((data) => (
              <Box key={data.id} sx={{ position: "relative" }}>
                {/* Main Box */}
                <Box
                  sx={{
                    maxWidth: contentLibraryOpen ? '230px' : "270px",
                    height: "120px",
                    backgroundColor: "#EFF3F6",
                    borderRadius: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    paddingLeft: "5%",
                    paddingRight: "10%",
                    paddingY: "15px",
                    cursor: "pointer",
                    position: "relative",
                    overflow: "hidden", // Ensures overlay stays inside
                    "&:hover .hover-overlay": {
                      // Trigger the overlay on hover
                      opacity: 1,
                    },
                    transition: "all 0.3s ease",
                  }}
                >
                  <Typography
                    sx={{
                      paddingX: "5%",
                      paddingY: "5px",
                      color: "#568FF3",
                      border: "1px solid #568FF3",
                      borderRadius: "8px",
                      display: "inline-block",
                      width: "fit-content",
                      backgroundColor: "rgba(86, 143, 243, 0.15)",
                    }}
                  >
                    {data.title}
                  </Typography>

                  <Typography
                    sx={{
                      color: "black",
                      width: "fit-content",
                      fontSize: "9px",
                    }}
                  >
                    {data.description}
                  </Typography>

                  {/* Floating "Create New Thread" Overlay */}
                  <Box
                    className="hover-overlay"
                    sx={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      top: 0,
                      left: 0,
                      borderRadius: "20px",
                      backgroundColor: "rgba(255, 255, 255, 0.6)", // 80% transparent white
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      opacity: 0, // Hidden by default
                      transition: "opacity 0.3s ease", // Smooth transition on hover
                    }}
                    onClick={() => setPromptFromContentLibraryAsInput(data.description)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        backgroundColor: "transparent",
                        borderRadius: "50px",
                        paddingX: "15px",
                        paddingY: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <NewThreadIcon color={"#000000"} />

                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 800,
                          color: "black",
                        }}
                      >
                        New Thread
                      </Typography>
                    </Box>
                  </Box>


                </Box>
              </Box>
            ))}
          </Carousel>



        </Box>
      </Box>
    </Box>
  );
};

export default StartingPrompts;



const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  return (
    <Box className="carousel-button-group" sx={{
      position: 'absolute',
      display: 'flex',
      gap: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      right: 40,
    }}>
      <Box onClick={() => previous()}
        sx={{
          padding: '7px',
          cursor: 'pointer',
          backgroundColor: 'white ',
          color: 'black',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid black',
        }}
      >
        <VCPromptPreviousBtnVC />
      </Box>

      <Box onClick={() => next()}
        sx={{
          padding: '7px',
          cursor: 'pointer',
          backgroundColor: 'white ',
          color: 'black',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid black',
        }}
      >
        <VCPromptCarouselNext />
      </Box>

    </Box>
  );
};




