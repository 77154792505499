import {
    Box,
    Typography,
  } from "@mui/material";
  import { styled } from "@mui/material/styles";

  export const GrayStatusPill = ({ text }) => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "15px", 
                backgroundColor: "#F2F4F7",
                width: "100px", 
                height: "32px", 
                paddingX: "0", 
                paddingY: "0", 
            }}
        >
            <Typography
                sx={{
                    textAlign: "center",
                    color: "#364254",
                    fontSize: "14px",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    lineHeight: "18px", 
                    overflow: "hidden", 
                    whiteSpace: "nowrap", 
                    textOverflow: "ellipsis",
                }}
            >
                {text || " "}
            </Typography>
        </Box>
    );
};

export const GreenStatusPill = ({ text }) => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "15px", 
                backgroundColor: "#B4FFD2", 
                width: "100px",
                height: "32px",
                paddingX: "0", 
                paddingY: "0", 
            }}
        >
            <Typography
                sx={{
                    textAlign: "center",
                    color: "#00643A", 
                    fontSize: "14px",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    lineHeight: "18px", 
                    overflow: "hidden", 
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                }}
            >
                {text || " "}
            </Typography>
        </Box>
    );
};

export const BlueStatusPill = ({ text }) => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "15px", 
                backgroundColor: "#C5E8FF",
                width: "100px", 
                height: "32px", 
                paddingX: "0", 
                paddingY: "0", 
            }}
        >
            <Typography
                sx={{
                    textAlign: "center",
                    color: "#00588B", 
                    fontSize: "14px",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    lineHeight: "18px", 
                    overflow: "hidden", 
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                }}
            >
                {text || " "}
            </Typography>
        </Box>
    );
};

export const OrangeStatusPill = ({ text }) => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "15px", // Match GeneratingBadge pill's rounded corners
                backgroundColor: "#f8e9e2", // Light orange background color
                width: "100px", // Match width
                height: "32px", // Match height
                paddingX: "0", // Remove extra horizontal padding
                paddingY: "0", // Remove extra vertical padding
            }}
        >
            <Typography
                sx={{
                    textAlign: "center",
                    color: "#FF5E00", // Orange text color
                    fontSize: "14px",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    lineHeight: "18px", // Match lineHeight for consistent spacing
                    overflow: "hidden", // Prevent text overflow
                    whiteSpace: "nowrap", // Keep text in a single line
                    textOverflow: "ellipsis", // Add ellipsis for overflowed text
                }}
            >
                {text || " "}
            </Typography>
        </Box>
    );
};

const GeneratingBadge = ({ text }) => {
    return (
      <svg
        width="100"
        height="32"
        viewBox="0 0 100 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g style={{ mixBlendMode: "multiply" }}>
          <g clipPath="url(#clip0_9917_8208)">
            <rect x="1" y="1" width="98" height="30" rx="15" fill="#F2F4F7" />
            <rect
              x="1"
              y="1"
              width="98"
              height="30"
              rx="15"
              stroke="url(#paint0_linear_9917_8208)"
              strokeWidth="2"
            />
            <text
              x="50%"
              y="50%"
              dominantBaseline="middle"
              textAnchor="middle"
              fill="#3320FF"
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="500"
              lineHeight="18px"
            >
              {text}
            </text>
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_9917_8208"
            x1="50"
            y1="-9.5"
            x2="98"
            y2="23.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EEEEEE" />
            <stop offset="1" stopColor="#568FF3" />
            <animateTransform
              attributeName="gradientTransform"
              type="rotate"
              from="0 50 16"
              to="360 50 16"
              dur="4s"
              repeatCount="indefinite"
            />
          </linearGradient>
          <clipPath id="clip0_9917_8208">
            <rect width="100" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  };
  
export { GeneratingBadge };
  