import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Routes, Route, useNavigate } from "react-router-dom";
import CoachDashboard from "./pages/platform/coach/CoachDashboard";
import SignUpPage from "./pages/signup.jsx";
import PasswordReset from "./scenes/PasswordReset";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { Amplify } from "aws-amplify";
import { Hub } from "aws-amplify/utils";
// import awsConfig from "./aws-exports";
import TermsPage from "./scenes/SignUp/TermsPage";
import ClientSignUp from "./scenes/ClientSignup";
import ClientDashboard from "./pages/platform/client/ClientDashboard.jsx";
import { ToastContainer } from "react-toastify";
import Settings from "pages/settings";
import { Provider as ReduxProvider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import Terms from "./scenes/Terms";
import Support from "./scenes/Support";
import PrivacyPolicy from "scenes/PrivacyPolicy";
import Actions from "scenes/Actions/Actions";
import VcPaymentSuccessPage from "pages/vcPaymentSuccess";
import CpPlatformPaymentSuccessPage from "pages/cpPlatformPaymentSuccess";
import SignInPage from "scenes/SignIn";
import CoachingSignUp from "scenes/CoachingSignUp";
import { Authenticator } from "@aws-amplify/ui-react";
import { PaymentProvider } from "hooks/PaymentProvider";
import UserGuide from "pages/userGuide";
import RetuneBotPage from "./pages/bot";
import CoachVirtualCoachPage from "pages/platform/coach/CoachVirtualCoach";
import ClientVirtualCoachPage from "pages/platform/client/ClientVirtualCoach";
import ClientSessionPage from "pages/platform/client/ClientSession";
import CoachSessionPage from "pages/platform/coach/CoachSession";
import CancelPaymentPage from "pages/cancelPaymentPage";
import PricingTablePage from "pages/pricingTablePage";
import ClientOverview from "pages/platform/UserOverview";
import HomePage from "./pages";
import { usePayment } from "hooks/PaymentContext";
import AuthenticatingLoader from "pages/authenticatingLoader";
import Blogs from "scenes/Blogs/Blogs";
import SingleBlog from "scenes/Blogs/SingleBlog";
import InviteAcceptance from "pages/platform/client/InviteAcceptance";
import AddNewBlog from "scenes/Blogs/AddNewBlog";
import Resources from "./scenes/Resources/ResourceContainer";
import ResourcePage from "scenes/Resources/ResourcePage";
import ResourceEditingPage from "scenes/Resources/ResourceEditingPage";
import CoachLaraAnalytics from "pages/platform/coach/CoachLaraAnalytics";
import ClientLaraAnalytics from "pages/platform/client/ClientLaraAnalytics";
import { PrimeReactProvider } from 'primereact/api';
import {
  getCurrentUser,
  fetchAuthSession,
  fetchUserAttributes,
} from "aws-amplify/auth";

// import outputs from "./config/amplify_outputs.json";
import AssessmentTestPage from "pages/platform/AssessmentTestPage";
import CoachAssessments from "pages/platform/coach/CoachAssessments";
import ClientAssessments from "pages/platform/client/ClientAssessments";
import CoachIndividualBreakdown from "pages/platform/coach/CoachIndividualBreakdown";
import CoachTeamBreakdown from "pages/platform/coach/CoachTeamBreakdown";
import AdminPortal from "pages/platform/enterprise/AdminPortal";
import * as Frigade from "@frigade/react";
import { NotificationProvider } from "./contexts/NotificationContext";
import { AssessmentsProvider } from './hooks/assessmentsProvider'; // Adjust the path as needed
import { fetchAssessments } from "utils/fetchAssessments";
import { fetchLara } from "utils/fetchLara";
import { queryOptions } from "@tanstack/react-query";

import Ocean from "scenes/OceanIframe/Ocean";
import OceanFunnel from "scenes/OceanIframe/OceanFunnel";

let outputs;

if (process.env.CI === 'true' || process.env.USE_MOCK_CONFIG === 'true') {
  outputs = require('./data/cypressMock.json');
} else {
  try {
    console.log("Attempting to load amplify_outputs.json for production or local development");
    outputs = require('./config/amplify_outputs.json');
  } catch (error) {
    console.warn("amplify_outputs.json not found, defaulting to cypressMock.json");
    outputs = require('./data/cypressMock.json');
  }
}

Amplify.configure(outputs);

// Initialize React Query Client
const queryClient = new QueryClient();


const FRIGADE_API_KEY = process.env.REACT_APP_FRIGADE_API_KEY;

const RoutesWithPayment = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const routesAllowed = [
      "/signup",
      "/signup-client",
      "/terms-signup",
      "/terms-login",
      "/support",
      "/privacy-policy",
      "/sign-in",
      "/resetpassword",
      "/virtual-signup",
      "/virtual-signin",
      "/bot",
      "/priceselect",
      "/resources",
      "/resources/page",
      "/blog",
      "/blogs",
      "/signin-client-accept",
      "/add-new-blog",
      "/add-new-resource",
      "/edit-resources",
      "/Ocean",
      "/OceanFunnel",
    ];

    const checkAuthStatus = async () => {
      try {
        let user;

        // Fetch the user's session
        const session = await fetchAuthSession();

        // Extract the user's group from the Cognito token (assuming the user belongs to one or more groups)
        const userGroups = session?.tokens?.idToken?.payload?.['cognito:groups'] || [];

        // Filter out any group that includes "Google"
        const filteredGroups = userGroups.filter(group => !group.includes('Google'));

        // Use the first available group or default to 'USER' if no valid group is found
        const userRole = filteredGroups[0] || 'USER'; // Default to 'USER' if no group is found
        console.log("user role: ", userRole)

        // If the user is authenticated, fetch their attributes
        user = await fetchUserAttributes();

        // Combine the user attributes with their role (group)
        user = {
          ...user, // Spread user attributes
          role: userRole, // Add role to the user object
        };

        setIsAuthenticated(true);
        setUserInfo(user);

        // Check if the user has the custom attribute "is_client"
        if (user?.["custom:is_coachee"] === "true") {
          setIsClient(true);
        }
      } catch (error) {
        console.error("Error checking authentication status:", error);

        // If user is not authenticated and not on a specific page, navigate to the homepage
        if (window.location.pathname !== "/signin-client-accept") {
          navigate("/");
        }
      }
    };

    const updateUserState = async () => {
      try {
        // Attempt to retrieve the current authenticated user
        const { username, userId, signInDetails } = await getCurrentUser();

      } catch (error) {
        // If an error is thrown, the user is not authenticated
        console.error(
          "Error updating user state (likely not authenticated):",
          error
        );
        setIsAuthenticated(false);
        setIsClient(false);
        setUserInfo(null);
        navigate("/");
      }
    };

    // Subscribe to sign-out events
    const unsubscribe = Hub.listen("auth", ({ payload: { event } }) => {
      if (event === "signedOut") {
        updateUserState();
      }
      if (event === "signedIn") {
        checkAuthStatus();
      }
    });

    // Initial check of authentication status
    const blogRouteRegex = /^\/blogs\/([1-9]|[1-9][0-9]|[1-4][0-9][0-9]|500)$/; // Regex to check if the route is something like /blog/
    if (
      routesAllowed.indexOf(window.location.pathname) < 0 && //* Adjusted conditional check
      !blogRouteRegex.test(window.location.pathname) //* Adjusted conditional check
    ) {
      checkAuthStatus();
    } else if (window.location.pathname === "/signin-client-accept") {
      checkAuthStatus(); // Always check auth status for this specific route
    }

    return () => {
      unsubscribe();
    };
  }, [navigate]);

  const { userData } = usePayment();

  const prefetchLara = async (user, userData) => {
    try {
      if (user && userData?.companyInformation?.company_id) {
        const queryKey = ["laraData", { userId: user.sub, companyId: userData.companyInformation.company_id }];
        const options = queryOptions({
          queryKey,
          queryFn: () => fetchLara({ user, userData }),
        });
  
        // Prefetch the assessments
        await queryClient.prefetchQuery(options);
  
        // Get the prefetched data from the cache
        const prefetchedData = queryClient.getQueryData(queryKey);
        console.log("Prefetched lara data:", prefetchedData);
      }
    } catch (error) {
      console.error("Error prefetching lara:", error);
    }
  }

  const prefetchAssessments = async (user, userData) => {
    console.log("user info === ", user, userData)
    try {
      if (user && userData?.companyInformation?.company_id) {
        const queryKey = ["assessments", { userId: user.sub, companyId: userData.companyInformation.company_id }];
        const options = queryOptions({
          queryKey,
          queryFn: () => fetchAssessments({ user, userData }),
        });
  
        // Prefetch the assessments
        await queryClient.prefetchQuery(options);
  
        // Get the prefetched data from the cache
        const prefetchedData = queryClient.getQueryData(queryKey);
        console.log("Prefetched assessments data:", prefetchedData);
      }
    } catch (error) {
      console.error("Error prefetching assessments:", error);
    }
  };
   // Trigger prefetching when user or userData changes
   useEffect(() => {
    const prefetchData = async () => {
      if (userInfo && userData?.companyInformation?.company_id) {
        const results = await Promise.allSettled([
          prefetchLara(userInfo, userData),
          prefetchAssessments(userInfo, userData),
        ]);
  
        results.forEach((result, index) => {
          if (result.status === "fulfilled") {
            console.log(`Function ${index === 0 ? "prefetchLara" : "prefetchAssessments"} succeeded.`);
          } else {
            console.error(`Function ${index === 0 ? "prefetchLara" : "prefetchAssessments"} failed:`, result.reason);
          }
        });
      }
    };
  
    prefetchData();
  }, [userInfo, userData]);
  



  return (
    <Frigade.Provider apiKey={FRIGADE_API_KEY} userId={userInfo?.sub}>
     <Routes>
      {/* Global */}
      <Route path="/" element={<HomePage user={userInfo} userData={userData} />} />
      <Route path="/support" element={<Support />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-signup" element={<TermsPage />} />
      <Route path="/terms-login" element={<Terms />} />
      <Route path="/blogs" element={<Blogs user={userInfo} userData={userData} />} />
      <Route path="/blog" element={<SingleBlog user={userInfo} userData={userData} />} />
      <Route path="/add-new-blog" element={<AddNewBlog />} />

      <Route path="/edit-resources" element={<ResourceEditingPage />} />
      <Route path="/Ocean" element={<Ocean />} />
        <Route path="/OceanFunnel" element={<OceanFunnel />} />

      <Route path="/resources" element={<Resources user={userInfo} userData={userData} />} >
        <Route index element={< ResourcePage />} />
        {/* <Route path='page' element={< ResourcePage />} /> */}
      </Route>

      {/* Auth Actions */}
      <Route path="/authenticating" element={<AuthenticatingLoader user={userInfo} />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/resetpassword" element={<PasswordReset />} />
      <Route path="/signup-client" element={<ClientSignUp />} />
      <Route path="/signin-client-accept" element={<InviteAcceptance user={userInfo} userData={userData} />} />
      <Route path="/sign-in" element={<SignInPage user={userInfo} userData={userData} />} />

      {/* Logged In */}
      <Route path="/products" element={<CoachingSignUp />} />
      <Route path="/coaching-signup" element={<CoachingSignUp />} />
      <Route
        path="/settings"
        element={isAuthenticated && <Settings user={userInfo} userData={userData} />}
      />

      {/* Payment Actions */}
      <Route path="/payment-cancellation" element={<CancelPaymentPage />} />
      <Route path="/vc-payment-success" element={<VcPaymentSuccessPage />} />
      <Route path="/cp-payment-success" element={<CpPlatformPaymentSuccessPage />} />
      <Route path="/priceselect" element={<PricingTablePage />}></Route>


      <Route path="/user_guide" element={<UserGuide />} />

      {/* Virtual Coach */}
      <Route
        path="/virtualcoach/bot"
        element={
          isAuthenticated &&
          userData.accessTo === 'virtual_coach' && (
            <RetuneBotPage user={userInfo} userData={userData} />
          )
        }
      />


      {/* Coaching Platform */}

      <Route
        path="/platform/team-portal"
        element={
          isAuthenticated && (userInfo.role === "OWNER" || userInfo.role === 'SUPERADMINS' || userInfo.role === 'ADMINS' || userInfo.role === 'USERS') && (
            <AdminPortal user={userInfo} userData={userData} />
          )
        }
      />

      <Route
        path="/platform/coach"
        element={
          (userData.accessTo === 'platform' || userData.accessTo === 'platform_professional') &&
          isAuthenticated &&
          userData.paymentInfo?.isCustomer && (
            <CoachDashboard user={userInfo} userData={userData} />
          )
        }
      />
      {/* <Route
        path="/platform/professional"
        element={
          accessTo === 'platform_professional' &&
          isAuthenticated &&
          allowAccessToPlatform && (
            <CoachDashboard user={userInfo} />
          )
        }
      /> */}
      {/* <Route
        path="/platform/coach/leadership-center"
        element={
          isAuthenticated &&
          ((userData.accessTo === 'platform' || userData.accessTo === 'platform_professional')) &&
          userData.paymentInfo?.isCustomer && (
            <ClientCenter user={userInfo} userData={userData} />
          )
        }
      /> */}
      <Route
        path="/platform/coach/lara-analytics"
        element={
          (userData.accessTo === 'platform' || userData.accessTo === 'platform_professional') &&
          isAuthenticated &&
          userData.paymentInfo?.isCustomer && (
            <CoachLaraAnalytics user={userInfo} userData={userData}/>
          )
        }
      />
      <Route
        path="/platform/coach/coachee-overview/:client_id"
        element={
          userData.accessTo === 'platform' &&
          isAuthenticated &&
          userData.paymentInfo?.isCustomer && (
            <ClientOverview user={userInfo} userData={userData} />
          )
        }
      />
      <Route
        path="/platform/coach/session/:file_name"
        element={
          (userData.accessTo === 'platform' || userData.accessTo === 'platform_professional') &&
          isAuthenticated && userData.paymentInfo?.isCustomer && (
            <CoachSessionPage user={userInfo} userData={userData} />
          )
        }
      />
      <Route
        path="/platform/coach/actions"
        element={
          (userData.accessTo === 'platform' || userData.accessTo === 'platform_professional') &&
          isAuthenticated &&
          userData.paymentInfo?.isCustomer && (
            <Actions user={userInfo} userData={userData} />
          )
        }
      />

        <Route
          path="/platform/coach/assessments"
          element={
            (userData.accessTo === "platform" ||
              userData.accessTo === "platform_professional") &&
            isAuthenticated &&
            userData.paymentInfo?.isCustomer && (
              <CoachAssessments user={userInfo} userData={userData}/>
            )
          }
        />
        <Route
          path="/platform/coach/assessments/view/individual-breakdown/:testId/:userId/:tab?"
          element={
            (userData.accessTo === "platform" ||
              userData.accessTo === "platform_professional") &&
            isAuthenticated &&
            userData.paymentInfo?.isCustomer && (
              <CoachIndividualBreakdown user={userInfo} userData={userData} />
            )
          }
        />
        <Route
          path="/platform/coach/assessments/view/team-breakdown"
          element={
            (userData.accessTo === "platform" ||
              userData.accessTo === "platform_professional") &&
            isAuthenticated &&
            userData.paymentInfo?.isCustomer && (
              <CoachTeamBreakdown user={userInfo} userData={userData} />
            )
          }
        />

        <Route
          path="/platform/coach/assessments/:assessmentType/:coachId?/:assessmentId?/:teamId?"
          element={
            (userData.accessTo === "platform" ||
              userData.accessTo === "platform_professional") &&
            isAuthenticated &&
            userData.paymentInfo?.isCustomer && (
              <AssessmentTestPage user={userInfo} userData={userData} />
            )
          }
        />

        <Route
          path="/platform/coach/virtualcoach"
          element={
            (userData.accessTo === "platform" ||
              userData.accessTo === "platform_professional") &&
            isAuthenticated &&
            userData.paymentInfo?.isCustomer && (
              <CoachVirtualCoachPage user={userInfo} userData={userData} />
            )
          }
        />

        {/* Client */}
        <Route
          path="/platform/coachee"
          element={
            isClient &&
            isAuthenticated && (
              <ClientDashboard user={userInfo} userData={userData} />
            )
          }
        />
        <Route
          path="/platform/coachee/session/:file_name"
          element={
            isAuthenticated && (
              <ClientSessionPage user={userInfo} userData={userData} />
            )
          }
        />
        <Route
          path="/platform/coachee/lara-analytics"
          element={
            isClient &&
            isAuthenticated && (
              <ClientLaraAnalytics user={userInfo} userData={userData} />
            )
          }
        />

        <Route
          path="/platform/coachee/actions"
          element={
            (isClient ||
              (isAuthenticated && userData.paymentInfo?.isCustomer)) && (
              <Actions user={userInfo} userData={userData} />
            )
          }
        />

        <Route
          path="/platform/coachee/assessments"
          element={
            (isClient ||
              (isAuthenticated && userData.paymentInfo?.isCustomer)) && (
              <ClientAssessments user={userInfo} userData={userData} />
            )
          }
        />

        <Route
          path="/platform/coachee/assessments/:assessmentType/:coachId?/:assessmentId?/:teamId?"
          element={
            (isClient ||
              (isAuthenticated && userData.paymentInfo?.isCustomer)) && (
              <AssessmentTestPage user={userInfo} userData={userData} />
            )
          }
        />

        <Route
          path="/platform/coachee/virtualcoach"
          element={
            isClient &&
            isAuthenticated && (
              <ClientVirtualCoachPage user={userInfo} userData={userData} />
            )
          }
        />
      </Routes>
    </Frigade.Provider>
  );
};

function App() {
  const [theme, colorMode] = useMode();

  useEffect(() => {
    // Add Mouseflow script
    const script = document.createElement("script");
    script.src =
      "https://cdn.mouseflow.com/projects/d04bc01d-98c6-450b-9c20-e481573aa184.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  

  return (
    <Authenticator.Provider>
      <NotificationProvider>
        <PaymentProvider>
          {({ user, userData }) => {

            return (
              <AssessmentsProvider user={user} userData={userData}>
                <ReduxProvider store={store}>
                  <PersistGate loading={null} persistor={persistor}>
                    <ColorModeContext.Provider value={colorMode}>
                      <ThemeProvider theme={theme}>
                        <PrimeReactProvider>
                          <QueryClientProvider client={queryClient}>
                            <CssBaseline />
                            <ToastContainer />
                            <div className="app">
                              <main style={{ width: "100%" }}>
                                <RoutesWithPayment />
                              </main>
                            </div>
                          </QueryClientProvider>
                        </PrimeReactProvider>
                      </ThemeProvider>
                    </ColorModeContext.Provider>
                  </PersistGate>
                </ReduxProvider>
              </AssessmentsProvider>
            );
          }}
        </PaymentProvider>
      </NotificationProvider>
    </Authenticator.Provider>
  );
}

export default App;
