import React from 'react';

const VoiceToChatSvg = ({ color = '#FF5E00' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 27 26">
            <path stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M7.576 7.576v11.151M2 11.757v2.789M13.848 2v22.303m5.576-16.727v11.151M25 11.757v2.789" />
        </svg>

    );
};

export default VoiceToChatSvg;


// <svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" fill="none" viewBox="0 0 27 26">
//     <path stroke="#FF5E00" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M7.576 7.576v11.151M2 11.757v2.789M13.848 2v22.303m5.576-16.727v11.151M25 11.757v2.789" />
// </svg>
