import React from "react";

function UserCheck({ color = "black", width = "25", height = "25" }) {
  let strokeColor;

  // Set stroke color based on the `color` prop
  if (color === "disabled") {
    strokeColor = "#A6A6A6"; // Gray for disabled
  } else if (color === "enabled") {
    strokeColor = "black"; // Black for enabled
  } else {
    strokeColor = "black"; // Default or custom color passed as prop
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 15H7a4 4 0 0 0-4 4 2 2 0 0 0 2 2h8m1-5.334 2.341 2.339a14.984 14.984 0 0 1 4.558-4.936L21 13m-6-6a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default UserCheck;
