import { Box, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import ClientLimitReached from "components/ClientLimitReachedModal";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { generateClient } from "aws-amplify/data";
import domains from 'components/Assessments/TheBigFive/en/index';

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */

const resourceClient = generateClient({ authMode: "userPool" });

const CoachAssessments = ({ user, userData }) => {
    const [selectedAssessment, setSelectedAssessment] = useState({});
    const [teamData, setTeamData] = useState([]);
    const [assessmentData, setAssessmentData] = useState([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filteredAssessments, setFilteredAssessments] = useState([])
    const [latestUncompletedAssessment, setLatestUncompletedAssessment] = useState({})
    const [latestCompletedAssessment, setLatestCompletedAssessment] = useState({})
    const tagNames = ["General", "Neuroticism", "Extraversion", "Openness", "Agreeableness", "Conscientiousness"]
    const [selectedTag, setSelectedTag] = useState(tagNames[0]);

    const domainColors = {
        G: '#000000',
        N: '#9865CF', // Neuroticism
        O: '#EF406E', // Openness to Experience
        A: '#F3AB54', // Agreeableness 
        E: '#337BE8', // Extraversion 
        C: '#49CA7A'  // Conscientiousness 
      };
      const facetColors = {
        0: '#9865CF',
        1: '#337BE8',
        2: '#EF406E',
        3: '#F3AB54',
        4: '#49CA7A',
        5: '#000000'
      };

    const navigate = useNavigate();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const collapsed = useSelector((state) => state.layout.collapsed);

    const fetchData = async () => {
        setLoading(true);
        try {
            const userId = user?.sub;
        
            // Fetch assessments assigned by the user (coach)
            const { data, errors } = await resourceClient.models.assessments.list({
                filter: {
                    user_id: { eq: userId }, // Fetch assessments where user is the coachee
                }
            });
    
            if (errors) {
                console.error("Errors fetching assessments: ", errors);
                setLoading(false);
                return;
            }
    
            const assessments = data || [];
            setAssessmentData(assessments);  // Store the fetched assessments in the state
    
            // Extract unique coach_ids
            const uniqueCoachIds = [...new Set(assessments.map(assessment => assessment.coach_id))];
    
            let assessmentsWithCoachNames = [...assessments]; // Clone assessments array for modification
    
            if (uniqueCoachIds.length > 0) {
                const userNamesResponse = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-name-by-user-id`, { userIds: uniqueCoachIds });
                
                if (userNamesResponse && userNamesResponse.data) {
                    const coachNamesMap = userNamesResponse.data.reduce((acc, user) => {
                      acc[user.user_id] = user.name;  // Map user_id to name
                      return acc;
                    }, {});
              
                    // Attach the coach_name to the respective assessments
                    assessmentsWithCoachNames = assessments.map(assessment => ({
                      ...assessment,
                      coach_name: coachNamesMap[assessment.coach_id] || 'Unknown Coach',
                    }));
              
                    setAssessmentData(assessmentsWithCoachNames); // Store the updated assessments with coach names
                }
            }
        
            // Find the latest completed and uncompleted assessments
            const { latestUncompletedRes, latestCompletedRes } = getLatestAssessments(assessmentsWithCoachNames);
        
            // Handle latest uncompleted assessment
            if (latestUncompletedRes) {
                const results = JSON.parse(latestUncompletedRes.results);
                setLatestUncompletedAssessment({ ...latestUncompletedRes, results });
            }
        
            // Handle latest completed assessment
            if (latestCompletedRes) {
                const results = JSON.parse(latestCompletedRes.results);
                setLatestCompletedAssessment({ ...latestCompletedRes, results });
            }
        
        
        } catch (error) {
            console.error("Error fetching client data:", error);
        } finally {
            setLoading(false);
        }
    };
    
    

    useEffect(() => {
        fetchData();
    }, [user]);

    // Function to find the most recent uncompleted and completed assessments
    const getLatestAssessments = (assessments) => {
        // Sort assessments by createdAt field (descending order)
        const sortedAssessments = assessments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        // Find the first uncompleted assessment
        const latestUncompletedRes = sortedAssessments.find(assessment => assessment.completed === null || assessment.completed === false) || null;

        // Find the first completed assessment
        const latestCompletedRes = sortedAssessments.find(assessment => assessment.completed === true) || null;

        return { latestUncompletedRes, latestCompletedRes };
    };

      
        // Find the domain based on the selectedTag
        const selectedDomain = domains.find(
            (domain) => domain.title === selectedTag
        );


        // Get the result for the selected domain
        const domainResult = assessmentData?.results?.[selectedDomain?.domain];

        const scaledScore = (facetScore, count) => {
            const maxScore = count * 5;
            return (facetScore / maxScore) * 10
        }
      

    return (
        <GlobalPageComponent user={user} userData={userData}>
            <ClientLimitReached user={user} userData={userData} />
                <Box
                    sx={{
                    backgroundColor: "#EFF3F6",
                    width: "100%",
                    height: "100%",
                    padding: {
                        xs: "16px",
                        md: "32px 40px",
                    },
                    display: {
                        xs: !collapsed ? "none" : "block",
                    },
                    overflowY: "auto",
                }}
            >
                {/* <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <Typography
                        sx={{
                            color: "black",
                            fontFamily: "Poppins",
                            fontSize: "36px",
                            fontWeight: 300,
                            marginBottom: "16px",
                            }}
                        >
                            Assessments
                        </Typography>
                    </Box> */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            height: "88vh",
                        }}
                    >
                        <Box
                            sx={{
                                background: "white",
                                borderRadius: "40px",
                                padding: "24px",
                                display: "flex",
                                flexDirection: {xs: "column", md: "row"},
                                width: "100%",
                                alignItems: "center",
                                gap: {xs: "16px", md: "0px"}
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "8px",
                                    width: {xs:"90%", md: "40%"}
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "#8F8F8F",
                                        fontSize: "18px",
                                        fontFamily: "Lexend",
                                        fontWeight: 400,
                                    }}
                                >
                                    The Big Five personality test, also known as the OCEAN personality test, is based on the Big Five model that defines human personality as the combination of 5 personality traits or factors – Openness, Conscientiousness, Agreeableness, Extraversion and Neuroticism. Learn More.
                                </Typography>
                            </Box>
                            <Divider orientation="vertical" flexItem color="#EFF3F6" sx={{marginLeft: "30px"}}/>
                            <Box
                                sx={{
                                    width: {xs: "100%", md:"40%"},
                                    display: "flex", // Use flexbox for alignment
                                    justifyContent: "center", // Center horizontally
                                    alignItems: "center", // Center vertically
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex", // Flexbox to center content
                                        flexDirection: "column",
                                        justifyContent: "center", // Horizontal centering
                                        alignItems: latestUncompletedAssessment ? 'flex-start' : 'center',
                                        height: "75px", // Set a height to center content vertically (optional, can adjust)
                                        width: "90%"
                                        }}
                                >
                                    {latestUncompletedAssessment.test_type && (
                                        <Typography sx={{color: '#79889C', fontSize: 20, fontFamily: 'Poppins', fontWeight: '500', textTransform: "uppercase"}}>
                                            Assessment Pending
                                        </Typography>
                                    )}
                                    <Typography
                                        sx={{color: 'black', fontSize: 20, fontFamily: 'Lexend', fontWeight: '400'}}
                                    >
                                        {loading ? (
                                            "Loading..."
                                        ) : latestUncompletedAssessment.test_type ? (
                                            `${latestUncompletedAssessment.coach_name ?? 'Coach'} has invited you to complete ${latestUncompletedAssessment.test_type}. You can get started by clicking “TAKE THE TEST”, or learn more.`
                                        ) : (
                                            "No recent updates"
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                            <Divider orientation="vertical" flexItem color="#EFF3F6" sx={{marginLeft: "0px"}}/>
                            <Box
                                sx={{
                                    width: {xs: "100%", md:"30%"},
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: {xs: "12px", md:"8px"},
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <Button
                                    sx={{
                                        background: "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
                                        padding: "10px",
                                        borderRadius: "50px",
                                        width: "60%",
                                        "&:disabled": {
                                            background: "#d3d3d3", 
                                            color: "#B7B7B7",
                                            border: "none",
                                            cursor: "not-allowed",
                                          },
                                    }}
                                    disabled={!latestUncompletedAssessment.id}
                                    onClick={() =>
                                        navigate(`/platform/coachee/assessments/${latestUncompletedAssessment.test_type}/${latestUncompletedAssessment.id}`)
                                    }
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontSize: "12px",
                                            fontWeight: "700",
                                            color: "white",
                                            textTransform: "capitalize",
                                            letterSpacing: 2
                                        }}
                                    >   
                                        Take the Test
                                    </Typography>
                                </Button>
                                <Button
                                    sx={{
                                        background: "white",
                                        border: "1px solid black",
                                        padding: "10px",
                                        color: "black",
                                        borderRadius: "50px",
                                        width: "60%",
                                        "&:disabled": {
                                            background: "#d3d3d3", 
                                            color: "#B7B7B7",
                                            cursor: "not-allowed",
                                          },
                                    }}
                                    onClick={() =>
                                        navigate(``)
                                    }
                                    >
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontSize: "12px",
                                            fontWeight: "700",
                                            textTransform: "capitalize",
                                            letterSpacing: 2
                                        }}
                                    >   
                                        Learn More
                                    </Typography>
                                </Button>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            gap: "8px",
                            width: "100%",
                            height: "100%",
                            flexGrow: 1,
                        }}
                    >
                        <Box
                            sx={{
                                background: "white",
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                borderRadius: "40px",
                                padding: "24px",
                                marginBottom: "30px",
                                alignItems: selectedDomain?.domain && selectedDomain?.domain === 'G' ? "center" : ""
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%"
                                }}>
                                <Typography
                                    sx={{
                                        color: "black",
                                        fontSize: "28px",
                                        fontFamily: "Poppins",
                                        fontWeight: 300,
                                        wordWrap: "break-word"
                                    }}
                                >
                                    Results
                                </Typography>
                                <Box
                                    sx={{
                                        width: "70%",
                                        display: "flex",
                                        gap: "20px",
                                        flexDirection: {
                                            xs: "column",
                                            md: "row",
                                        },
                                    }}
                                >
                                    {tagNames.map((name, index) => <Box key={index}
                                            onClick={() => setSelectedTag(name)}
                                            sx={{
                                                width: '160px',
                                                height: '35px',
                                                borderRadius: "60px",
                                                border: selectedTag === name ? `2px solid ${domainColors[selectedDomain?.domain]}` : "2px solid #E3E3E3",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                cursor: "pointer",

                                            }}>
                                            <Typography sx={{
                                                color: selectedTag === name ? domainColors[selectedDomain?.domain] : "#E3E3E3",
                                                fontFamily: "Poppins",
                                                fontSize: "12px",
                                                fontWeight: 700,
                                                linHeight: "150%",
                                                letterSpacing: "2px",
                                                textTransform: "capitalize",
                                                paddingX: "10px"
                                            }}>
                                                {name}
                                            </Typography>
                                        </Box>)
                                    }
                                </Box>
                            </Box>

                            {latestCompletedAssessment && latestCompletedAssessment.results ? (
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        height: "100%",
                                        flexGrow: 1,
                                        marginTop: "50px",
                                        overflow: "auto",
                                        alignItems: "flex-start",
                                        textAlign:  selectedDomain?.domain && selectedDomain?.domain === 'G' ? "center" : ""
                                    }}
                                >
                                    {selectedDomain?.domain && selectedDomain?.domain === 'G' ? (
                                            Object.keys(latestCompletedAssessment.results).map((domainKey) => {
                                                const domainData = latestCompletedAssessment.results[domainKey];
                                                const scaledScore = Math.round(domainData.score / 10); // Divide the score by 10 to get a scale of 0-10
                                        
                                                return (
                                                    <Box key={domainKey} sx={{ marginBottom: '20px', display: "flex", gap: "20px", justifyContent: "center", textAlign: "left" }}>
                                                        <Typography
                                                            sx={{
                                                                color: domainColors[domainKey] || '#000',
                                                                fontFamily: "Lexend",
                                                                fontSize: "20px",
                                                                fontWeight: 400,
                                                                marginBottom: '10px',
                                                                minWidth: "200px"
                                                            }}
                                                        >
                                                            <svg
                                                                style={{ cursor: 'pointer' }}
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="15"
                                                                height="16"
                                                                viewBox="0 0 15 16"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M7.49902 1.99089C6.73298 1.99089 5.97444 2.14177 5.2667 2.43492C4.55897 2.72807 3.91591 3.15775 3.37423 3.69943C2.83256 4.2411 2.40288 4.88417 2.10973 5.5919C1.81657 6.29963 1.66569 7.05817 1.66569 7.82422C1.66569 8.59026 1.81657 9.34881 2.10973 10.0565C2.40288 10.7643 2.83256 11.4073 3.37423 11.949C3.91591 12.4907 4.55897 12.9204 5.2667 13.2135C5.97444 13.5067 6.73298 13.6576 7.49902 13.6576C9.04612 13.6576 10.5299 13.043 11.6238 11.949C12.7178 10.855 13.3324 9.37132 13.3324 7.82422C13.3324 6.27712 12.7178 4.79339 11.6238 3.69943C10.5299 2.60547 9.04612 1.99089 7.49902 1.99089ZM-0.000976562 7.82422C-0.000976562 5.83509 0.7892 3.92744 2.19572 2.52092C3.60225 1.1144 5.5099 0.324219 7.49902 0.324219C9.48815 0.324219 11.3958 1.1144 12.8023 2.52092C14.2088 3.92744 14.999 5.83509 14.999 7.82422C14.999 9.81334 14.2088 11.721 12.8023 13.1275C11.3958 14.534 9.48815 15.3242 7.49902 15.3242C5.5099 15.3242 3.60225 14.534 2.19572 13.1275C0.7892 11.721 -0.000976562 9.81334 -0.000976562 7.82422ZM6.66569 4.49089C6.66569 4.26987 6.75349 4.05791 6.90977 3.90163C7.06605 3.74535 7.27801 3.65755 7.49902 3.65755L7.50736 3.65755C7.72837 3.65755 7.94033 3.74535 8.09661 3.90163C8.25289 4.05791 8.34069 4.26987 8.34069 4.49089C8.34069 4.7119 8.25289 4.92386 8.09661 5.08014C7.94033 5.23642 7.72837 5.32422 7.50736 5.32422L7.49902 5.32422C7.27801 5.32422 7.06605 5.23642 6.90977 5.08014C6.75349 4.92386 6.66569 4.7119 6.66569 4.49089ZM6.67402 11.1576C6.67402 11.3786 6.76182 11.5905 6.9181 11.7468C7.07438 11.9031 7.28634 11.9909 7.50736 11.9909C7.72837 11.9909 7.94033 11.9031 8.09661 11.7468C8.25289 11.5905 8.34069 11.3786 8.34069 11.1576L8.34069 6.99089C8.34069 6.76987 8.25289 6.55791 8.09661 6.40163C7.94033 6.24535 7.72837 6.15755 7.50736 6.15755C7.28634 6.15755 7.07438 6.24535 6.9181 6.40163C6.76182 6.55791 6.67402 6.76987 6.67402 6.99089L6.67402 11.1576Z"
                                                                    fill="#8F8F8F"
                                                                />
                                                            </svg>
                                                            {'     '}
                                                            {/* Get the domain title or fallback to the domain key */}
                                                            {domains.find(d => d.domain === domainKey)?.title || domainKey}
                                                        </Typography>
                                        
                                                        {/* Display the circles */}
                                                        <Box sx={{ display: 'flex', gap: '5px' }}>
                                                            {/* Render the filled circles */}
                                                            {Array.from(Array(scaledScore)).map((item, subIndex) => (
                                                                <Box
                                                                    key={subIndex}
                                                                    sx={{
                                                                        width: {
                                                                            xs: "18px",
                                                                            md: '27px'
                                                                        },
                                                                        height: {
                                                                            xs: "18px",
                                                                            md: '27px'
                                                                        },
                                                                        borderRadius: '50%',
                                                                        backgroundColor: domainColors[domainKey], // Color based on the domain
                                                                    }}
                                                                />
                                                            ))}
                                        
                                                            {/* Render the empty circles */}
                                                            {Array.from(Array(10 - scaledScore)).map((item, subIndex) => (
                                                                <Box
                                                                    key={subIndex}
                                                                    sx={{
                                                                        width: {
                                                                            xs: "18px",
                                                                            md: '27px'
                                                                        },
                                                                        height: {
                                                                            xs: "18px",
                                                                            md: '27px'
                                                                        },
                                                                        borderRadius: '50%',
                                                                        backgroundColor: '#EFF3F6', // Light gray for empty circles
                                                                    }}
                                                                />
                                                            ))}
                                                        </Box>
                                                        {domainData.score !== 0 && (
                                                            <Typography
                                                                sx={{
                                                                    color: "black",
                                                                    fontFamily: "Lexend",
                                                                    fontSize: "16px"
                                                                }}
                                                            >
                                                                {domainData.score} / 100
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                );
                                            })
                                    ) : (
                                        <>
                                             <Box 
                                                sx={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    height: {
                                                        xs: "auto",
                                                        md: "300px",
                                                    },
                                                    flexDirection: {
                                                        xs: "column",
                                                        md: "row",
                                                    },
                                                }}
                                            >
                                                <Box sx={{
                                                    flex: 2,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    flexDirection: 'column',
                                                }}>
                                                    <Box>
                                                        <Typography
                                                            sx={{
                                                                color: domainColors[selectedDomain?.domain] || '#000000',
                                                                fontFamily: "Lexend",
                                                                fontSize: {
                                                                    xs: '35px',
                                                                    md: "44px"
                                                                },
                                                                fontWeight: 400,
                                                                lineHeight: "160%",
                                                            }}
                                                        >
                                                            {selectedDomain?.title || selectedTag}
                                                        </Typography>
                                                    </Box>

                                                    {loading || !selectedDomain?.domain ? (
                                                        <>
                                                            <CircularProgress sx={{color: "black"}}/>
                                                        </>
                                                    ) : (
                                                        <Box>
                                                            <Typography sx={{
                                                                color: "#0C0C0C",
                                                                fontFamily: "Lexend",
                                                                fontSize: "16px",
                                                                fontWeight: 400,
                                                                lineHeight: "160%"
                                                            }}>
                                                                {selectedDomain?.shortDescription || "No description available for this domain."}
                                                            </Typography>
                                                            <Typography sx={{
                                                                color: "#0C0C0C",
                                                                fontFamily: "Lexend",
                                                                fontSize: "16px",
                                                                fontWeight: 400,
                                                                lineHeight: "160%"
                                                            }}>
                                                                {
                                                                    selectedDomain?.results?.find(result => result?.score === latestCompletedAssessment?.results[selectedDomain?.domain]?.result)?.text
                                                                    || "No description available for this result."
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </Box>

                                                <Box 
                                                    sx={{
                                                        flex: 1,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            borderRadius: '50%',
                                                            marginY: {
                                                                xs: '20px',
                                                                md: '0px'
                                                            },
                                                            width: {
                                                                xs: '80px',
                                                                md: '142px'
                                                            },
                                                            height: {
                                                                xs: '80px',
                                                                md: '142px'
                                                            },
                                                            backgroundColor: domainColors[selectedDomain?.domain] || "000",
                                                        }}
                                                    >
                                                        <Typography sx={{
                                                            margin: 0,
                                                            padding: 0,
                                                            color: "FFF" || "000",
                                                            fontFamily: "Lexend",
                                                            fontSize: "44px",
                                                            fontWeight: 400,
                                                            lineHeight: "160%",
                                                        }}>
                                                            {selectedDomain?.domain 
                                                                ? latestCompletedAssessment?.results[selectedDomain?.domain]?.score
                                                                : ""
                                                            }
                                                        </Typography>
                                                    </Box>

                                                </Box>

                                            </Box>

                                            <Box 
                                                sx={{
                                                    backgroundColor: '#EFF3F6',
                                                    width: '100%',
                                                    height: '7px',
                                                    borderRadius: '10px',
                                                    marginY: {
                                                        xs: '30px',
                                                    }
                                                }}
                                            >
                                            </Box>

                                            <Box 
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: {
                                                        xs: '40px',
                                                        md: '20px',
                                                    },
                                                    marginTop: '30px',
                                                }}
                                            >
                                                {selectedDomain?.facets?.map((facet, index) => (
                                                    <Box key={index} sx={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        height: {
                                                            xs: 'auto',
                                                            md: '200px'
                                                        },
                                                        flexDirection: {
                                                            xs: 'column',
                                                            md: 'row'
                                                        },
                                                        gap: {
                                                            xs: '20px',
                                                            md: '0px'
                                                        }
                                                    }}>
                                                        <Box sx={{
                                                            flex: 2,
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            flexDirection: 'column',
                                                            gap: '13px',
                                                        }}>
                                                            <Box>
                                                                <Typography
                                                                    sx={{
                                                                        color: facetColors[index] || '#000000', // Color based on the domain
                                                                        fontFamily: "Lexend",
                                                                        fontSize: "24px",
                                                                        fontWeight: 400,
                                                                        lineHeight: "160%",
                                                                    }}
                                                                >
                                                                    {facet.title}
                                                                </Typography>
                                                            </Box>

                                                            <Box>
                                                                <Typography sx={{
                                                                    color: "#0C0C0C",
                                                                    fontFamily: "Lexend",
                                                                    fontSize: "16px",
                                                                    fontWeight: 400,
                                                                    lineHeight: "160%"
                                                                }}>
                                                                    {facet.text}
                                                                </Typography>
                                                            </Box>
                                                        </Box>

                                                        <Box sx={{
                                                            flex: 1,
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            paddingX: '20px',
                                                            rowGap: '10px',
                                                        }}>
                                                            <Box sx={{ display: 'flex', gap: '5px' }}>
                                                            {latestCompletedAssessment && Array.from(Array(Math.round(scaledScore(
                                                                latestCompletedAssessment.results[selectedDomain.domain]?.facet[index + 1]?.score,
                                                                latestCompletedAssessment.results[selectedDomain.domain]?.facet[index + 1]?.count
                                                            )))).map((item, subIndex) => (
                                                                <Box
                                                                    key={subIndex}  // Use a different variable name to avoid shadowing `index`
                                                                    sx={{
                                                                        width: {
                                                                            xs: "18px",
                                                                            md: '27px'
                                                                        },
                                                                        height: {
                                                                            xs: "18px",
                                                                            md: '27px'
                                                                        },
                                                                        borderRadius: '50%',
                                                                        backgroundColor: domainColors[selectedDomain?.domain], // Color based on the domain
                                                                    }}
                                                                />
                                                            ))}

                                                            {latestCompletedAssessment && Array.from(Array(10 - Math.round(scaledScore(
                                                                latestCompletedAssessment.results[selectedDomain.domain]?.facet[index + 1]?.score,
                                                                latestCompletedAssessment.results[selectedDomain.domain]?.facet[index + 1]?.count
                                                            )))).map((item, subIndex) => (
                                                                <Box
                                                                    key={subIndex}
                                                                    sx={{
                                                                        width: {
                                                                            xs: "18px",
                                                                            md: '27px'
                                                                        },
                                                                        height: {
                                                                            xs: "18px",
                                                                            md: '27px'
                                                                        },
                                                                        borderRadius: '50%',
                                                                        backgroundColor: '#EFF3F6', // Light gray for empty circles
                                                                    }}
                                                                />
                                                            ))}
                                                        </Box>
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </>
                                    )}
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        height: "100%",
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textAlign: "center"
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: "#79889C",
                                            fontSize: "18px",
                                            fontFamily: "Poppins",
                                            fontWeight: 300,
                                            width: "80%"
                                        }}
                                    >
                                        No assessment data available.
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </GlobalPageComponent>
    )
}

export default CoachAssessments;