import { useState } from "react";
import { Box, Typography, Popover } from "@mui/material";
import TrophyIcon from "assets/TrophyIcon";
import EditIcon from "components/Icons/Edit.svg";
import MarkAsCompletedIcon from "components/Icons/MarkAsCompleted.svg";
import ActionCalendarIcon from "components/Icons/actioncalender.svg";
import ActionDeleteIcon from "components/Icons/ActionDelete.svg";
import ShareIcon from "components/Icons/Share.svg";
import { Undo } from "@mui/icons-material";
import {
    LocalizationProvider,
    StaticDatePicker,
  } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";


export const TodoActionsToolbar = ({ user, selected, selectedIds, setUpdatedAction, setInitialAction, setOpenEditActionModal, setOpenAddActionsToGoalModal, setOpenCompleteActionModal, setOpenDeleteActionModal, navigate, setSelectedDate, selectedDate, setOpenDueDateSetModal }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [openDatePicker, setOpenDatePicker] = useState(false);
  
    const handleIconClick = (event) => {
      if (selected.length > 0) {
        setAnchorEl(event.currentTarget);
        setOpenDatePicker(true);
      }
    };
  
    const handleClose = () => {
      setAnchorEl(null);
      setOpenDatePicker(false);
    };

    console.log("***** selected: ", selected)


    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: {
                    xs: "wrap",
                    md: "unset",
                },
                '& > :nth-of-type(n)': {
                    flexBasis: {
                        xs: "calc(50% - 24px)",
                        md: "unset",
                    },
                },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    padding: "8px",
                    borderRadius: "8px",
                    transition: "all 0.3s",
                    cursor: (selected?.length === 1 && selected[0]?.file_name) ? "pointer" : "not-allowed",
                    "&:hover": {
                        backgroundColor: "#F5F7FF",
                    },
                }}

                onClick={(e) => {
                    e.stopPropagation();
                    if (selected[0]?.file_name && selected?.length === 1)
                    if (user?.sub === selected[0].client_id) {
                        navigate("/platform/coachee/session/" + selected[0].file_name);
                    }
                    else {
                        navigate("/platform/coach/session/" + selected[0].file_name);
                    }
                }}
            >
                <ShareIcon color={selected?.length === 1 && selected[0]?.file_name ? "enabled": "disabled"} height={22} width={22} />
                <Typography
                    sx={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        color: (selected?.length === 1 && selected[0]?.file_name) ? "#000000" :  "#B6B6B6",
                    }}
                >
                    Go to Session
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    padding: "8px",
                    borderRadius: "8px",
                    transition: "all 0.3s",
                    "&:hover": {
                        background: "#F5F7FF",
                        cursor: (selected.length === 1 ? "pointer" : "not-allowed"),
                    },
                }}
                onClick={() => {
                    if (selected.length === 1) {
                        setUpdatedAction(selected[0]);
                        setInitialAction(selected[0]);
                        setOpenEditActionModal(true);
                    }
                }}
            >
                <EditIcon
                    sx={{
                        fill: (selected.length === 1 ? "black" : "#B6B6B6")
                    }}
                    color={selected.length === 1 ? "black" : "#B6B6B6"} 
                    height={20}
                    width={20}
                />
                <Typography
                    sx={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        color: (selected.length === 1 ? "#000000" : "#B6B6B6"),
                    }}
                >
                    Edit
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    padding: "8px",
                    borderRadius: "8px",
                    transition: "all 0.3s",
                    "&:hover": {
                        background: "#F5F7FF",
                        cursor: (selected.length > 0 ? "pointer" : "not-allowed"),
                    },
                }}
                onClick={() => selected.length > 0 && setOpenAddActionsToGoalModal(true)}
            >
                <TrophyIcon 
                    fill={selected.length > 0 ? "black" : "#B6B6B6"} 
                    height={20}
                    width={20}
                />
                <Typography
                    sx={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        color: (selected.length > 0 ? "#000000" : "#B6B6B6"),
                    }}
                >
                    Assign to Goal
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    padding: "8px",
                    borderRadius: "8px",
                    transition: "all 0.3s",
                    "&:hover": {
                        background: "#F5F7FF",
                        cursor: (selected.length > 0 ? "pointer" : "not-allowed"),
                    },
                }}
                onClick={() => selected.length > 0 && setOpenCompleteActionModal(true)}
            >
                <MarkAsCompletedIcon
                    color={selected.length > 0 ? "black" : "#B6B6B6"} 
                    height={20}
                    width={20}
                />
                <Typography
                    sx={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        color: (selected.length > 0 ? "#000000" : "#B6B6B6"),
                    }}
                >
                    Mark as Completed
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    padding: "8px",
                    borderRadius: "8px",
                    transition: "all 0.3s",
                    "&:hover": {
                        background: "#F5F7FF",
                        cursor: (selected.length > 0 ? "pointer" : "not-allowed"),
                    },
                }}
                onClick={handleIconClick}
            >
                <ActionCalendarIcon
                    color={selected.length > 0 ? "black" : "#B6B6B6"} 
                    height={20}
                    width={20}
                />
                <Typography
                    sx={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        color: (selected.length > 0 ? "#000000" : "#B6B6B6"),
                    }}
                >
                    Assign Due Date
                </Typography>
            </Box>
                <Box
                    sx={{
                        display: "flex",
                        padding: "8px",
                        gap: "8px",
                        borderRadius: "8px",
                        alignItems: "center",
                        "&:hover": {
                            background: "#F5F7FF",
                            cursor: (selected?.length > 0 && selected.every(item => item.created_by === user?.sub)) ? "pointer" : "not-allowed",
                        },
                    }}
                    onClick={() => {
                        const isAuthorized = (selected?.length > 0 && selected.every(item => item.created_by === user?.sub));
                        if (isAuthorized) {
                            setOpenDeleteActionModal(true);
                        }
                    }}
                >
                    <ActionDeleteIcon
                        fill={(selected?.length > 0 && selected.every(item => item.created_by === user?.sub)) ? "black" : "#B6B6B6"}
                        height={20}
                        width={20}
                    />
                    <Typography
                        sx={{
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            color: (selected?.length > 0 && selected.every(item => item.created_by === user?.sub)) ? "#000000" : "#B6B6B6",
                        }}
                    >
                        Delete
                    </Typography>
                </Box>
                <Popover
                        open={openDatePicker}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <Box sx={{ zIndex: 999 }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <StaticDatePicker
                              defaultValue={null}
                              onChange={(newValue) => {
                                setSelectedDate(newValue);
                                handleClose();
                                setOpenDueDateSetModal(true);
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                      </Popover>
        </Box>
    );
}


export const CompletedActionsToolbar = ({ user, selectedCompleted, selectedCompletedIds, setUpdatedAction, setInitialAction, setOpenEditActionModal, setOpenAddActionsToGoalModal, setOpenUndoActionModal, setOpenDeleteActionModal, navigate, setSelectedDate, selectedDate, setOpenDueDateSetModal }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDatePicker, setOpenDatePicker] = useState(false);
  
    const handleIconClick = (event) => {
      if (selectedCompleted.length > 0) {
        setAnchorEl(event.currentTarget);
        setOpenDatePicker(true);
      }
    };
  
    const handleClose = () => {
      setAnchorEl(null);
      setOpenDatePicker(false);
    };
    return(
        <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: {
                    xs: "wrap",
                    md: "unset",
                },
                '& > :nth-of-type(n)': {
                    flexBasis: {
                        xs: "calc(50% - 24px)",
                        md: "unset",
                    },
                },
              }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        padding: "8px",
                        borderRadius: "8px",
                        transition: "all 0.3s",
                        cursor: (selectedCompleted?.length === 1 && selectedCompleted[0]?.file_name) ? "pointer" : "not-allowed",
                        "&:hover": {
                            backgroundColor: "#F5F7FF",
                        },
                    }}

                    onClick={(e) => {
                        e.stopPropagation();
                        if (selectedCompleted[0]?.file_name && selectedCompleted?.length === 1)
                        if (user?.sub === selectedCompleted[0].client_id) {
                            navigate("/platform/coachee/session/" + selectedCompleted[0].file_name);
                        }
                        else {
                            navigate("/platform/coach/session/" + selectedCompleted[0].file_name);
                        }
                    }}
                >

                    <ShareIcon color={selectedCompleted?.length === 1 && selectedCompleted[0]?.file_name ? "enabled": "disabled"} height={22} width={22} />
                    <Typography
                        sx={{
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            color: (selectedCompleted?.length === 1 && selectedCompleted[0]?.file_name) ? "#000000" :  "#B6B6B6",
                        }}
                    >
                        Go to Session
                    </Typography>
                </Box>
                      <Box 
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          padding: "8px",
                          borderRadius: "8px",
                          transition: "all 0.3s",
                          "&:hover": {
                              background: "#F5F7FF",
                              cursor: (selectedCompleted.length === 1 ? "pointer" : "not-allowed"),
                          },
                        }} 
                        onClick={() => {
                          selectedCompleted.length === 1 && setUpdatedAction(selectedCompleted[0]);
                          selectedCompleted.length === 1 && setInitialAction(selectedCompleted[0]);
                          selectedCompleted.length === 1 && setOpenEditActionModal(true);
                        }}
                      >
                        <EditIcon
                            sx={{
                                fill: (selectedCompleted.length === 1 ? "black" : "#B6B6B6")
                            }}
                            color={selectedCompleted.length === 1 ? "black" : "#B6B6B6"} 
                            height={20}
                            width={20}
                        />
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            color: (selectedCompleted.length === 1 ? "#000000" : "#B6B6B6"),
                          }}
                        >
                          Edit
                        </Typography>
                      </Box>
                      <Box 
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          padding: "8px",
                          borderRadius: "8px",
                          transition: "all 0.3s",
                          "&:hover": {
                            background: "#F5F7FF",
                            cursor: (selectedCompleted.length > 0 ? "pointer" : "not-allowed"),
                          },
                        }} 
                        onClick={() => selectedCompleted.length > 0 && setOpenAddActionsToGoalModal(true)}
                      >
                        <TrophyIcon
                          fill={selectedCompleted.length > 0 ? "black" : "#B6B6B6"}
                          height={20}
                          width={20}
                        />
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            color: (selectedCompleted.length > 0 ? "#000000" : "#B6B6B6"),
                          }}
                        >
                          Assign to Goal
                        </Typography>
                      </Box>
                      <Box 
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          padding: "8px",
                          borderRadius: "8px",
                          transition: "all 0.3s",
                          "&:hover": {
                            background: "#F5F7FF",
                            cursor: (selectedCompleted.length > 0 ? "pointer" : "not-allowed"),
                          },
                        }} 
                        onClick={() => selectedCompleted.length > 0 && setOpenUndoActionModal(true)}
                      >
                        <Undo
                          sx={{
                            fill: (selectedCompleted.length > 0 ? "black" : "#B6B6B6")
                          }}
                        />
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            color: (selectedCompleted.length > 0 ? "#000000" : "#B6B6B6"),
                          }}
                        >
                          Mark as To-Do
                        </Typography>
                      </Box>
                  <Box 
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      padding: "8px",
                      borderRadius: "8px",
                      transition: "all 0.3s",
                      "&:hover": {
                        background: "#F5F7FF",
                        cursor: (selectedCompleted.length > 0 ? "pointer" : "not-allowed"),
                      },
                    }} 
                    onClick={handleIconClick}
                  >
                    <ActionCalendarIcon
                    color={selectedCompleted.length > 0 ? "black" : "#B6B6B6"} 
                    height={20}
                    width={20}

                    />
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        color: (selectedCompleted.length > 0 ? "#000000" : "#B6B6B6"),
                      }}
                    >
                      Assign Due Date
                    </Typography>
                  </Box>
                      <Box 
                        sx={{
                          display: "flex",
                          padding: "8px",
                          gap: "8px",
                          borderRadius: "8px",
                          alignItems: "center",
                          "&:hover": {
                            background: "#F5F7FF",
                            cursor: ((selectedCompleted?.length > 0 && selectedCompleted.every(item => item.created_by === user?.sub)) ? "pointer" : "not-allowed"),
                          },
                        }} 
                        onClick={() => {
                          const isAuthorized = (selectedCompleted?.length > 0 && selectedCompleted.every(item => item.created_by === user?.sub));
                          if (isAuthorized) {
                              setOpenDeleteActionModal(true);
                          }
                      }}
                      >
                        <ActionDeleteIcon
                            fill = {((selectedCompleted?.length > 0 && selectedCompleted.every(item => item.created_by === user?.sub)) ? "black" : "#B6B6B6")}
                            height={20}
                            width={20}
                        />
                        <Typography
                          sx={{
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            lineHeight: "1.66",
                            color: ((selectedCompleted?.length > 0 && selectedCompleted.every(item => item.created_by === user?.sub)) ? "black" : "#B6B6B6"),
                          }}
                        >
                          Delete
                        </Typography>
                      </Box>
                      <Popover
                        open={openDatePicker}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <Box sx={{ zIndex: 999 }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <StaticDatePicker
                              defaultValue={null}
                              onChange={(newValue) => {
                                setSelectedDate(newValue);
                                handleClose();
                                setOpenDueDateSetModal(true);
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                      </Popover>
                </Box>
        </>
    )
}

