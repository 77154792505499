import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import TheBigFiveQuestions from "../../components/Assessments/TheBigFive/TheBigFiveQuestions";
import TheBigFiveChoices from "../../components/Assessments/TheBigFive/TheBigFiveChoices";
import { generateClient } from "aws-amplify/data";
import AltercallLogoWhite from "../../components/Icons/AltercallLogoWhite.svg";
import OceanBack from "../../components/Icons/OceanBack.svg";
import CompleteOcean from "../../components/Icons/Completeocean.svg";
import { processAnswers } from "../../components/Assessments/TheBigFive/TheBigFiveScoring";
import domains from "../../components/Assessments/TheBigFive/en/index";
import { PDFDocument, StandardFonts, rgb, degrees, PDFName } from "pdf-lib";
import AltercallBlackLogo from "../../components/Icons/AltercallBlackLogo.svg";
import dayjs from "dayjs";
import EmailInputOcean from "./EmailInputOcean";
import EmailIconOcean from "../../components/Icons/EmailIconOcean.svg";
import AltercallLogoHorizontal from "../../components/Icons/AltercallLogoHorizontal.svg";
import {
  verticalLineSvg,
  backgroundSvg,
  logoSvg3,
  gradientEllipseSvg,
  learnMoreSvg,
  vectorFooterwhiteSvg,
  page2BackgroundSvg,
  Namesvg,
  vectorFooterBlackSvg,
  newSvg,
  generatePieCircleSvg,
  gradientSvg,
  logoSvg,
} from "components/Icons/AssessmentReportSvg.svg";
import {
  getStoredUserId,
  setStoredUserId,
  getStoredAssessmentId,
  setStoredAssessmentId,
} from "./helper";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { Buffer } from "buffer";
import axios from "axios";
const resourceClient = generateClient({ authMode: "apiKey" });

const OceanFunnel = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [responses, setResponses] = useState([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [assessment, setAssessment] = useState(null);
  const [loadingFinish, setLoadingFinish] = useState(false);
  const [selectedChoice, setSelectedChoice] = useState(null);
  const [userId, setUserId] = useState(() => {
    const stored = getStoredUserId();
    if (stored) return stored;
    const newId = uuidv4();
    setStoredUserId(newId);
    return newId;
  });
  const creatingRef = useRef(false); // Use ref to track creation status
  const [isEmailStep, setIsEmailStep] = useState(true); // New state to handle the email submission step
  // const [selectedTag, setSelectedTag] = useState(tagNames[0]);

  const questions = TheBigFiveQuestions;
  const tagNames = [
    "General",
    "Openness",
    "Conscientiousness",
    "Extraversion",
    "Agreeableness",
    "Neuroticism",
  ];
  const [selectedTag, setSelectedTag] = useState(tagNames[0]);

  useEffect(() => {
    if (!userId || creatingRef.current) return;

    const createAssessment = async () => {
      try {
        let storedAssessmentId = getStoredAssessmentId();
        if (storedAssessmentId) {
          // see if we have an incomplete assessment in DB
          const { data, errors } = await resourceClient.models.assessments.get({
            id: storedAssessmentId,
          });
          if (!errors && data) {
            // If it's incomplete, reuse it
            if (!data.completed) {
              setAssessment(data);
              creatingRef.current = false;
              return;
            } else {
              setAssessment(data);
              creatingRef.current = false;
              return;
            }
          }
        }
        if (!creatingRef.current || !assessment || !assessment.id) {
          setLoading(true);
          creatingRef.current = true;
          const { data, errors } =
            await resourceClient.models.assessments.create({
              user_id: userId,
              test_type: "The Big Five Personality Test",
              completed: false,
              assigned: true,
              team_id: null,
              coach_id: null,
            });
          if (errors) {
            console.error("Error creating assessment:", errors);
          } else {
            setAssessment(data);
            setStoredAssessmentId(data.id);
          }
          creatingRef.current = false;
        }
      } catch (error) {
        console.error("Error creating assessment:", error);
      } finally {
        setLoading(false);
      }
    };

    createAssessment();
  }, [userId]);

  const handleResponse = (choice) => {
    const question = questions[currentQuestionIndex];

    // Create or update the response for the current question
    const newResponses = [...responses];
    newResponses[currentQuestionIndex] = {
      questionId: question.id,
      domain: question.domain,
      facet: question.facet,
      score: choice,
    };

    setResponses(newResponses); // Update local state
    // saveProgress(newResponses); // Save progress to the backend

    // Move to the next question immediately (optional)
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
    } else {
      setIsCompleted(true);
      completeAssessment(newResponses);
    }
  };

  // const saveProgress = async (formattedResponses) => {
  //   if (assessment) {
  //     try {
  //       const { data, errors } = await resourceClient.models.assessments.update(
  //         {
  //           id: assessment.id,
  //           responses: JSON.stringify(formattedResponses),
  //         }
  //       );
  //       if (errors) {
  //         console.error("Error saving progress:", errors);
  //       } else {
  //         console.log("Progress saved:", data);
  //       }
  //     } catch (error) {
  //       console.error("Error saving data:", error);
  //     }
  //   }
  // };

  const completeAssessment = async (finalResponses) => {
    if (!assessment) return;
    if (assessment) {
      try {
        const processedResults = processAnswers(finalResponses);
        setLoadingFinish(true);
        // setIsCompleted(true);
        // toast(
        //   "Assessment completed! Click back to view your results. An analysis will be available shortly.",
        //   {
        //     hideProgressBar: true,
        //     style: {
        //       background: "white",
        //       boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
        //       borderRadius: "8px",
        //       padding: "16px 28px",
        //       color: "#160042",
        //     },
        //     icon: () => <Check color="success" />,
        //   }
        // );
        const { data, errors } = await resourceClient.models.assessments.update(
          {
            id: assessment.id,
            responses: JSON.stringify(finalResponses),
            results: JSON.stringify(processedResults),
            completed: true,
            updatedAt: new Date().toISOString(),
          }
        );
        console.log("Assessment completed!", data, errors);
        const { data: analysisResult, errors: analysisErrors } =
          await resourceClient.mutations.analyzeAssessmentResults({
            assessment_id: assessment.id,
          });
        console.log("Analysis result:", analysisResult);
        setAssessment({
          ...assessment,
          completed: true,
          results: data.results, // from the update above
        });
        setIsCompleted(true);
        // setAssessment(analysisResult);
        // await generateAndEmailPDF(analysisResult);
      } catch (error) {
        console.error("Error completing assessment:", error);
      } finally {
        setLoadingFinish(false);
      }
    }
  };
  // console.log("assessment data shares from analysisresult", assessment);
  const pollAnalysisResult = async (
    assessmentId,
    maxAttempts = 15,
    intervalMs = 5000
  ) => {
    for (let attempt = 1; attempt <= maxAttempts; attempt++) {
      console.log(`Polling analysis attempt #${attempt}`);
      const { data, errors } = await resourceClient.models.assessments.get({
        id: assessmentId,
      });
      if (!data) {
        setLoading(false);
      }
      if (errors) {
        console.error("Error polling assessment:", errors);
      }

      // If analysis is done, the DB record should have `summary` or `communication_tips`.
      if (data?.summary && data?.communication_tips && data?.results) {
        console.log("Analysis is ready!", data);
        setAssessment(data);
        return data;
      }

      // Otherwise, wait intervalMs and try again
      await new Promise((resolve) => setTimeout(resolve, intervalMs));
    }
    throw new Error("Timed out waiting for analysis to complete.");
  };

  async function generateFullAssessmentPdf(finalAssessmentData) {
    try {
      // Find the domain based on the selectedTag
      const selectedDomain = domains.find(
        (domain) => domain.title === selectedTag
      );

      // Get the result for the selected domain
      const domainResult =
        finalAssessmentData?.results?.[selectedDomain?.domain];
      const scaledScore = (facetScore, count) => {
        const maxScore = count * 5;
        return (facetScore / maxScore) * 10;
      };
      const domainColors = {
        G: "#000000", //General
        N: "#9865CF", // Neuroticism
        O: "#EF406E", // Openness to Experience
        A: "#F3AB54", // Agreeableness
        E: "#337BE8", // Extraversion
        C: "#49CA7A", // Conscientiousness
      };

      const facetColors = {
        0: "#9865CF",
        1: "#337BE8",
        2: "#EF406E",
        3: "#F3AB54",
        4: "#49CA7A",
        5: "#000000",
      };

      const domainOrder = ["O", "C", "E", "A", "N"];
      // Generate the PDF report
      const downloadPDF = async () => {
        console.log("Generating PDF report...");
        // let name = coacheeData?.full_name || "Coachee";
        // console.log("Generating PDF report for:", name);
        const pdfDoc = await PDFDocument.create();
        try {
          console.log("domaindata:", domains);
          // console.log("assessmentData:", analysisResult);
          // console.log("domainResult:", domainResult);
          // console.log("selectedDomain:", selectedDomain);
          // console.log("selectedTag:", selectedTag);
          console.log("scaledScore:", scaledScore);

          //page1
          const page1 = pdfDoc.addPage([1500, 1974]); // Custom size
          const svgToPng = async (svg) => {
            const svgBlob = new Blob([svg], { type: "image/svg+xml" });
            const url = URL.createObjectURL(svgBlob);
            const img = new Image();
            img.src = url;
            await new Promise((resolve) => (img.onload = resolve));
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            URL.revokeObjectURL(url);
            return canvas.toDataURL("image/png");
          };

          //page 1 background color:
          const backgroundPng = await svgToPng(backgroundSvg);
          const backgroundImage = await pdfDoc.embedPng(backgroundPng);

          page1.drawImage(backgroundImage, {
            x: 0,
            y: 0,
            width: 1500,
            height: 1974,
          });

          const logoPng = await svgToPng(logoSvg3);
          const logoImage3 = await pdfDoc.embedPng(logoPng);

          page1.drawImage(logoImage3, {
            x: 191,
            y: page1.getHeight() - 150 - 75, // Adjusted for top position
            width: 527,
            height: 101,
            opacity: 1,
          });
          const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
          const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
          const gradientEllipsePng = await svgToPng(gradientEllipseSvg);
          const gradientEllipseImage = await pdfDoc.embedPng(
            gradientEllipsePng
          );

          page1.drawImage(gradientEllipseImage, {
            x: 5,
            y: page1.getHeight() - 404 - 1750, // Adjusted for top position
            width: 1495,
            height: 1474,
            opacity: 1,
          });
          const customdate = finalAssessmentData?.updatedAt
            ? dayjs(finalAssessmentData?.updatedAt).format("MMMM D, YYYY")
            : "";
          console.log("customdate:", customdate);
          page1.drawText(`${customdate}`, {
            x: 194,
            y: page1.getHeight() - 440,
            size: 32,
            color: rgb(1, 1, 1),
            fontFamily: "lexend",
            width: 247,
            height: 30,
            lineHeight: 35, // Updated line height
            fontWeight: 300,
            textAlign: "left",
            opacity: 1, // Updated opacity to 1
          });

          page1.drawText(`${email}`, {
            x: 194, // Adjusted to match the left position
            y: page1.getHeight() - 525, // Adjusted for top position
            size: 68,
            fontFamily: "lexend",
            color: rgb(1, 1, 1),
            width: 170, // Adjusted to match the width
            height: 30, // Adjusted to match the height
            lineHeight: 30,
            fontWeight: 300,
            textAlign: "left",
            opacity: 1, // Adjusted to match the opacity
          });
          // Draw the text "from" in white color
          page1.drawText(`The O.C.E.A.N.`, {
            x: 194,
            y: page1.getHeight() - 750, // Adjusted for top position
            size: 120,
            color: rgb(1, 1, 1), // White color
            fontFamily: "Poppins",
            width: 1066,
            height: 300,
            lineHeight: 127, // Updated line height
            fontWeight: 300,
            textAlign: "left",
            opacity: 1,
          });

          // Draw the text "callName" in orange color
          page1.drawText(`Assessment`, {
            x: 194,
            y: page1.getHeight() - 750 - 120, // Adjusted for top position
            size: 120,
            color: rgb(1, 94 / 255, 0), // Orange color
            fontFamily: "Poppins",
            lineHeight: 127, // Updated line height
            fontWeight: 300, // Updated font weight
            textAlign: "left",
            opacity: 1,
          });
          page1.drawText(`Report`, {
            x: 194,
            y: page1.getHeight() - 750 - 240, // Adjusted for top position
            size: 120,
            color: rgb(1, 94 / 255, 0), // Orange color
            fontFamily: "Poppins",
            lineHeight: 127, // Updated line height
            fontWeight: 300, // Updated font weight
            textAlign: "left",
            opacity: 1,
          });

          // Draw the "Learn More Here" rectangle
          const learnMorePng = await svgToPng(learnMoreSvg);
          const learnMoreImage = await pdfDoc.embedPng(learnMorePng);
          page1.drawImage(learnMoreImage, {
            x: 190,
            y: page1.getHeight() - 1492,
            width: 273,
            height: 58,
            opacity: 1,
            flexShrink: 0,
          });

          // Draw the "Learn More Here" text
          page1.drawText("Learn More Here", {
            x: 230,
            y: page1.getHeight() - 1470, // Adjusted to center the text vertically
            size: 24,
            color: rgb(1, 1, 1), // White color
            width: 273,
            height: 58,
            lineHeight: 35,
            fontWeight: 500,
            textAlign: "center",
            opacity: 1,
            fontFamily: "Poppins",
          });

          // Add a hyperlink to the "Learn More Here" text
          const linkAnnotation = pdfDoc.context.obj({
            Type: "Annot",
            Subtype: "Link",
            Rect: [
              190,
              page1.getHeight() - 1492,
              190 + 273,
              page1.getHeight() - 1492 + 58,
            ],
            Border: [0, 0, 0],
            A: {
              Type: "Action",
              S: "URI",
              URI: "https://altercall.com/aai",
            },
          });

          // Add the annotation to the page's annotations array
          page1.node.set(
            PDFName.of("Annots"),
            pdfDoc.context.obj([linkAnnotation])
          );

          const vectorFooterwhitePng = await svgToPng(vectorFooterwhiteSvg);
          const vectorFooterwhiteImage = await pdfDoc.embedPng(
            vectorFooterwhitePng
          );

          const page2 = pdfDoc.addPage([1500, 1974]); // Custom size
          page2.drawRectangle({
            x: 0,
            y: 0,
            width: page2.getWidth(),
            height: page2.getHeight(),
            color: rgb(0, 0, 0),
            opacity: 1,
          });

          const page2BackgroundPng = await svgToPng(page2BackgroundSvg);
          const page2BackgroundImage = await pdfDoc.embedPng(
            page2BackgroundPng
          );

          // Draw the gradient background from y = 0 to y = 1861
          page2.drawImage(page2BackgroundImage, {
            x: 0,
            y: page2.getHeight() - 1861, // Start from the top
            width: page2.getWidth(), // Full width of the page
            height: 1861, // Height of 1861
            opacity: 1, // Set opacity to 1
          });

          // Draw a white rectangle for the rest of the page
          page2.drawRectangle({
            x: 0,
            y: 0, // Start from the bottom
            width: page2.getWidth(), // Full width of the page
            height: page2.getHeight() - 1863, // Height from y = 0 to y = 1040
            color: rgb(1, 1, 1), // White color
          });
          page2.drawImage(logoImage3, {
            x: 80,
            y: page2.getHeight() - 33 - 44, // Adjusted for top position
            width: 229,
            height: 44,
            opacity: 1, // Adjusted opacity
          });

          const png = await svgToPng(Namesvg);
          const image = await pdfDoc.embedPng(png);

          page2.drawImage(image, {
            x: 392,
            y: page2.getHeight() - 33 - 44,
            width: 192,
            height: 40,
            flexShrink: 0,
          });

          // Draw client name
          page2.drawText(`User`, {
            x: 430, // Adjusted to match the left position
            y: page2.getHeight() - 33 - 30, // Adjusted for top position
            size: 18,
            font: helveticaFont, // Assuming Lexend is not available in PDFKit, using helveticaFont
            color: rgb(0, 0, 0), // var(--Colors-Grey-Black, #000)
            width: 170, // Adjusted to match the width
            height: 30, // Adjusted to match the height
            lineHeight: 30, // normal
            fontWeight: 300,
            textAlign: "left",
            opacity: 1, // Adjusted to match the opacity
          });
          // Draw the custom date with the specified layout
          page2.drawText(`${customdate}`, {
            x: 1273,
            y: page2.getHeight() - 45 - 19, // Adjusted for top position
            size: 15,
            color: rgb(1, 1, 1),
            width: 155,
            height: 19,
            lineHeight: 19, // Updated line height
            fontWeight: 300,
            textAlign: "left",
            opacity: 1, // Updated opacity to 1
            fontFamily: "Lexend",
          });
          // Draw the "Report Overview" text
          // Define the spacing between each heading
          // Text wrap function
          const wrapText = (text, maxWidth, fontSize, font) => {
            const words = text.split(" ");
            let lines = [];
            let currentLine = words[0];

            for (let i = 1; i < words.length; i++) {
              const word = words[i];
              const width = font.widthOfTextAtSize(
                currentLine + " " + word,
                fontSize
              );
              if (width < maxWidth) {
                currentLine += " " + word;
              } else {
                lines.push(currentLine);
                currentLine = word;
              }
            }
            lines.push(currentLine);
            return lines;
          };

          // Draw the "Assessment Summary" text
          page2.drawText("Assessment Summary", {
            x: 115,
            y: page2.getHeight() - 150 - 60, // Adjusted for top position
            size: 52,
            color: rgb(1, 1, 1), // White color
            fontFamily: "Poppins",
            width: 540,
            height: 75,
            lineHeight: 75,
            fontWeight: 300,
            textAlign: "left",
            opacity: 1,
          });

          const reportoverview =
            finalAssessmentData?.summary || "The summary is being generated.";
          const reportFontSize = 20;
          const reportMaxWidth = 1200; // Updated width according to layout
          let reportLines = wrapText(
            reportoverview,
            reportMaxWidth,
            reportFontSize,
            helveticaFont
          );

          // Calculate the total height needed for the report overview text
          let reportTotalHeight = reportLines.length * reportFontSize * 1.2;
          // Adjust the y position dynamically based on the total height
          let reportCurrentY = page2.getHeight() - 150 - 110; // Updated top position according to layout

          reportLines.forEach((line, index) => {
            page2.drawText(line, {
              x: 115, // Updated left position according to layout
              y: reportCurrentY - index * reportFontSize * 1.2,
              size: 20,
              fontFamily: "lexend",
              color: rgb(1, 1, 1),
              width: reportMaxWidth,
              height: 97, // Updated height according to layout
              lineHeight: 24,
              fontWeight: 400,
              textAlign: "left",
              opacity: 1,
              letterSpacing: -0.03,
            });
          });

          // Draw the "Communication Report" text
          page2.drawText("Communication Report", {
            x: 115,
            y: reportCurrentY - reportTotalHeight - 100, // Adjusted for equal spacing
            size: 52,
            color: rgb(1, 1, 1), // White color
            fontFamily: "Poppins",
            width: 490,
            height: 75,
            lineHeight: 75,
            fontWeight: 300,
            textAlign: "left",
            opacity: 1,
          });

          const summary =
            finalAssessmentData?.communication_tips ||
            "The Communication Report is being generated.";
          const summaryFontSize = 20;
          const summaryMaxWidth = 1200;
          let summaryLines = wrapText(
            summary,
            summaryMaxWidth,
            summaryFontSize,
            helveticaFont
          );

          let summaryCurrentY = reportCurrentY - reportTotalHeight - 160; // Adjusted top position according to layout
          let summaryTotalHeight = summaryLines.length * summaryFontSize * 1.2;

          summaryLines.forEach((line, index) => {
            page2.drawText(line, {
              x: 115, // Updated left position according to layout
              y: summaryCurrentY - index * summaryFontSize * 1.2,
              size: summaryFontSize,
              fontFamily: "lexend",
              color: rgb(1, 1, 1),
              width: summaryMaxWidth,
              height: 409, // Updated height according to layout
              lineHeight: 24,
              fontWeight: 400,
              textAlign: "left",
              opacity: 1,
              letterSpacing: -0.03,
            });
          });

          let comm_style =
            finalAssessmentData?.communication_style ||
            "The communication style is being generated.";
          page2.drawText("Communication Style", {
            x: 115,
            y: summaryCurrentY - summaryTotalHeight - 100, // Adjusted for equal spacing
            size: 52,
            color: rgb(1, 1, 1), // White color
            fontFamily: "Poppins",
            width: 490,
            height: 75,
            lineHeight: 75,
            fontWeight: 300,
            textAlign: "left",
            opacity: 1,
          });

          const comm_styleFontSize = 20;
          const commStyleMaxWidth = 1200;
          let commStyleLines = wrapText(
            comm_style,
            commStyleMaxWidth,
            comm_styleFontSize,
            helveticaFont
          );

          let commStyleCurrentY = summaryCurrentY - summaryTotalHeight - 160; // Adjusted top position according to layout
          let commStyleTotalHeight =
            commStyleLines.length * comm_styleFontSize * 1.2;

          commStyleLines.forEach((line, index) => {
            page2.drawText(line, {
              x: 115, // Updated left position according to layout
              y: commStyleCurrentY - index * comm_styleFontSize * 1.2,
              size: comm_styleFontSize,
              fontFamily: "lexend",
              color: rgb(1, 1, 1),
              width: commStyleMaxWidth,
              height: 409, // Updated height according to layout
              lineHeight: 24,
              fontWeight: 400,
              textAlign: "left",
              opacity: 1,
              letterSpacing: -0.03,
            });
          });

          // Check if the communication preference is available
          let comm_pref =
            finalAssessmentData?.communication_preference ||
            "The communication preference is being generated.";

          // Draw "Communication Preference" heading
          page2.drawText("Communication Preference", {
            x: 115,
            y: commStyleCurrentY - commStyleTotalHeight - 100, // Adjusted for equal spacing
            size: 52,
            color: rgb(1, 1, 1), // Black color
            fontFamily: "Poppins",
            width: 490,
            height: 75,
            lineHeight: 75,
            fontWeight: 300,
            textAlign: "left",
            opacity: 1,
          });

          // Wrap the communication preference text
          const commPrefFontSize = 20;
          const commPrefMaxWidth = 1200;
          let commPrefLines = wrapText(
            comm_pref,
            commPrefMaxWidth,
            commPrefFontSize,
            helveticaFont
          );

          // Adjust top position according to layout
          let commPrefCurrentY = commStyleCurrentY - commStyleTotalHeight - 160;
          let commPrefTotalHeight =
            commPrefLines.length * commPrefFontSize * 1.2;

          // Draw the communication preference text
          commPrefLines.forEach((line, index) => {
            page2.drawText(line, {
              x: 115, // Updated left position according to layout
              y: commPrefCurrentY - index * commPrefFontSize * 1.2,
              size: commPrefFontSize,
              fontFamily: "Lexend",
              color: rgb(1, 1, 1),
              width: commPrefMaxWidth,
              height: 409, // Updated height according to layout
              lineHeight: 24,
              fontWeight: 400,
              textAlign: "left",
              opacity: 1,
              letterSpacing: -0.03,
            });
          });

          // Draw the text "Learn more about L.A.R.A at www.altercall.com/aai"
          page2.drawText("Learn more about at", {
            x: 630,
            y: page2.getHeight() - 1933,
            size: 20,
            color: rgb(0, 0, 0),
            fontFamily: "lexend",
            width: 894,
            height: 41,
            lineHeight: 24,
            fontWeight: 400,
            textAlign: "left",
            opacity: 1,
            letterSpacing: -0.03,
          });

          // Draw the clickable link "www.altercall.com/aai"
          page2.drawText("www.altercall.com/aai", {
            x: 825,
            y: page2.getHeight() - 1933,
            size: 20,
            color: rgb(0, 118 / 255, 186 / 255), // #0076BA color
            fontFamily: "lexend",
            width: 894,
            height: 41,
            lineHeight: 24,
            fontWeight: 400,
            textAlign: "left",
            opacity: 1,
            letterSpacing: -0.03,
            underline: true,
          });

          page2.drawText("02", {
            x: 1437,
            y: page2.getHeight() - 1933,
            size: 18,
            color: rgb(1, 94 / 255, 0), // Orange color (#FF5E00)
            width: 18,
            height: 19,
            opacity: 1,
          });

          const vectorFooterBlackPng = await svgToPng(vectorFooterBlackSvg);
          const vectorFooterBlackImage = await pdfDoc.embedPng(
            vectorFooterBlackPng
          );

          page2.drawImage(vectorFooterBlackImage, {
            x: 54,
            y: page2.getHeight() - 1933,
            width: 199,
            height: 38,
            opacity: 1,
          });

          //new page
          const page3 = pdfDoc.addPage([1500, 1974]); // Custom size

          const verticalLinePng = await svgToPng(verticalLineSvg);
          const verticalLineImage = await pdfDoc.embedPng(verticalLinePng);

          page3.drawImage(verticalLineImage, {
            x: 0,
            y: page3.getHeight() - 1863, // Start from the top
            width: 340,
            height: 1863,
            opacity: 1,
          });
          //logo
          page3.drawImage(logoImage3, {
            x: 53,
            y: page3.getHeight() - 33 - 44, // Adjusted for top position
            width: 229,
            height: 44,
            opacity: 1, // Adjusted opacity
          });

          const newImagePng = await svgToPng(newSvg);
          const newImage = await pdfDoc.embedPng(newImagePng);

          page3.drawImage(newImage, {
            x: 392,
            y: page3.getHeight() - 33 - 44,
            width: 192,
            height: 40,
            flexShrink: 0,
          });

          // Draw client name
          page3.drawText(`User`, {
            x: 430, // Adjusted to match the left position
            y: page3.getHeight() - 33 - 30, // Adjusted for top position
            size: 18,
            font: helveticaFont, // Assuming Lexend is not available in PDFKit, using helveticaFont
            color: rgb(0, 0, 0), // var(--Colors-Grey-Black, #000)
            width: 170, // Adjusted to match the width
            height: 30, // Adjusted to match the height
            lineHeight: 30, // normal
            fontWeight: 300,
            textAlign: "left",
            opacity: 1, // Adjusted to match the opacity
          });
          // Draw the custom date with the specified layout
          page3.drawText(`${customdate}`, {
            x: 1273,
            y: page3.getHeight() - 45 - 19, // Adjusted for top position
            size: 15,
            color: rgb(0, 0, 0),
            width: 155,
            height: 19,
            lineHeight: 19, // Updated line height
            fontWeight: 300,
            textAlign: "left",
            opacity: 1, // Updated opacity to 1
            fontFamily: "Lexend",
          });
          // Draw the text "O.C.E.A.N. Traits" with the specified styles
          page3.drawText("O.C.E.A.N. Traits", {
            x: 417,
            y: page3.getHeight() - 185, // Adjusted for top position and line height
            size: 67,
            color: rgb(0, 0, 0),
            fontFamily: "Poppins",
            lineHeight: 75,
            fontWeight: 300,
            textAlign: "left",
            opacity: 0.8,
          });
          const text1 =
            "This page offers a detailed explanation of these sub-scores, shedding light on how they shape your behaviors, preferences, & interactions in various settings:";
          const text2 =
            " Openness, Conscientiousness, Extraversion, Agreeableness, and Neuroticism.";

          // Define the layout properties
          const layout = {
            width: 916, // Total width for the text block
            height: 201, // Height of the block
            top: 250, // Starting position from the top
            left: 417, // Left margin for alignment
            gap: 0, // No gap between text1 and text2
            opacity: 0.7, // Text opacity
            fontFamily: "Lexend", // Font family
            fontSize: 32, // Font size for both texts
            fontWeight: 300, // Regular font weight for text1
            lineHeight: 42, // Line height for both texts
            letterSpacing: -0.96, // Letter spacing for both texts
            textAlign: "left", // Left aligned
          };

          // Wrap the text using the wrapText function
          const text1Lines = wrapText(
            text1,
            layout.width,
            layout.fontSize,
            helveticaFont
          );
          const text2Lines = wrapText(
            text2,
            layout.width,
            layout.fontSize,
            helveticaFont
          );

          // Draw text1 with black color
          let text1Y = page3.getHeight() - layout.top;
          text1Lines.forEach((line, index) => {
            page3.drawText(line, {
              x: layout.left,
              y: text1Y - index * layout.lineHeight,
              size: layout.fontSize,
              frontfamily: "Lexend",
              color: rgb(0, 0, 0), // Black color for text1
              lineHeight: layout.lineHeight,
              fontWeight: layout.fontWeight, // Regular weight
              textAlign: layout.textAlign,
              opacity: layout.opacity,
              letterSpacing: layout.letterSpacing,
            });
          });

          // Get the width of the last line of text1
          const lastLine = text1Lines[text1Lines.length - 1];
          let lastLineWidth = helveticaFont.widthOfTextAtSize(
            lastLine,
            layout.fontSize
          ); // Width of the last line of text1

          // Calculate how much of text2 can fit on the same line
          let remainingWidth = layout.width - lastLineWidth; // Remaining width on the last line of text1
          let currentText = ""; // To store the portion of text2 that fits
          let index = 0; // Index for characters in text2

          // Loop to calculate the portion of text2 that fits on the same line
          while (
            index < text2.length &&
            helveticaFont.widthOfTextAtSize(
              currentText + text2[index],
              layout.fontSize
            ) < remainingWidth
          ) {
            currentText += text2[index];
            index++;
          }

          // Draw the portion of text2 that fits on the same line
          if (currentText) {
            page3.drawText(currentText, {
              x: layout.left + lastLineWidth,
              y: text1Y - (text1Lines.length - 1) * layout.lineHeight, // Same line as the last line of text1
              size: layout.fontSize,
              fontFamily: "Lexend",
              color: rgb(0, 0, 0), // Blue color for text2
              lineHeight: layout.lineHeight,
              fontWeight: "700px", // Bold font for text2
              textAlign: layout.textAlign,
              opacity: 1,
              letterSpacing: layout.letterSpacing,
            });
            text2Lines.shift(); // Remove the first line as it is already drawn
          }

          // Draw the remaining part of text2 on the next line with consistent line height
          let remainingText = text2.substring(index); // Get the remaining part of text2 that doesn't fit

          if (remainingText) {
            page3.drawText(remainingText, {
              x: layout.left, // Start from the left
              y: text1Y - text1Lines.length * layout.lineHeight, // Same spacing as other lines
              size: layout.fontSize,
              fontFamily: "Lexend",
              color: rgb(0, 0, 0), // Blue color for text2
              lineHeight: layout.lineHeight,
              fontWeight: 700, // Bold font for text2
              textAlign: layout.textAlign,
              opacity: 1,
              letterSpacing: layout.letterSpacing,
            });
          }
          // Draw the horizontal line with the specified properties
          page3.drawRectangle({
            x: 340,
            y: page3.getHeight() - 438, // Adjusted for top position
            width: 1160,
            height: 0,
            color: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
            borderWidth: 2,
            borderColor: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
            opacity: 1,
          });
          const domainColors = {
            Openness: rgb(239 / 255, 64 / 255, 110 / 255), // Openness to Experience
            Conscientiousness: rgb(73 / 255, 202 / 255, 122 / 255), // Conscientiousness
            Extraversion: rgb(51 / 255, 123 / 255, 232 / 255), // Extraversion
            Agreeableness: rgb(243 / 255, 171 / 255, 84 / 255), // Agreeableness
            Neuroticism: rgb(152 / 255, 101 / 255, 207 / 255), // Neuroticism
          };

          // Define domain order
          const domainOrder = [
            "Openness",
            "Conscientiousness",
            "Extraversion",
            "Agreeableness",
            "Neuroticism",
          ];

          // Mapping between full trait names and their corresponding keys in `assessmentData.results`
          const domainMapping = {
            Openness: "O",
            Conscientiousness: "C",
            Extraversion: "E",
            Agreeableness: "A",
            Neuroticism: "N",
          };
          const traitDefinitions = {
            Openness:
              "This trait reflects how open-minded, imaginative, and curious someone is. People who score high in Openness are typically creative, curious, and interested in novel experiences, while those with low scores may prefer routine, structure, and familiarity.",
            Conscientiousness:
              "This trait reflects an individual’s level of organization, reliability, and work ethic. High Conscientiousness individuals are disciplined, detail-oriented, and goal-focused. Low Conscientiousness individuals may be more spontaneous, flexible, and less detail-oriented.",
            Extraversion:
              "This trait reflects the degree to which a person is outgoing, energetic, and sociable. High Extraversion individuals are sociable, talkative, and energetic. Low Extraversion individuals are more reserved, introverted, and may prefer solitude.",
            Agreeableness:
              "This trait reflects an individual’s level of empathy, cooperativeness, and social harmony. High Agreeableness individuals are kind, empathetic, and eager to avoid conflict. Low Agreeableness individuals tend to be more competitive, critical, or skeptical.",
            Neuroticism:
              "Neuroticism refers to an individual’s emotional stability and susceptibility to stress. High Neuroticism individuals are more prone to anxiety, mood swings, and negative emotions, while low Neuroticism individuals tend to be calm, resilient, and emotionally stable.",
          };

          // Starting point for drawing the Score Overview section
          let startX = 417;
          let startY = page3.getHeight() - 530; // Adjust the Y position as needed based on page layout
          const circleRadius = 15.7956; // Radius for the filled and empty circles
          const circleGap = 12; // Gap between circles
          const textSpacing = 20; // Space between the trait title, circles, and score text
          // Draw each trait
          domainOrder.forEach((domainTitle) => {
            const domainKey = domainMapping[domainTitle]; // Map full trait name to corresponding key ('A', 'C', etc.)
            const domainColor = domainColors[domainTitle] || rgb(0, 0, 0);
            const parsedResults = JSON.parse(
              finalAssessmentData.results || "{}"
            );
            const domainData = parsedResults[domainKey] || {};
            console.log("parsedResults", parsedResults);
            console.log("domainData: get from assessment data", domainData);
            const scoreValue = Number(domainData.score) || 0;
            console.log("scoreValue:", scoreValue);

            const scaledScore = Math.round((scoreValue / 120) * 100);
            const filledCircles = Math.floor(scaledScore / 10);
            const emptyCircles = 10 - filledCircles;

            // Draw Trait Title
            page3.drawText(domainTitle, {
              x: startX,
              y: startY,
              size: 30.67,
              color: domainColor, // Use domainColor
              fontFamily: "Lexend",
              lineHeight: 49.07,
              fontWeight: 400,
            });

            // Draw Circles
            let currentX = startX + 425; // Position for first circle
            for (let i = 0; i < filledCircles; i++) {
              page3.drawCircle({
                x: currentX,
                y: startY + circleRadius / 2,
                size: circleRadius,
                color: domainColor,
                fill: domainColor,
              });
              currentX += circleRadius * 2 + circleGap;
            }
            for (let i = 0; i < emptyCircles; i++) {
              page3.drawCircle({
                x: currentX,
                y: startY + circleRadius / 2,
                size: circleRadius,
                color: rgb(239 / 255, 243 / 255, 246 / 255), // #EFF3F6 color
                fill: rgb(239 / 255, 243 / 255, 246 / 255), // #EFF3F6 color
              });
              currentX += circleRadius * 2 + circleGap;
            }

            // Draw Score
            page3.drawText(`${scaledScore} / 100`, {
              x: currentX - 10,
              y: startY,
              size: 20.643,
              color: rgb(143 / 255, 143 / 255, 143 / 255), // #8F8F8F color
              lineHeight: 33.029,
              fontWeight: 400,
              fontFamily: "Lexend",
            });

            // Draw Short Description
            // Render Trait Definition
            const traitDefinition = traitDefinitions[domainTitle];
            if (traitDefinition) {
              const definitionLines = wrapText(
                traitDefinition,
                925,
                20,
                helveticaFont
              ); // Wrap text for fitting into the layout
              let definitionY = startY - 50; // Adjust Y position for definition
              definitionLines.forEach((line, lineIndex) => {
                page3.drawText(line, {
                  x: startX,
                  y: definitionY - lineIndex * 28,
                  size: 20,
                  color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
                  fontFamily: "Lexend",
                  fontWeight: 400,
                  letterSpacing: -0.6,
                });
              });
              startY -= definitionLines.length * 20 + 10; // Adjust spacing after the definition
            }

            //console.log("domaindata know", domains);
            // Move Y position for the next trait
            startY -= 150; // Adjust spacing between traits
          });

          // Draw the vertical line with the specified properties
          page3.drawRectangle({
            x: 24,
            y: page3.getHeight() - 150 - 44, // Adjusted for top position
            width: 8,
            height: 44,
            color: rgb(86 / 255, 143 / 255, 243 / 255), // #568FF3 color
            opacity: 1,
          });
          // Draw the "About OCEAN" text with the specified styles
          page3.drawText("About OCEAN:", {
            x: 52,
            y: page3.getHeight() - 159 - 20,
            size: 20,
            fontFamily: "Lexend",
            fontWeight: 900, // Increased font weight for boldness
            lineHeight: 24,
            letterSpacing: -0.03,
            textAlign: "left",
            color: rgb(1, 1, 1), // White color
            opacity: 1,
            underline: true,
            textUnderlinePosition: "from-font",
            textDecorationSkipInk: "none",
          });
          // Draw the text block with the specified properties
          const textBlock =
            "The Big 5 Personality Traits provide a comprehensive framework for understanding human behavior. These traits are commonly referred to by their acronym OCEAN, which stands for Openness, Conscientiousness, Extraversion, Agreeableness, and Neuroticism.";
          const textBlockFontSize = 20;
          const textBlockMaxWidth = 252;
          const textBlockLines = wrapText(
            textBlock,
            textBlockMaxWidth,
            textBlockFontSize,
            helveticaFont
          );

          let textBlockCurrentY = page3.getHeight() - 250; // Adjusted top position according to layout
          textBlockLines.forEach((line, index) => {
            page3.drawText(line, {
              x: 52, // Adjusted X position based on layout
              y: textBlockCurrentY - index * textBlockFontSize * 1.2,
              size: textBlockFontSize,
              fontFamily: "Lexend",
              color: rgb(1, 1, 1), // Black color
              width: textBlockMaxWidth,
              height: 24,
              lineHeight: 24,
              fontWeight: 400,
              textAlign: "left",
              opacity: 1,
              letterSpacing: -0.03,
              textUnderlinePosition: "from-font",
              textDecorationSkipInk: "none",
            });
          });
          // Draw the explanation text for each trait
          const explanationText =
            "Below will explain each trait in detail, how to communicate effectively with someone who has a low or high score in that trait, and the best ways to address key workplace interactions.";
          const explanationLines = wrapText(
            explanationText,
            252,
            20,
            helveticaFont
          );
          let explanationY = page3.getHeight() - 580; // Adjust the Y position as needed

          explanationLines.forEach((line, index) => {
            page3.drawText(line, {
              x: 52, // Adjust the X position as needed
              y: explanationY - index * 24, // Adjust the line height as needed
              size: 20,
              fontFamily: "Lexend",
              color: rgb(1, 1, 1),
              lineHeight: 24,
              fontWeight: 400,
              letterSpacing: -0.6,
              opacity: 1, // Set opacity to 0
            });
          });
          // Draw the first line of text
          page3.drawText(
            "Continue reading the report to learn about each of your scores, our",
            {
              x: 417,
              y: page3.getHeight() - 1687 - 24, // Adjusted for top position
              size: 20,
              color: rgb(160 / 255, 160 / 255, 160 / 255), // #A0A0A0 color
              lineHeight: 24,
              fontFamily: "Lexend",
              fontWeight: 400,
              textAlign: "left",
              opacity: 1,
              letterSpacing: -0.03,
            }
          );

          // Draw the second line of text
          page3.drawText(
            "suggested improvements to your communication style and ways of",
            {
              x: 417,
              y: page3.getHeight() - 1711 - 24,
              size: 20,
              color: rgb(160 / 255, 160 / 255, 160 / 255),
              lineHeight: 24,
              fontFamily: "Lexend",
              fontWeight: 400,
              textAlign: "left",
              opacity: 1,
              letterSpacing: -0.03,
            }
          );

          // Draw the third line of text
          page3.drawText("communicating with others based on their scores.", {
            x: 417,
            y: page3.getHeight() - 1735 - 24, // Adjusted for top position
            size: 20,
            color: rgb(160 / 255, 160 / 255, 160 / 255), // #A0A0A0 color
            fontFamily: "Lexend",
            lineHeight: 24,
            fontWeight: 400,
            textAlign: "left",
            opacity: 1,
            letterSpacing: -0.03,
          });

          // Draw the text "Learn more about L.A.R.A at www.altercall.com/aai"
          page3.drawText("Learn more about at", {
            x: 630,
            y: page3.getHeight() - 1933,
            size: 20,
            color: rgb(0, 0, 0),
            fontFamily: "lexend",
            width: 894,
            height: 41,
            lineHeight: 24,
            fontWeight: 400,
            textAlign: "left",
            opacity: 1,
            letterSpacing: -0.03,
          });

          // Draw the clickable link "www.altercall.com/aai"
          page3.drawText("www.altercall.com/aai", {
            x: 825,
            y: page3.getHeight() - 1933,
            size: 20,
            color: rgb(0, 118 / 255, 186 / 255), // #0076BA color
            fontFamily: "lexend",
            width: 894,
            height: 41,
            lineHeight: 24,
            fontWeight: 400,
            textAlign: "left",
            opacity: 1,
            letterSpacing: -0.03,
            underline: true,
          });

          page3.drawText("03", {
            x: 1437,
            y: page3.getHeight() - 1933,
            size: 18,
            color: rgb(1, 94 / 255, 0), // Orange color (#FF5E00)
            width: 18,
            height: 19,
            opacity: 1,
          });

          page3.drawImage(vectorFooterBlackImage, {
            x: 54,
            y: page3.getHeight() - 1933,
            width: 199,
            height: 38,
            opacity: 1,
          });

          //new page for all domains making
          const parsedResults = JSON.parse(
            finalAssessmentData?.results || "{}"
          );
          const domainSections = [
            {
              title: "Openness",
              score: parsedResults?.O?.score ?? 0, // Use parsedResults
              communicationStyle:
                finalAssessmentData?.communication_style ?? "",
              page: "03", // Page number where this domain appears in the PDF
            },
            {
              title: "Conscientiousness",
              score: parsedResults?.C?.score ?? 0,
              communicationStyle:
                finalAssessmentData?.communication_style ?? "",
              page: "04",
            },
            {
              title: "Extraversion",
              score: parsedResults?.E?.score ?? 0,
              communicationStyle:
                finalAssessmentData?.communication_style ?? "",
              page: "05",
            },
            {
              title: "Agreeableness",
              score: parsedResults?.A?.score ?? 0,
              communicationStyle:
                finalAssessmentData?.communication_style ?? "",
              page: "06",
            },
            {
              title: "Neuroticism",
              score: parsedResults?.N?.score ?? 0,
              communicationStyle:
                finalAssessmentData?.communication_style ?? "",
              page: "07",
            },
          ];

          const domainData = domainSections.map((section) => {
            const domainKey = domainMapping[section.title];
            const selectedDomain =
              domains.find((domain) => domain.domain === domainKey) || {};
            const domainInfo = {
              title: section.title,
              shortDescription:
                selectedDomain.shortDescription ||
                "No description available for this domain.",
              description:
                selectedDomain.description ||
                "No description available for this result.",
              results: selectedDomain.results || [],
              facets:
                selectedDomain.facets.map((facet, index) => {
                  const facetData =
                    parsedResults[domainKey]?.facet[index + 1] || {}; // Use parsedResults here
                  const scoreValue =
                    Number(facetData.score) ||
                    Math.floor(Math.random() * (18 - 7 + 1)) + 7;
                  const countValue = Number(facetData.count) || 1;
                  const scaledFacetScore = Math.min(
                    Math.max(
                      Math.round(((scoreValue / (countValue * 5)) * 100) / 10),
                      0
                    ),
                    10
                  );
                  return {
                    title: facet.title,
                    text: facet.text,
                    score: scaledFacetScore,
                  };
                }) || [],
            };
            return domainInfo;
          });

          console.log("domainData:", domainData);
          console.log("domainSections:", domainSections);
          for (const [index, domainSec] of domainSections.entries()) {
            const page = pdfDoc.addPage([1500, 1974]); // Custom size
            // Draw the background for the domain page
            page.drawImage(verticalLineImage, {
              x: 0,
              y: page.getHeight() - 1863, // Start from the top
              width: 340,
              height: 1863,
              opacity: 1,
            });
            // Draw the logo image
            page.drawImage(logoImage3, {
              x: 53,
              y: page.getHeight() - 33 - 44, // Adjusted for top position
              width: 229,
              height: 44,
              opacity: 1, // Adjusted opacity
            });
            //outer container
            page.drawImage(newImage, {
              x: 392,
              y: page.getHeight() - 33 - 44,
              width: 192,
              height: 40,
              flexShrink: 0,
            });

            // Draw client name
            page.drawText(`User`, {
              x: 430, // Adjusted to match the left position
              y: page.getHeight() - 33 - 30, // Adjusted for top position
              size: 18,
              fontFamily: "Lexend",
              color: rgb(0, 0, 0), // var(--Colors-Grey-Black, #000)
              width: 170, // Adjusted to match the width
              height: 30, // Adjusted to match the height
              lineHeight: 30, // normal
              fontWeight: 300,
              textAlign: "left",
              opacity: 1, // Adjusted to match the opacity
            });
            // Draw the custom date with the specified layout
            page.drawText(`${customdate}`, {
              x: 1271,
              y: page.getHeight() - 45 - 19, // Adjusted for top position
              size: 15,
              color: rgb(0, 0, 0),
              width: 155,
              height: 19,
              lineHeight: 19, // Updated line height
              fontWeight: 300,
              textAlign: "left",
              opacity: 1, // Updated opacity to 1
              fontFamily: "Lexend",
            });

            // Draw the domain title
            page.drawText(domainSec.title, {
              x: 417,
              y: page.getHeight() - 128 - 75,
              size: 67,
              color: rgb(0, 0, 0), // #000
              fontFamily: "Poppins",
              fontWeight: 300,
              lineHeight: 75, // 111.94%
              opacity: 0.8,
            });

            // Draw the domain score
            const domainScore =
              Number(domainSec.score) || Math.floor(Math.random() * 51) + 40;
            let domainscore1 = Math.round((domainScore / 120) * 100);
            let colorPie = domainColors[domainSec.title];
            //console.log("colorPie:", colorPie);

            let colorPieHex = "000000"; // Default to black if invalid
            if (colorPie.type === "RGB") {
              const r = Math.round(colorPie.red * 255);
              const g = Math.round(colorPie.green * 255);
              const b = Math.round(colorPie.blue * 255);
              colorPieHex = [r, g, b]
                .map((x) => x.toString(16).padStart(2, "0"))
                .join("");
            }

            const pieCircleSvg = generatePieCircleSvg(
              colorPieHex,
              domainscore1
            );
            const pieCirclePng = await svgToPng(pieCircleSvg);
            const pieCircleImage = await pdfDoc.embedPng(pieCirclePng);

            page.drawImage(pieCircleImage, {
              x: 67,
              y: page.getHeight() - 151 - 190.25,
              width: 191,
              height: 191,
              opacity: 1,
            });
            //about your score text
            page.drawText("Your Category Score", {
              x: 57,
              y: page.getHeight() - 366 - 32, // Adjusted for top position
              size: 20,
              color: rgb(1, 1, 1), // White color
              fontFamily: "Lexend",
              width: 225,
              height: 41,
              lineHeight: 24,
              fontWeight: 900, // Increased font weight for boldness
              textAlign: "center",
              opacity: 1,
              letterSpacing: -0.6,
              flexShrink: 0,
            });
            // Draw the short description
            const shortDescriptionLines = wrapText(
              domainData[index].shortDescription,
              980,
              32,
              helveticaFont
            );
            let shortDescriptionY = page.getHeight() - 260;

            shortDescriptionLines.forEach((line, lineIndex) => {
              page.drawText(line, {
                x: 417,
                y: shortDescriptionY - lineIndex * 42,
                size: 32,
                color: rgb(0, 0, 0),
                fontWeight: 300,
                fontFamily: "Lexend",
                lineHeight: 42,
                letterSpacing: -0.96,
                textAlign: "left",
                opacity: 0.7,
              });
            });
            // Draw the horizontal line with the specified properties
            page.drawRectangle({
              x: 340,
              y: page.getHeight() - 438, // Adjusted for top position
              width: 1160,
              height: 0,
              color: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
              borderWidth: 2,
              borderColor: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
              opacity: 1,
            });
            //about you text
            // Draw the "About You" text
            page.drawText("About You:", {
              x: 55,
              y: page.getHeight() - 484,
              size: 20,
              color: rgb(1, 1, 1), // White color
              fontFamily: "Lexend",
              width: 237,
              height: 290,
              lineHeight: 24, // 120%
              fontWeight: 700,
              textAlign: "left",
              opacity: 1,
              letterSpacing: -0.6,
              flexShrink: 0,
            });
            page.drawRectangle({
              x: 27,
              y: page.getHeight() - 500, // Adjusted for top position
              width: 8,
              height: 44,
              color: rgb(86 / 255, 143 / 255, 243 / 255), // Blue color (#568FF3)
              opacity: 1,
              flexShrink: 0,
            });

            //draw result low text
            // Determine the score category and corresponding text
            let resultText = "";
            if (domainscore1 >= 0 && domainscore1 <= 40) {
              resultText =
                domainData[index].results.find(
                  (result) => result.score === "low"
                )?.text || "";
            } else if (domainscore1 > 40 && domainscore1 <= 70) {
              resultText =
                domainData[index].results.find(
                  (result) => result.score === "neutral"
                )?.text || "";
            } else if (domainscore1 > 70 && domainscore1 <= 100) {
              resultText =
                domainData[index].results.find(
                  (result) => result.score === "high"
                )?.text || "";
            }
            // Remove newline characters from resultText
            resultText = resultText.replace(/\n/g, " ");
            // Draw the result text with height limit
            const resultTextLines = wrapText(
              resultText,
              238,
              20,
              helveticaFont
            );
            let resultTextY = page.getHeight() - 540;
            const maxYY = page.getHeight() - 775; // Maximum Y position

            for (
              let lineIndex = 0;
              lineIndex < resultTextLines.length;
              lineIndex++
            ) {
              const line = resultTextLines[lineIndex];
              if (resultTextY - lineIndex * 28 < maxYY) {
                // Stop rendering if the next line exceeds the maxY
                const truncatedLine = line.substring(
                  0,
                  line.lastIndexOf(".") + 1
                );
                page.drawText(truncatedLine, {
                  x: 55,
                  y: resultTextY - lineIndex * 28,
                  size: 20,
                  color: rgb(1, 1, 1), // White color
                  fontFamily: "Lexend",
                  lineHeight: 28,
                  fontWeight: 700,
                  letterSpacing: -0.6,
                  textAlign: "left",
                  opacity: 1,
                });
                break;
              }
              page.drawText(line, {
                x: 55,
                y: resultTextY - lineIndex * 28,
                size: 20,
                color: rgb(1, 1, 1), // White color
                fontFamily: "Lexend",
                lineHeight: 28,
                fontWeight: 700,
                letterSpacing: -0.6,
                textAlign: "left",
                opacity: 1,
              });
            }

            // Draw the facets
            let facetY = page.getHeight() - 520; // Starting y position for the first facet
            const maxY = page.getHeight() - 1600; // Maximum y position for the facets
            const gapBetweenFacets =
              (facetY - maxY) / (domainData[index].facets.length - 1); // Calculate the gap between facets

            domainData[index].facets.forEach((facet, facetIndex) => {
              // Draw the facet title
              page.drawText(`${facet.title}`, {
                x: 417, // Adjusted X position based on CSS (left: 78px)
                y: facetY,
                size: 30.67, // Font size based on CSS
                color: domainColors[domainSec.title],
                fontWeight: 700, // Bold
                fontFamily: "lexend",
                lineHeight: 49.07, // Line height from your CSS
                letterSpacing: 2, // Letter spacing from your CSS
                textAlign: "left",
                opacity: 1,
                width: 693, // Width from CSS (width: 693px)
                height: 18,
                textTransform: "capitalize",
              });

              // Draw the circles representing the facet score
              let newscore = facet.score * 10;
              console.log("newscore facet:", newscore);
              const filledCircles = Math.floor(newscore / 10);
              const emptyCircles = 10 - filledCircles;
              const circleRadius = 12; // Radius for circle
              const circleGap = 10; // Gap between circles
              let currentX = 947; // Adjust X position based on CSS (left: 627px)
              console.log("currentX:", currentX);
              // Filled circles
              for (let i = 0; i < filledCircles; i++) {
                page.drawCircle({
                  x: currentX,
                  y: facetY,
                  size: circleRadius,
                  color: domainColors[domainSec.title], // Use domain color
                  fill: domainColors[domainSec.title],
                });
                currentX += circleRadius * 2 + circleGap;
              }

              // Empty circles
              for (let i = 0; i < emptyCircles; i++) {
                page.drawCircle({
                  x: currentX,
                  y: facetY,
                  size: circleRadius,
                  color: rgb(239 / 255, 243 / 255, 246 / 255),
                  fill: rgb(239 / 255, 243 / 255, 246 / 255),
                });
                currentX += circleRadius * 2 + circleGap;
              }
              page.drawText(`Score:`, {
                x: 1300, // Positioned based on your layout (left: 1002px)
                y: facetY - 5,
                size: 16, // Font size
                color: rgb(0, 0, 0),
                fontFamily: "Lexend",
                fontWeight: 700,
                lineHeight: 25.6, // 160% of 16px
                textAlign: "right",
                opacity: 0.8,
              });
              // Draw the facet score
              page.drawText(`${newscore}/100`, {
                x: 1350, // Positioned based on your layout (left: 1002px)
                y: facetY - 5,
                size: 16, // Font size
                color: rgb(0, 0, 0),
                fontfamily: "Lexend",
                fontWeight: 900,
                lineHeight: 25.6, // 160% of 16px
                textAlign: "right",
                opacity: 1,
              });

              const facetTextLines = wrapText(
                facet.text.replace(/\n/g, " "),
                880, // Adjust width for text wrapping after the bullet
                18,
                helveticaFont
              );

              let facetTextY = facetY - 60;

              facetTextLines.forEach((line, lineIndex) => {
                if (lineIndex === 0) {
                  // Draw the bullet separately for the first line
                  page.drawText("•", {
                    x: 425,
                    y: facetTextY,
                    size: 18,
                    color: rgb(0, 0, 0),
                    fontFamily: "Lexend",
                    fontWeight: 400,
                    letterSpacing: -0.54,
                    textAlign: "left",
                    opacity: 1,
                  });

                  // Draw the first line of text aligned with the wrapped lines
                  page.drawText(line, {
                    x: 445, // Align the text after the bullet
                    y: facetTextY,
                    size: 18,
                    color: rgb(0, 0, 0),
                    fontFamily: "Lexend",
                    fontWeight: 400,
                    letterSpacing: -0.54,
                    textAlign: "left",
                    opacity: 1,
                  });
                } else {
                  // Draw wrapped lines
                  page.drawText(line, {
                    x: 445, // Ensure wrapped lines align with the first line
                    y: facetTextY - lineIndex * 22,
                    size: 18,
                    color: rgb(0, 0, 0),
                    fontFamily: "Lexend",
                    fontWeight: 400,
                    letterSpacing: -0.54,
                    textAlign: "left",
                    opacity: 1,
                  });
                }
              });

              // Draw the horizontal line with the specified properties if not the last iteration
              if (facetIndex < domainData[index].facets.length - 1) {
                page.drawRectangle({
                  x: 340,
                  // Adjusted for top position
                  width: 1160,
                  height: 7,
                  color: rgb(1, 1, 1), // White color
                  opacity: 1,
                });

                // Update the y position for the next facet, adding 50 units after the line
                facetY = facetTextY - facetTextLines.length * 22 - 20 - 50;
              } else {
                // Update the y position for the next facet without adding the line
                facetY -= gapBetweenFacets;
              }
            });
            // Draw a vertical line with the specified properties
            page.drawRectangle({
              x: 27,
              y: page.getHeight() - 891,
              width: 8,
              height: 44,
              color: rgb(255 / 255, 94 / 255, 0 / 255),
              opacity: 1,
            });

            // Draw the "Key Scoring Criteria" title
            page.drawText("Key Scoring Criteria:", {
              x: 55,
              y: page.getHeight() - 878,
              size: 20,
              fontFamily: "Lexend",
              color: rgb(1, 1, 1), // Changed to #A0A0A0
              width: 225,
              height: 24,
              lineHeight: 24,
              fontWeight: 700,
              textAlign: "left",
              opacity: 1,
              letterSpacing: -0.6,
            });
            const domainSections = [
              {
                title: "Openness",
                contentText:
                  "Measures creativity, curiosity, and openness to new experiences.",
                high: "High: Imaginative, open-minded, enjoys novelty.",
                low: "Low: Prefers routine, practical, less open to change.",
              },
              {
                title: "Conscientiousness",
                contentText:
                  "Measures organization, reliability, and work ethic.",
                high: "High: Disciplined, detail-oriented, goal-focused.",
                low: "Low: Spontaneous, flexible, less detail-oriented.",
              },
              {
                title: "Extraversion",
                contentText: "Measures outgoingness, energy, and sociability.",
                high: "High: Sociable, talkative, energetic.",
                low: "Low: Reserved, introverted, prefers solitude.",
              },
              {
                title: "Agreeableness",
                contentText:
                  "Measures empathy, cooperativeness, and social harmony.",
                high: "High: Kind, empathetic, eager to avoid conflict.",
                low: "Low: Competitive, critical, skeptical.",
              },
              {
                title: "Neuroticism",
                contentText:
                  "Measures emotional stability and susceptibility to stress.",
                high: "High: Prone to anxiety, mood swings, negative emotions.",
                low: "Low: Calm, resilient, emotionally stable.",
              },
            ];
            // Find the matching content from the domainSections array
            function renderDomainContent(page, domainSec, helveticaFont) {
              const matchedDomain = domainSections.find(
                (domain) => domain.title === domainSec.title
              );

              if (matchedDomain) {
                const { contentText, high, low } = matchedDomain;

                // Draw the content text
                const contentFontSize = 20;
                const contentMaxWidth = 225;
                const contentLines = wrapText(
                  contentText,
                  contentMaxWidth,
                  contentFontSize,
                  helveticaFont
                );

                let contentCurrentY = page.getHeight() - 935;
                contentLines.forEach((line, index) => {
                  page.drawText(line, {
                    x: 55,
                    y: contentCurrentY - index * contentFontSize * 1.2,
                    size: contentFontSize,
                    color: rgb(1, 1, 1), // White color
                    fontFamily: "Lexend",
                    fontWeight: 700,
                    lineHeight: 24,
                    letterSpacing: -0.6,
                    textAlign: "left",
                    opacity: 1,
                  });
                });

                // Draw the high text
                const highFontSize = 20;
                const highMaxWidth = 200; // Adjusted width to account for indentation
                const highBulletX = 55; // X position for the bullet
                const highTextX = 75; // Indentation for text after the bullet
                const highLines = wrapText(
                  high,
                  highMaxWidth,
                  highFontSize,
                  helveticaFont
                );

                let highCurrentY =
                  contentCurrentY -
                  contentLines.length * contentFontSize * 1.2 -
                  30;
                page.drawText("•", {
                  // Draw the bullet separately
                  x: highBulletX,
                  y: highCurrentY,
                  size: highFontSize,
                  color: rgb(1, 1, 1), // White color
                  fontfamily: "Lexend",
                });

                highLines.forEach((line, index) => {
                  page.drawText(line, {
                    x: highTextX, // Indentation for all lines
                    y: highCurrentY - index * highFontSize * 1.2,
                    size: highFontSize,
                    color: rgb(1, 1, 1), // White color
                    fontfamily: "Lexend",
                    letterSpacing: -0.6,
                    lineHeight: 24,
                    textAlign: "left",
                    opacity: 1,
                  });
                });

                // Draw the low text with bullet
                const lowFontSize = 20;
                const lowMaxWidth = 200;
                const lowBulletX = 55; // X position for the bullet
                const lowTextX = 75; // Indentation for text after the bullet
                const lowLines = wrapText(
                  low,
                  lowMaxWidth,
                  lowFontSize,
                  helveticaFont
                );

                let lowCurrentY =
                  highCurrentY - highLines.length * highFontSize * 1.2 - 30;
                page.drawText("•", {
                  // Draw the bullet separately
                  x: lowBulletX,
                  y: lowCurrentY,
                  size: lowFontSize,
                  color: rgb(1, 1, 1), // White color
                  fontfamily: "Lexend",
                  letterSpacing: -0.6,
                  lineHeight: 24,
                });

                lowLines.forEach((line, index) => {
                  page.drawText(line, {
                    x: lowTextX, // Indentation for all lines
                    y: lowCurrentY - index * lowFontSize * 1.2,
                    size: lowFontSize,
                    color: rgb(1, 1, 1), // White color
                    fontfamily: "Lexend",
                    letterSpacing: -0.6,
                    lineHeight: 24,
                    textAlign: "left",
                    opacity: 1,
                  });
                });
              } else {
                console.error(
                  `No matching domain found for title: ${domainSec.title}`
                );
              }
            }
            renderDomainContent(page, domainSec, helveticaFont);
            // Draw the horizontal line with the specified properties
            page.drawRectangle({
              x: 340,
              y: page.getHeight() - 1863, // Adjusted for top position
              width: 1200,
              height: 0,
              color: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
              borderWidth: 2,
              borderColor: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
              opacity: 1,
              rotate: degrees(90),
            });
            // Draw the verticle line with the specified properties
            page.drawRectangle({
              x: -38,
              y: page.getHeight() - 1863, // Adjusted for top position
              width: 1606,
              height: 0,
              color: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
              borderWidth: 2,
              borderColor: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
              opacity: 1,
            });
            // Draw the text "Learn more about L.A.R.A at www.altercall.com/aai"
            page.drawText("Learn more about at", {
              x: 630,
              y: page.getHeight() - 1933,
              size: 20,
              color: rgb(0, 0, 0),
              fontFamily: "lexend",
              width: 894,
              height: 41,
              lineHeight: 24,
              fontWeight: 400,
              textAlign: "left",
              opacity: 1,
              letterSpacing: -0.03,
            });

            // Draw the clickable link "www.altercall.com/aai"
            page.drawText("www.altercall.com/aai", {
              x: 825,
              y: page.getHeight() - 1933,
              size: 20,
              color: rgb(0, 118 / 255, 186 / 255), // #0076BA color
              fontFamily: "lexend",
              width: 894,
              height: 41,
              lineHeight: 24,
              fontWeight: 400,
              textAlign: "left",
              opacity: 1,
              letterSpacing: -0.03,
              underline: true,
            });

            page.drawImage(vectorFooterBlackImage, {
              x: 54,
              y: page.getHeight() - 1933,
              width: 199,
              height: 38,
              opacity: 1,
            });

            // Draw the page number
            page.drawText(domainSec.page, {
              x: 1400,
              y: 50,
              size: 18,
              color: rgb(1, 94 / 255, 0), // Orange color (#FF5E00)
              fontFamily: "Lexend",
            });
          }

          //new page
          const page4 = pdfDoc.addPage([1500, 1974]); // Custom size
          page4.drawRectangle({
            x: 0,
            y: 0,
            width: page4.getWidth(),
            height: page4.getHeight(),
            color: rgb(1, 1, 1),
            opacity: 1,
          });

          // Draw the gradient overlay
          const gradientPng = await svgToPng(gradientSvg);
          const gradientImage = await pdfDoc.embedPng(gradientPng);

          page4.drawImage(gradientImage, {
            x: 0,
            y: page4.getHeight() - 1863, // Adjusted for top position
            width: page4.getWidth(),
            height: 1863,
            opacity: 1,
          });

          const logoImagePng = await svgToPng(logoSvg);
          const logoImageEmbedded = await pdfDoc.embedPng(logoImagePng);

          page4.drawImage(logoImageEmbedded, {
            x: 612,
            y: page4.getHeight() - 112 - 156,
            width: 275.58,
            height: 156,
            opacity: 1,
          });
          // Draw the first text block

          const text11 = "AlterCall AI is a";
          const text22 = "revolutionary";
          const ltext3 = "Leadership Platform";
          const text4 = "we measure the";
          const text5 = "previously";
          const text6 = "unmeasurable.";

          const pageWidth = page4.getWidth();
          const pageHeight = page4.getHeight();

          // Text Configuration
          const lfontSize = 78;
          const lineHeight = 88;
          const left = 136;
          const top = pageHeight - 987;
          const colorWhite = rgb(1, 1, 1); // White color
          const colorOrange = rgb(1, 94 / 255, 0); // Orange color

          // Draw Text 1
          page4.drawText(text11, {
            x: left,
            y: top,
            size: lfontSize,
            fontFamily: "poppins",
            color: colorWhite,
            lineHeight: lineHeight,
            textAlign: "left",
          });

          // Draw Text 2
          page4.drawText(text22, {
            x: left,
            y: top - lineHeight,
            size: lfontSize,
            fontFamily: "poppins",
            color: colorWhite,
            lineHeight: lineHeight,
            textAlign: "left",
          });

          // Draw Text 3
          page4.drawText(ltext3, {
            x: left,
            y: top - 2 * lineHeight,
            size: lfontSize,
            fontFamily: "poppins",
            color: colorWhite,
            lineHeight: lineHeight,
            textAlign: "left",
          });

          // Draw Text 4
          page4.drawText(text4, {
            x: left,
            y: top - 3 * lineHeight,
            size: lfontSize,
            fontFamily: "poppins",
            color: colorOrange,
            lineHeight: lineHeight,
            textAlign: "left",
          });

          // Draw Text 5
          page4.drawText(text5, {
            x: left,
            y: top - 4 * lineHeight,
            size: lfontSize,
            fontFamily: "poppins",
            color: colorOrange,
            lineHeight: lineHeight,
            textAlign: "left",
          });

          // Draw Text 6
          page4.drawText(text6, {
            x: left,
            y: top - 5 * lineHeight,
            size: lfontSize,
            fontFamily: "poppins",
            color: colorOrange,
            lineHeight: lineHeight,
            textAlign: "left",
          });
          // Draw the new text block
          const newText1 =
            "We are pioneering the coaching industry with a specialized";
          const newText2 =
            "AI platform that doesn't just speculate about your potential;";
          const newText3 = "it knows it,";
          const newText4 = "and more importantly, it's here to unlock it.";

          const newFontSize = 24;
          const newLineHeight = 35;
          const newLeft = 136;
          const newTop = pageHeight - 1544;
          const newColor = rgb(1, 1, 1); // White color

          // Draw new Text 1
          page4.drawText(newText1, {
            x: newLeft,
            y: newTop,
            size: newFontSize,
            fontFamily: "Lexend",
            color: newColor,
            lineHeight: newLineHeight,
            textAlign: "left",
            fontWeight: 300,
          });

          // Draw new Text 2
          page4.drawText(newText2, {
            x: newLeft,
            y: newTop - newLineHeight,
            size: newFontSize,
            fontFamily: "Lexend",
            color: newColor,
            lineHeight: newLineHeight,
            textAlign: "left",
            fontWeight: 300,
          });

          // Draw new Text 3
          // Draw new Text 3
          page4.drawText(newText3, {
            x: newLeft,
            y: newTop - 2 * newLineHeight,
            size: newFontSize,
            fontFamily: "Lexend",
            color: newColor,
            lineHeight: newLineHeight,
            textAlign: "left",
            fontWeight: 700,
          });

          // Draw new Text 4
          page4.drawText(newText4, {
            x: newLeft + 115,
            y: newTop - 2 * newLineHeight,
            size: newFontSize,
            fontFamily: "Lexend",
            color: newColor,
            lineHeight: newLineHeight,
            textAlign: "left",
            fontWeight: 1400,
          });

          // Draw a horizontal line with the specified properties
          page4.drawRectangle({
            x: -38,
            y: page4.getHeight() - 1863,
            width: 1606,
            height: 0,
            borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
            borderWidth: 2,
            opacity: 1,
            rotate: degrees(0),
          });
          page4.drawText("Learn more about at", {
            x: 630,
            y: page4.getHeight() - 1933,
            size: 20,
            color: rgb(0, 0, 0),
            fontFamily: "Lexend",
            width: 894,
            height: 41,
            lineHeight: 24,
            fontWeight: 400,
            textAlign: "left",
            opacity: 1,
            letterSpacing: -0.03,
          });

          // Draw the clickable link "www.altercall.com/aai"
          page4.drawText("www.altercall.com/aai", {
            x: 825,
            y: page4.getHeight() - 1933,
            size: 20,
            fontfamily: "Lexend",
            color: rgb(0, 118 / 255, 186 / 255), // #0076BA color
            width: 894,
            height: 41,
            lineHeight: 24,
            fontWeight: 400,
            textAlign: "left",
            opacity: 1,
            letterSpacing: -0.03,
            underline: true,
          });

          page4.drawText("08", {
            x: 1437,
            y: page4.getHeight() - 1933,
            size: 18,
            color: rgb(1, 94 / 255, 0), // Orange color (#FF5E00)
            width: 18,
            height: 19,
            opacity: 1,
          });

          page4.drawImage(vectorFooterBlackImage, {
            x: 54,
            y: page4.getHeight() - 1933,
            width: 199,
            height: 38,
            opacity: 1,
          });

          // Serialize the PDF to Uint8Array and start the download
          const pdfBytes = await pdfDoc.save();
          const compressedPdfBytes = await pdfDoc.save({
            useObjectStreams: false,
          });
          return compressedPdfBytes;
          // return pdfBytes; // Return the in-memory PDF as a Uint8Array
          //const blob = new Blob([pdfBytes], { type: "application/pdf" });
          //saveAs(blob, `${name} Assessment.pdf`);
        } catch (error) {
          console.error("Error generating PDF report: ", error);
        }
      };
      const pdfBytes = await downloadPDF();
      return pdfBytes;
    } catch (error) {
      console.error("Error generating PDF from function:", error);
    }
  }

  const handleEmailSubmission = async () => {
    setLoading(true);
    try {
      console.log("Starting PDF generation in the browser...");
      console.log("Polling the DB for final analysis...");
      console.log("Assessment ID:", assessment?.id);
      console.log("Assessment data:", assessment);

      const finalAssessmentData = await pollAnalysisResult(
        assessment.id,
        15,
        5000
      );
      console.log("Final analysis data received:", finalAssessmentData);

      console.log("Waiting 7 seconds before generating PDF...");
      await new Promise((resolve) => setTimeout(resolve, 7000));

      console.log("Generating PDF report...");
      const pdfBytes = await generateFullAssessmentPdf(finalAssessmentData);
      const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
      console.log("PDF generation complete for blob, size =", pdfBlob.size);
      console.log("PDF generation complete, size =", pdfBytes.length);

      const uniqueFilename = `pdfReports/${email.toLocaleLowerCase()}-AssessmentReport.pdf`;

      console.log("Requesting pre-signed URL for S3 upload...");
      const presignedUrlResponse = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/getPresignedUrl`,
        {
          filename: uniqueFilename,
        }
      );

      const { presignedUrl } = presignedUrlResponse.data;
      console.log("Received pre-signed URL:", presignedUrl);

      console.log("pdfBlob", pdfBlob);
      console.log("Uploading PDF to S3...");
      try {
        await axios.put(presignedUrl, pdfBlob, {
          headers: {
            "Content-Type": "application/pdf",
          },
        });
        console.log("PDF uploaded to S3 successfully.");
      } catch (uploadError) {
        console.error("Error uploading PDF to S3:", uploadError);
        throw uploadError;
      }

      const pdfUrl = `https://ocean-assessment-pdf-prod.s3.us-east-1.amazonaws.com/${uniqueFilename}`;
      console.log("Constructed PDF URL:", pdfUrl);
      setIsCompleted(true);
      setIsEmailStep(false); // Move to the "Thank You" step
      setEmail("");
      setLoading(false);
    } catch (error) {
      console.error("Error generating PDF report:", error);
      setLoading(false);
    }
  };

  const getChoiceText = (text) => {
    switch (text) {
      case "Very Inaccurate":
        return "Disagree";
      case "Moderately Inaccurate":
        return "Slightly Disagree";
      case "Neither Accurate Nor Inaccurate":
        return "Neutral";
      case "Moderately Accurate":
        return "Slightly Agree";
      case "Very Accurate":
        return "Agree";
      default:
        return text;
    }
  };

  const currentQuestion = questions[currentQuestionIndex];
  const currentChoices = TheBigFiveChoices[currentQuestion.keyed];

  const handleClick = async () => {
    await handleEmailSubmission();
    const targetUrl = `https://go.altercall.co/assessment-co?email=${encodeURIComponent(
      email
    )}`;
    window.top.location.href = targetUrl;
  };

  useEffect(() => {
    setSelectedChoice(null);
  }, [currentQuestionIndex]);
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        background: "linear-gradient(315deg, #000428, #004e92)",
      }}
    >
      <Box
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "24px",
          position: "relative",
          width: { xs: "95%", sm: "95%", md: "95%", lg: "95%" },
          height: "98%",
          margin: "0 auto",
          top: "50%",
          transform: "translateY(-50%)",
          // background: "linear-gradient(45deg, #ACCAFF 0%, #ffffff 0%)",
          background: "linear-gradient(45deg, rgba(172, 202, 255, 0.9) 0%, #ffffff 70%)",
          border: "2px solid #8C8C8C",
          borderRadius: "23px",
          color: "black",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: 4,
            py: 1,
            width: "100%",
            position: "sticky",
            top: 0,
            zIndex: 2,
            backgroundColor: "transparent",
          }}
        >
          {/* Left-aligned text */}
          <Typography
            sx={{
              fontFamily: "Lexend",
              fontSize: { xs: "10px", md: "24px" },
              fontWeight: "400",
              lineHeight: "27px",
              textAlign: "left",
              color: "#000",
            }}
          >
            O.C.E.A.N. Personality Assessment
          </Typography>

          {/* Right-aligned content */}
          {!isCompleted ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  fontSize: { xs: "10px", md: "24px" },
                  fontWeight: "400",
                  lineHeight: "33px",
                  textAlign: "right",
                  color: "#000",
                  mr: 1,
                }}
              >
                Question:
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: "8px 16px",
                  borderRadius: "14px",
                  backgroundColor: "transparent",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontSize: { xs: "10px", md: "24px" },
                    fontWeight: "400",
                    lineHeight: "33px",
                    textAlign: "center",
                    color: "#000",
                  }}
                >
                  {`${
                    currentQuestionIndex + 1 < 10
                      ? "0" + (currentQuestionIndex + 1)
                      : currentQuestionIndex + 1
                  }`}
                  {"/"}
                  {`${
                    questions.length < 10
                      ? "0" + questions.length
                      : questions.length
                  }`}{" "}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: {
                  xs: "6px",
                  sm: "10px",
                  md: "10px",
                  lg: "10px",
                  xl: "10px",
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  fontSize: { xs: "10px", md: "24px" },
                  fontWeight: "400",
                  lineHeight: "33px",
                  color: "#568FF3",
                }}
              >
                Complete
              </Typography>
              <Box sx={{ position: "relative" }}>
                <CompleteOcean />
              </Box>
            </Box>
          )}
        </Box>

        {/* Horizontal Line: only visible on desktop (lg and xl) */}
        <Box
          sx={{
            display: { xs: "none", md: "block" },
            position: "absolute",
            top: "15%",
            width: "100%",
            height: "0px",
            border: "2px solid #8F8F8F",
            zIndex: 2,
            backgroundColor: "transparent",
          }}
        />
        <Box
          sx={{
            flex: 1,
            width: "100%",
            overflowX: "hidden",
            overflowY: isEmailStep ? "auto" : { xs: "auto", md: "auto" },
            position: "relative",
            pt: { xs: "0px", sm: "24px", md: "24px", lg: "24px" },
            top: { xs: "6%", sm: "6%", md: "6%", lg: "5%" },
            pb: "100px",
            "&::-webkit-scrollbar": { width: "8px" },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#8F8F8F",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": { backgroundColor: "#8F8F8F" },
            "&::-webkit-scrollbar-track": { backgroundColor: "transparent" },
            top: "8%",
          }}
        >
          <Box
            sx={{
              flex: 1,
              p: 2,
              position: "relative",
              maxWidth: "100%",
              margin: "0 auto",
            }}
          >
            {!isCompleted ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    gap: { xs: "1px", sm: "50px", md: "50px", lg: "60px" },
                    position: "relative",
                    mt: { xs: 0, md: 4 },
                  }}
                >
                  <Typography
                    sx={{
                      marginBottom: "24px",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontSize: { xs: "14px", md: "40px" },
                      fontWeight: "400",
                      lineHeight: { xs: "27px", md: "60px" },
                      color: "#000",
                      maxWidth: "90%",
                      marginTop: { xs: "0%", md: "1%", sm: "1%", lg: "1%" },
                      padding: "0 5px",
                      display: "block",
                      opacity: 0, // Initially, set opacity to 0
                      animation: "fadeIn 1.5s forwards", // Apply the fadeIn animation
                      "@keyframes fadeIn": {  // Defining the keyframes directly in the sx prop
                        "0%": {
                          opacity: 0,
                        },
                        "100%": {
                          opacity: 1,
                        },
                      },
                    }}
                    key={currentQuestion.text}                                          
                  >
                    {currentQuestion.text}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: { xs: "4px", sm: "40px", md: "40px", lg: "60px" },
                      flexWrap: "wrap",
                    }}
                  >
                    {currentChoices.map((choice, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: { xs: "0px", md: "20px" },
                        }}
                      >
                        <Button
                          disableRipple
                          disableFocusRipple
                          tabIndex={-1}
                          onTouchStart={(e) => e.preventDefault()}
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => {
                            handleResponse(choice.score);
                            // Reset any selection state so that no choice remains selected on next question.
                            setSelectedChoice(null);
                          }}
                          sx={{
                            background:
                              "linear-gradient(215.94deg, rgba(255, 255, 255, 0.02) 22.24%, rgba(215, 240, 255, 0.7) 360.77%)",
                            border: {
                              xs: "3px solid #568FF3",
                              sm: "3px solid #568FF3", // tablets/ipads: thinner border
                              md: "5.182px solid #568FF3",
                              lg: "7.182px solid #568FF3",
                            },
                            borderRadius: "50%",
                            width: {
                              xs: "70px",
                              sm: "70px",
                              md: "90px",
                              lg: "131.67px",
                            },
                            height: {
                              xs: "70px",
                              sm: "70px",
                              md: "90px",
                              lg: "131.67px",
                            },
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            "@media (hover: hover)": {
                              "&:hover": {
                                backgroundColor: "#568FF3",
                                color: "black",
                              },
                              "&:active": {
                                backgroundColor: "#568FF3",
                                color: "black",
                              },
                            },
                            "&:focus": { outline: "none" },
                            "&:focus-visible": { outline: "none" },
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Poppins",
                              fontSize: {
                                xs: "11px",
                                sm: "25px",
                                md: "25px",
                                lg: "50px",
                              },
                              fontWeight: "400",
                              lineHeight: "75px",
                              color: "#000",
                              transform: "rotate(-0.05deg)",
                            }}
                          >
                            {index + 1}
                          </Typography>
                        </Button>
                        <Typography
                          sx={{
                            padding: "5px 0px",
                            fontFamily: "Poppins",
                            fontSize: { xs: "11px", md: "20px" },
                            fontWeight: "300",
                            lineHeight: "30px",
                            textAlign: "center",
                            color: "#000",
                          }}
                        >
                          {getChoiceText(choice.text)}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
                {/* Bottom Navigation Container */}
                <Box
                  sx={{
                    position: { xs: "relative", md: "fixed" },
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    zIndex: 2,
                    py: "15px",
                    px: 2,
                    mb: "14px",
                  }}
                >
                  <Box
                    sx={{
                      mt: { xs: "12px", md: "24px" },
                      display: { xs: "block", md: "flex" },
                      alignItems: "center",
                      justifyContent: "space-between",
                      position: "relative",
                      width: "100%",
                      gap: { xs: "4px", md: "0px" },
                    }}
                  >
                    {/* Back Button Container */}
                    {currentQuestionIndex > 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: { xs: "4px", md: "0px" },
                        }}
                      >
                        {/* <OceanBack /> */}
                        <Button
                          onClick={() =>
                            setCurrentQuestionIndex((prev) => prev - 1)
                          }
                          disabled={currentQuestionIndex === 0}
                          sx={{
                            backgroundColor: "transparent",
                            fontFamily: "Lexend",
                            fontSize: { xs: "11px", sm: "18px", md: "24px" },
                            fontWeight: "300",
                            lineHeight: { xs: "13px", sm: "33px" },
                            color: "black",
                            padding: { xs: "4px 30px", md: "4px 30px" },
                            ml: "5px",
                          }}
                        >
                          Back
                        </Button>
                      </Box>
                    )}

                    {/* Helper Text */}
                    <Box
                      sx={{
                        textAlign: "center",
                        fontFamily: "Lexend",
                        fontSize: { xs: "9px", sm: "14px" },
                        fontWeight: "400",
                        lineHeight: { xs: "12px", sm: "22.4px" },
                        color: "#000",
                        position: { xs: "relative", md: "absolute" },
                        left: { xs: "auto", md: "50%" },
                        transform: { xs: "none", md: "translateX(-50%)" },
                      }}
                    >
                      Your answers are recorded in real time. Please don’t leave
                      or refresh this page!
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  overflowY: "auto",
                  padding: "16px",
                  gap: "30px",
                  top: "15%",
                  flex: "none",
                  order: 1,
                  flexGrow: 0,
                  margin: "0 auto",
                  width: { xs: "250px", sm: "400px", md: "428px" },
                  color: "black",
                  pb: "20px",
                  "&::-webkit-scrollbar": { width: "8px" },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "transparent",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "transparent",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                {isEmailStep ? (
                  <>
                    <Box>
                      <AltercallLogoHorizontal />
                    </Box>
                    <Typography
                      sx={{
                        width: "100%",
                        height: "100%",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: { xs: "20px", lg: "30px" },
                        lineHeight: { xs: "45px", md: "60px" },
                        textAlign: "center",
                        color: "#000",
                        transform: "rotate(-0.05deg)",
                      }}
                    >
                      Your Results Are Ready
                    </Typography>
                    <Typography
                      sx={{
                        width: {xs:"100%",md:"428px"},
                        height: "30px",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "300",
                        fontSize: { xs: "12px", lg: "20px" },
                        lineHeight: { xs: "24px", md: "30px" },
                        textAlign: "center",
                        color: "#000",
                        transform: "rotate(-0.05deg)",
                      }}
                    >
                      Enter your email to receive your full report.
                    </Typography>

                      <EmailInputOcean
                        email={email}
                        setEmail={setEmail}
                      />

                    <Button
                      onClick={handleClick}
                      variant="contained"
                      sx={{
                        position: "relative",
                        width: "100%",
                        maxWidth: { xs: "250px", sm: "400px", md: "400px" },
                        height: "64.46px",
                        background:
                          "linear-gradient(218.09deg, rgba(255, 255, 255, 0) -20.13%, rgba(215, 240, 255, 0.55) 210.56%)",
                        border: "2px solid #568FF3",
                        borderRadius: "40.59px",
                        color: "#000",
                        "&:hover": {
                          backgroundColor: "#568FF3",
                        },
                      }}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={20} sx={{ color: "#000" }} />
                      ) : (
                        <>
                        <EmailIconOcean />
                        <Box sx={{ width: "10px" }} />
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: {
                              xs: "11px",
                              sm: "20px",
                              md: "20px",
                              lg: "20px",
                              xl: "20px",
                            },
                            lineHeight: {
                              xs: "14px",
                              sm: "18px",
                              md: "18px",
                              lg: "18px",
                              xl: "18px",
                            },
                            textAlign: "center",
                            textTransform: "capitalize",
                          }}
                        >
                          Get My Report
                        </Typography>
                      </>
                      )}
                    </Button>
                  </>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "0px",
                      gap: {
                        xs: "15px",
                        sm: "30px",
                        md: "30px",
                        lg: "30px",
                        xl: "30px",
                      },
                      flex: "none",
                      order: 1,
                      flexGrow: 0,
                    }}
                  >
                    {/* The following block is commented out */}
                    <Box>
                      <AltercallLogoHorizontal />
                    </Box>
                     
                    <Typography
                        sx={{
                          width: { xs: "100%", sm: "428px" },
                          height: { xs: "auto", sm: "60px" },
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: { xs: "11px", sm: "40px" },
                          lineHeight: { xs: "14px", sm: "60px" },
                          textAlign: "center",
                          color: "#000",
                          transform: "rotate(-0.05deg)",
                          flex: "none",
                          order: 0,
                          alignSelf: "stretch",
                          flexGrow: 0,
                        }}
                      >
                        Thank You!
                      </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OceanFunnel;
