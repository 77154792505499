import * as React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import ContentLibrarySearchIcon from 'components/Icons/ContentLibrarySearchIcon.svg';
import { InputAdornment } from '@mui/material';
import axios from 'axios'; // Import axios for making API calls
import { useState, useEffect } from 'react';

export default function ContentTitleSearch({ setSelectedTitle, setLoadingContentLibrary }) {
  const [suggestions, setSuggestions] = useState([]); // State to store autocomplete suggestions
  const [fetchedOptions, setFetchedSetOptions] = useState([]); // State to store autocomplete options

  const [loading, setLoading] = useState(false); // Optional loading state
  const [inputValueForDebouncing, setInputValueForDebouncing] = useState(''); // State to store input value for debouncing


  useEffect(() => {
    const hanlder = setTimeout(() => {
      if (inputValueForDebouncing.length > 2) {
        fetchAutoCompleteValues(inputValueForDebouncing);
      }
    }, 300)
    return () => {
      clearTimeout(hanlder);
    }
  }, [inputValueForDebouncing]); // Debounce the input value

  // Fetch options based on user input
  const handleInputChange = async (event, value) => {
    console.log('input value -------:', value);
    if (value.trim() === '') {
      setSelectedTitle({ suggestion: '', type: 'rawInput' });
    }

    setInputValueForDebouncing(value); // Update input value
  };

  const fetchAutoCompleteValues = async (value) => {
    if (!value) {
      setFetchedSetOptions([]);
      setSuggestions([]);
      return;
    }

    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/get-autocomplete-values`, {
        params: {
          input: value,
        },
      })

      const options = response.data.rows;
      const onlyKeywords = options.map((item) => item.keyword);

      const onlySuggestions = options.map((item) => item.suggestion);
      console.log('auto complete options ------:', options);


      setFetchedSetOptions(options);
      setSuggestions(onlySuggestions);
    } catch (error) {
      console.error("Error fetching autocomplete suggestions:", error);
      setFetchedSetOptions([]);
      setSuggestions([]);
    } finally {
      setLoading(false);
    }
  }

  // Handle selection change
  const handleOptionChange = (event, value) => {
    console.log('selected value for option change ------: 111111', value);
    const selectedOption = fetchedOptions.find((option) => option.suggestion === value);
    if (!selectedOption) {
      // console.log('RAW INFPUTTTT ------: 222222', value);
      setSelectedTitle({ suggestion: value, type: 'rawInput' });
      return;
    }
    setSelectedTitle(selectedOption); // Update the search query state with the selected value
  };

  // const handleEnterPress = (value) => {

  //   console.log('Enter key pressed with value:', value);
  //   // if (!value.trim()) {
  //   //   return;
  //   // }
  //   setSelectedTitle({ suggestion: value.trim(), type: 'rawInput' });
  // }

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Autocomplete
        freeSolo
        options={suggestions} // Pass the filtered options to autocomplete
        onInputChange={handleInputChange} // Fetch content from backend based on input change
        onChange={handleOptionChange} // Handle the selected option
        loading={loading} // Show loading indicator if necessary
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search" // Set placeholder text
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid #CBD0C8', // Set border color
                  borderRadius: 4, // Add border radius
                },
                '&:hover fieldset': {
                  border: '1px solid #CBD0C8', // Set border color on hover
                  borderRadius: 4, // Add border radius
                },
                '&.Mui-focused fieldset': {
                  border: '1px solid #CBD0C8', // Set border color when focused
                  borderRadius: 4, // Add border radius
                },
                borderRadius: 4, // Add border radius
              },
              '& .MuiInputBase-input': {
                color: 'black', // Set text color
              },
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <ContentLibrarySearchIcon color="#828282" /> {/* Use your icon here */}
                </InputAdornment>
              ),
            }}
          // onKeyDown={(e) => {
          //   // e.preventDefault();
          //   if (e.key === 'Enter') {
          //     handleEnterPress(e.target.value); // Handle Enter press when no autocomplete selection is made
          //   }
          // }}
          />
        )}
        PaperComponent={({ children }) => (
          <div style={{ backgroundColor: 'white', color: 'black' }}>
            {children}
          </div>
        )}
        ListboxProps={{
          sx: {
            marginTop: '5px',
            backgroundColor: 'white',
            boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
            color: 'black',
            // border: '1px solid #CBD0C8', // Add border to dropdown
            borderRadius: 4,
            '& .MuiAutocomplete-option': {
              '&:hover': {
                backgroundColor: '#f0f0f0',
                color: 'black',
              },
            },
          },
        }}
      />
    </Stack>
  );
}
