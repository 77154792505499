import React from 'react';

const SummaryWhiteIcon = () => (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="18" 
        height="18" 
        viewBox="0 0 18 18" 
        fill="none"
        style={{ width: '18px', height: '18px', flexShrink: 0 }}
    >
        <path d="M7.5 16.5L10.5 16.5M9 9L9 12M9.75 9L8.25 9M3.75 7.30263C3.75 4.51214 6.1005 2.25 9 2.25C11.8995 2.25 14.25 4.51214 14.25 7.30263C14.25 8.83225 13.5437 10.2031 12.4279 11.1297C11.9566 11.521 11.5514 12.0069 11.3974 12.5999L11.1875 13.4078C11.0587 13.9038 10.611 14.25 10.0986 14.25L7.90135 14.25C7.38897 14.25 6.94131 13.9038 6.81249 13.4078L6.60261 12.5999C6.44858 12.0069 6.04339 11.521 5.57208 11.1297C4.45627 10.2031 3.75 8.83225 3.75 7.30263Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export default SummaryWhiteIcon;