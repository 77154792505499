import React from 'react';

function TwoUsersIcon({ color = 'black', width = '24', height = '24' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path d="M17 3.535c1.196.692 2 1.984 2 3.465 0 1.48-.804 2.773-2 3.465m4 10.268A2 2 0 0 0 22 19c0-1.48-.804-2.773-2-3.465M14 7a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm-8 8h8a4 4 0 0 1 4 4 2 2 0 0 1-2 2H4a2 2 0 0 1-2-2 4 4 0 0 1 4-4Z" />
    </svg>
  );
}

export default TwoUsersIcon;
