import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Modal, TextField, Typography, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
//import { API } from "aws-amplify";
import NegativeActionButton from "components/NegativeActionButton";
import PositiveActionButton from "components/PositiveActionButton";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useGetCoachesForUser } from "hooks/useGetCoachesForUser";
import { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import axios from "axios";
import { useMutation, useQueryClient } from '@tanstack/react-query';

const ClientAddActionModal = ({
  user,
  userData,
  setOpenAddActionModal,
  openAddActionModal,
}) => {
  const [actionDate, setActionDate] = useState("");
  const { membersWithoutUser } = useGetCoachesForUser(user?.sub, userData, user);
  const queryClient = useQueryClient();

  const handleClose = () => {
    setOpenAddActionModal(false);
    formik3.resetForm();
    setActionDate("");
  };

  const CustomIcon = (props) => (
    <KeyboardArrowDown {...props} style={{ color: "black" }} />
  );

  // Mutation for adding action
  const addActionMutation = useMutation({
    mutationFn: async (newAction) => {
      const response = await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/add-action`,
        newAction
      );
      console.log("response: ", response)
      return response.data;
    },
    onMutate: async (newAction) => {
      await queryClient.cancelQueries(['actions', user?.sub]);
  
      const previousActions = queryClient.getQueryData(['actions', user?.sub]);
  
      queryClient.setQueryData(['actions', user?.sub], (old) => [
        ...(old || []),
        {
          ...newAction,
          action_id: Math.random().toString(36).substr(2, 9), // Temporary ID
          date: new Date().toISOString(),
          status: 'incomplete',
        },
      ]);
  
      return { previousActions };
    },
    onError: (error, newAction, context) => {
      queryClient.setQueryData(['actions', user?.sub], context.previousActions);
      toast.error("Failed to add action. Please try again.");
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['actions', user?.sub]);
      queryClient.invalidateQueries(['counts', user?.sub]);
      toast.success("Action added successfully!");
    },
    onSettled: () => {
      queryClient.invalidateQueries(['actions', user?.sub]);
      queryClient.invalidateQueries(['counts', user?.sub]);
    },
  });
  
  

  const formik3 = useFormik({
    initialValues: {
      action_title: "",
      action: "",
      coach_id: "",
      coach_name: "",
    },
    validationSchema: Yup.object().shape({
      action: Yup.string().required("Action is required"),
      coach_id: Yup.string().required("Coach selection is required"),
    }),
    onSubmit: async (values) => {
      const newAction = {
        created_for: user?.sub,
        title: values.action_title,
        action: values.action,
        due_date: actionDate || null,
        created_by: values.coach_id,
        client_name: `${user?.given_name} ${user?.family_name}`,
        coach_name: values.coach_name,
      };

      addActionMutation.mutate(newAction);
      handleClose();
    },
  });

  return (
    <Modal
      open={openAddActionModal}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "white",
          color: "black",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 1000,
          boxShadow: 24,
          padding: {
            xs: "24px",
            lg: "64px",
          },
          gap: {
            xs: "24px",
            lg: "unset",
          },
          borderRadius: "30px",
          display: "flex",
          flexDirection: {
            xs: "column",
            lg: "row",
          },
          justifyContent: "space-between",
          maxWidth: "calc(100% - 40px)",
          margin: "20px auto",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            width: {
              xs: "100%",
              lg: "50%",
            },
            display: "flex",
            flexDirection: "column",
            gap: {
              xs: "8px",
              lg: "16px",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Lexend",
              maxWidth: {
                md: "350px",
              },
              fontSize: "36px",
              color: "black",
            }}
          >
            Create a new action
          </Typography>
        </Box>
        <Box
          sx={{
            width: {
              xs: "100%",
              lg: "50%",
            },
            maxWidth: {
              xs: "100%",
              lg: "340px",
            },
          }}
        >
          <form onSubmit={formik3.handleSubmit}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "22px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontSize: "12px",
                    lineHeight: "1.5",
                    letterSpacing: "2px",
                    fontWeight: "bold",
                    color: "black",
                    textTransform: "capitalize",
                  }}
                >
                  Action
                </Typography>
                <TextField
                  required
                  fullWidth
                  multiline
                  rows={4}
                  error={
                    formik3.touched.action && Boolean(formik3.errors.action)
                  }
                  {...formik3.getFieldProps("action")}
                  InputProps={{
                    style: {
                      color: "black",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "black",
                    },
                  }}
                  sx={{
                    color: "#22272F",
                    background: "white",
                    borderRadius: "16px",
                    width: "100%",
                    border: "1px solid #C4CAD4",
                    "&:before, &:after": {
                      display: "none",
                    },
                    "& fieldset": {
                      border: "none !important",
                    },
                  }}
                  focused={formik3.touched.action}
                />
                {formik3.touched.action && formik3.errors.action && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#BE0808",
                      letterSpacing: "2px",
                      fontSize: "8px",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    {formik3.touched.action && formik3.errors.action}
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontSize: "12px",
                    lineHeight: "1.5",
                    letterSpacing: "2px",
                    fontWeight: "bold",
                    color: "black",
                    textTransform: "capitalize",
                  }}
                >
                  Due Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="YYYY-MM-DD"
                    onChange={(date) => {
                      if (date) {
                        const formattedDate = dayjs(date).utc().format("YYYY-MM-DD");
                        setActionDate(formattedDate);
                      }
                    }}
                    sx={{
                      borderRadius: "16px",
                      border: "1px solid #C4CAD4",
                      "& svg": {
                        color: "#22272F",
                      },
                      "& input": {
                        color: "#22272F",
                      },
                      "& fieldset": {
                        border: "none !important",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontSize: "12px",
                    lineHeight: "1.5",
                    letterSpacing: "2px",
                    fontWeight: "bold",
                    color: "black",
                    textTransform: "capitalize",
                  }}
                >
                  Coach
                </Typography>
                <Select
                  fullWidth
                  required
                  value={formik3.values.coach_id}
                  onChange={(event) => {
                    const selectedCoach = membersWithoutUser.find(coach => coach.user_id === event.target.value);
                    formik3.setFieldValue('coach_id', event.target.value);
                    formik3.setFieldValue('coach_name', selectedCoach?.full_name || "");
                  }}
                  IconComponent={CustomIcon}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        backgroundColor: 'white',
                      },
                    },
                  }}
                  sx={{
                    color: "black",
                    background: "white",
                    borderRadius: "16px",
                    border: "1px solid #C4CAD4",
                    "&:before, &:after": {
                      display: "none",
                    },
                    "& fieldset": {
                      border: "none !important",
                    },
                  }}
                >
                  {userData?.accessTo === 'platform' && membersWithoutUser && membersWithoutUser.map((coach) => (
                    <MenuItem key={coach.user_id} value={coach.user_id} sx={{background: "white", color: "black"}}>
                      {coach.full_name}
                    </MenuItem>
                  ))}
                  {userData?.accessTo === 'platform_professional' && (
                      <MenuItem key={user?.sub} value={user?.sub} sx={{background: "white", color: "black"}}>
                        Personal Goal
                    </MenuItem>
                  )}
                </Select>
                {formik3.touched.coach_id && formik3.errors.coach_id && 
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#BE0808",
                      letterSpacing: "2px",
                      fontSize: "8px",
                      fontWeight: "bold",
                      textTransform: "uppercase"       
                    }}
                  >
                    *{formik3.touched.coach_id && formik3.errors.coach_id}
                  </Typography>
                }
              </Box>
            </Box>
            <Box
              marginTop="42px"
              display="flex"
              justifyContent="flex-end"
              gap="15px"
            >
              <NegativeActionButton onClick={handleClose} label={"Cancel"} />
              <PositiveActionButton
                onClick={formik3.handleSubmit}
                label={"Create Action"}
                disabled={!actionDate || !formik3.values.action || !formik3.values.coach_id}
              />
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  )
}

export default ClientAddActionModal;
