export function getStoredUserId() {
    return localStorage.getItem("bigFiveUserId");
  }
  
  export function setStoredUserId(id) {
    localStorage.setItem("bigFiveUserId", id);
  }
  
  export function getStoredAssessmentId() {
    return localStorage.getItem("bigFiveAssessmentId");
  }
  
  export function setStoredAssessmentId(id) {
    localStorage.setItem("bigFiveAssessmentId", id);
  }
// // Helpers to get or set localStorage keys

// function getStoredUserId() {
//     return localStorage.getItem("bigFiveUserId");
//   }
  
//   function setStoredUserId(id) {
//     localStorage.setItem("bigFiveUserId", id);
//   }
  
//   function getStoredAssessmentId() {
//     return localStorage.getItem("bigFiveAssessmentId");
//   }
  
//   function setStoredAssessmentId(id) {
//     localStorage.setItem("bigFiveAssessmentId", id);
//   }
  