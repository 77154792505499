import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { fetchAvatarUrl } from "utils/fetchAvatarUrl"

const AssessmentsContext = createContext();

export const useAssessments = () => useContext(AssessmentsContext);

export const AssessmentsProvider = ({ user, userData, children }) => {
  const [assessmentsData, setAssessmentsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchAssessments = async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const userId = user?.userId;
      const companyId = userData?.companyInformation?.company_id;

      if (!userId || !companyId) {
        setAssessmentsData(null);
        return;
      }

      let response;
      let members = [];
      let teams = [];
      let assessments = [];

      if (userData?.accessTo === "platform") {
        const endpointMap = {
          owner: "/zoom/owner-fetch-company-information",
          super_admin: "/zoom/super-admin-fetch-company-members",
          admin: "/zoom/admin-fetch-company-members",
          user: "/zoom/user-fetch-company-members",
          private: "/zoom/private-user-fetch-assessments",
        };

        const roleMap = {
            owner: "OWNER",
            super_admin: "SUPERADMINS",
            admin: "ADMINS",
            user: "USERS",
            private: "PRIVATE",
          };

        const endpoint = endpointMap[userData?.companyInformation?.my_user_role?.role_name];
        const cognitoRole = roleMap[userData?.companyInformation?.my_user_role?.role_name]
        if (endpoint) {
          response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}${endpoint}`, {
            params: {
              userId,
              userGroup: cognitoRole,
              companyId,
              fetchAssessments: true,
            },
          });

          members = response.data.members || [];
          teams = response.data.teams || response.data.myTeams || [];
          assessments = response.data.assessments || [];
        }
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/executive-user-fetch-assessments`,
          {
            params: { userId, companyId },
          }
        );

        members = response.data.members || [];
        assessments = response.data.assessments || [];
      }

      if (teams.length > 0) {
        teams = teams.sort((a, b) => a.team_name.localeCompare(b.team_name));
      }

      const updateAvatarUrls = async (assessments) => {
        return Promise.all(
          assessments.map(async (assessment) => {
            if (assessment.user_avatar) {
              const avatarUrl = await fetchAvatarUrl(assessment.user_avatar);
              return { ...assessment, user_avatar: avatarUrl };
            }
            return assessment;
          })
        );
      };

      const updatedAssessments = await updateAvatarUrls(assessments);

      setAssessmentsData({
        members,
        teams,
        assessments: updatedAssessments,
      });
    } catch (error) {
      console.error("Error fetching assessments:", error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  // Automatically fetch assessments when `user` or `userData` changes
  useEffect(() => {
    if (user && userData?.companyInformation?.company_id) {
      fetchAssessments();
    }
  }, [user, userData]);

  return (
    <AssessmentsContext.Provider value={{ assessmentsData, isLoading, isError, refetch: fetchAssessments }}>
      {children}
    </AssessmentsContext.Provider>
  );
};
