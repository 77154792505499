import { useCallback, useState, useEffect } from 'react';
import axios from 'axios';

export function useGetCoachesForUser(user_id, userData, user) {
  const [coaches, setCoaches] = useState([]);
  const [membersWithoutUser, setMembersWithoutUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getCoaches = useCallback(async () => {
    setLoading(true);
    setError(null);
    
    try {
      const userId = user?.sub;
      let response;

      if (userData?.companyInformation) {
        switch (user?.role) {
          case "OWNER":
            response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/owner-fetch-company-information`, {
              params: { userId: userId },
            });
            break;
          case "SUPERADMINS":
            response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/super-admin-fetch-company-members`, {
              params: { userId: userId, userGroup: user?.role },
            });
            break;
          case "ADMINS":
            response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/admin-fetch-company-members`, {
              params: { userId: userId, userGroup: user?.role }
            });
            break;
          case "USERS":
            response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/user-fetch-company-members`, {
              params: { userId: userId, userGroup: user?.role },
            });
            break;
          case "PRIVATE":
            response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/private-fetch-company-members`, {
              params: { userId: userId, userGroup: user?.role, companyId: userData?.companyInformation?.company_id },
            });
            break;
          default:
            break;
        }

      } else {
        response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-coaches`, {
          params: { user_id }
        });
      }

      const members = response?.data?.members || response?.data?.coaches || [];

      // Set the coaches data
      setCoaches(members);

      // Filter out the member with user_id equal to user?.sub and set the membersWithoutUser state
      const filteredMembers = members.filter(member => member.user_id !== user?.sub);
      setMembersWithoutUser(filteredMembers);

    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [user_id, userData, user]);

  useEffect(() => {
    if (user_id) {
      getCoaches();
    }
  }, [user_id, getCoaches]);

  return { coaches, membersWithoutUser, loading, error };
}
