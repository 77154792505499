import { OrganizationChart as OrgChart } from 'primereact/organizationchart';
import React, { useRef, useEffect, useState, useCallback } from "react";
import avatar from "assets/avatar.png";
import { getUrl } from "aws-amplify/storage";
import { CircularProgress, Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ProfilePicture from "components/ProfilePicture";


const OrganizationChart = ({ user, userData, orgChartMembers, loadingCompanyData }) => {
    const [zoomLevel, setZoomLevel] = React.useState(0.8);
    const [avatarUrls, setAvatarUrls] = React.useState({});
    const [allChartsData, setAllChartsData] = useState([]);
    const navigate = useNavigate();

    const scrollContainerRef = useRef(null);
    const [isMounted, setIsMounted] = useState(false);


    useEffect(() => {
        if (scrollContainerRef.current && allChartsData.length > 0) {
            const { scrollWidth, clientWidth } = scrollContainerRef.current;
            scrollContainerRef.current.scrollLeft = (scrollWidth - clientWidth) / 2;
        }
    }, [allChartsData, orgChartMembers, isMounted]);

    useEffect(() => {
        // Set mounted state to true on component mount
        setIsMounted(true);

        // Optional cleanup to handle unmounts if needed
        return () => setIsMounted(false);
    }, []);

    const handleZoomIn = () => setZoomLevel(prev => Math.min(prev + 0.1, 2));
    const handleZoomOut = () => setZoomLevel(prev => Math.max(prev - 0.1, 0.5));

    const fetchAvatarUrl = async (avatarPath) => {
        if (!avatarPath) return null;

        try {

            const encodeFilePath = (path) => {
                const fileName = path.split("/").pop();
                return encodeURIComponent(fileName).replace(/%20/g, " ");
              };
            
              const encodedFileName = encodeFilePath(avatarPath);
            const filePath = `public/avatar/${encodedFileName}`;
            const { url } = await getUrl({
                path: filePath,
                expires: 900,
                validateObjectExistence: true,
            });
            return url ? url.href : null;
        } catch (error) {
            console.error("Error fetching avatar URL:", error);
            return null;
        }
    };

    const prefetchAvatarUrls = async (members) => {
        // Helper function to fetch avatars recursively
        const fetchAvatarsRecursive = async (node) => {
            const avatars = [];
    
            if (node.avatar && !avatarUrls[node.user_id]) {
                const url = await fetchAvatarUrl(node.avatar);
                avatars.push({ userId: node.user_id, url });
            }
    
            if (Array.isArray(node.children)) {
                for (const child of node.children) {
                    avatars.push(...(await fetchAvatarsRecursive(child)));
                }
            }
    
            return avatars;
        };
    
        // Collect avatars from all nodes
        const avatarPromises = members.map(fetchAvatarsRecursive);
        const avatarResults = await Promise.all(avatarPromises);
    
        // Flatten results and merge into state
        const newAvatarUrls = avatarResults.flat().reduce((acc, item) => {
            if (item) acc[item.userId] = item.url;
            return acc;
        }, {});
    
        return newAvatarUrls; // Return the new avatar URLs
    };
     

    const transformOrgChartData = async (members, avatarUrls, level = 0) => {
        const transformNode = async (node, level) => ({
            expanded: true,
            type: 'person',
            className: `text-white`,
            style: {
                borderRadius: '15pt',
                minHeight: '180px',
                border: '3px solid #D9E4EC',
            },
            data: {
                user_id: node?.user_id || '',
                image: avatarUrls[node.user_id] || avatar, // Use the passed avatarUrls
                name: node?.full_name || "Unknown",
                title: node?.company_role_name || node?.role || "No Title",
                teams: node?.teams,
            },
            children: Array.isArray(node?.children)
                ? await Promise.all(node.children.map((child) => transformNode(child, level + 1)))
                : [],
        });
    
        return Promise.all(members.map((member) => transformNode(member, level)));
    };
    
    

    useEffect(() => {
        const fetchData = async () => {
            if (!orgChartMembers) return;
    
            // Step 1: Fetch avatar URLs for all nodes in the org chart
            const fetchedAvatarUrls = await prefetchAvatarUrls(orgChartMembers);
    
            // Step 2: Update the avatarUrls state
            setAvatarUrls((prev) => ({ ...prev, ...fetchedAvatarUrls }));
    
            // Step 3: Transform org chart data using the updated avatar URLs
            const updatedAvatarUrls = { ...avatarUrls, ...fetchedAvatarUrls };
            const allData = await Promise.all(
                orgChartMembers.map((rootNode) => transformOrgChartData([rootNode], updatedAvatarUrls))
            );
    
            // Step 4: Update the chart data state
            setAllChartsData(allData);
        };
    
        fetchData();
    }, [orgChartMembers]);
    
    
    
    const nodeTemplate = useCallback(
        (node) => {
            const navigateToNode = () => {
                if (node?.data?.user_id !== user?.sub) {
                    navigate(`/platform/coach/coachee-overview/${node?.data?.user_id}`);
                }
            };
    
            const isCurrentUser = node?.data?.user_id === user?.sub;
    
            return (
                <div
                    onClick={navigateToNode}
                    style={{
                        cursor: isCurrentUser ? "default" : "pointer",
                        transition: "transform 0.2s ease",
                        borderRadius: "15pt",
                    }}
                    className={`flex flex-col items-center b-3 px-2 py-6 min-w-48 min-h-52 ${
                        isCurrentUser ? "" : "hover:shadow-lg hover:scale-105"
                    }`}
                >
                    <ProfilePicture
                        src={node.data.image}
                        size={56} // Adjust size as needed
                        addBorder={false}
                        fallback={avatar}
                    />
                    <span
                        style={{
                            color: "black",
                            fontSize: 21,
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            wordWrap: "break-word",
                        }}
                    >
                        {node.data.name}
                    </span>
                    <span
                        style={{
                            color: "#A3A3A3",
                            fontSize: 14,
                            fontFamily: "Lexend",
                            fontWeight: 200,
                            wordWrap: "break-word",
                        }}
                    >
                        {node.data.title}
                    </span>
                    {Array.isArray(node.data.teams) && node.data.teams.length > 0 && (
                        <div className="text-sm text-gray-200">
                            {node.data.teams.map((team, index) => (
                                <div key={index} className="flex flex-col items-center">
                                    <span
                                        style={{
                                            color: "#A3A3A3",
                                            fontSize: 14,
                                            fontFamily: "Lexend",
                                            wordWrap: "break-word",
                                            textTransform: "capitalize",
                                        }}
                                    >
                                        {team.team_name}
                                    </span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            );
        },
        [user, navigate] // Dependencies
    );
    

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflow: "hidden",
            }}
        >
            {/* Zoom controls */}
            <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        mb: 2,
                        border: "1px solid black",
                        borderRadius: "4px",
                    }}
                >
                    <Button
                        sx={{
                            color: "black",
                            fontSize: "16px",
                            padding: "4px",
                            minWidth: "40px",
                        }}
                        onClick={handleZoomIn}
                    >
                        +
                    </Button>
                    <Button
                        sx={{
                            color: "black",
                            fontSize: "16px",
                            padding: "4px",
                            minWidth: "40px",
                        }}
                        onClick={handleZoomOut}
                    >
                        -
                    </Button>
                </Box>
            </Box>
    
            {/* Scrollable container for multiple charts */}
            <Box
                ref={scrollContainerRef}
                sx={{
                    width: "100%",
                    height: "100%",
                    overflowX: "auto", // Allow horizontal scrolling
                    overflowY: "auto", // Allow vertical scrolling
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingBottom: "8px",
                    paddingRight: "8px",
                    "&::-webkit-scrollbar": {
                        width: "8px",
                        height: "8px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "rgb(207, 207, 207)",
                        borderRadius: "4px",
                      },
                      "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "rgb(207, 207, 207)",
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "transparent",
                      }
                    //   scrollbarWidth: "none", // Firefox
                    //   "&::-webkit-scrollbar": {
                    //       // Chrome, Safari, and Edge
                    //       display: "none",
                    //   },
                }}
            >
                {/* Show loading spinner if data is not loaded */}
                {loadingCompanyData || !orgChartMembers ? (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        width="100%"
                    >
                        <CircularProgress />
                        <Typography variant="body1" sx={{ marginTop: 2 }}>
                            Loading Organization Chart...
                        </Typography>
                    </Box>
                ) : (
                    allChartsData.map((chartData, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    transform: `scale(${zoomLevel})`, // Apply scaling only to the chart content
                                    transformOrigin: "top center",
                                    transition: "transform 0.2s ease-in-out", // Smooth zoom transitions
                                }}
                            >
                                <OrgChart
                                    value={chartData} // Pass the root node's data
                                    nodeTemplate={nodeTemplate}
                                    pt={{
                                        root: { style: {} },
                                        table: { style: { width: "auto" } },
                                        nodeToggler: {
                                            className: "flex justify-center bg-white rounded-full",
                                        },
                                        lineDown: { style: { background: "#D9E4EC" } },
                                    }}
                                />
                            </Box>
                        </Box>
                    ))
                )}
            </Box>
        </Box>
    );
    
    
};

export default OrganizationChart;