import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCallsByCoachID = createAsyncThunk(
  "coach/getCallsByCoachID",
  async ({ coachId }) => {
    const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-call-data-for-user`, {
      params: {
        coach_id: coachId,
      },
    });
    console.log('response data:', response.data)
    return response.data;
  }
);
 
const coachSlice = createSlice({
  name: "coach",
  initialState: { data: null, status: "idle", error: null, latest: false },
  reducers: {
    setCallsByCoachID: (state, action) => {
      state.data = action.payload;
    },
    setLatestCalls: (state, action) => {
      state.latest = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCallsByCoachID.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCallsByCoachID.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(getCallsByCoachID.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setCallsByCoachID, setLatestCalls } = coachSlice.actions;
export default coachSlice.reducer;
