import React from "react";

function NotificationCenter({ color }) {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.15854 15.7235C4.91232 15.6639 3.66752 15.5652 2.42604 15.4273C1.42166 15.3157 0.775685 14.3064 1.09526 13.3477C1.25729 12.8616 1.42046 12.3835 1.46337 11.8685L1.81837 7.60855C2.12965 3.87312 5.25229 0.999878 9.00067 0.999878C12.7491 0.999878 15.8717 3.87312 16.183 7.60855L16.5382 11.8709C16.5811 12.3855 16.7441 12.8636 16.9054 13.3495C17.2235 14.3077 16.5779 15.3155 15.5745 15.427C14.3333 15.565 13.0887 15.6638 11.8428 15.7234M6.15854 15.7235C8.05218 15.814 9.94912 15.8139 11.8428 15.7234M6.15854 15.7235L6.15857 16.1578C6.15857 17.7274 7.43102 18.9999 9.00067 18.9999C10.5703 18.9999 11.8428 17.7274 11.8428 16.1578L11.8428 15.7234"
        stroke="#111111"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default NotificationCenter;