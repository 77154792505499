import { Box, useTheme, useMediaQuery, Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { LiveAudioVisualizer } from 'react-audio-visualize';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VirtualCoachSendIcon from 'components/Icons/VirtualCoachSendIcon.svg';
import { toast } from 'react-toastify';

const SpeechToText = ({ setShowAudioRecordingModal, sendAudioTranscriptToBackendForAiResponse }) => {
    // Core states for managing recording
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioStream, setAudioStream] = useState(null);
    const [isRecording, setIsRecording] = useState(true);
    const [fullTranscript, setFullTranscript] = useState('');

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Mobile screens
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md')); // Tablet screens
    const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // Desktop and larger screens

    // Reference to maintain speech recognition instance
    const recognitionRef = useRef(null);

    // Initialize speech recognition and audio recording
    useEffect(() => {
        // Set up speech recognition
        const setupSpeechRecognition = () => {
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            if (!SpeechRecognition) {
                toast.error('Browser not supported. Please use Chrome or Safari');
                console.error('Speech Recognition not supported');
                return;
            }

            const recognition = new SpeechRecognition();
            recognition.lang = 'en-US';
            recognition.continuous = true;
            recognition.interimResults = false;

            // Handle speech recognition results
            recognition.onresult = (event) => {
                const transcript = event.results[event.results.length - 1][0].transcript;
                setFullTranscript(prev => prev + ' ' + transcript);
            };

            recognition.onerror = (event) => {
                console.error('Speech Recognition Error:', event.error);
            };

            return recognition;
        };

        // Set up media recorder for audio visualization
        const setupMediaRecorder = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                setAudioStream(stream);
                const recorder = new MediaRecorder(stream);
                recorder.start();
                setMediaRecorder(recorder);
                return { stream, recorder };
            } catch (error) {
                toast.error('Browser not supported. Please use Chrome or Safari');
                console.error('Media Recorder Error:', error);
                throw error;
            }
        };

        // Initialize both speech recognition and media recorder
        const initialize = async () => {
            try {
                const recognition = setupSpeechRecognition();
                recognitionRef.current = recognition;
                await setupMediaRecorder();
                recognition.start();
            } catch (error) {
                console.error('Initialization error:', error);
                setShowAudioRecordingModal(false);
            }
        };

        initialize();

        // Cleanup function
        return () => {
            if (audioStream) {
                audioStream.getTracks().forEach(track => track.stop());
            }
            if (recognitionRef.current) {
                recognitionRef.current.stop();
            }
        };
    }, [setShowAudioRecordingModal]);

    // Handle pause/resume functionality
    const handlePauseResume = () => {
        setIsRecording(prev => !prev);
        if (recognitionRef.current) {
            if (isRecording) {
                recognitionRef.current.stop();  // Pause recording
            } else {
                recognitionRef.current.start(); // Resume recording
            }
        }
    };

    // Handle completion of recording
    const handleDone = async () => {
        if (recognitionRef.current) {
            recognitionRef.current.stop();
        }
        if (fullTranscript.trim()) {
            setShowAudioRecordingModal(false);
            await sendAudioTranscriptToBackendForAiResponse(fullTranscript.trim());
        }

    };

    // Handle cancellation of recording
    const handleCancel = () => {
        if (mediaRecorder) {
            mediaRecorder.stop();
        }
        if (recognitionRef.current) {
            recognitionRef.current.stop();
        }
        setShowAudioRecordingModal(false);
    };

    return (
        <Box sx={{
            px: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '10px',
            width: '100%',
        }}>
            {mediaRecorder && isRecording ? (
                <LiveAudioVisualizer
                    mediaRecorder={mediaRecorder}
                    width={isMobile ? 250 : isTablet ? 450 : 850}
                    height={25}
                    barWidth={2}
                    gap={1}
                    barColor="#FF5E00"
                />
            )
                :
                <Box></Box>
            }

            <Box sx={{
                display: 'flex',
                justifyContent: 'end',
                gap: '8px',
            }}>
                <Tooltip title={isRecording ? 'Pause' : 'Resume'}>
                    <Box
                        onClick={handlePauseResume}
                        sx={{
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',

                        }}
                    >
                        {isRecording ? (

                            <PauseIcon sx={{ color: '#FF5E00', fontSize: '30px' }} />

                        ) : (

                            <PlayArrowIcon sx={{ color: '#FF5E00', fontSize: '30px' }} />

                        )}
                    </Box>
                </Tooltip>
                {
                    !isRecording &&
                    <Tooltip title='Send'>
                        <Box
                            onClick={handleDone}
                            sx={{
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',

                            }}
                        >

                            <VirtualCoachSendIcon color='#FF5E00' />
                        </Box>
                    </Tooltip>
                }

                {
                    !isRecording &&
                    <Tooltip title='Cancel'>
                        <Box
                            onClick={handleCancel}
                            sx={{
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',

                            }}
                        >
                            <StopCircleIcon sx={{ color: '#FF5E00', fontSize: '30px' }} />
                        </Box>
                    </Tooltip>
                }

            </Box>
        </Box >
    );
};

export default SpeechToText;
