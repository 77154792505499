import { Box, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuid } from "uuid";
import DeleteThreadIcon from 'components/Icons/DeleteThreadIcon.svg'


const ThreadArea = ({ sidebarOpen, selectedThread, setSelectedThread, userId, baseApiUrl, setThreadNames, handleClickNewThread, threadNames }) => {

    const [todaysThreads, setTodaysThreads] = useState([]);
    const [last7DaysThreads, setLast7DaysThreads] = useState([]);
    const [moreThan7DaysThreads, setMoreThan7DaysThreads] = useState([]);

    useEffect(() => {
        getThreadNames();
    }, [])

    useEffect(() => {
        if (!threadNames.length) return;
        const todayItems = threadNames?.filter(item => isToday(item?.lastMessageAt));
        const last7DaysItems = threadNames?.filter(item => isLastSevenDays(item?.lastMessageAt));
        const olderThan7DaysItems = threadNames?.filter(item => isOlderThanSevenDays(item?.lastMessageAt));

        setTodaysThreads(todayItems);
        setLast7DaysThreads(last7DaysItems);
        setMoreThan7DaysThreads(olderThan7DaysItems);

    }, [threadNames])

    function isToday(utcDateStr) {
        if (!utcDateStr) return false;

        try {
            const localDate = new Date(utcDateStr);
            if (isNaN(localDate.getTime())) return false;

            const today = new Date();

            // Compare just the date portion
            return localDate.getFullYear() === today.getFullYear() &&
                localDate.getMonth() === today.getMonth() &&
                localDate.getDate() === today.getDate();
        } catch (error) {
            // console.error('Error in isToday:', error);
            return false;
        }
    }

    function isLastSevenDays(utcDateStr) {
        if (!utcDateStr) return false;

        try {
            const localDate = new Date(utcDateStr);
            if (isNaN(localDate.getTime())) return false;

            const today = new Date();
            const todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate());

            const sevenDaysAgo = new Date(todayStart);
            sevenDaysAgo.setDate(todayStart.getDate() - 7);

            const dateToCompare = new Date(localDate.getFullYear(), localDate.getMonth(), localDate.getDate());

            // Check if date is between 7 days ago and yesterday
            return dateToCompare >= sevenDaysAgo && dateToCompare < todayStart;
        } catch (error) {
            console.error('Error in isLastSevenDays:', error);
            return false;
        }
    }

    function isOlderThanSevenDays(utcDateStr) {
        if (!utcDateStr) return false;

        try {
            const localDate = new Date(utcDateStr);
            if (isNaN(localDate.getTime())) return false;

            const today = new Date();
            const todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate());

            const sevenDaysAgo = new Date(todayStart);
            sevenDaysAgo.setDate(todayStart.getDate() - 7);

            const dateToCompare = new Date(localDate.getFullYear(), localDate.getMonth(), localDate.getDate());

            // Check if date is before 7 days ago
            return dateToCompare < sevenDaysAgo;
        } catch (error) {
            console.error('Error in isOlderThanSevenDays:', error);
            return false;
        }
    }

    const getThreadNames = async () => {
        try {
            const response = await axios.post(`${baseApiUrl}/get-thread-names`, { userId })
            // console.log('Response from get-thread-names', response.data);

            // Check if response and response.data.thread are valid
            if (response && response.data && Array.isArray(response.data.threads)) {
                const responseDataThread = response.data.threads;
                // console.log('Response from get-thread-names', responseDataThread);

                const sortedThreadsData = responseDataThread.sort((a, b) => new Date(b.messages[0].createdAt) - new Date(a.messages[0].createdAt))
                const threadNamesArray = sortedThreadsData.map((thread) => {
                    const lastMessage = thread.messages && thread.messages.length > 0
                        ? thread.messages[0].content
                        : 'No messages'; // Provide fallback if there are no messages

                    return {
                        id: thread.id || uuid(), // Provide fallback for missing ID
                        name: thread.name ? thread.name : lastMessage,
                        lastMessageAt: thread.messages[0].createdAt,
                    }
                })


                setThreadNames(threadNamesArray);
                handleClickNewThread();
            } else {
                toast.error('No thread data available. Please try again.');
            }
        } catch (error) {
            console.log(error);
            toast.error('Oops! Something went wrong. Please reload.');
        }
    }

    const deleteThread = async (threadId, threadName) => {
        try {
            await axios.post(`${baseApiUrl}/delete-a-thread`, { userId, threadId })
            const newThreads = threadNames.filter(thread => thread.id !== threadId);
            setThreadNames(newThreads);
            setSelectedThread(newThreads[0].id);
            toast.success(`Thread '${threadName}' Deleted Successfully!`)
        } catch (error) {
            console.log(error);
            toast.error('Failed to delete thread. Please try again!')
        }
    }

    const truncateThreadName = (input) => {
        // Step 1: Remove URLs
        const cleanedInput = input.replace(/https?:\/\/[^\s]+/g, '');

        // Step 2: Remove asterisks (*)
        const noAsterisksInput = cleanedInput.replace(/\*/g, '');

        // Step 3: Truncate the string to 50 characters if needed
        return noAsterisksInput.length > 50
            ? `${noAsterisksInput.substring(0, 50)}...`
            : noAsterisksInput;
    };

    return (
        < Box sx={{
            width: {
                xs: sidebarOpen ? '60%' : '0%',
                sm: sidebarOpen ? '40%' : '0%',
                md: sidebarOpen ? '22%' : '0%',
            },
            maxWidth: '320px',
            transition: "width 0.5s ease",
            height: '100%',
            // border: '1px solid green',
            display: 'flex',
            border: '0',
            alignItems: 'end',
            borderTopLeftRadius: { xs: '0px', md: '40px' },
            borderBottomLeftRadius: '40px',
            backgroundColor: '#D9E4EC',
            overflow: 'hidden',
        }
        }>

            {
                sidebarOpen &&
                <Box
                    sx={{
                        width: '100%',
                        // border: '1px solid red',
                        height: '87%',
                        paddingY: '20px',
                        paddingX: sidebarOpen ? '15px' : '0px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        boxSizing: 'border-box',
                        overflowY: 'scroll',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },

                    }}>
                    <Typography
                        sx={{
                            color: 'black',
                            fontWeight: '500',
                            fontFamily: 'Poppins',
                            fontSize: '14px',
                            paddingLeft: '7px',
                        }}
                    >Today</Typography>
                    {todaysThreads &&
                        todaysThreads?.map((thread, index) =>
                            <Box key={thread.id} sx={{
                                // border: '1px solid blue',
                                display: sidebarOpen ? 'flex' : 'none',
                                width: "100%",
                                gap: "6px",
                                justifyContent: 'space-between'
                            }}>
                                <Box
                                    onClick={() => setSelectedThread(thread.id)}
                                    key={index}
                                    sx={{
                                        // border: '1px solid green',
                                        paddingY: '7px',
                                        paddingX: '7px',
                                        borderRadius: '5px',
                                        backgroundColor: selectedThread === thread.id ? '#EFF3F6' : '',
                                        cursor: 'pointer',
                                        "&:hover": {
                                            backgroundColor: "#eaf0f5"
                                        },
                                    }}  >
                                    <Typography sx={{
                                        color: 'black',
                                        fontSize: '12px',
                                        fontFamily: "Lexend",
                                    }}>{truncateThreadName(thread.name)}</Typography>

                                </Box>

                                <Box
                                    onClick={() => deleteThread(thread.id, thread.name)}
                                    sx={{
                                        cursor: 'pointer',
                                        width: '17px',
                                        height: '17px',
                                        my: 'auto'
                                    }}
                                >
                                    <DeleteThreadIcon />
                                </Box>
                            </Box>
                        )
                    }

                    <Typography
                        sx={{
                            display: last7DaysThreads.length ? 'block' : 'none',
                            color: 'black',
                            fontWeight: '500',
                            fontFamily: 'Poppins',
                            fontSize: '14px',
                            paddingLeft: '7px',
                            marginTop: '20px',
                        }}
                    >
                        Last 7 days
                    </Typography>

                    {last7DaysThreads &&
                        last7DaysThreads?.map((thread, index) =>
                            <Box sx={{
                                // border: '1px solid blue',
                                display: sidebarOpen ? 'flex' : 'none',
                                width: "100%",
                                gap: "6px",
                                justifyContent: 'space-between'
                            }}>
                                <Box
                                    onClick={() => setSelectedThread(thread.id)}
                                    key={index}
                                    sx={{
                                        // border: '1px solid green',
                                        paddingY: '7px',
                                        paddingX: '7px',
                                        borderRadius: '5px',
                                        backgroundColor: selectedThread === thread.id ? '#EFF3F6' : '',
                                        cursor: 'pointer',
                                        "&:hover": {
                                            backgroundColor: "#eaf0f5"
                                        },
                                    }}  >
                                    <Typography sx={{
                                        color: 'black',
                                        fontSize: '12px',
                                        fontFamily: "Lexend",
                                    }}>{truncateThreadName(thread.name)}</Typography>

                                </Box>

                                <Box
                                    onClick={() => deleteThread(thread.id, thread.name)}
                                    sx={{
                                        cursor: 'pointer',
                                        width: '17px',
                                        height: '17px',
                                        my: 'auto'
                                    }}
                                >
                                    <DeleteThreadIcon />
                                </Box>
                            </Box>
                        )
                    }


                    <Typography
                        sx={{
                            display: moreThan7DaysThreads.length ? 'block' : 'none',
                            color: 'black',
                            fontWeight: '500',
                            fontFamily: 'Poppins',
                            fontSize: '14px',
                            paddingLeft: '7px',
                            marginTop: '20px',

                        }}
                    >Older than 7 days</Typography>

                    {moreThan7DaysThreads &&
                        moreThan7DaysThreads?.map((thread, index) =>
                            <Box sx={{
                                // border: '1px solid blue',
                                display: sidebarOpen ? 'flex' : 'none',
                                width: "100%",
                                gap: "6px",
                                justifyContent: 'space-between'
                            }}>
                                <Box
                                    onClick={() => setSelectedThread(thread.id)}
                                    key={index}
                                    sx={{
                                        // border: '1px solid green',
                                        paddingY: '7px',
                                        paddingX: '7px',
                                        borderRadius: '5px',
                                        backgroundColor: selectedThread === thread.id ? '#EFF3F6' : '',
                                        cursor: 'pointer',
                                        "&:hover": {
                                            backgroundColor: "#eaf0f5"
                                        },
                                    }}  >
                                    <Typography sx={{
                                        color: 'black',
                                        fontSize: '12px',
                                        fontFamily: "Lexend",
                                    }}>{truncateThreadName(thread?.name)}</Typography>

                                </Box>

                                <Box
                                    onClick={() => deleteThread(thread.id, thread.name)}
                                    sx={{
                                        cursor: 'pointer',
                                        width: '17px',
                                        height: '17px',
                                        my: 'auto'
                                    }}
                                >
                                    <DeleteThreadIcon />
                                </Box>
                            </Box>
                        )
                    }


                </Box>
            }

        </ Box>
    );
};

export default ThreadArea;