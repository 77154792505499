import React, { useState, useEffect } from "react";

import { Modal, Box, Button, Typography, Divider } from "@mui/material"
import axios from "axios";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import {
  Check,
} from "@mui/icons-material";
import CustomCheckbox from "components/Table/CustomCheckbox"
import { KeyboardArrowRight, KeyboardArrowLeft } from "@mui/icons-material";


const CallShare = ({ user, userData, callData, selectedUsers, setSelectedUsers, openAssignModal, setOpenAssignModal, fetchUserData, reFetchUserData}) => {
    const [assignLoading, setAssignLoading] = useState(false);
    const [selectedParticipants, setSelectedParticipants] = useState({}); // Tracks which participant is assigned to which user
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isConfirmationScreen, setIsConfirmationScreen] = useState(false); // Tracks assigned participants to prevent duplicates
    const [usedParticipants, setUsedParticipants] = useState({});

    useEffect(() => {
      // Filter out users who are already assigned and shared (either in participants or guest_shares)
      const filteredUsers = selectedUsers.filter((user) => {
        const isParticipantShared = callData?.participants.some(
          (participant) => participant?.user_id === user?.user_id && participant?.shared === true
        );
    
        const isGuestShared = callData?.guest_shares.some(
          (guest) => guest?.user_id === user?.user_id
        );
    
        // Keep only users who are not shared in participants or guest_shares
        return !isParticipantShared && !isGuestShared;
      });
    
      if (filteredUsers.length !== selectedUsers.length) {
        setSelectedUsers(filteredUsers); // Update selectedUsers to exclude already assigned ones
      }
    }, [selectedUsers, callData]);
    

    const closeAssignModal = () => {
      setSelectedUsers([]);
      setOpenAssignModal(false);
      setSelectedParticipants({});
      setUsedParticipants({});
      setCurrentIndex(0);
      setIsConfirmationScreen(false);
    };
    
    const queryClient = useQueryClient();

  // Handle participant assignment
  const handleParticipantAssignment = (userId, participantId) => {
    setSelectedParticipants((prev) => {
      const updatedSelections = { ...prev };
  
      // Clear the current assignment for this user
      const currentAssignment = updatedSelections[userId];
      if (currentAssignment) {
        setUsedParticipants((prevUsed) => {
          const updatedUsed = { ...prevUsed };
          delete updatedUsed[currentAssignment]; // Free the currently assigned participant
          return updatedUsed;
        });
      }
  
      if (participantId === "share_copy") {
        // Allow multiple users to select "share_copy"
        updatedSelections[userId] = "share_copy";
      } else {
        // Ensure the new participant is not already assigned to another user
        if (!Object.values(updatedSelections).includes(participantId)) {
          // Assign the new participant to the user
          updatedSelections[userId] = participantId;
  
          // Mark the new participant as used
          setUsedParticipants((prevUsed) => ({
            ...prevUsed,
            [participantId]: true,
          }));
        }
      }
  
      return updatedSelections;
    });
  };
  

    const unmatchedUsers = selectedUsers?.filter((user) => {
      const matchedParticipant = callData?.participants.find(
        (participant) => participant?.user_id === user?.user_id
      );
      return !matchedParticipant;
    });
  
  
    const currentUser = unmatchedUsers[currentIndex] || "";
    const unmatchedParticipants = callData?.participants?.filter(
      (participant) => !participant?.user_id
    );

      // Filter unmatched participants for the current user
      const availableParticipants = unmatchedParticipants?.filter(
        (participant) =>
          !usedParticipants[participant?.participant_id] ||
          selectedParticipants[currentUser?.user_id] === participant?.participant_id
      );         
      
  
    const navigateToNext = () => {
      if (currentIndex < unmatchedUsers.length - 1) {
        setCurrentIndex(currentIndex + 1);
      } else {
        setIsConfirmationScreen(true); // Proceed to confirmation screen
      }
    };
  
    const navigateToPrevious = () => {
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      }
    };
  
    const handleFinalSubmit = () => {
      // Combine manually selected participants with automatically matched ones
      const finalMatchedParticipants = { ...selectedParticipants };
    
      // Iterate over selectedUsers to find automatically matched participants
      selectedUsers.forEach((user) => {
        const matchedParticipant = callData?.participants.find(
          (participant) => participant?.user_id === user?.user_id
        );
    
        if (matchedParticipant && !finalMatchedParticipants[user.user_id]) {
          finalMatchedParticipants[user.user_id] = matchedParticipant.participant_id;
        }
      });
    
      // Call the function to assign the call with the final matched participants
      assignCall(finalMatchedParticipants);
    };
    
     
    const assignCall = async (finalMatchedParticipants) => {
      setAssignLoading(true);
    
      const data = callData;
      if (!data) {
        console.error("No data available in callData");
        return;
      }
    
      try {
        const body = {
          call_id: data.call_id,
          user_id: data.user_id,
          call_name: data?.call_name,
          zoom_date: data?.zoom_date,
          coach_name: `${user?.given_name} ${user?.family_name}`,
          duration: data?.duration_minutes,
          selected_participants: Object.entries(finalMatchedParticipants).map(
            ([userId, participantId]) => ({ user_id: userId, participant_id: participantId })
          ),
        };
    
        if (!body.call_id) {
          console.error("Invalid payload: No call ID provided.");
          return;
        }
    
        const res = await axios.put(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/assign-call`,
          body
        );
    
        // Handle response messages
        if (res.status === 200 && res.data.message === "succeed") {
          closeAssignModal();
          fetchUserData();
    
          toast("Session shared successfully", {
            hideProgressBar: true,
            style: {
              background: "white",
              borderRadius: "8px",
              color: "#160042",
            },
            icon: () => <Check color="success" />,
          });
    
          queryClient.invalidateQueries({
            predicate: (query) => query.queryKey.includes("clientData"),
          });
        } else if (res.status === 207 && res.data.message === "Partial success") {
          closeAssignModal();
          fetchUserData();
    
          toast(`Some participants couldn't be assigned.`, {
            hideProgressBar: true,
            style: {
              borderRadius: "8px",
              color: "black",
            },
          });
    
          // Optionally, display failed participants details
          console.warn("Failed Participants: ", res.data.failedParticipants);
        } else {
          toast.error("Unexpected response while sharing calls");
        }
      } catch (err) {
        console.error("Error in assign call", err);
        toast.error("Failed to share sessions. Please try again.");
      } finally {
        if (typeof reFetchUserData === "function") {
          reFetchUserData();
        }
        setAssignLoading(false);
      }
    };
    

    return (
      <Modal open={openAssignModal} onClose={closeAssignModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            padding: "20px 20px",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            maxWidth: "calc(100% - 40px)",
            minHeight: (!isConfirmationScreen && (!isConfirmationScreen && unmatchedUsers?.length > 0)) && "500px",
            maxHeight: "500px",
          }}
        >
          {(isConfirmationScreen || unmatchedUsers?.length === 0) ? (
            <>
              {/* Confirmation Screen */}
              <Typography
                sx={{
                  color: "#160042",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "18px",
                  letterSpacing: "-0.03",
                }}
              >
                Share Confirmation
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  background: "#B4FFD2",
                  paddingY: "2px", 
                  paddingX: "8px",
                  width: "50%",
                  borderRadius: "53px",
                  justifyContent: "center"
                }}
              >
                <Typography
                  sx={{
                    color: '#00643A',
                    fontSize: "14px",
                    fontFamily: 'Inter',
                    fontWeight: '600',
                    lineHeight: "18px",
                    wordWrap: 'break-word'
                  }}
                >
                  All Confirmed Participants
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  marginLeft: "20px",
                  marginBottom: "20px"
                }}
              >
                {selectedUsers?.length > 0 && selectedUsers.map((user) => {
                  // Find the matched participant for this user
                  const matchedParticipant = callData?.participants.find(
                    (participant) => participant?.user_id === user?.user_id
                  );

                  // Determine if the user has a manually selected participant
                  const manuallySelectedParticipant = selectedParticipants[user?.user_id]
                    ? callData?.participants.find(
                        (participant) =>
                          participant?.participant_id === selectedParticipants[user?.user_id]
                      )
                    : null;

                  // Final participant determination
                  const finalParticipant = matchedParticipant || manuallySelectedParticipant;

                  return (
                    <Typography key={user?.user_id} sx={{ color: "black", fontFamily: "Poppins", fontWeight: "500", lineHeight: "18px" }}>
                      {user?.name}
                    </Typography>
                  );
                })}
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between"}}>
              <Box display="flex" justifyContent="center" gap="15px">
                {unmatchedUsers.length > 0 && (
                <Button
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "53px",
                    border: "1px solid black",
                    textTransform: "capitalize",
                    paddingY: "2px",
                    paddingX: "4px"
                  }}
                  onClick={() => {
                    setIsConfirmationScreen(false); // Switch back to assignment screen
                    setCurrentIndex(currentIndex); // Set index to the last user
                  }}
                >
                  <KeyboardArrowLeft sx={{ color: "black" }} />
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "12px",
                      fontFamily: "Lexend",
                      textTransform: "none",
                    }}
                  >
                    Back
                  </Typography>
                </Button>
                )}
                <Button
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "53px",
                    border: "1px solid black",
                    color: "white",
                    textTransform: "capitalize",
                    paddingY: "2px",
                    paddingX: "10px",
                    background: "#0076BA",
                    "&:hover": {
                      background: "#",
                      color: "white",
                    },

                  }}
                  onClick={handleFinalSubmit}
                  disabled={assignLoading}
                >
                  Share
                </Button>
              </Box>
              <Button
                  sx={{
                    paddingY: "2px",
                    paddingX: "12px",
                    borderRadius: "53px",
                    background: "#FFFFFF",
                    color: "black",
                    fontWeight: "500",
                    fontSize: "12px",
                    border: "1px solid #D9E4EC",
                    textTransform: "capitalize",
                    "&:hover": {
                      background: "rgba(190, 8, 8, 0.4)",
                      color: "white",
                    },
                  }}
                  onClick={closeAssignModal}
                  disabled={assignLoading}
                >
                  Cancel
              </Button>
              </Box>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between",
                flexGrow: 1,
              }}
            >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", height: "100%" }}>
              {/* User Identification Screen */}
              <Typography
                sx={{
                  color: "#667085",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "18px",
                  letterSpacing: "0.40",
                }}
              >
                {`${currentIndex + 1} of ${unmatchedUsers?.length}` }
              </Typography>
              <Typography
                sx={{
                  color: "black",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "18px",
                  letterSpacing: "-0.03",
                }}
              >
                Resolve unmatched session participants
              </Typography>
              <Box
                sx={{
                  display: "flex", 
                  flexDirection: "column",
                  gap: "10px",
                  height: "100%"
                }}
              >
              <Typography
                sx={{
                  color: "black",
                  fontFamily: "Lexend",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "18px",
                  letterSpacing: "-0.03",
                }}
              >
                <span style={{ fontWeight: "600" }}>{currentUser?.name}</span> does not match with any participants. Choose how to share this session.
              </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    marginBottom: "8px",
                  }}
                >
                  <CustomCheckbox
                    checked={selectedParticipants[currentUser?.user_id] === "share_copy"}
                    onChange={() =>
                      handleParticipantAssignment(currentUser?.user_id, "share_copy")
                    }
                  />
                  <Typography sx={{ color: "black", fontSize: "14px", fontFamily: "Lexend", fontWeight: "500", textTransform: "capitalize", lineHeight: "18px", letterSpacing: "0.60" }}>
                    Share as Guest
                  </Typography>
                </Box>
                
                <Typography sx={{color: "black", fontFamily: "Lexend", fontWeight: "400", fontSize: "14px", lineHeight: "18px", letterSpacing: "-0.03"}}>
                  { availableParticipants?.length > 0 ? "Match with a participant in the call" : "No remaining participants to assign"}
                </Typography>
                <Box 
                  sx={{display: "flex", flexDirection: "column", maxHeight: "140px", overflow: "auto", gap: "10px",             
                      "&::-webkit-scrollbar": {
                        width: "8px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "rgb(207, 207, 207)",
                        borderRadius: "4px",
                      },
                      "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "rgb(207, 207, 207)",
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "transparent",
                      },
                    }} 
                >
                  {availableParticipants?.length > 0 &&
                    availableParticipants?.map((participant) => (
                      <>
                      <Box
                        key={participant.participant_id}
                        sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                      >
                        <CustomCheckbox
                          checked={
                            selectedParticipants[currentUser?.user_id] ===
                            participant?.participant_id
                          }
                          onChange={() =>
                            handleParticipantAssignment(
                              currentUser?.user_id,
                              participant?.participant_id
                            )
                          }
                        />
                        <Typography sx={{ color: "black", fontSize: "14px", fontFamily: "Lexend", fontWeight: "500", textTransform: "capitalize", lineHeight: "18px", letterSpacing: "0.60" }}>
                          {participant?.display_name || "Unknown Participant"}
                        </Typography>
                      </Box>
                      </>
                    ))}
                  </Box>
              </Box>
              </Box>

              {selectedParticipants[currentUser?.user_id] === "share_copy" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    paddingX: "20px",
                    paddingY: "16px",
                    background: "#EFF3F6",
                    borderRadius: "16px",
                  }}
                >
                  <Typography
                    sx={{
                      color: '#1E1B39', fontSize: "14px", fontFamily: 'Lexend', fontWeight: '400', lineHeight: "20px", wordWrap: 'break-word'
                    }}
                  >
                    Guest Sharing Restrictions
                  </Typography>
                  <Typography
                    sx={{
                      color: '#828282', fontSize: "12px", fontFamily: 'Lexend', fontWeight: '400', lineHeight: "18px", wordWrap: 'break-word'
                    }}
                  >
                    Guests will not be assigned LARA scores or Actions in the communication report
                  </Typography>
                </Box>
              )}

              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Box display="flex" gap="15px">
                  <Button
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "53px",
                      border: "1px solid black",
                      textTransform: "capitalize",
                      paddingY: "3px",
                      paddingX: "8px",
                      "&:disabled": {
                        color: "#B0B7BD",
                        border: "1px solid #D5D7DA",
                        background: "#FAFCFE"
                      },
                    }}
                    onClick={navigateToPrevious}
                    disabled={currentIndex === 0}
                  >
                    <KeyboardArrowLeft sx={{color: currentIndex === 0 ? "#B0B7BD" : "black", height: "16px"}}/>
                    <Typography sx={{fontSize: "12px", fontFamily: "Lexend", paddingRight: "6px"}}>
                      Back
                    </Typography>
                  </Button>
                  <Button
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "53px",
                      border: "1px solid black",
                      textTransform: "capitalize",
                      paddingY: "3px",
                      paddingX: "8px",
                      color: "white",
                      background: "#0076BA",
                      "&:disabled": {
                        color: "#B0B7BD",
                        border: "1px solid #D5D7DA",
                        background: "#FAFCFE"
                      },
                    }}
                    onClick={navigateToNext}
                    disabled={
                      !selectedParticipants[currentUser?.user_id] // Disable "Next" until user assigns or selects "Share Copy"
                    }
                  >
                    <Typography sx={{ fontSize: "12px", fontFamily: "Lexend", paddingLeft: "6px"}}>
                      Next
                    </Typography>
                    <KeyboardArrowRight sx={{color: !selectedParticipants[currentUser?.user_id] ? "#B0B7BD" : "white", height: "16px"}}/>
                  </Button>
                </Box>
                <Button
                  sx={{
                    paddingX: "14px",
                    paddingY: "2px",
                    borderRadius: "53px",
                    background: "#FFFFFF",
                    color: "black",
                    fontWeight: "500",
                    fontSize: "12px",
                    border: "1px solid #D9E4EC",
                    textTransform: "capitalize",
                    "&:hover": {
                      background: "rgba(190, 8, 8, 0.4)",
                      color: "white",
                    },
                  }}
                  onClick={closeAssignModal}
                  disabled={assignLoading}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    );
}

export default CallShare;