import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { generateClient } from "aws-amplify/data";
import VideoPlayerCard from "scenes/SessionPageCards/VideoPlayerCard";

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Modal,
  TextField,
  Typography,
  Checkbox,
  Stack,
} from "@mui/material";
import avatar from "assets/avatar.png";
import { useNavigate } from "react-router";
import axios from "axios";
import { PDFDocument, StandardFonts, rgb, degrees, PDFName } from "pdf-lib";
import { saveAs } from "file-saver";
import altercallLogo from "assets/altercall1.png"; // Ensure this is a valid PNG file
//import { create, defaultLanguage, logErrors, registerFormat, setDefaultLanguage } from 'fontkit';

// Queries
import ShareIcon from "components/Icons/Share.svg";
import { useSelector } from "react-redux";
import { Check, ErrorOutline, PersonAddAlt } from "@mui/icons-material";
import { toast } from "react-toastify";
import NegativeActionButton from "components/NegativeActionButton";
import PositiveActionButton from "components/PositiveActionButton";
import { formatDistanceToNow } from "date-fns";
import dayjs from "dayjs";
import LaraSessionPage from "components/SessionPage/LaraSessionPage";
import SessionDetails from "components/SessionPage/SessionDetails";
import { fetchAvatarUrl } from "utils/fetchAvatarUrl";
import PoppinsRegular from "components/Icons/Poppins-Regular.ttf";

import fontkit from "@pdf-lib/fontkit";
import LexendRegular from "components/Icons/Lexend-Regular.ttf";
import LexendBold from "components/Icons/Lexend-Bold.ttf";
import CustomCheckbox from "components/Table/CustomCheckbox";
import CallShare from "components/SessionsList/CallShare";
import TwoUsersIcon from "components/Icons/TwoUsers.svg";
import UserCheck from "components/Icons/UserCheck.svg";

import PoppinsBold from "components/Icons/Poppins-Bold.ttf";
import SessionAction from "components/SessionPage/SessionAction";
import SessionActions from "components/SessionPage/SessionAction";
import CheckboxNew from "components/Icons/CheckboxNew.svg";
import SessionPageBack from "components/Icons/SessionPageBack.svg";
import ShareIconSession from "components/Icons/ShareIconSession.svg";
import AnalysisSessionpage from "components/Icons/AnalysisSessionpage.svg";
import ActionsIcon from "components/Icons/Actions.svg";
import SummarySession from "components/Icons/SummarySession.svg";
import ActionWhite from "components/Icons/ActionWhite.svg";
import SumaryWhite from "components/Icons/SumaryWhite.svg";
import Actions from "components/Icons/Actions.svg";
import Analysiswhite from "components/Icons/Analysiswhite.svg";
import ActionSession from "components/Icons/ActionSession.svg";

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });

const SessionPage = ({ user, isProfessionalTier, userData }) => {
  const { file_name } = useParams();
  const collapsed = useSelector((state) => state.layout.collapsed);
  const encodedFileName = encodeURIComponent(file_name);
  const [fileData, setFileData] = useState({});
  const [laraData, setLaraData] = useState({});
  const [callActions, setCallActions] = useState({});
  const [videoUrl, setVideoUrl] = useState("");
  const [transcriptUrl, setTranscriptUrl] = useState("");
  const [vttText, setVttText] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(true);
  const [showClientList, setShowClientList] = useState(false);
  const [clientShared, setClientShared] = useState("");
  const [clientData, setClient] = useState([]);
  const [assignee, setAssignee] = useState({});
  const [client, setAssignClient] = useState({});
  const [clientId, setClientId] = useState("");
  const [coachId, setCoachId] = useState("");
  const [updatedAction, setUpdatedAction] = useState({});
  const [initialAction, setInitialAction] = useState({});
  const [quickRecap, setQuickRecap] = useState("");
  const [updatedQuickRecap, setUpdatedQuickRecap] = useState({});
  const [openEditActionModal, setOpenEditActionModal] = useState(false);
  const [openEditQuickRecapModal, setOpenEditQuickRecapModal] = useState(false);
  const [activeTab, setActiveTab] = useState("editText");
  const [selectedClients, setSelectedClients] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [openIdentifyModal, setOpenIdentifyModal] = useState(false);
  const [listparticipants, setlistParticipants] = useState([]);

  // Step/wizard: current participant index
  const [currentIndex, setCurrentIndex] = useState(0);
  // Loading state for API calls
  const [loadingIdentify, setLoadingIdentify] = useState(false);
  // Single client selection
  const [selectedClientId, setSelectedClientId] = useState(null);

  const [openAssignModal, setOpenAssignModal] = useState(false);

  const handleCloseEditAction = () => {
    setOpenEditActionModal(false);
    setInitialAction({});
    setActiveTab("editText");
  };
  const [activeTabright, setActiveTabright] = useState("analysis");
  const handleCloseEditQuickRecap = () => {
    setOpenEditQuickRecapModal(false);
  };
  const navigate = useNavigate();

  const handleOpenAssignModal = () => {
    setShowClientList(false);
    setOpenAssignModal(true);
  };

  const fetchFileData = useCallback(async () => {
    try {
      setLoadingFile(true);
      const { data: response } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-call`,
        {
          params: { file_name: encodedFileName },
        }
      );
      console.log("response", response.data);

      console.log("res call: ", response?.data);
      if (!response?.data) {
        setLoadingFile(false);
        return;
      }

      const responseData = response.data;
      setFileData(response.data);
      setQuickRecap(responseData?.quick_recap);
      setCoachId(responseData?.user_id || "");
      const callId = responseData?.call_id;
      console.log("callId", callId);

      const { data: laraResponse } = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-session-lara-data`,
        { call_id: callId }
      );
      console.log("LARA Data Response:", laraResponse.data);
      if (laraResponse.data.status === "success" || laraResponse) {
        const laraData1 = laraResponse.data;
        console.log("Detailed LARA Data:", laraData1);
        console.log("LARA Data: we have ", laraResponse.data);

        // Process LARA Data as Needed
        setLaraData(laraResponse.data); // Ensure you have this state defined
        console.log("LARA Data: we have ", laraData);
      }

      const participantResponse = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-participants`,
        {
          params: { call_id: callId },
        }
      );
      if (response) {
        console.log("response for participant", participantResponse.data);
        setlistParticipants(participantResponse.data);
      }
      console.log("listparticipant", listparticipants);

      const vttUrl = responseData?.vtt_s3_link || "";
      const mp4Url = responseData?.mp4_s3_link || "";

      const fileDataObject = { vtt_s3_link: vttUrl, mp4_s3_link: mp4Url };
      const fileDataStringified = JSON.stringify(fileDataObject);

      // No need to stringify again
      const { data, errors } = await resourceClient.queries.fetchPresignedUrls({
        input: fileDataStringified, // Send the once-stringified object directly
      });

      const responseObject = JSON.parse(data);

      if (responseObject) {
        const vttPresignedUrl = responseObject.vtt_presigned;
        const mp4PresignedUrl = responseObject.mp4_presigned;

        if (vttPresignedUrl) {
          setTranscriptUrl(vttPresignedUrl);
          fetch(vttPresignedUrl)
            .then((response) => response.text())
            .then((data) => setVttText(data))
            .catch((error) => console.error("Error:", error));
        }

        if (mp4PresignedUrl) {
          setVideoUrl(mp4PresignedUrl);
        }
      }
    } catch (error) {
      console.error("Error fetching file data:", error);
    } finally {
      setLoadingFile(false);
      setLoading(false);
    }
  }, [encodedFileName]);

  const fetchClientData = useCallback(async () => {
    try {
      const userId = user?.sub;

      let response = null;

      if (userData?.companyInformation) {
        switch (user?.role) {
          case "OWNER":
            response = await axios.get(
              `${process.env.REACT_APP_API_ENDPOINT}/zoom/owner-fetch-company-information`,
              {
                params: { userId: userId },
              }
            );
            break;
          case "SUPERADMINS":
            response = await axios.get(
              `${process.env.REACT_APP_API_ENDPOINT}/zoom/super-admin-fetch-company-members`,
              {
                params: { userId: userId, userGroup: user?.role },
              }
            );
            break;
          case "ADMINS":
            response = await axios.get(
              `${process.env.REACT_APP_API_ENDPOINT}/zoom/admin-fetch-company-members`,
              {
                params: { userId: userId, userGroup: user?.role },
              }
            );
            console.log("response");
            break;
          case "USERS":
            response = await axios.get(
              `${process.env.REACT_APP_API_ENDPOINT}/zoom/user-fetch-company-members`,
              {
                params: { userId: userId, userGroup: user?.role },
              }
            );
            break;
          case "PRIVATE":
            response = await axios.get(
              `${process.env.REACT_APP_API_ENDPOINT}/zoom/private-fetch-company-members`,
              {
                params: {
                  userId: userId,
                  userGroup: user?.role,
                  companyId: userData?.companyInformation?.company_id,
                },
              }
            );
            break;
          default:
            break;
        }
      } else {
        // Make the API call for clients if the user does not belong to a company
        response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-clients`,
          {
            params: { coach_id: userId },
          }
        );
      }

      response = response.data;

      if (!response.members) {
        setLoading(false);
        return;
      }

      // Filter out the row where member.user_id is equal to the current user's sub
      const filteredMembers = response.members.filter(
        (member) => member.user_id !== userId
      );

      // Map through the filtered client data

      // Fetch avatars asynchronously
      const updatedMembers = await Promise.all(
        filteredMembers.map(async (item) => {
          const avatarUrl = item.avatar
            ? await fetchAvatarUrl(item.avatar)
            : null;
          return {
            ...item,
            avatar: avatarUrl, // Update the avatar field with the fetched URL
          };
        })
      );

      // Sort the filtered data by zoom_date
      const sortedData = updatedMembers.sort(
        (a, b) => new Date(b.zoom_date) - new Date(a.zoom_date)
      );

      // Update the state with the filtered and sorted client data
      setClient(sortedData);
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  }, [user, userData]);

  useEffect(() => {
    fetchFileData();
    fetchClientData();
  }, [fetchFileData, fetchClientData]);

  const callName = fileData?.call_name;
  const coachName = fileData?.coach_name;
  const zoomDate = fileData?.zoom_date;

  const fetchActions = useCallback(async () => {
    console.log("");
    try {
      const { data: response } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-actions-by-call`,
        {
          params: { call_id: fileData?.call_id },
        }
      );
      console.log("call res: ", response);
      if (response) {
        setCallActions(response.actions);
      }
    } catch (error) {
      console.error("error fetching actions", error);
    }
  }, [fileData]);
  useEffect(() => {
    if (fileData?.call_id && user?.sub) {
      fetchActions();
    }
  }, [fileData, fetchActions, user]);

  //check if the user's id is either the coach or client id registered on the session
  useEffect(() => {
    if (fileData?.user_id && user?.sub) {
      //Navigate away only if the user is neither the owner, nor in participants, nor in guest shares
      if (
        user?.sub !== fileData.user_id && // Not the owner
        !fileData?.participants?.some(
          (participant) => participant.user_id === user?.sub
        ) && // Not a participant
        !fileData?.guest_shares?.some((guest) => guest.user_id === user?.sub) // Not in guest shares
      ) {
        navigate(-1); // Navigate to the previous page
      }
    }
  }, [fileData, user?.sub, navigate]);

  const summary = fileData?.summary;
  const labels = summary
    ?.match(/"label": "(.*?)"/g)
    ?.map((label) => label.replace(/"label": "(.*?)"/, "$1"));
  const summaries = summary
    ?.match(/"summary": "(.*?)"/g)
    ?.map((summary) => summary.replace(/"summary": "(.*?)"/, "$1"));

  const status = fileData?.status;
  const duration = fileData?.duration_minutes;
  const formattedZoomDate = dayjs(zoomDate).utc().format("MM.DD.YY");

  const downloadTranscript = async () => {
    try {
      const response = await fetch(transcriptUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${callName}.vtt`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error(err);
    }
  };
  console.log("laraData", laraData);
  console.log("clientData", clientData);
  console.log("fileData", fileData);

  const editActionModal = () => {
    const handleTabChange = (tab) => {
      setActiveTab(tab);
    };
    let originalAction = "";
    let sortedEdits = [];
    if (initialAction && initialAction.edits && initialAction.edits[0]) {
      sortedEdits = initialAction.edits
        ? [...initialAction.edits].sort(
            (a, b) => new Date(a.edit_timestamp) - new Date(b.edit_timestamp)
          )
        : [];
      originalAction = sortedEdits[0].previous_value;
    }
    return (
      <Modal open={openEditActionModal} onClose={handleCloseEditAction}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: {
                xs: "8px",
                lg: "32px",
              },
            }}
          >
            <Box display="flex" gap="10px">
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 600,
                  padding: "4px",
                  borderBottom:
                    activeTab === "editText" ? "1px solid black" : "none",
                  textTransform: "capitalize",
                  boxShadow: "none",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => handleTabChange("editText")}
              >
                Edit Action
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 600,
                  padding: "4px",
                  borderBottom:
                    activeTab === "editHistory" ? "1px solid black" : "none",
                  textTransform: "capitalize",
                  boxShadow: "none",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => handleTabChange("editHistory")}
              >
                View History
              </Typography>
            </Box>
            {activeTab === "editHistory" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  height: "220px",
                  overflowY: "auto",
                }}
              >
                {sortedEdits &&
                  sortedEdits
                    .filter((edit) => edit && edit.edit_id !== null)
                    .slice()
                    .reverse()
                    .map((edit, index) => (
                      <Box key={index} sx={{ gap: "2px" }}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "black",
                          }}
                        >
                          {`${edit.new_value}`}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#2D2D2D",
                            fontSize: "12px",
                            marginLeft: "10px",
                            marginBottom: "6px",
                          }}
                        >
                          {`edited by 
                              ${edit?.editor_full_name} 
                                ${formatDistanceToNow(
                                  new Date(edit?.edit_timestamp)
                                )} ago`}
                        </Typography>
                        <Divider color="#d3d3d3" />
                      </Box>
                    ))}
                {initialAction.edits && originalAction && (
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "black",
                      }}
                    >
                      {originalAction}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#2D2D2D",
                        fontSize: "12px",
                        marginLeft: "10px",
                      }}
                    >
                      orginal action
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
            {activeTab === "editText" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Lexend",
                      fontSize: "12px",
                      lineHeight: "150%",
                      letterSpacing: "2px",
                      color: "black",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                  >
                    Action
                  </Typography>
                  <TextField
                    required
                    fullWidth
                    autoFocus
                    multiline
                    rows={4}
                    value={updatedAction.action}
                    InputProps={{
                      inputProps: {
                        style: {
                          color: "black",
                        },
                      },
                      style: {
                        borderRadius: "16px",
                        border: "1px solid #C4CAD4",
                        backgroundColor: "transparent",
                      },
                    }}
                    onChange={(event) => {
                      setUpdatedAction((prev) => ({
                        ...prev,
                        action: event.target.value,
                      }));
                    }}
                  />
                </Box>
                <Box display="flex" justifyContent="flex-end" gap="15px">
                  <NegativeActionButton
                    onClick={handleCloseEditAction}
                    label={"Cancel"}
                  />
                  <PositiveActionButton
                    onClick={saveEditAction}
                    label={"Save Action"}
                    disabled={updatedAction.action === initialAction.action}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Modal>
    );
  };

  const editQuickRecapModal = () => {
    return (
      <Modal
        open={openEditQuickRecapModal}
        onClose={() => setOpenEditQuickRecapModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "50%",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Lexend",
              fontSize: "36px",
              color: "black",
              fontWeight: 300,
            }}
          >
            Edit Recap
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: {
                xs: "8px",
                lg: "32px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  fontSize: "12px",
                  lineHeight: "150%",
                  letterSpacing: "2px",
                  color: "black",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                }}
              >
                Recap
              </Typography>
              <TextField
                required
                fullWidth
                autoFocus
                multiline
                rows={4}
                value={updatedQuickRecap}
                InputProps={{
                  inputProps: {
                    style: {
                      color: "black",
                      height: "70px",
                    },
                  },
                  style: {
                    borderRadius: "16px",
                    border: "1px solid #C4CAD4",
                    backgroundColor: "transparent",
                  },
                }}
                onChange={(event) => {
                  setUpdatedQuickRecap(event.target.value);
                }}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" gap="15px">
              <NegativeActionButton
                onClick={handleCloseEditQuickRecap}
                label={"Cancel"}
              />
              <PositiveActionButton
                onClick={saveEditQuickRecap}
                label={"Save Recap"}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };

  const saveEditAction = async () => {
    console.log("here");
    try {
      setOpenEditActionModal(false);
      const { status, response } = await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/update-action`,
        {
          action_id: updatedAction.action_id,
          action: updatedAction.action,
          initial_action: initialAction.action,
          editor_id: user?.sub,
        }
      );
      if (status === "success") {
        toast(`Updated action successfully`, {
          hideProgressBar: true,
          style: {
            background: "white",
            boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
            borderRadius: "8px",
            padding: "16px 28px",
            color: "#160042",
          },
          icon: () => <Check color="success" />,
        });
        setCallActions((prev) =>
          prev.map((prevRow) =>
            prevRow.action_id === updatedAction.action_id
              ? { ...prevRow, action: updatedAction.action }
              : prevRow
          )
        );
        setUpdatedAction("");
      }
    } catch (error) {
      console.error("Error updating action:", error);
    }
  };

  const saveEditQuickRecap = async () => {
    try {
      setOpenEditActionModal(false);
      const { status } = await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/update-file-recap`,
        {
          call_id: fileData?.call_id,
          quick_recap: updatedQuickRecap,
        }
      );
      if (status === "success") {
        toast(`Updated recap successfully`, {
          hideProgressBar: true,
          style: {
            background: "white",
            boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
            borderRadius: "8px",
            padding: "16px 28px",
            color: "#160042",
          },
          icon: () => <Check color="success" />,
        });
        setQuickRecap(updatedQuickRecap);
        setOpenEditQuickRecapModal(false);
      }
    } catch (error) {
      console.error("Error updating action:", error);
      setOpenEditQuickRecapModal(false);
    }
  };

  const ClientListModal = () => {
    const handleCheckboxChange = (client, isChecked) => {
      setSelectedClients((prevSelected) => {
        if (isChecked) {
          return [
            ...prevSelected,
            { user_id: client.user_id, name: client.full_name },
          ];
        } else {
          // Remove from selected clients
          return prevSelected.filter((item) => item.user_id !== client.user_id);
        }
      });
    };

    const handleSelectAll = () => {
      if (clientData && clientData.length > 0) {
        if (selectedClients.length === clientData.length) {
          // If all clients are selected, clear the selection
          setSelectedClients([]);
        } else {
          // Select all clients
          setSelectedClients(
            clientData.map((client) => ({
              user_id: client.user_id,
              name: client.full_name,
            }))
          );
        }
      }
    };

    const handleCloseClientListModal = () => {
      setSelectedClients([]);
      setShowClientList(false);
    };

    return (
      <Modal open={showClientList} onClose={handleCloseClientListModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "#FFF",
            borderRadius: "16px",
            boxShadow: 24,
            padding: "16px 28px",
            width: { xs: "90%", sm: "80%", md: "415px" },
            maxHeight: "90vh",
            height: "510px",
            overflowY: "auto",
          }}
        >
          {/* Header and Subheader */}
          <Stack direction="column" spacing={1}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                id="client-list-modal-title"
                sx={{
                  fontFamily: "Lexend",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  textAlign: "left",
                  color: "#160042",
                }}
              >
                Who is this session being shared with?
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" gap="4px">
              <ErrorOutline
                sx={{
                  color: "#00B11C",
                  fontSize: "11px",
                  width: "11px",
                  height: "11px",
                }}
              />
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "10px",
                  fontWeight: "500",
                  lineHeight: "12px",
                  textAlign: "left",
                  color: "#828282",
                }}
              >
                Once confirmed, the recipient will be notified
              </Typography>
            </Box>
          </Stack>

          {/* Select All Section */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "9.87px",
              position: "relative",
              width: "100%",
              mt: 2,
              p: "9.87px 9.87px 0.87px 4.93px",
              borderRadius: "14.8px 0px 0px 0px",
            }}
          >
            <CustomCheckbox
              checked={
                clientData && clientData.length > 0
                  ? selectedClients.length === clientData.length
                  : false
              }
              onChange={handleSelectAll} // Handle "Select All" toggle
            />
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "18px",
                letterSpacing: "0.05em",
                textAlign: "left",
                color: "#1B114A",
              }}
            >
              All Participants from Session
            </Typography>
          </Box>

          {/* Divider */}
          <Box
            sx={{
              border: "1px solid #D9E4EC",
              my: 2,
              mx: "auto",
              width: "100%",
            }}
          />

          {/* Client List */}
          <Box
            sx={{
              background: "#fff",
              borderRadius: "8px",
              maxHeight: "285px",
              overflowY: "auto",
              padding: "9px 3px",
            }}
          >
            {fileData && clientData && clientData.length > 0 ? (
              clientData.map((client, key) =>
                client.full_name ? (
                  <Box
                    key={key}
                    display="flex"
                    alignItems="center"
                    gap="12px"
                    padding={"9px 3px"}
                    borderRadius="4px"
                    sx={{
                      // If single-assignee highlight is needed:
                      background:
                        client.full_name === assignee.name
                          ? "#F5F5F5"
                          : "inherit",
                      "&:hover": {
                        background: "#F5F5F5",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <CustomCheckbox
                      checked={
                        Array.isArray(selectedClients) &&
                        selectedClients.some(
                          (item) => item.user_id === client.user_id
                        )
                      }
                      disabled={
                        (fileData &&
                          Array.isArray(fileData?.participants) &&
                          fileData?.participants.some(
                            (participant) =>
                              participant?.user_id === client?.user_id &&
                              participant?.shared === true
                          )) ||
                        (fileData &&
                          Array.isArray(fileData?.guest_shares) &&
                          fileData?.guest_shares.some(
                            (guest) => guest?.user_id === client?.user_id
                          ))
                      }
                      onChange={(e) =>
                        handleCheckboxChange(client, e.target.checked)
                      }
                    />
                    <Typography
                      sx={{
                        color: "#1B114A",
                        fontSize: "14px",
                        fontWeight: "300",
                        lineHeight: "24px",
                      }}
                    >
                      {client.full_name}
                    </Typography>
                  </Box>
                ) : null
              )
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "12px",
                  flexDirection: "column",
                  py: 2,
                }}
              >
                <Typography
                  sx={{
                    color: "#1B114A",
                    fontSize: "14px",
                    fontWeight: "300",
                    textAlign: "center",
                  }}
                >
                  Add Your First Team Member
                </Typography>
                <PersonAddAlt
                  sx={{
                    color: "black",
                    fontSize: "24px",
                    width: "24px",
                    height: "24px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowClientList(false); // close modal before navigating
                    navigate("/platform/team-portal");
                  }}
                />
              </Box>
            )}
          </Box>

          {/* Cancel / Confirm Buttons */}
          <Stack direction="row" spacing={2} mt={2}>
            <Button
              sx={{
                paddingX: "20pt",
                paddingY: "5pt",
                borderRadius: "50px",
                color: "#fff",
                background: "#0076BA",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  color: "#fff",
                  background: "#0076BA",
                },
              }}
              disabled={selectedClients?.length === 0}
              onClick={handleOpenAssignModal}
            >
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  fontSize: "10px",
                  fontWeight: "bold",
                  letterSpacing: "2px",
                  textTransform: "capitalize",
                }}
              >
                Confirm
              </Typography>
            </Button>
            <Button
              sx={{
                paddingX: "20pt",
                paddingY: "5pt",
                borderRadius: "50px",
                background: "#FFFFFF",
                color: "#B6B6B6",
                border: "1px solid #EFF3F6",
                fontWeight: "bold",
                "&:hover": {
                  background: "#FFFFFF",
                  border: "1px solid #EFF3F6",
                  color: "#B6B6B6",
                },
              }}
              onClick={handleCloseClientListModal}
            >
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  fontSize: "10px",
                  fontWeight: "bold",
                  letterSpacing: "2px",
                  textTransform: "capitalize",
                }}
              >
                Cancel
              </Typography>
            </Button>
          </Stack>
        </Box>
      </Modal>
    );
  };

  const IdentifyParticipantsModal = () => {
    const unmatchedParticipants =
      fileData?.participants?.filter((p) => {
        const matchedParticipant = Array.isArray(listparticipants.participants)
          ? listparticipants.participants.find(
              (lp) => lp.participant_id === p.participant_id
            )
          : null;

        if (p.user_id) {
          return false;
        }

        if (p.match_status === "guest" || p.match_status === "matched") {
          return false;
        }

        if (
          matchedParticipant &&
          (matchedParticipant.match_status === "guest" ||
            matchedParticipant.match_status === "matched")
        ) {
          return false;
        }

        return true;
      }) || [];

    if (!unmatchedParticipants.length) return null;
    const currentParticipant = unmatchedParticipants[currentIndex];

    // Close the modal
    const closeModal = () => {
      setOpenIdentifyModal(false);
      fetchFileData();
    };

    // Mark participant as guest
    const markAsGuest = async (participantId) => {
      try {
        setLoadingIdentify(true);
        await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/mark-participant-guest`,
          {
            participant_id: participantId,
          }
        );
        handleNavigateNext();
      } catch (error) {
        console.error("Error marking participant as guest:", error);
        toast.error("Failed to mark participant as guest");
      } finally {
        setLoadingIdentify(false);
      }
    };

    // Identify participant with user
    const identifyParticipant = async (participantId, userId) => {
      try {
        setLoadingIdentify(true);
        await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/identify-participant`,
          {
            participant_id: participantId,
            user_id: userId,
          }
        );
        handleNavigateNext();
      } catch (error) {
        console.error("Error identifying participant:", error);
        toast.error("Failed to identify participant");
      } finally {
        setLoadingIdentify(false);
      }
    };

    // Move to the next participant, or close
    const handleNavigateNext = () => {
      setSelectedClientId(null);
      if (currentIndex < unmatchedParticipants.length - 1) {
        setCurrentIndex(currentIndex + 1);
      } else {
        // Last participant => close
        closeModal();
      }
    };

    const displayName =
      currentParticipant?.display_name || "Unknown Participant";

    return (
      <Modal open={openIdentifyModal} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "415px",
            height: "510px",
            maxWidth: "90%",
            background: "#FFFFFF",
            borderRadius: "16px",
            boxShadow: 24,
            padding: "24px 28px",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography
            sx={{
              color: "#828282",
              fontFamily: "Poppins",
              fontSize: "9.207px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "13.811px",
              letterSpacing: "0.46px",
            }}
          >
            {currentIndex + 1} of {unmatchedParticipants.length}
          </Typography>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Lexend",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            Identify the Participant
          </Typography>

          {/* Participant avatar + name */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Avatar
              sx={{
                width: 40,
                height: 40,
                backgroundColor: "#FDE8E8",
                color: "#555",
                fontWeight: 600,
              }}
            >
              {displayName
                .split(" ")
                .map((w) => w[0])
                .join("")
                .toUpperCase()}
            </Avatar>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: "#000",
              }}
            >
              {displayName}
            </Typography>
          </Box>

          <Box
            sx={{
              width: "366px",
              maxWidth: "100%",
              height: "0px",
              border: "1px solid #D9E4EC",
              marginY: 2,
              alignSelf: "center",
            }}
          />
          <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
          >

          {/* Client List (single-select) */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                maxHeight: "285px",
                overflowY: "auto",
                background: "#fff",
                borderRadius: "8px",
                padding: "8px",
              }}
            >
              {clientData && clientData.length > 0 ? (
                clientData.map((client) => (
                  <Box
                    key={client.user_id}
                    display="flex"
                    alignItems="center"
                    gap="8px"
                    sx={{
                      borderRadius: "6px",
                      padding: "4px",
                      mb: "4px",
                      "&:hover": {
                        background: "#F5F5F5",
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => setSelectedClientId(client.user_id)}
                  >
                    <CustomCheckbox
                      checked={selectedClientId === client.user_id}
                    />
                    <Typography sx={{ fontSize: "14px", color: "#000" }}>
                      {client.full_name}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "12px",
                    flexDirection: "column",
                    py: 2,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#1B114A",
                      fontSize: "14px",
                      fontWeight: "300",
                      textAlign: "center",
                    }}
                  >
                    No clients found
                  </Typography>
                  <PersonAddAlt
                    sx={{ color: "black", fontSize: "24px", cursor: "pointer" }}
                    onClick={() => {
                      setOpenIdentifyModal(false);
                      // Navigate to add team member
                      // e.g. navigate("/platform/team-portal");
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>

          {/* Buttons at the bottom */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              justifyContent: "flex-start",
              marginTop: "8px",
            }}
          >
            {/* Identify as Client */}
            <Button
              disabled={!selectedClientId || loadingIdentify}
              onClick={() => {
                if (selectedClientId) {
                  identifyParticipant(
                    currentParticipant.participant_id,
                    selectedClientId
                  );
                }
              }}
              sx={{
                display: "flex",
                width: "134.983px",
                height: "31.871px",
                padding: "9.374px 14.061px",
                justifyContent: "center",
                alignItems: "center",
                gap: "9.374px",
                borderRadius: "46.869px",
                border: "0.937px solid var(--Box-Gray, #D9E4EC)",
                background: "#FFF",
                color: "#000",
                fontFamily: "Poppins",
                fontSize: "11.249px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "16.873px",
                letterSpacing: "0.562px",
                textTransform: "capitalize",
                "&:hover": { background: "#fff" },
                "&:disabled": {
                  backgroundColor: "#FFF",
                  color: "#C2C9CF",
                  cursor: "not-allowed",
                },
              }}
            >
              confirm
            </Button>

            {/* Mark as Guest */}
            <Button
              disabled={loadingIdentify}
              onClick={() => markAsGuest(currentParticipant.participant_id)}
              sx={{
                display: "flex",
                width: "134.983px",
                height: "31.871px",
                padding: "9.374px 14.061px",
                justifyContent: "center",
                alignItems: "center",
                gap: "9.374px",
                borderRadius: "46.869px",
                border: "0.937px solid var(--Box-Gray, #D9E4EC)",
                background: "rgba(33, 111, 246, 0.13)",
                textTransform: "none",
                color: "#000",
                fontWeight: 500,
                fontSize: "12px",
                "&:hover": {
                  background: "rgba(33, 111, 246, 0.13)",
                  color: "#000",
                  border: "0.937px solid var(--Box-Gray, #D9E4EC)",
                },
              }}
            >
              Mark as Guest
            </Button>
          </Box>
          </Box>
        </Box>
      </Modal>
    );
  };

  if (loading || loadingFile) {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  //change from here
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
        backgroundColor: "#EFF3F6",
        position: "relative",
        gap: "6px",
        overflowY: "auto",
        display: {
          xs: !collapsed ? "none" : "flex",
          md: !collapsed ? "none" : "flex",
        },
        padding: {
          xs: "40px 16px",
          sm: "30px 24px",
          lg: "16px",
        },
      }}
    >
      {/* ---------------------------
          LEFT SIDE SECTION
      ----------------------------*/}
      <Box
        sx={{
          width: { xs: "100%", sm: "80%", md: "25%" },
          height: "100%",
          position: "relative",
          padding: { xs: "12px", md: "16px" },
          backgroundColor: "#fff",
          borderRadius: "32px",
        }}
      >
        {/* Back button */}
        <Button
          sx={{
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
            width: "90px",
            height: "28px",
            background: "#FFFFFF",
            border: "1px solid #D9E4EC",
            borderRadius: "50px",
            marginBottom: "16px",
            padding: "10px 15px",
          }}
          onClick={() => navigate("/platform/coach")}
        >
          <SessionPageBack />
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "18px",
              letterSpacing: "0.05em",
              textTransform: "capitalize",
              color: "#000000",
              fontWeight: "500px",
            }}
          >
            Back
          </Typography>
        </Button>

        {/* Call Name */}
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: { xs: "18px", md: "21px" },
            fontWeight: 400,
            lineHeight: "24px",
            color: "#1E1B39",
            marginBottom: "8px",
          }}
        >
          {callName}
        </Typography>

        {/* Video player */}
        <Box
          sx={{
            width: "100%",
            height: { xs: "auto", md: "189px" },
            marginBottom: "16px",
            marginTop: "16px",
            display: "flex",
          }}
        >
          <Box
            sx={{
              marginBottom: "16px",
              width: { xs: "100%", sm: "100%" },
              height: { xs: "auto", sm: "100%" },
              "& video": {
                width: "100%",
                height: "100%",
                objectFit: "cover",
              },
            }}
          >
            <VideoPlayerCard videoUrl={videoUrl} />
          </Box>
        </Box>

        {/* Date & Duration */}
        <Box display="flex" alignItems="center" gap="40px" marginBottom="6px">
          <Typography
            sx={{
              fontFamily: "Lexend",
              fontSize: "11px",
              lineHeight: "18px",
              color: "#4A4A4A",
              fontWeight: "400px",
            }}
          >
            Date: {formattedZoomDate}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Lexend",
              fontSize: "11px",
              lineHeight: "18px",
              color: "#4A4A4A",
              fontWeight: "400px",
            }}
          >
            Duration: {duration} mins
          </Typography>
        </Box>

        {/* Call Status and Share functionality */}
        {userData?.accessTo === "platform" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              marginBottom: "8px",
            }}
          >
            {/* Top row with Status left and Share option right */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  fontSize: "11px",
                  color: "#4A4A4A",
                  lineHeight: "18px",
                  fontWeight: "400px",
                }}
              >
                Status: {status === "assigned" ? "Shared" : "Ready to Share"}
              </Typography>
            </Box>
          </Box>
        )}

        {/* A simple divider (if needed) */}
        <Box
          sx={{
            width: "100%",
            height: "0px",
            borderTop: "1.5px solid #EFF3F6",
            borderRadius: "16px 0px 0px 0px",
            opacity: 1,
          }}
        />

        {/* Participants */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            marginTop: "16px",
            overflow: "auto",
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* */}
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <TwoUsersIcon width={22} height={22} />
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#000000",
                }}
              >
                {`${
                  fileData ? fileData?.participants?.length + 1 : ""
                } Participants`}
              </Typography>
            </Box>
            {((status === "assigned" || status === "ReadyToPublish") && (user?.sub === fileData?.user_id)) && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  padding: "4px 7px",
                  transition: "all 0.3s",
                  borderRadius: "8px", // Add border-radius for a rounded effect
                  "&:hover": { cursor: "pointer", backgroundColor: "#F5F7FF" },
                }}
                onClick={() => {
                  if (status === "assigned" || status === "ReadyToPublish") {
                    setShowClientList(!showClientList);
                  }
                }}
              >
                <UserCheck color="enabled" height={20} width={20} />
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontSize: "10px",
                    fontWeight: "500px",
                    letterSpacing: "0.4px",
                    lineHeight: "12px",
                    color: "black",
                  }}
                >
                  Share
                </Typography>
              </Box>
            )}
          </Box>
          {/* Client List Popup */}

          {/* The remaining participants UI */}
          <Box
            display="flex"
            flexDirection="column"
            gap="10px"
            height={"330px"}
            overflowY="auto"
          >
            <Typography
              sx={{
                fontFamily: "Lexend",
                fontWeight: "300",
                fontSize: "12px",
                lineHeight: "18px",
                color: "#4B4B4B",
              }}
            >
              Speakers
            </Typography>
            <Box
              sx={{
                overflowY: "auto",
                width: "100% ",
                gap: "17px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {fileData && fileData?.coach_name && (
                <Box display="flex" alignItems="center" gap="8px">
                  <Avatar
                    src={fileData?.coach_avatar || undefined} // Use undefined if no avatar is available
                    sx={{
                      width: "24px",
                      height: "24px",
                      fontSize: "12px", // Adjust font size for initials
                      fontWeight: "600",
                      fontFamily: "Inter",
                      color: "#535862",
                      backgroundColor: !fileData?.coach_avatar && "#E9E9E9", // Optional background color if no avatar
                    }}
                  >
                    {!fileData?.coach_avatar && fileData?.coach_name
                      ? fileData.coach_name
                          .split(" ") // Split the full name into words
                          .map((word) => word[0]) // Take the first letter of each word
                          .join("") // Join them to form initials
                          .toUpperCase() // Convert to uppercase
                      : ""}
                  </Avatar>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "black",
                      fontFamily: "lexend",
                      fontWeight: "400px",
                      lineHeight: "18px",
                    }}
                  >
                    {fileData?.coach_name || "Me"}
                  </Typography>
                </Box>
              )}
              {fileData &&
                fileData?.participants?.length > 0 &&
                fileData?.participants?.map((participant) => (
                  <>
                    <Box display="flex" alignItems="center" gap="6px">
                      <Avatar
                        src={participant?.avatar || undefined}
                        sx={{
                          width: "24px",
                          height: "24px",
                          fontSize: "12px",
                          fontWeight: "600",
                          fontFamily: "Inter",
                          color: "#535862",
                          backgroundColor: (() => {
                            // Find the row for this participant in the "listparticipants.participants" array
                            const matchedParticipant = Array.isArray(
                              listparticipants.participants
                            )
                              ? listparticipants.participants.find(
                                  (lp) =>
                                    lp.participant_id ===
                                    participant.participant_id
                                )
                              : null;

                            if (
                              !participant?.user_id &&
                              matchedParticipant &&
                              matchedParticipant.match_status !== "guest" &&
                              matchedParticipant.match_status !== "matched"
                            ) {
                              return "#FDE8E8";
                            } else {
                              // Otherwise, gray background
                              return "#E9E9E9";
                            }
                          })(),
                        }}
                      >
                        {/* If you have no avatar, show initials */}
                        {!participant?.avatar &&
                          (participant?.name.trim()
                            ? participant.name
                                .split(" ")
                                .map((word) => word[0])
                                .join("")
                                .toUpperCase()
                            : participant?.display_name
                            ? participant.display_name
                                .split(" ")
                                .map((word) => word[0])
                                .join("")
                                .toUpperCase()
                            : "")}
                      </Avatar>

                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "black",
                          fontFamily: "lexend",
                          fontWeight: "400px",
                          lineHeight: "18px",
                        }}
                      >
                        {participant?.name?.trim() ||
                          participant?.display_name ||
                          "Unknown"}
                      </Typography>
                    </Box>
                  </>
                ))}
            </Box>
            {fileData &&
              fileData?.participants?.filter(
                (participant) => !participant?.user_id
              ).length > 1 &&
              Array.isArray(listparticipants.participants) &&
              listparticipants.participants.some(
                (lp) =>
                  lp.match_status !== "guest" && lp.match_status !== "matched"
              ) && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "#FDE8E8",
                      width: "90%",
                      borderRadius: "50px",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // e.g. open your Identify Modal
                      setOpenIdentifyModal(!showClientList);
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: "500",
                        fontSize: "12px",
                        lineHeight: "18px",
                        letterSpacing: "0.6px",
                        color: "#D11104",
                        paddingX: "4px",
                        paddingY: "2px",
                        borderRadius: "4px",
                      }}
                    >
                      Identify Participant
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      color: "#D11104",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "10px",
                      lineHeight: "18px",
                      letterSpacing: "0.4px",
                    }}
                  >
                    One or more participants could not be identified.
                  </Typography>
                </Box>
              )}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          width: { xs: "100%", sm: "100%", md: "75%" },
          height: "100%",
          backgroundColor: "#fff",
          padding: { xs: "8px 12px", md: "10px 20px" },
          borderRadius: "32px",
          display: "flex",
          flexDirection: "column",
          overflowY: "hidden",
          display: {
            xs: !collapsed ? "none" : "block",
            md: !collapsed ? "none" : "block",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            marginBottom: "24px",
            width: "100%",
            height: "42px",
            top: "35px",
            left: "665px",
            padding: "4px 4px",
            borderRadius: "32px",
            backgroundColor: "#EFF3F6",
          }}
        >
          <Button
            onClick={() => setActiveTabright("analysis")}
            sx={{
              height: "34px",
              width: { xs: "33%", md: "40%" },
              textTransform: "none",
              padding: { xs: "8px 12px", md: "10px 51px " },
              border:
                activeTabright === "analysis" ? "1px solid #D9E4EC" : "none",
              borderRadius: "50px",
              backgroundColor:
                activeTabright === "analysis" ? "#216FF6" : "#EFF3F6",
              color: activeTabright === "analysis" ? "#FFFFFF" : "#000000",
              boxShadow:
                activeTabright === "analysis"
                  ? "0px 4px 10.7px -1px #0000001A"
                  : "none",
              "&:hover": {
                backgroundColor:
                  activeTabright === "analysis" ? "#216FF6" : "#EFF3F6",
              },
            }}
          >
            {activeTabright === "analysis" ? (
              <AnalysisSessionpage sx={{ color: "#FFFFFF" }} />
            ) : (
              <Analysiswhite sx={{ color: "#000000" }} />
            )}{" "}
            <Box sx={{ width: "10px" }} />
            Communication Analysis
          </Button>

          <Button
            onClick={() => setActiveTabright("actions")}
            sx={{
              height: "34px",
              width: { xs: "33%", md: "30%" },
              textTransform: "none",
              padding: { xs: "8px 12px", md: "10px 51px" },
              border:
                activeTabright === "actions" ? "1px solid #D9E4EC" : "none",
              borderRadius: "50px",
              backgroundColor:
                activeTabright === "actions" ? "#216FF6" : "#EFF3F6",
              color: activeTabright === "actions" ? "#FFFFFF" : "#000000",
              boxShadow:
                activeTabright === "actions"
                  ? "0px 4px 10.7px -1px #0000001A"
                  : "none",
              "&:hover": {
                backgroundColor:
                  activeTabright === "actions" ? "#216FF6" : "#EFF3F6",
              },
            }}
          >
            {activeTabright === "actions" ? (
              <SumaryWhite sx={{ color: "#000000" }} />
            ) : (
              <ActionSession
              />
            )}
            <Box sx={{ width: "10px" }} />
            Actions
          </Button>

          <Button
            onClick={() => setActiveTabright("summary")}
            sx={{
              height: "34px",
              width: { xs: "33%", md: "30%" },
              textTransform: "none",
              border:
                activeTabright === "summary" ? "1px solid #D9E4EC" : "none",
              borderRadius: "50px",
              padding: { xs: "8px 12px", md: "10px 51px" },
              backgroundColor:
                activeTabright === "summary" ? "#216FF6" : "#EFF3F6",
              color: activeTabright === "summary" ? "#FFFFFF" : "#000000",
              boxShadow:
                activeTabright === "summary"
                  ? "0px 4px 10.7px -1px #0000001A"
                  : "none",
              "&:hover": {
                backgroundColor:
                  activeTabright === "summary" ? "#216FF6" : "#EFF3F6",
              },
            }}
          >
            {activeTabright === "summary" ? (
              <ActionWhite sx={{ color: "#FFFFFF" }} />
            ) : (
              <SummarySession sx={{ color: "#000000" }} />
            )}
            <Box sx={{ width: "10px" }} />
            Call Summary
          </Button>
        </Box>
        {/* TAB CONTENT */}
        <Box
          sx={{
            width: "100%",
            height: "90%",
            overflowY: "auto",
            pr: "8px",
            flex: 1,
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgb(207, 207, 207)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "rgb(207, 207, 207)",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
          }}
        >
          {activeTabright === "analysis" && (
            <Box
              sx={{
                overflowY: "auto",
              }}
            >
              {/* Communication Analysis content here */}
              {/* e.g. LaraSessionPage from your snippet */}
              <LaraSessionPage
                laraData={laraData}
                fileData={fileData}
                fetchUserData={fetchFileData}
                setCallActions={setCallActions}
                callActions={callActions}
                quickRecap={quickRecap}
                listparticipants={listparticipants}
              />
            </Box>
          )}

          {activeTabright === "actions" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: { xs: "100%", md: "calc((100% / 3) * 3)" },
                height: "100%",
              }}
            >
              {/* Actions content here—if you also use SessionDetails, place it or your custom component */}
              <SessionActions
                user={user}
                fileData={fileData}
                setCallActions={setCallActions}
                actionsArray={callActions}
                labels={labels}
                coachName={coachName}
                clientShared={clientShared}
                userData={userData}
                listparticipants={listparticipants}
              />
            </Box>
          )}

          {activeTabright === "summary" && (
            <Box
              sx={{
                flex: 1,
                minHeight: 0,
                marginBottom: "16px",
                overflowY: "auto",
                pr: "8px",
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "rgb(207, 207, 207)",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "rgb(207, 207, 207)",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <SessionDetails
                user={user}
                fileData={fileData}
                quickRecap={quickRecap}
                setQuickRecap={setQuickRecap}
                setCallActions={setCallActions}
                actionsArray={callActions}
                labels={labels}
                summaries={summaries}
                coachName={coachName}
                clientShared={clientShared}
              />
            </Box>
          )}
        </Box>
      </Box>

      {editActionModal()}
      {editQuickRecapModal()}
      {ClientListModal()}
      <CallShare
        user={user}
        userData={userData}
        callData={fileData}
        selectedUsers={selectedClients}
        setSelectedUsers={setSelectedClients}
        openAssignModal={openAssignModal}
        setOpenAssignModal={setOpenAssignModal}
        fetchUserData={fetchFileData}
      />
      {IdentifyParticipantsModal()}
    </Box>
  );
};

export default SessionPage;
