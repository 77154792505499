/**
 * Conditions for enabling action buttons based on roles.
 */
export const actionButtonConditions = {
    OWNER: (selectedUser, user, companyInfo, myPrivateUsers) => {
        if (!selectedUser) {
        console.warn("Row is undefined in OWNER check.");
        return false;
        }

        // Apply the condition: disable action button for private users not in myPrivateUsers
        return !(selectedUser?.role === "private" && !myPrivateUsers?.includes(selectedUser?.user_id));
    },

    SUPERADMINS: (selectedUser, user, companyInfo, myPrivateUsers) => {
      // Ensure row is defined
      if (!selectedUser) {
        console.warn("Row is undefined in SUPERADMINS check.");
        return false;
      }
  
      return !(
        (selectedUser?.user_id === companyInfo?.owner_user_id && user?.sub !== companyInfo?.owner_user_id) ||
        (selectedUser?.role === "private" && !myPrivateUsers?.includes(selectedUser?.user_id))
      );
    },

    ADMINS: (selectedUser, user, companyInfo, myPrivateUsers, myTeamsInformation) => {
        if (!selectedUser) {
          console.warn("Row is undefined in ADMINS check.");
          return false;
        }
    
        // Reuse the isRowSelectable logic
        const isNotOwner = selectedUser.user_id !== companyInfo?.owner_user_id;
        const isInMyTeams = myTeamsInformation?.some((team) =>
          team?.members?.some((member) => member?.user_id === selectedUser?.user_id)
        );
        const isInMyPrivateUsers = myPrivateUsers?.includes(selectedUser?.user_id);
    
        return isNotOwner && (isInMyTeams || isInMyPrivateUsers);
    },
    USERS: (selectedUser, user, companyInfo, myPrivateUsers) => {
        if (!selectedUser) {
          console.warn("Row is undefined in USERS check.");
          return false;
        }
    
        const isNotOwner = selectedUser.user_id !== companyInfo?.owner_user_id;
        const isInMyPrivateUsers = myPrivateUsers?.includes(selectedUser?.user_id);
    
        return isNotOwner && (isInMyPrivateUsers);
      },
  };
  
  /**
   * Determines if the Actions button should be enabled.
   *
   * @param {Array} selected - The currently selected rows in the table.
   * @param {object} selectedUser - The full data of the selected user.
   * @param {string} userRole - The role of the current user.
   * @param {object} user - The current user's information.
   * @param {object} companyInfo - Information about the company.
   * @param {Array} myPrivateUsers - List of private users for the current user.
   * @returns {boolean} - Whether the Actions button should be enabled.
   */
  export const isActionButtonEnabled = (selected, user, companyInfo, myPrivateUsers, myTeamsInformation) => {
    if (!selected || selected.length !== 1) return false; // Only allow actions when a single row is selected
  
    const selectedUser = selected[0]; // Single selected row
    if (!selectedUser) {
      console.warn("Selected row is undefined.");
      return false;
    }
  
    const evaluateCondition = actionButtonConditions[user?.role]; // Get conditions for the role
  
    if (!evaluateCondition) {
      console.warn(`No conditions defined for role: ${user?.role}`);
      return false; // Default to disabled if no conditions are defined
    }
  
    return evaluateCondition(selectedUser, user, companyInfo, myPrivateUsers, myTeamsInformation);
  };
  