import React, { useState } from "react";

import { Modal, Box, Button, Typography, Divider } from "@mui/material"
import axios from "axios";
import {
    ErrorOutline,
    Check,
  } from "@mui/icons-material";
import DeclineButton from "components/DeclineButton";
import ConfirmButton from "components/ConfirmButton";
import { toast } from "react-toastify";



const UnshareCall = ( {user, userData, selected, openUnshareModal, setOpenUnshareModal, fetchUserData, reFetchUserData}) => {

    const [loadingUnshare, setLoadingUnshare] = useState(false);

    const closeUnshareModal = () => {
        setOpenUnshareModal(false);
    };

    //function to call endpoint and unshare calls
    const unshareCalls = async () => {
    try {
        setLoadingUnshare(true);
        if (selected?.length === 0) {
            throw new Error("No selected calls"); 
        }

        const callIds = selected.map((call) => call.call_id);
        const res = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/zoom/unassign-calls`,
            { call_ids: callIds } // Pass the array of call_ids
          );

        if(res.status === 200){
            toast.success("Sessions unshared successfully.")
        };

    } catch (error) {
        console.log("error: ", error)
        toast.error("Error while unsharing sessions. Please try again.")
    } finally{
        reFetchUserData();
        setLoadingUnshare(false);
        closeUnshareModal();
    }
    }

    return(
        <Modal open={openUnshareModal} onClose={closeUnshareModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            padding: "20px 20px 10px",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="#160042" fontWeight="400">
              Are you sure you want to unshare these session(s)?
            </Typography>
            <Box display="flex" gap="8px" alignItems="center">
              <ErrorOutline
                sx={{
                  color: "#CC5F0A",
                }}
              />
              <Typography color="#6B7094" fontWeight="400">
                All actions and LARA will be unassigned.
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              gap: { xs: "8px", md: "15px" },
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <DeclineButton onClick={closeUnshareModal} label={"No"} />
            <ConfirmButton onClick={unshareCalls} label={"Yes"} />
          </Box>
        </Box>
        </Modal>
    )
}

export default UnshareCall;