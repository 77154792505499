import React, { useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, CircularProgress, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import TheBigFiveHistoryModal from "./TheBigFive/TheBigFiveHistoryModal";
import AssessmentsHistoryIcon from "../../components/Icons/AssessmentHistory.svg";
import avatar from "assets/avatar.png";
import AssessmentsTableCustom from "components/Table/CustomTable";
import { OrangeStatusPill } from "components/Table/StatusPills";

const AssessmentsTable = ({ user, individualData, loading, viewAssessment, setViewAssessment }) => {
  const navigate = useNavigate();
  const [openHistoryModal, setOpenHistoryModal] = useState(false); // Modal open state
  const [selectedAssessment, setSelectedAssessment] = useState(null); // Selected assessment state

  const handleOpenHistoryModal = (assessment) => {
    setSelectedAssessment(assessment); // Set the selected assessment
    setOpenHistoryModal(true); // Open the modal
  };

  const handleCloseHistoryModal = () => {
    setOpenHistoryModal(false); // Close the modal
    setSelectedAssessment(null); // Clear the selected assessment
  };

  const columns = [
    {
      field: "user_name",
      headerName: "Name",
      flex:  0.8,
      renderCell: (params) => (
        <>
            <Box
              sx={{
                borderRadius: "50%",
                width: "24px",
                height: "24px",
                marginRight: "6px"
              }}
            >
              <Avatar
                sx={{
                  width: "26px",
                  height: "26px",
                }}
                src={params.row.user_avatar || avatar}
                alt={params.value || "Default Avatar"}
                onError={(e) => {
                  e.target.src = avatar; // Fallback to default avatar
                }}
              />
            </Box>
          {params.value || "Unknown Coachee"}
        </>
      ),
    },
    {
      field: "completion_date",
      headerName: "Completion Date",
      flex: 0.7,
      renderCell: (params) => {
        const completed = params.row.completed;
        const updatedAt = params.row.updatedAt;
        const createdAt = params.row.createdAt;
    
        return (
          <div
            style={{
              fontSize: "14px", 
              fontFamily: 'Lexend', 
              fontWeight: '400', 
              whiteSpace: "normal",
              wordWrap: "break-word",
            }}
          >
            {completed
              ? new Date(updatedAt).toLocaleDateString()
              : `Sent ${formatDistanceToNow(new Date(createdAt))} ago`}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params) => {
        const completed = params.row.completed;
  
        return (  
          <>
            {completed ? "Complete" : "Incomplete"}
          </>
        );
      },
    },
    {
      field: "result",
      headerName: "Result",
      flex: 0.4,
      renderCell: (params) => {
        const { completed, id, user_id, test_type, coach_id } = params.row;
        const isSelected = viewAssessment && viewAssessment.id === params.row.id;
        if (completed) {
          return (
            <Button
              sx={{
                backgroundColor: isSelected ? "#49CA7A": "#B4FFD2",
                "&:hover": {
                  backgroundColor: isSelected ? "#3EA562" : "#98E7B8", // Slightly darker shade
                },
                transition: "background-color 0.2s ease, opacity 0.2s ease",
                borderRadius: "12px",
                textTransform: "capitalize",
                paddingX: "6pt",
                paddingY: "2pt"
              }}
              variant="contained"
              onClick={() => setViewAssessment(params.row)}
            >
              <Typography
                sx={{
                    textAlign: 'center',
                    color: isSelected ? "black" : '#00643A', 
                    fontSize: "14px", 
                    fontFamily: 'Inter', 
                    fontWeight: '500', 
                    wordWrap: 'break-word'
                }}
                data-cy="view-assessment"
              >
                View
              </Typography>
            </Button>
          );
        } else if (user?.sub === user_id) {
          return (
            <Button
              sx={{
                color: "black",
                background: "white",
                borderRadius: "60px",
                textTransform: "capitalize",
              }}
              variant="outlined"
              onClick={() =>
                navigate(`/platform/coachee/assessments/${test_type}/${coach_id}/${id}`)
              }
            >
              My Test
            </Button>
          );
        } else {
          return (
            <Typography
              sx={{
                color: "#93989A",
                fontSize: "15px",
                fontFamily: "Lexend",
                textTransform: "capitalize",
              }}
            >
              <OrangeStatusPill text={"Pending"}/>
            </Typography>
          );
        }
      },
    },
    {
      field: "history",
      headerName: "",
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        const { total_completed_assessments_count } = params.row;
  
        if (total_completed_assessments_count > 0) {
          return (
            <Box
            SX={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%"
            }}
            >
            <Button
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={() => handleOpenHistoryModal(params.row)}
            >
              <AssessmentsHistoryIcon />
            </Button>
            </Box>
          );
        }
        return null;
      },
    },
  ];

  return (
    <Box sx={{ height: "100%" }}>
      {loading ? (
        <Box sx={{ display: "flex", padding: "10px", width: "100%", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress sx={{ color: "black" }} />
        </Box>
      ) : (
        <>

          <AssessmentsTableCustom 
            rows={individualData}
            columns={columns}
            pageSize={25}
            rowsPerPageOptions={[10, 20, 25, 30]}
            rowHeight={70} 
            getRowId={(row) => row.key} // Use the key added to filtered assessments
            checkboxSelection={false}
            loading={loading}
            disableColumnFilter
            disableSelectionOnClick
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            localeText={{
              noRowsLabel: "No data available",
            }}
          />



          {/* The History Modal */}
          {selectedAssessment && (
            <TheBigFiveHistoryModal
              open={openHistoryModal}
              handleClose={handleCloseHistoryModal}
              userId={selectedAssessment.user_id}
              assessmentId={selectedAssessment.id}
              userName={selectedAssessment.user_name}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default AssessmentsTable;

