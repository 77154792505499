import React, { useEffect, useState } from "react";
import { PaymentContext } from "./PaymentContext";
import { Hub } from "aws-amplify/utils";
import { useCheckClientInviteAllowance } from "hooks/useCheckClientInviteAllowance";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { generateClient } from "aws-amplify/data";
import { getCurrentUser } from "aws-amplify/auth";

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */

const resourceClient = generateClient({ authMode: "userPool" });

export const PaymentProvider = ({ children }) => {
  const location = useLocation();
  const [userData, setUserData] = useState({});
  const { checkClientInviteAllowance } = useCheckClientInviteAllowance();
  const [user, setUser] = useState({});

  const fetchUser = async () => {
    try {
      const userObj = await getCurrentUser();
      setUser(userObj);
    } catch (error) {
      console.log("Error fetching current user:", error);
    }
  };

  useEffect(() => {
    if (!user || !user.userId) {
      fetchUser();
    }
  }, [user]);

  const fetchUserAttributes = async (userID) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-user-attributes`,
        {
          params: { user_id: userID },
        }
      );

      const userAttributesResponse = response.data;

      if (!userAttributesResponse) {
        return;
      }

      const {
        data: userAttributes,
        companyInformation,
        teams,
        myTeams,
        allCompanies
      } = userAttributesResponse;

      setUserData((prevData) => ({
        ...prevData,
        accessTo: userAttributes?.access_to || null,
        companyInformation: companyInformation || null,
        allCompanies: allCompanies || null,
        teamsInformation: teams || null,
        myTeamsInformation: myTeams || null,
      }));

      return userAttributesResponse;
    } catch (error) {
      console.error("Error fetching user attributes:", error);
    }
  };

  const fetchPayments = async () => {
    try {
      const userObj = await getCurrentUser();
      const userId = userObj.userId;
      const userAttributesResponse = await fetchUserAttributes(userId);

      const {
        companyInformation,
      } = userAttributesResponse || {};

      let paymentUserId = userId; // Default to current user's ID

      // If user is associated with a company, use the owner's user_id to fetch payments
      if (
        companyInformation &&
        companyInformation.owner &&
        companyInformation.owner.user_id
      ) {
        paymentUserId = companyInformation.owner.user_id;
      }

      const { data: payments } =
        await resourceClient.models.payments.list({
          filter: {
            user_id: {
              eq: paymentUserId,
            },
          },
          limit: 1500,
          selectionSet: [
            "stripe_customer_id",
            "user_id",
            "coaching_platform",
            "coaching_platform_tier",
            "customer",
            "virtual_coach",
          ],
        });


      const clientCounter = await checkClientInviteAllowance(userId);

      if (payments && payments[0]) {
        const payment = payments[0];
        setUserData((prevData) => ({
          ...prevData,
          paymentInfo: {
            stripeCustomerId: payment?.stripe_customer_id || null,
            allowAccessToPlatform:
              (payment?.coaching_platform && payment?.customer) || null,
            allowAccessToBot:
              (payment?.virtual_coach && payment?.customer) || null,
            isCustomer: payment?.customer || null,
            coachingPlatformTier: payment?.coaching_platform_tier || null,
          },
          isProfessionalTier:
            payment?.coaching_platform_tier === "personal" || null,
          isClientCountLimitReached: clientCounter
            ? clientCounter?.clientCount > clientCounter?.clientTierAllowance
            : false,
        }));
      } else {
        setUserData((prevData) => ({
          ...prevData,
          paymentInfo: {
            allowAccessToBot: false,
            allowAccessToPlatform: false,
          },
          isProfessionalTier: false,
        }));
      }
    } catch (error) {
      console.log("Error fetching payments:", error);
    }
  };

  useEffect(() => {
    if (user && user.userId) {
      fetchPayments();
    }
  }, [user]);

  useEffect(() => {
    if (
      user &&
      (location.pathname === "/platform/coach" ||
        location.pathname === "/platform/coachee" ||
        location.pathname === "/platform/team-portal" ||
        location.pathname === "/virtualcoach/bot")
    ) {
      fetchPayments();
    }
  }, [user, location.pathname]);

  useEffect(() => {
    const hubListener = (data) => {
      if (
        data.payload.event === "signIn" ||
        data.payload.event === "signInWithRedirect"
      ) {
        fetchPayments();
      } else if (data.payload.event === "signOut") {
        // Clear the context or set to default values
        setUserData({});
      }
    };

    const removeListener = Hub.listen("auth", hubListener);

    return () => {
      // Clean up the listener by calling the returned function
      removeListener();
    };
  }, []);

  return (
    <PaymentContext.Provider value={{ userData, user }}>
      {typeof children === "function" ? children({ user, userData }) : children}
    </PaymentContext.Provider>
  );
};