import {
  Modal,
  Box,
  Button,
  TextField,
  Typography,
  Chip,
  Grid,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useState } from "react";
import axios from "axios";
import ConfirmButton from "components/ConfirmButton";
import AddIcon from "@mui/icons-material/Add"; // Import Add Icon
import { toast } from "react-toastify";

import { generateClient } from "aws-amplify/data";
import { KeyboardArrowDown } from "@mui/icons-material";

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });

const AdminAddMemberModal = ({
  user,
  userData,
  openAddMemberModal,
  setOpenAddMemberModal,
  companyInformation,
  teamsInformation,
  myTeamsInformation,
  fetchCompanyData,
  companyMembers,
  internalCompanyMembers
}) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [invites, setInvites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null); // New state to hold selected team
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedManager, setSelectedManager] = useState(null);
  const CustomIcon = (props) => (
    <KeyboardArrowDown {...props} style={{ color: "black" }} />
  );

  // Add invite function
  const handleAddInvite = () => {
    if (!firstName || !lastName) {
      setErrorMessage("Please provide a first and last name");
      return;
    }
    if (!selectedTeam) {
      setErrorMessage("Please select a team");
      return;
    }

    if (email && validateEmail(email) && selectedTeam) {
      const emailExists = invites.some(
        (invite) => invite.email === email.trim()
      );
      const emailExistsInCompanyMembers = companyMembers.some(
        (member) => member.email === email.trim()
      );

      if (emailExists) {
        setErrorMessage("Email has already been added");
        return;
      }

      if (emailExistsInCompanyMembers) {
        setErrorMessage("A member already exists with this email");
        return;
      }

      // Add invite to the list
      setInvites([
        ...invites,
        {
          email: email.trim(),
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          isAdmin: false,
          title: title.trim(),
          selectedTeam: selectedTeam.team_name, // Use selected team name
          selectedTeamId: selectedTeam.team_id, // Use selected team id
          managerUserId: selectedManager || null
        },
      ]);

      // Clear input fields
      setEmail("");
      setFirstName("");
      setLastName("");
      setTitle("");
      setSelectedManager(null);
      setSelectedTeam(null); // Reset selected team
      setErrorMessage("");
    } else {
      setErrorMessage("Please provide a valid email address");
    }
  };

  const handleCloseAddMemberModal = () => {
    setEmail("");
    setFirstName("");
    setLastName("");
    setTitle("");
    setSelectedTeam(null); // Reset selected team
    setSelectedManager(null);
    setErrorMessage("");
    setInvites([]);
    setOpenAddMemberModal(false);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleDeleteInvite = (inviteToDelete) => () => {
    setInvites(
      invites.filter((invite) => invite.email !== inviteToDelete.email)
    );
  };

  const handleInvite = async () => {
    try {
      const { data: responseSeats, errors } =
        await resourceClient.queries.checkCoacheeInviteAllowance({
          userID: companyInformation?.owner_user_id,
        });

      const availableSeats =
        responseSeats.coachee_seat_tier_allowance -
        responseSeats.coachee_seat_count;

      if (availableSeats < 1) {
        toast.error(
          "Not enough seats available. Please upgrade your seat count to continue."
        );
        return; // Stop execution if no seats are available
      }

      if (invites.length > availableSeats) {
        toast.error(
          `You are trying to invite ${invites.length} members, but only ${availableSeats} seat(s) are available.`
        );
        return; // Stop execution if invites exceed available seats
      }
      setLoading(true);
      const body = {
        user_id: user?.sub,
        user_group: user?.role,
        user_full_name: user?.given_name + " " + user?.family_name,
        user_email: user?.email,
        invites: invites,
        company_id: companyInformation.company_id, // Assuming you have the company_id in userData
        company_name: userData?.companyInformation?.company_name || "",
      };
      await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/add-members-to-company`,
        body
      );
      for (const invite of invites) {
          await resourceClient.mutations.incrementCoacheeSeatCount({
            user_id: companyInformation?.owner_user_id,
          });

      }

      toast.success(
        `${invites.length} invitation${
          invites.length === 1 ? "" : "s"
        } sent successfully`
      );
      fetchCompanyData();
    } catch (error) {
      console.log("error in sending invites: ", error);
      alert("Failed to send invites.");
    } finally {
      handleCloseAddMemberModal();
      setLoading(false);
    }
  };

  return (
    <Modal
      open={openAddMemberModal}
      onClose={handleCloseAddMemberModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "white",
          color: "black",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          maxWidth: "calc(100% - 40px)",
          maxHeight: {
            xs: "calc(100% - 50px)",
            md: "90%",
          },
          overflowY: "hidden",
          boxShadow: 24,
          borderRadius: "30px",
          padding: {
            xs: "24px",
            md: "64px",
          },
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ marginBottom: "16px" }}>
          <Typography
            sx={{
              color: "black",
              fontSize: 36,
              fontFamily: "Lexend",
              fontWeight: "300",
            }}
          >
            Invite Members to a Team
          </Typography>
          <Typography
            sx={{
              color: "#828282",
              fontSize: 16,
              fontFamily: "Lexend",
              fontWeight: "500",
            }}
          >
            Press + to add the user to your team.
          </Typography>
        </Box>
        <Box
          sx={{
            overflowY: "auto", // Add scrolling to this section
            flexGrow: 1, // Ensure this section grows to fill available space
            marginBottom: "16px", // Ensure some margin before the footer
            paddingRight: "16px", // For scrollbar padding (optional)
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              {/* Team Selection Dropdown */}
              <Typography
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  letterSpacing: "0.05em",
                }}
              >
                Team
              </Typography>
              <FormControl fullWidth>
                <Select
                  fullWidth
                  value={selectedTeam}
                  onChange={(e) => {
                    const value = e.target.value;
                    setSelectedTeam(value);
                  }}
                  displayEmpty
                  IconComponent={CustomIcon}
                  inputProps={{
                    style: { color: "#4F4F4F" },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        backgroundColor: "white",
                        maxHeight: 300,
                        overflow: "auto",
                        maxWidth: "20%",
                        width: "20%",
                      },
                    },
                  }}
                  sx={{
                    color: "black",
                    background: "white",
                    borderRadius: "16px",
                    border: "1px solid #C4CAD4",
                    "&:before, &:after": {
                      display: "none",
                    },
                    "& fieldset": {
                      border: "none !important",
                    },
                  }}
                >
                  {myTeamsInformation?.map((team) => (
                    <MenuItem
                      key={team.team_id}
                      value={team}
                      sx={{
                        background: "white",
                        color: "black",
                        "&:hover": {
                          background: "#F7F7F7",
                          cursor: "pointer",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "12px",
                          whiteSpace: "normal",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          wordBreak: "break-word",
                        }}
                      >
                        {team.team_name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <Typography
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  letterSpacing: "0.05em",
                }}
              >
                First Name
              </Typography>
              <TextField
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                fullWidth
                InputLabelProps={{ style: { color: "#4F4F4F" }, shrink: false }}
                InputProps={{
                  style: {
                    color: "black",
                    borderColor: "1px #C4CAD4 solid",
                    borderRadius: 16,
                  },
                  sx: {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C4CAD4",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C4CAD4",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "1px #C4CAD4 solid",
                    },
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <Typography
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  letterSpacing: "0.05em",
                }}
              >
                Last Name
              </Typography>
              <TextField
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                fullWidth
                InputLabelProps={{ style: { color: "#4F4F4F" }, shrink: false }}
                InputProps={{
                  style: {
                    color: "black",
                    borderColor: "1px #C4CAD4 solid",
                    borderRadius: 16,
                  },
                  sx: {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C4CAD4",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C4CAD4",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "1px #C4CAD4 solid",
                    },
                  },
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <Typography
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  letterSpacing: "0.05em",
                }}
              >
                Email
              </Typography>
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                InputLabelProps={{ style: { color: "#4F4F4F" }, shrink: false }}
                InputProps={{
                  style: {
                    color: "black",
                    borderColor: "1px #C4CAD4 solid",
                    borderRadius: 16,
                  },
                  sx: {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C4CAD4",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C4CAD4",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "1px #C4CAD4 solid",
                    },
                  },
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <Typography
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  letterSpacing: "0.05em",
                }}
              >
                Title
              </Typography>
              <TextField
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
                InputLabelProps={{ style: { color: "#4F4F4F" }, shrink: false }}
                InputProps={{
                  style: {
                    color: "black",
                    borderColor: "1px #C4CAD4 solid",
                    borderRadius: 16,
                  },
                  sx: {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C4CAD4",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C4CAD4",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "1px #C4CAD4 solid",
                    },
                  },
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <Typography
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  letterSpacing: "0.05em",
                }}
              >
                {`Assign Manager (optional)`}
              </Typography>
                <FormControl fullWidth>
                  <Select
                      value={selectedManager || ""}
                      onChange={(e) => {
                          const value = e.target.value;
                          setSelectedManager(value);
                      }}
                      IconComponent={CustomIcon}
                      displayEmpty
                      inputProps={{
                          style: { color: "#4F4F4F" },
                      }}
                      MenuProps={{
                          PaperProps: {
                            style: {
                              backgroundColor: 'white',
                              maxHeight: 300,
                              overflow: 'auto',
                              maxWidth: '20%',
                              width: '20%',
                            },
                          },
                        }}
                        sx={{
                          color: "black",
                          background: "white",
                          borderRadius: "16px",
                          border: "1px solid #C4CAD4",
                          "&:before, &:after": {
                            display: "none",
                          },
                          "& fieldset": {
                            border: "none !important",
                          },
                        }}
                      >
                        {internalCompanyMembers?.length > 0 && internalCompanyMembers?.map((member) => (
                            <MenuItem 
                                key={member.user_id} 
                                value={member.user_id} 
                                sx={{
                                    background: "white",
                                    color: "black",
                                    "&:hover": {
                                      background: "#F7F7F7",
                                      cursor: "pointer"
                                    },
                                  }}
                            >
                                <Typography
                                    sx={{
                                        color: "black",
                                        fontSize: "12px",
                                        whiteSpace: "normal",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        wordBreak: "break-word"
                                    }}
                                >
                                    {member?.full_name || "Unknown"}
                                </Typography>
                            </MenuItem>
                        ))}
                  </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ color: "red", fontSize: "12px", fontFamily: "Lexend" }}
              >
                {errorMessage ?? ""}
              </Typography>
              {/* Plus Icon Button to Add Invite */}
              <IconButton
                color="primary"
                onClick={handleAddInvite} // Call add invite function
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>

          {/* Display Invited Users */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "8px",
              marginTop: "16px",
            }}
          >
            {invites.length > 0 &&
              invites.map((invite, index) => (
                <Chip
                  key={index}
                  label={`${invite.firstName} ${invite.lastName} (${invite.email}) - ${invite.title} - Team: ${invite.selectedTeam}`}
                  onDelete={handleDeleteInvite(invite)}
                  color="primary"
                  sx={{
                    fontSize: 14,
                    backgroundColor: "#F1F1F1",
                    color: "black",
                    overflowX: "auto",
                  }}
                />
              ))}
          </Box>
        </Box>

        {/* Invite Button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "24px",
          }}
        >
          <ConfirmButton
            onClick={handleInvite}
            label={"Send"}
            disabled={invites.length < 1} // Disable if no invites or no team selected
            loading={loading}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default AdminAddMemberModal;
