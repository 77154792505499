import { Box, Typography } from "@mui/material";
import SuperAdmin from "components/AdminPortal/SuperAdmin";
import Admin from "components/AdminPortal/Admin";
import User from "components/AdminPortal/User";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import { useSelector } from "react-redux";
import Owner from "../../../components/AdminPortal/Owner";
import VcOwner from "components/AdminPortal/VcOwner";
import VcUser from "../../../components/AdminPortal/VcUser";
import VcAdmin from "components/AdminPortal/VcAdmin";

const AdminPortal = ({ user, userData }) => {
    const collapsed = useSelector((state) => state.layout.collapsed);
    return(
        <GlobalPageComponent user={user} userData={userData}>
            <Box
                sx={{
                backgroundColor: "#EFF3F6",
                width: "100%",
                height: "100%",
                padding: "16px",
                display: {
                    xs: !collapsed ? "none" : "block",
                },
                overflowY: "auto",
                }}
            >
                {user?.role === 'OWNER' ? (
                    userData?.companyInformation?.company_type === 'teams' ? (
                        <Owner user={user} userData={userData} />
                    ) : (
                        <VcOwner user={user} userData={userData} />
                    )
                ) : user?.role === 'SUPERADMINS' ? (
                    <SuperAdmin user={user} userData={userData} />
                ) : user?.role === 'ADMINS' ? (
                    userData?.companyInformation?.company_type === 'teams' ? (
                        <Admin user={user} userData={userData} />   
                    ) : (
                        <VcAdmin user={user} userData={userData}/>
                    )
                ) : user?.role === 'USERS' ? (
                    userData?.companyInformation?.company_type === 'teams' ? (
                        <User user={user} userData={userData} />
                    ) : (
                        <VcUser user={user} userData={userData} />
                    )
                ) : null}
            </Box>
        </GlobalPageComponent>
    )
}

export default AdminPortal;