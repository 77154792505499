import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Tooltip } from "@mui/material";
import axios from "axios";
import UsersTable from "components/Table/CustomTable";
import AdminPortalViewTabs from "./Tabs/AdminPortalViewTabs"
import OrganizationChart from "./OrganizationChart/OrganizationChart";
import ToolBar from "./ToolBar/ToolBar";
import AddPrivateUserModal from "./AddMembers/AddPrivateUserModal";
import { generateClient } from "aws-amplify/data";
import { checkUserGroupAlignment } from "helpers/userCognitoGroupCheck";
import * as Frigade from "@frigade/react";
import AdminPortalActionIcon from "../Icons/AdminPortalAction.svg";
import ActionOnUserModal from "./UserActions/ActionOnUserModal";
import { GreenStatusPill, OrangeStatusPill } from "components/Table/StatusPills";
import { useNavigate } from 'react-router-dom';


// const resourceClient = generateClient({authMode: 'userPool'});
/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });

const User = ({ user, userData }) => {
  // Initialize selectedView from localStorage, default to 'list' if not set
  const [selectedView, setSelectedView] = useState(
    localStorage.getItem("selectedAdminView") || "list"
  );

  // Function to handle view change and store it in localStorage
  const handleViewChange = (newValue) => {
    const view = newValue === 0 ? "list" : "chart";
    setSelectedView(view);
    localStorage.setItem("selectedAdminView", view); // Store the selected view in localStorage
  };
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false); // For loading state
  const [companyMembers, setCompanyMembers] = useState([]); // Store company members here
  const [companyInformation, setCompanyInformation] = useState([]);
  const [teamsInformation, setTeamsInformation] = useState([]);
  const [myTeamInformation, setMyTeamInformation] = useState([]);
  const [myPrivateUsers, setMyPrivateUsers] = useState([]);
  const [openAddMemberModal, setOpenAddMemberModal] = useState(false); // Modal state
  const [openAddPrivateUserModal, setOpenAddPrivateUserModal] = useState(false);
  const [availableSeats, setAvailableSeats] = useState("");
  const [selectedUser, setSelectedUser] = useState(false)
  const [openActionOnUserModal, setOpenActionOnUserModal] = useState(false)
  const [internalCompanyMembers, setInternalCompanyMembers] = useState(null)
  const [orgChartMembers, setOrgChartMembers] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const navigate = useNavigate();

  console.log("user data: ", user);

  // Function to fetch company members
  const fetchCompanyData = async () => {
    try {
      setLoading(true);

      checkUserGroupAlignment(
        user?.sub,
        user?.role,
        userData?.companyInformation?.company_id
      );

      const { data: response } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/user-fetch-company-members`,
        {
          params: {
            userId: user?.sub,
            userGroup: user?.role,
          },
        }
      );
      setCompanyMembers(response.members);
      setCompanyInformation(response.companyInformation);
      setTeamsInformation(response.teams);
      setMyTeamInformation(response.myTeam);
      setMyPrivateUsers(response.myPrivateUsers)
      setInternalCompanyMembers(response.internalCompanyMembers); //excludes any private users
      setOrgChartMembers(response.orgChartMembers);

      const { data: responseSeats, errors } =
        await resourceClient.queries.checkCoacheeInviteAllowance({
          userID: response?.companyInformation?.owner_user_id,
        });

      console.log("seat info: ", responseSeats, errors);

      setAvailableSeats(
        responseSeats.coachee_seat_tier_allowance -
          responseSeats.coachee_seat_count
      );

      console.log("response from fetch company information: ", response);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching company members: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  const columns = [
    {
      field: "full_name",
      headerName: "Full Name",
      flex: 0.7,
      renderCell: (params) => {
        
        const handleClick = () => {
          const userId = params.row.user_id; 
          if (userId) {
            navigate(`/platform/coach/coachee-overview/${userId}`);
          }
        };
  
        // Check if this specific row is the hovered row
        const isHovered = hoveredRow === params.row.user_id;
  
        return (
          <Tooltip title={params.value || ""}>
            <span
              onClick={handleClick}
              onMouseEnter={() => setHoveredRow(params.row.user_id)}
              onMouseLeave={() => setHoveredRow(null)}
              style={{
                cursor: "pointer",
                textDecoration: isHovered ? "underline" : "none",
                color: isHovered ? "#0000FF" : "inherit",
              }}
            >
              {params.value}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
          <Typography
            sx={{
              color: "black",
              fontSize: "14px",
              fontFamily: "Lexend",
              fontWeight: "400",
              wordWrap: "break-word",
              textTransform: "none"
            }}
          >
            {params.value}
          </Typography>
        </Tooltip>
      )
    },
    {
      field: "team_name",
      headerName: "Team",
      flex: 1,
      renderCell: (params) => {
        const value = Array.isArray(params.value) ? params.value.join(", ") : params.value || "No Team";
        return (
          <Tooltip title={value}>
            {value}
          </Tooltip>
        );
      },
    },
    {
      field: "title",
      headerName: "Title",
      flex: 0.8,
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
            {params.value || ""}
        </Tooltip>
      ),
    },
    {
      field: "manager",
      headerName: "Manager",
      flex: 0.5,
      renderCell: (params) => {
        const value = Array.isArray(params.value) ? params.value.join(", ") : params.value || "No Manager";
        return (
          <Tooltip title={value}>
            {value}
          </Tooltip>
        );
      },
    },
    {
      field: "invite_status",
      headerName: "Status",
      flex: 0.4,
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
          {params.value === "accepted" ? (
            <GreenStatusPill text={params.value} />
          ) : params.value === "pending" || params.value === "expired" ? (
            <OrangeStatusPill text={params.value} />
          ) : null}
        </Tooltip>
      ),
    },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   flex: 0.5,
    //   renderCell: (params) => {
    //     // Compare the row's user_id with the current user's id
    //     if (
    //       params.row.user_id &&
    //       (params.row.user_id === companyInformation?.owner_user_id &&
    //       user?.sub !== companyInformation?.owner_user_id) ||
    //       !myPrivateUsers.includes(params.row.user_id)
    //     ) {
    //       return null; // Hide the action button if user_id matches the current user's id
    //     }

    //     return (
    //       <Box sx={{ display: "flex", gap: "8px" }}>
    //         <Box
    //           sx={{
    //             display: "flex",
    //             flexDirection: "row",
    //             gap: "4px",
    //             alignItems: "center",
    //             "&:hover": {
    //               cursor: "pointer",
    //             },
    //           }}
    //           onClick={() => handleActionOnUser(params.row)}
    //         >
    //           <AdminPortalActionIcon />
    //           <Typography
    //             sx={{
    //               color: "#93989A",
    //               fontSize: 14,
    //               fontFamily: "Lexend",
    //               fontWeight: "400",
    //               wordWrap: "break-word",
    //             }}
    //           >
    //             Actions
    //           </Typography>
    //         </Box>
    //       </Box>
    //     );
    //   },
    // },
  ];

  const rows = companyMembers.map((member, index) => {
    const teams = member.teams
      .map((team) => team.team_name)
      .sort((a, b) => a.localeCompare(b)); // Sort teams alphabetically
  
    // Normalize manager_full_name to an array for consistent handling
    const managerNames = Array.isArray(member.manager_full_name)
      ? member.manager_full_name
      : member.manager_full_name
      ? [member.manager_full_name]
      : []; // Handle missing or null manager_full_name

    return {
      id: index,
      user_id: member.user_id || "",
      full_name: member.full_name || "",
      email: member.email || "",
      team_name: teams.length > 0 ? teams.join(", ") : "No Team", // Convert to a string for sorting
      title: member.company_role_name || "No Title",
      invite_status: member.invite_status || "",
      role: member.role,
      manager: managerNames.length > 0 ? managerNames.join(", ") : "No Manager", // Convert to a string for sorting
      manager_user_id: member.manager_user_id || "",
    };
  });

  const handleActionOnUser = (member) => {
    setSelectedUser(member);
    handleOpenActionOnUserModal();
  };

  const handleopenAddPrivateUserModal = () => setOpenAddPrivateUserModal(true);
  const handleOpenActionOnUserModal = () => {
    if(selected?.length === 1){
      setSelectedUser(selected[0] || "");
      setOpenActionOnUserModal(true)
    }
  };

  return (
    <Box
      sx={{
        background: "white",
        borderRadius: "30px",
        paddingX: { xs: "30px", md: "50px" },
        paddingY: { xs: "30px", md: "30px" },
        height: "100%",
        overflow: "hidden",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Frigade.Tour
        flowId="flow_6Qn3uhRC"
        defaultOpen={true}
        sequential={true}
        dismissible={true}
        css={{
          ".fr-card": {
            backgroundColor: "#EFF3F6",
          },
          ".fr-button-primary": {
            backgroundColor: "white",
            color: "black",
            borderColor: "black",
            borderRadius: "50px",
            borderWidth: "1px",
            borderStyle: "solid",
            transition: "background-color 0.3s, color 0.3s",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          },
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <Typography
            sx={{
              color: "black",
              fontSize: 28,
              fontFamily: "Montserrat",
              fontWeight: "400",
              wordWrap: "break-word",
            }}
          >
            <span id="hint-anchor-1">Teams & Members</span>
          </Typography> */}
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {selectedView === 'list' ? (
              <Box
                id="hint-anchor-1"
              >
              <ToolBar
                user={user}
                userData={userData}
                selected={selected}
                selectedUser={selectedUser}
                fetchCompanyData={fetchCompanyData}
                handleOpenActionOnUserModal={handleOpenActionOnUserModal}
                companyInformation={companyInformation}
                myPrivateUsers={myPrivateUsers}
              />
              </Box>
            ) : (
              <Typography
                sx={{
                  color: "black",
                  fontSize: 28,
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                Organization Chart
              </Typography>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: "15px",
              }}
            >
              {selectedView === 'list' && (
              <>
              <Typography
                sx={{
                  color: "black",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  textTransform: "capitalize",
                  letterSpacing: "0.05em",
                  lineHeight: "18px",
                  wordWrap: "break-word",
                  borderRadius: 50,
                  padding: "12px",
                  background: "#F1F1F1",
                }}
              >
                {`${availableSeats} seats are open`}
              </Typography>
              <Button
                onClick={handleopenAddPrivateUserModal}
                sx={{
                  color: "black",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  textTransform: "capitalize",
                  letterSpacing: "0.05em",
                  lineHeight: "18px",
                  wordWrap: "break-word",
                  borderRadius: 50,
                  border: "1px black solid",
                  transition: "all 0.3s",
                  "&:hover": {
                    cursor: "pointer",
                    background: "black",
                    borderColor: "black",
                    color: "white",
                  },
                  "&:hover *": {
                    color: "white",
                  },
                  "&:hover svg *": {
                    stroke: "white",
                  },
                }}
              >
                <span id="hint-anchor-5">Add Private Users</span>
              </Button>
              </>
              )}
              <AdminPortalViewTabs setSelectedView={handleViewChange} />
            </Box>
          </Box>
        </Box>
        {/* Styled DataGrid Component */}
        <Box
          sx={{
            flexGrow: 1,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            overflow: "auto"
          }}
        >
          {selectedView === 'list' ? (
          <>
          <UsersTable
            rows={rows}
            columns={columns}
            pageSize={25}
            rowsPerPageOptions={[10, 20, 25, 30]}
            checkboxSelection={true}
            loading={loading}
            onSelectionModelChange={(newSelection) => {
              const selectedUsers = newSelection.map((selectionId) => {
                const selectedUserInfo = rows.find((row) => row.id === selectionId);
                return selectedUserInfo; // Return the user_id instead of the index id
              });
              setSelected(selectedUsers); // Update state with selected user_ids
            }}
            isRowSelectable={(params) => {
              const isNotOwner = params.row.user_id !== companyInformation.owner_user_id;
              const isInMyPrivateUsers = myPrivateUsers.includes(params.row.user_id);
            
              return isNotOwner && isInMyPrivateUsers;
            }}  
            disableColumnFilter
            disableSelectionOnClick
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            localeText={{
              noRowsLabel: "No data available",
            }}
          />
          </>
          ) : (
            <OrganizationChart user={user} userData={userData} orgChartMembers={orgChartMembers} loadingCompanyData={loading}/>
          )}
        </Box>
      </Box>
      <AddPrivateUserModal
        user={user}
        userData={userData}
        openAddPrivateUserModal={openAddPrivateUserModal}
        setOpenAddPrivateUserModal={setOpenAddPrivateUserModal}
        companyInformation={companyInformation}
        teamsInformation={teamsInformation}
        companyMembers={companyMembers}
        fetchCompanyData={fetchCompanyData}
      />
      <ActionOnUserModal
        user={user}
        userData={userData}
        openActionOnUserModal={openActionOnUserModal}
        setOpenActionOnUserModal={setOpenActionOnUserModal}
        companyMembers={companyMembers}
        companyInformation={companyInformation}
        teamsInformation={teamsInformation}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        myPrivateUsers={myPrivateUsers}
        fetchCompanyData={fetchCompanyData}
        internalCompanyMembers={internalCompanyMembers}
      />
    </Box>
  );
};

export default User;