import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Typography,
  IconButton,
  Collapse,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import * as Frigade from "@frigade/react";
import OverallScoreRing from "./OverallScoreRing";

import {
  backgroundSvg,
  logoSvg3,
  gradientEllipseSvg,
  learnMoreSvg,
  vectorFooterwhiteSvg,
  page2BackgroundSvg,
  svgButton,
  generatePieChartSvg,
  vectorFooterBlackSvg,
  arrowUpRightStrengthsSvg,
  arrowDownRightWeaknessesSvg,
  improvementSvg,
  listSvg,
  logoSvg,
  gradientSvg,
  svgGradient,
} from "components/Icons/LaraReportSvg.svg";
import PoppinsRegular from "components/Icons/Poppins-Regular.ttf";

import fontkit from "@pdf-lib/fontkit";
import LexendRegular from "components/Icons/Lexend-Regular.ttf";
import LexendBold from "components/Icons/Lexend-Bold.ttf";
import { formatDistanceToNow } from "date-fns";
import dayjs from "dayjs";
import { PDFDocument, StandardFonts, rgb, degrees, PDFName } from "pdf-lib";
import { saveAs } from "file-saver";
import altercallLogo from "assets/altercall1.png"; // Ensure this is a valid PNG file
import PoppinsBold from "components/Icons/Poppins-Bold.ttf";
import DownloadIcon from "components/Icons/Download.svg";

const getPieChartColor = (score) => {
  if (score >= 1 && score <= 20) return "#E62E05"; // Red
  if (score >= 21 && score <= 40) return "#F38744"; // Orange
  if (score >= 41 && score <= 60) return "#FAC515"; // Yellow
  if (score >= 61 && score <= 70) return "#ACDC79"; // Light Blue
  if (score >= 71 && score <= 80) return "#66C61C"; // Green
  if (score >= 81 && score <= 95) return "#16B364"; // Green
  if (score >= 95 && score <= 100) return "#0E9384"; // Green
  return "#D3D3D3"; // Grey for undefined/zero
};

const Pie = ({ score, radius, textSize, fillStopColor, isOverall }) => {
  const backgroundColor = getPieChartColor(score);
  return (
    <Box
      sx={{
        width: "65px",
        height: "65px",
        flexShrink: 0,
        borderRadius: "12px",
        border: "1px solid #F0F0F0",
        background: backgroundColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: textSize,
        color: "#FFF",
        fontFamily: "Poppins",
        fontWeight: 400,
        textAlign: "center",
        lineHeight: "28.377px",
        letterSpacing: "-0.464px",
      }}
    >
      {isNaN(score) ? "-" : score === 0 ? "0" : Math.round(score)}
    </Box>
  );
};

// Renders strengths, weaknesses, improvements, etc.
const renderSectionContent = (section, role, laraData) => {
  const isCoach = role === "coach";
  const sectionData = isCoach ? laraData?.coach : laraData?.client;

  const getStrengthField = () =>
    isCoach
      ? sectionData?.[`coach_${section}_strengths`]
      : sectionData?.[`${section}_strengths`];

  const getWeaknessField = () =>
    isCoach
      ? sectionData?.[`coach_${section}_weaknesses`]
      : sectionData?.[`${section}_weaknesses`];

  const getImprovementField = () =>
    isCoach
      ? sectionData?.[`coach_${section}_area_of_improvement`]
      : sectionData?.[`${section}_area_of_improvement`];

  const getDetailsField = () =>
    isCoach
      ? sectionData?.[`coach_${section}_details`]
      : sectionData?.[`${section}_details`];

  const renderText = (label, text) => (
    <>
      <Typography
        sx={{
          display: "flex",
          width: "100%",
          color: "#4A4A4A",
          fontFamily: "Lexend",
          fontSize: 12,
          fontWeight: 600,
          lineHeight: "18px",
          marginBottom: "8px",
        }}
      >
        {label}
      </Typography>
      <ul>
        <li>
          <Typography
            sx={{
              display: "flex",
              color: "#4A4A4A",
              fontFamily: "Lexend",
              fontSize: 11,
              fontWeight: 400,
              lineHeight: "18px",
              marginBottom: "16px",
              maxWidth: "100%",
            }}
          >
            {text
              ? text
              : `Not enough response ${label.toLowerCase()} data available.`}
          </Typography>
        </li>
      </ul>
    </>
  );

  return (
    <Box sx={{ padding: "3px", width: "98%" }}>
      <ul>
        <li>{renderText("Strengths", getStrengthField())}</li>
        <li>{renderText("Weaknesses", getWeaknessField())}</li>
        <li>{renderText("Improvements", getImprovementField())}</li>
        <li>{renderText("Details", getDetailsField())}</li>
      </ul>
    </Box>
  );
};

// New function to render "Ego" details
const renderEgoSectionContent = (laraData) => {
  const data = laraData?.client;
  if (!data) return <Box>No data available</Box>;

  // We’re showing 4 text fields in the collapsible content for "Ego":
  //   ego_centric, ego_group_polarity, ego_group_tendency, group_centric
  const { ego_centric, ego_group_polarity, ego_group_tendency, group_centric } =
    data;

  // Simple helper for labeling
  const renderLine = (label, value) => (
    <>
      <Typography
        sx={{
          display: "block",
          width: "100%",
          color: "#4A4A4A",
          fontFamily: "Lexend",
          fontSize: 12,
          fontWeight: 600,
          lineHeight: "18px",
          marginBottom: "8px",
        }}
      >
        {label}
      </Typography>
      <ul>
        <li>
          <Typography
            sx={{
              color: "#4A4A4A",
              fontFamily: "Lexend",
              fontSize: 11,
              fontWeight: 400,
              lineHeight: "18px",
              marginBottom: "16px",
              maxWidth: "100%",
            }}
          >
            {value || `No ${label.toLowerCase()} data available.`}
          </Typography>
        </li>
      </ul>
    </>
  );

  return (
    <Box sx={{ padding: "3px", width: "98%" }}>
      {renderLine("I Centric", ego_centric)}
      {renderLine("WE Centric", group_centric)}
      {renderLine("I Group Polarity", ego_group_polarity)}
      {renderLine("I Group Tendency", ego_group_tendency)}
    </Box>
  );
};
const ParticipantDropdown = ({
  participants,
  selectedParticipant,
  setSelectedParticipant,
}) => {
  const [open, setOpen] = useState(false);

  const current = participants.find((p) => p.role === selectedParticipant);

  const handleToggle = () => setOpen(!open);

  const handleSelect = (role) => {
    setSelectedParticipant(role);
    setOpen(false);
  };

  return (
    <Box sx={{ position: "relative", width: "165px" }}>
      <Box
        onClick={handleToggle}
        sx={{
          width: "100%",
          minHeight: "35px",
          border: "1px solid #D5D7DA",
          borderRadius: "8px",
          background: "#FFF",
          boxShadow: "0px 1px 2px 0px rgba(18, 13, 18, 0.05)",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "6px 8px",
          color: "#000",
          cursor: "pointer",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "10px",
            fontWeight: 500,
            lineHeight: "12px",
            letterSpacing: "0.4px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {current ? current.displayName : "Select"}
        </Typography>

        {/* Arrow */}
        <svg
          width="12"
          height="7"
          viewBox="0 0 12 7"
          fill="none"
          style={{
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.2s ease",
          }}
        >
          <path
            d="M1 1L6 6L11 1"
            stroke="#666"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Box>

      {open && (
        <Box
          sx={{
            position: "absolute",
            top: "100%", // dropdown starts right after the parent box
            height: "100px",
            left: 0,
            width: "100%",
            background: "#fff",
            border: "1px solid #D5D7DA",
            borderTop: "none", // removes the top border to visually merge with parent
            boxShadow: "0px 1px 2px rgba(10, 13, 18, 0.05)",
            borderRadius: "0 0 8px 8px", // match the parent's bottom border radius
            zIndex: 9999,
            overflowY: "auto",
          }}
        >
          {participants.map((p) => (
            <Box
              key={p.role}
              sx={{
                padding: "10px",
                fontFamily: "Poppins",
                fontSize: "10px",
                fontWeight: 500,
                lineHeight: "18px",
                letterSpacing: "0.4px",
                textTransform: "capitalize",
                color: "black",
                background: "white",
                cursor: "pointer",
                "&:hover": {
                  background: "#EFF3F6",
                },
                margin: "5px 6px",
                borderRadius: "8px",
                border: "1px solid transparent",
              }}
              onClick={() => handleSelect(p.role)}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "10px",
                  fontWeight: 500,
                  lineHeight: "12px",
                  letterSpacing: "0.4px",
                  color: "#000",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  "&:hover": {
                    background: "#EFF3F6",
                  },
                  margin: "5px 6px",
                  borderRadius: "8px",
                }}
              >
                {p.displayName}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

// ============== MAIN PAGE COMPONENT ==============
const LaraSessionPage = ({
  laraData,
  fileData,
  fetchfileData,
  setCallAction,
  callActions,
  quickRecap,
  listparticipants
}) => {
  const [openSections, setOpenSections] = useState(null);
  const [selectedParticipant, setSelectedParticipant] = useState("");
  const [localFileData, setLocalFileData] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // spinner for "Download"
  const [isLoadingCoach, setIsLoadingCoach] = useState(false); // not used
  const [showLimitBox, setShowLimitBox] = useState(true);
  const [showBox, setShowBox] = useState(true);
  useEffect(() => {
    if (!fileData) return;

    const clonedParticipants = Array.isArray(fileData.participants)
      ? [...fileData.participants]
      : [];

    // If we have a coach_name, push it as a participant
    if (fileData.coach_name) {
      clonedParticipants.unshift({
        participant_id: null, // unique ID using random number
        user_id: fileData.user_id,     // or undefined
        name: fileData.coach_name,     // store in "name"
        display_name: fileData.coach_name, // or store in "display_name"
        match_status: "no_match_attempted",
      });
    }

    // Store in local state, so everything else sees "coach" in participants
    setLocalFileData({
      ...fileData,
      participants: clonedParticipants,
    });
  }, [fileData]);

  // Build the list for the dropdown
  const participants = (localFileData?.participants || []).map((p) => {
    const name = typeof p.name === "string" ? p.name.trim() : "";
    const displayName = typeof p.display_name === "string" ? p.display_name.trim() : "";

    return {
      role: p.participant_id,       // used by dropdown
      displayName: name || displayName || "Unnamed",
    };
  });
  


  useEffect(() => {
    if (participants.length > 0 && !selectedParticipant) {
      setSelectedParticipant(participants[0].role); // role = participant_id
    }
  }, [participants, selectedParticipant]);

  console.log("laraData", laraData);
  console.log("fileData", fileData);

  // 3) Find the matched LARA record by participant_id
  //    We'll assume laraData is an array
  const matchedRecord = Array.isArray(laraData)
    ? laraData.find((r) => r.participant_id === selectedParticipant)
    : null;

  const tempClientData = matchedRecord
    ? {
        listen_score: matchedRecord.listen_score * 20,
        listen_report: matchedRecord.listen_report,
        listen_strengths: matchedRecord.listen_strengths,
        listen_weaknesses: matchedRecord.listen_weaknesses,
        listen_details: matchedRecord.listen_details,
        listen_area_of_improvement:
          matchedRecord.listen_area_of_improvement ||
          matchedRecord.listen_training_developments,

        affirm_score: matchedRecord.affirm_score * 20,
        affirm_report: matchedRecord.affirm_report,
        affirm_strengths: matchedRecord.affirm_strengths,
        affirm_weaknesses: matchedRecord.affirm_weaknesses,
        affirm_details: matchedRecord.affirm_details,
        affirm_area_of_improvement:
          matchedRecord.affirm_area_of_improvement ||
          matchedRecord.affirm_training_developments,

        respond_score: matchedRecord.respond_score * 20,
        respond_report: matchedRecord.respond_report,
        respond_strengths: matchedRecord.respond_strengths,
        respond_weaknesses: matchedRecord.respond_weaknesses,
        respond_details: matchedRecord.respond_details,
        respond_area_of_improvement:
          matchedRecord.respond_area_of_improvement ||
          matchedRecord.respond_training_developments,

        ask_score: matchedRecord.ask_score * 20,
        ask_report: matchedRecord.ask_report,
        ask_strengths: matchedRecord.ask_strengths,
        ask_weaknesses: matchedRecord.ask_weaknesses,
        ask_details: matchedRecord.ask_details,
        ask_area_of_improvement:
          matchedRecord.ask_area_of_improvement ||
          matchedRecord.ask_training_developments,

        ego_centric_score: matchedRecord.ego_centric_score * 20,
        ego_centric: matchedRecord.ego_centric,
        ego_group_polarity: matchedRecord.ego_group_polarity,
        ego_group_report: matchedRecord.ego_group_report,
        ego_group_tendency: matchedRecord.ego_group_tendency,
        group_centric: matchedRecord.group_centric,
        group_centric_score: matchedRecord.group_centric_score * 20,

        participant_overall_score: matchedRecord.participant_overall_score * 20,

        communication_report:
          matchedRecord.communication_report ||
          "No communication report available.",
      }
    : null;

  const updatedLaraData = {
    ...laraData,
    client: tempClientData,
    coach: {}, // remain empty so the code doesn't break
  };

  
  const calculateAverageScore = useCallback(() => {
    if (!tempClientData) return 0;
    const scores = [
      tempClientData.listen_score,
      tempClientData.affirm_score,
      tempClientData.respond_score,
      tempClientData.ask_score,
      tempClientData.ego_centric_score + tempClientData.group_centric_score,
    ].filter(score => score !== null && score !== 0);

    if (scores.length === 0) return 0;

    const totalScore = scores.reduce((acc, score) => acc + score, 0);
    return totalScore / scores.length;
  }, [tempClientData]);
  const computeEgoScore = useCallback(() => {
    if (!tempClientData) return 0;
    const e = parseFloat(tempClientData.ego_centric_score) || 0;
    const g = parseFloat(tempClientData.group_centric_score) || 0;
    return (e + g) / 2;
  }, [tempClientData]);

  const isCoacheeSelected = true;
  const selectedData = updatedLaraData?.client;

  const generateCoacheeSummary = useCallback(() => {
    return (
      matchedRecord?.communication_report ||
      "No communication report available."
    );
  }, [matchedRecord]);
  const generateCoachSummary = useCallback(() => "No coach summary", []);

  const summaryText = isCoacheeSelected
    ? generateCoacheeSummary()
    : generateCoachSummary();

  // 9) The categories and getCategoryScore / getCategoryReport
  const categories = ["listen", "affirm", "respond", "ask", "ego"];
  const getCategoryScore = (section) => {
    if (!tempClientData) return 0;
    if (section === "ego") {
      return computeEgoScore();
    } else {
      const val = tempClientData[`${section}_score`];
      return parseFloat(val) || 0;
    }
  };

  const getCategoryReport = (section) => {
    if (!tempClientData) return "No data available.";
    if (section === "ego") {
      return tempClientData.ego_group_report || "No EGO data available.";
    } else {
      return tempClientData[`${section}_report`] || "No data available.";
    }
  };

  const downloadReport = async () => {
    try {
      console.log("selectedData from report", matchedRecord);
      console.log("selectedData from report", matchedRecord.participant_id);
      console.log("tempClientData from report", tempClientData);
      // if (!fileData) {
      //   console.log("file data is missing");
      // } else {
      //   console.log("file data is present");
      // }
      // if (!fileData || !Array.isArray(fileData) || fileData.length === 0) {
      //   throw new Error("File data is missing or invalid.");
      // }
      console.log("Generating PDF report...");
      // Ensure actions is an array
      const fontBytes = await fetch(PoppinsRegular).then((res) =>
        res.arrayBuffer()
      );
      const fontlexendbytes = await fetch(LexendRegular).then((res) =>
        res.arrayBuffer()
      );
      // Create a new PDF document
      const pdfDoc = await PDFDocument.create();
      // Create the first page
      const page1 = pdfDoc.addPage([1500, 1974]); // Custom size

      // Embed the altercall.ai logo
      const logoImageBytes = await fetch(altercallLogo).then((res) =>
        res.arrayBuffer()
      );
      pdfDoc.registerFontkit(fontkit);
      const customFont = await pdfDoc.embedFont(fontBytes);
      const customFontLexend = await pdfDoc.embedFont(fontlexendbytes);

      const logoImage = await pdfDoc.embedPng(logoImageBytes);

      // Embed the aaiLogoVerticalBlack logo
      const svgToPng = async (svg) => {
        const svgBlob = new Blob([svg], { type: "image/svg+xml" });
        const url = URL.createObjectURL(svgBlob);
        const img = new Image();
        img.src = url;
        await new Promise((resolve) => (img.onload = resolve));
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        URL.revokeObjectURL(url);
        return canvas.toDataURL("image/png");
      };

      const wrapText = (text, maxWidth, fontSize, font) => {
        const words = text.split(" ");
        let lines = [];
        let currentLine = words[0];

        for (let i = 1; i < words.length; i++) {
          const word = words[i];
          const width = font.widthOfTextAtSize(
            currentLine + " " + word,
            fontSize
          );
          if (width < maxWidth) {
            currentLine += " " + word;
          } else {
            lines.push(currentLine);
            currentLine = word;
          }
        }
        lines.push(currentLine);
        return lines;
      };

      const backgroundPng = await svgToPng(backgroundSvg);
      const backgroundImage = await pdfDoc.embedPng(backgroundPng);

      page1.drawImage(backgroundImage, {
        x: 0,
        y: 0,
        width: 1500,
        height: 1974,
      });

      const logoPng = await svgToPng(logoSvg3);
      const logoImage3 = await pdfDoc.embedPng(logoPng);

      page1.drawImage(logoImage3, {
        x: 191,
        y: page1.getHeight() - 150 - 75, // Adjusted for top position
        width: 527,
        height: 101,
        opacity: 1,
      });

      const coachCommunication =
        matchedRecord?.communication_report ||
        "No communication report available.";
      const listenScore = matchedRecord.listen_score || 2.0;
      const respondScore = matchedRecord.respond_score || 4.0;
      const affirmScore = matchedRecord.affirm_score || 3.5;
      const askScore = matchedRecord.ask_score || 2.7;

      // Extract data from fileData (session info)
      const callName = fileData?.call_name || "Call Name";
      const coachName = fileData?.coach_name || "Coach Name";
      const zoomDate = fileData?.zoom_call_date || new Date();
      const formattedZoomDate = dayjs(zoomDate).utc().format("MM.DD.YY");
      const duration = fileData?.duration_minutes || 0;
      const dateCustom = dayjs(zoomDate).format("MMMM D, YYYY"); // Example: July 26, 2024
      // Embed a standard font (Helvetica)

      const gradientEllipsePng = await svgToPng(gradientEllipseSvg);
      const gradientEllipseImage = await pdfDoc.embedPng(gradientEllipsePng);

      page1.drawImage(gradientEllipseImage, {
        x: 5,
        y: page1.getHeight() - 404 - 2000, // Adjusted for top position
        width: 1495,
        height: 1474,
        opacity: 1,
      });
      page1.drawText(`${duration} minutes`, {
        x: 194,
        y: page1.getHeight() - 440,
        size: 32,
        color: rgb(1, 1, 1),
        width: 323,
        height: 80,
        lineHeight: 40,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
        font: customFontLexend,
        textDecoration: "underline",
        textUnderlinePosition: "from-font",
        textDecorationSkipInk: "none",
      });

      page1.drawText(`${dateCustom}`, {
        x: 194,
        y: page1.getHeight() - 485,
        size: 32,
        color: rgb(1, 1, 1),
        width: 323,
        height: 80,
        lineHeight: 40,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
        font: customFontLexend,
        textDecoration: "underline",
        textUnderlinePosition: "from-font",
        textDecorationSkipInk: "none",
      });

      let currentX = 191;
      let currentYY = page1.getHeight() - 570;
      const maxWidt = 1000;

      localFileData?.participants?.forEach((participant, index) => {
        const participantName = participant.display_name || "Unknown";
        const textWidth = customFontLexend.widthOfTextAtSize(participantName, 32);

        if (currentX + textWidth > maxWidt) {
          currentX = 191; // Reset X position
          currentYY -= 40; // Move to next line
        }

        page1.drawText(participantName, {
          x: currentX,
          y: currentYY,
          size: 32,
          color: rgb(1, 1, 1),
          font: customFontLexend,
          width: textWidth,
          height: 85,
          lineHeight: 85,
          fontWeight: 300,
          textAlign: "left",
          opacity: 1,
          textDecoration: "underline",
          textUnderlinePosition: "from-font",
          textDecorationSkipInk: "none",
        });

        currentX += textWidth + 15; // Move X position for the next name
      });
      // Draw the text "clientName Session Summary" in white color
      page1.drawText("L.A.R.A Analytics", {
        x: 191,
        y: page1.getHeight() - 925, // Adjusted for top position
        size: 120,
        color: rgb(1, 1, 1), // White color
        font: customFont,
        width: 1121,
        height: 508,
        lineHeight: 127,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });

      // Draw the text "and Call Summary" in white color
      page1.drawText(`and Call Summary`, {
        x: 191,
        y: page1.getHeight() - 925 - 130, // Adjusted for top position
        size: 120,
        color: rgb(1, 1, 1), // White color
        fontFamily: "Poppins",
        font: customFont,
        width: 1121,
        height: 508,
        lineHeight: 127,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
        textUnderlinePosition: "from-font",
        textDecorationSkipInk: "none",
      });

      // Draw the text "callName" in orange color
      const callNameText = callName || "Meeting Name";
      const callNameLines = wrapText(callNameText, 1121, 120, customFont);
      let callNameY = page1.getHeight() - 925 - 260;

      callNameLines.forEach((line) => {
        page1.drawText(line, {
          x: 191,
          y: callNameY,
          size: 120,
          color: rgb(1, 94 / 255, 0), // Orange color
          font: customFont,
          width: 1121,
          height: 508,
          lineHeight: 127,
          fontWeight: 300,
          textAlign: "left",
          opacity: 1,
          textUnderlinePosition: "from-font",
          textDecorationSkipInk: "none",
        });
        callNameY -= 130; // Adjust the line height
      });

      const learnMorePng = await svgToPng(learnMoreSvg);
      const learnMoreImage = await pdfDoc.embedPng(learnMorePng);

      page1.drawImage(learnMoreImage, {
        x: 190,
        y: page1.getHeight() - 1782,
        width: 273,
        height: 58,
        opacity: 1,
      });

      // Draw the "Learn More Here" text
      page1.drawText("Learn More Here", {
        x: 230,
        y: page1.getHeight() - 1760, // Adjusted to center the text vertically
        size: 24,
        color: rgb(1, 1, 1), // White color
        width: 273,
        height: 58,
        lineHeight: 35,
        fontWeight: 500,
        textAlign: "center",
        font: customFont,
        opacity: 1,
      });

      // Add a hyperlink to the "Learn More Here" text
      const linkAnnotation = pdfDoc.context.obj({
        Type: "Annot",
        Subtype: "Link",
        Rect: [
          225,
          page1.getHeight() - 1782,
          225 + 273,
          page1.getHeight() - 1782 + 58,
        ],
        Border: [0, 0, 0],
        A: {
          Type: "Action",
          S: "URI",
          URI: "https://altercall.ai/resources?search=LARA-Scores&pageId=33&sectionId=281",
        },
      });

      // Add the annotation to the page's annotations array
      page1.node.set(
        PDFName.of("Annots"),
        pdfDoc.context.obj([linkAnnotation])
      );

      const vectorFooterwhitePng = await svgToPng(vectorFooterwhiteSvg);
      const vectorFooterwhiteImage = await pdfDoc.embedPng(
        vectorFooterwhitePng
      );

      //page2
      const page2 = pdfDoc.addPage([1500, 1974]); // Custom size
      page2.drawRectangle({
        x: 0,
        y: 0,
        width: page2.getWidth(),
        height: page2.getHeight(),
        color: rgb(0, 0, 0),
        opacity: 1,
      });

      const page2BackgroundPng = await svgToPng(page2BackgroundSvg);
      const page2BackgroundImage = await pdfDoc.embedPng(page2BackgroundPng);

      // Draw the gradient background from y = 0 to y = 781
      page2.drawImage(page2BackgroundImage, {
        x: 0,
        y: page2.getHeight() - 781, // Start from the top
        width: page2.getWidth(), // Full width of the page
        height: 781, // Height of 781
        opacity: 1, // Set opacity to 1
      });

      // Draw a white rectangle for the rest of the page
      page2.drawRectangle({
        x: 0,
        y: 0, // Start from the bottom
        width: page2.getWidth(), // Full width of the page
        height: page2.getHeight() - 780, // Height from y = 0 to y = 1040
        color: rgb(1, 1, 1), // White color
      });

      page2.drawImage(logoImage3, {
        x: 80,
        y: page2.getHeight() - 33 - 44, // Adjusted for top position
        width: 229,
        height: 44,
        opacity: 1, // Adjusted opacity
      });

      const svgButtonPng = await svgToPng(svgButton);
      const svgButtonImage = await pdfDoc.embedPng(svgButtonPng);

      page2.drawImage(svgButtonImage, {
        x: 392,
        y: page2.getHeight() - 33 - 44,
        width: 192,
        height: 40,
        opacity: 1,
      });

      // Draw coach name or participant name
      page2.drawText(`${coachName}`, {
        x: 435, // Adjusted to match the left position
        y: page2.getHeight() - 33 - 30, // Adjusted for top position
        size: 18,
        font: customFontLexend,
        color: rgb(0, 0, 0),
        width: 136, // Adjusted to match the width
        height: 22.5, // Adjusted to match the height
        lineHeight: 22.5,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1, // Adjusted to match the opacity
        textUnderlinePosition: "from-font",
        textDecorationSkipInk: "none",
      });
      page2.drawText(`${dateCustom}`, {
        x: 1273,
        y: page2.getHeight() - 45 - 19, // Adjusted for top position
        size: 15,
        color: rgb(1, 1, 1),
        width: 155,
        height: 19,
        lineHeight: 19, // Updated line height
        fontWeight: 300,
        textAlign: "left",
        opacity: 1, // Updated opacity to 1
        fontFamily: "Lexend",
        font: customFontLexend,
      });

      page2.drawText("L.A.R.A. Score Overview", {
        x: 432, // Start of the text inside the box, centered
        y: page2.getHeight() - 217 - 35, // Adjust the Y position to center text in the box
        size: 67,
        font: customFont, // Using custom font
        color: rgb(1, 1, 1), // White color for the text
        width: 733, // Width of the text should match the box
        height: 75, // Height of the text area should match the box
        lineHeight: 75, // Line height to center text vertically
        fontWeight: 300,
        textAlign: "left", // Left align the text within the box
        opacity: 1,
      });

      let overallScore = matchedRecord.participant_overall_score * 20 || 0;
      const score1 = overallScore;

      const pieChartSvg = generatePieChartSvg(overallScore);

      const pieChartPng = await svgToPng(pieChartSvg);
      const pieChartImage = await pdfDoc.embedPng(pieChartPng);

      page2.drawImage(pieChartImage, {
        x: 115,
        y: page2.getHeight() - 244 - 138,
        width: 168,
        height: 168,
        opacity: 1,
      });

      const coachCommunicationText =
        matchedRecord.communication_report ||
        "No communication report available.";
      const coachFontSize = 20;
      const coachMaxWidth = 894; // Updated width according to layout
      const coachLines = wrapText(
        coachCommunicationText,
        coachMaxWidth,
        coachFontSize,
        customFontLexend
      );

      // Calculate the total height needed for the coach communication text
      const coachTotalHeight = coachLines.length * coachFontSize * 1.2;

      // Adjust the y position dynamically based on the total height
      let coachCurrentY = page2.getHeight() - 320; // Updated top position according to layout

      coachLines.forEach((line, index) => {
        page2.drawText(line, {
          x: 432, // Updated left position according to layout
          y: coachCurrentY - index * coachFontSize * 1.2,
          size: coachFontSize,
          font: customFontLexend,
          color: rgb(1, 1, 1),
          width: coachMaxWidth,
          height: 126, // Updated height according to layout
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1, // Updated opacity according to layout
          letterSpacing: -0.03,
        });
      });
      const ego_centric_score = ((matchedRecord.ego_centric_score * 20) + (matchedRecord.group_centric_score * 20)) / 2;

      // Score Analysis
      const getPieChartColor = (score) => {
        if (score >= 1 && score <= 20) return "#DA0000"; // Red
        if (score >= 21 && score <= 40) return "#F90"; // Orange
        if (score >= 41 && score <= 60) return "#FFD600"; // Yellow
        if (score >= 61 && score <= 70) return "#A2D831"; // Light Blue
        if (score >= 71 && score <= 80) return "#70CD27"; // Green
        if (score >= 81 && score <= 95) return "#4BC500"; // Green
        if (score >= 95 && score <= 100) return "#37AB00"; // Green
        return "#D3D3D3"; // Grey for undefined or zero
      };

      const scoreAnalysis = [
        { label: "Listen", score: listenScore * 20 },
        { label: "Affirm", score: affirmScore * 20 },
        { label: "Respond", score: respondScore * 20},
        { label: "Ask", score: askScore * 20 },
        { label: "I / We", score: ego_centric_score },
      ];

      const drawPieChart = async (score) => {
        const angle = (score / 100) * 360;
        const color = getPieChartColor(score);
        // Calculate start and end points for the arc
        const startX = 66.5 + 59 * Math.cos((Math.PI * -90) / 180);
        const startY = 66.5 + 59 * Math.sin((Math.PI * -90) / 180);
        const endX = 66.5 + 59 * Math.cos((Math.PI * (angle - 90)) / 180);
        const endY = 66.5 + 59 * Math.sin((Math.PI * (angle - 90)) / 180);

        const largeArcFlag = angle > 180 ? 1 : 0;
        const pieSvg = `
            <svg width="100" height="100" viewBox="0 0 133 133" xmlns="http://www.w3.org/2000/svg">
              <defs>
          <filter id="filter0_d" x="0" y="0" width="133" height="133" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="3"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          
              </defs>
              <g>
          <circle cx="66.5" cy="66.5" r="59" stroke="#D3D3D3" stroke-width="6" fill="white"/>
          <path d="M${startX},${startY} A 59,59 0 ${largeArcFlag} 1 ${endX},${endY}" stroke="${color}" stroke-width="6" fill="none" stroke-linecap="round"/>
              </g>
              <style>
            @font-face {
              font-family: 'Lexend';
              src: url('aai-web-app-dev/src/components/Icons/Lexend-Regular.ttf') format('truetype');
            }
            @font-face {
              font-family: 'Poppins';
              src: url('aai-web-app-dev/src/components/Icons/Poppins-Regular.ttf') format('truetype');
            }
          </style>
              <text x="50%" y="50%" text-anchor="middle" fill="black" font-size="54px" font-family="Lexend, Poppins, sans-serif" font-weight="275" line-height="26px" text-align="center" opacity="1" dy=".3em">${score}</text>
            </svg>
          `;

        const svgBlob = new Blob([pieSvg], { type: "image/svg+xml" });
        const url = URL.createObjectURL(svgBlob);
        const img = new Image();
        img.src = url;
        await new Promise((resolve) => (img.onload = resolve));
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        URL.revokeObjectURL(url);
        return canvas.toDataURL("image/png");
      };

      const baseX = 100; // Updated x position
      const baseY = page2.getHeight() - 504; // Updated y position
      const gap = 275; // Updated gap between each pie chart

      for (const [index, item] of scoreAnalysis.entries()) {
        const xPosition = baseX + index * gap;

        const pieImagePng = await drawPieChart(item.score);
        const pieImageEmbedded = await pdfDoc.embedPng(pieImagePng);

        page2.drawImage(pieImageEmbedded, {
          x: xPosition,
          y: baseY - 119,
          width: 100, // Updated width
          height: 100, // Updated height
          opacity: 1,
        });

        // Draw the label next to the pie chart
        page2.drawText(item.label, {
          x: xPosition + 120, // Increased x position
          y: baseY - 70, // Decreased y position
          size: 25, // Updated size
          color: rgb(1, 1, 1), // Changed color to white
          width: 115,
          height: 46,
          textAlign: "left",
          opacity: 1,
          lineHeight: 48,
          fontWeight: 300,
          letterSpacing: -0.02,
          font: customFont,
        });
      }
      page2.drawRectangle({
        x: 340,
        y: page2.getHeight() - 1863,
        width: 1082,
        height: 0,
        borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
        borderWidth: 2,
        opacity: 1,
        rotate: degrees(90),
      });

      //call summary heading
      page2.drawText("Call Summary", {
        x: 421,
        y: page2.getHeight() - 890,
        size: 41,
        color: rgb(86 / 255, 143 / 255, 243 / 255),
        font: customFont,
        width: 283,
        height: 48,
        lineHeight: 48,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.02,
        textUnderlinePosition: "from-font",
        textDecorationSkipInk: "none",
      });

      const quickRecapText = quickRecap || "No quick recap available.";
      const fontSize = 20;
      const maxWidth = 901; // Updated width
      const lines = wrapText(
        quickRecapText,
        maxWidth,
        fontSize,
        customFontLexend
      );

      // Calculate the total height needed for the quick recap text
      const totalHeight = lines.length * fontSize * 1.2;

      // Adjust the y position dynamically based on the total height
      let currentY = page2.getHeight() - 947; // Updated top position

      lines.forEach((line, index) => {
        page2.drawText(line, {
          x: 426, // Updated left position
          y: currentY - index * fontSize * 1.2,
          size: fontSize,
          font: customFontLexend,
          color: rgb(0, 0, 0), // White color
          width: 901, // Updated width
          height: 126, // Updated height
          lineHeight: 24,
          fontWeight: 300,
          textAlign: "left",
          opacity: 1,
        });
      });

      // Update the currentY position for the next section
      currentY -= totalHeight;

      page2.drawText("Actions", {
        x: 421, // Adjusted left position
        y: page2.getHeight() - 1165, // Adjusted top position
        size: 41, // Adjusted font size
        color: rgb(86 / 255, 143 / 255, 243 / 255), // Adjusted color
        width: 395, // Adjusted width
        height: 57, // Adjusted height
        lineHeight: 48, // Adjusted line height
        textAlign: "left", // Left align the text
        fontWeight: 400, // Adjusted font weight
        font: customFont, // Using custom font
        opacity: 1, // Adjusted opacity
        letterSpacing: -0.02, // Adjusted letter spacing
      });

      console.log("callActions", callActions);
      currentY = page2.getHeight() - 1238;
      const maxY = page2.getHeight() - 1800;
      const minFontSize = 20;
      let minLineHeight = 9;
      if (callActions.length < 7) {
        minLineHeight = 18;
      }

      callActions.forEach((action, index) => {
        let fontSize = 20;
        let lineHeight = 28; // Updated line height to 28px
        let wrappedLines = wrapText(
          action.action,
          951,
          fontSize,
          customFontLexend
        );

        // Check if the currentY position will overlap with the next section
        while (
          currentY - wrappedLines.length * lineHeight < maxY &&
          fontSize > minFontSize
        ) {
          fontSize -= 1;
          lineHeight -= 1;
          wrappedLines = wrapText(
            action.action,
            951,
            fontSize,
            customFontLexend
          );
        }

        if (currentY - wrappedLines.length * lineHeight < maxY) {
          return;
        }

        wrappedLines.forEach((line, lineIndex) => {
          page2.drawText(`${lineIndex === 0 ? "• " : "  "}${line}`, {
            x: 413,
            y: currentY - lineIndex * lineHeight,
            size: fontSize,
            font: customFontLexend,
            color: rgb(0, 0, 0),
            width: 951,
            height: 382,
            gap: 0,
            opacity: 1,
            fontWeight: 400,
            lineHeight: lineHeight,
            letterSpacing: -0.03, // Updated letter-spacing to -0.03em
            textAlign: "left",
          });
        });

        currentY -= wrappedLines.length * lineHeight + lineHeight; // Move to the next bullet point position
      });

      // Draw a fixed rectangle
      page2.drawRectangle({
        x: 27,
        y: page2.getHeight() - 868 - 44, // Adjusted Y position
        width: 8,
        height: 44,
        color: rgb(255 / 255, 94 / 255, 0 / 255), // rgba(255, 94, 0, 1)
        opacity: 1,
      });
      // Import the Lexend-Bold font

      // Fetch and embed the Lexend-Bold font
      const fontLexendBoldBytes = await fetch(LexendBold).then((res) =>
        res.arrayBuffer()
      );
      const customFontLexendBold = await pdfDoc.embedFont(fontLexendBoldBytes);

      // Use the Lexend-Bold font in the text
      page2.drawText("Your Report", {
        x: 55,
        y: page2.getHeight() - 900,
        size: 20,
        fontFamily: "Lexend",
        font: customFontLexendBold,
        color: rgb(160 / 255, 160 / 255, 160 / 255),
        width: 225,
        height: 290,
        lineHeight: 24,
        fontWeight: 700,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
      });

      const text3 =
        "Continue reading the report to learn about each of your scores and our suggested improvements to your communication.";
      const text1Lines = wrapText(text3, 225, 20, customFontLexend);

      text1Lines.forEach((line, index) => {
        page2.drawText(line, {
          x: 55,
          y: page2.getHeight() - 955 - index * 24, // Adjusted y position to be 20 more y
          size: 20,
          font: customFontLexend,
          color: rgb(160 / 255, 160 / 255, 160 / 255), // #A0A0A0
          width: 225,
          height: 24,
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        });
      });

      // Draw the disclaimer text
      const disclaimerText = "*This web app uses generative AI, which may occasionally produce errors or misinterpretations. If you notice any inaccuracies or unexpected outputs, please report them to help us improve the system.";
      const disclaimerFontSize = 14;
      const disclaimerMaxWidth = 240;
      const disclaimerLines = wrapText(disclaimerText, disclaimerMaxWidth, disclaimerFontSize, customFontLexend);

      let disclaimerY = page2.getHeight() - 1709;

      disclaimerLines.forEach((line, index) => {
        page2.drawText(line, {
          x: 55,
          y: disclaimerY - index * disclaimerFontSize * 1.28571,
          size: disclaimerFontSize,
          font: customFontLexend,
          color: rgb(160 / 255, 160 / 255, 160 / 255),
          width: disclaimerMaxWidth,
          height: disclaimerFontSize * 1.28571,
          lineHeight: 18,
          fontWeight: 400,
          textAlign: "left",
          letterSpacing: -0.42,
          opacity: 1,
        });
      });
      // Draw a rectangle with the specified properties
      page2.drawRectangle({
        x: -38,
        y: page2.getHeight() - 1863,
        width: 1606,
        height: 0,
        borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
        borderWidth: 2,
        opacity: 1,
        rotate: degrees(0),
      });

      // Draw the text "Learn more about L.A.R.A at www.altercall.com/aai"
      page2.drawText("Learn more about at", {
        x: 540,
        y: page2.getHeight() - 1933,
        size: 20,
        color: rgb(0, 0, 0),
        font: customFontLexend,
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
      });

      // Draw the clickable link "www.altercall.com/aai"
      page2.drawText("www.altercall.com/aai", {
        x: 750,
        y: page2.getHeight() - 1933,
        size: 20,
        color: rgb(0, 118 / 255, 186 / 255),
        font: customFontLexend,
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
        underline: true,
      });

      page2.drawText("02", {
        x: 1437,
        y: page2.getHeight() - 1933,
        size: 18,
        color: rgb(255 / 255, 94 / 255, 0 / 255),
        width: 18,
        height: 19,
        opacity: 1,
      });

      const vectorFooterBlackPng = await svgToPng(vectorFooterBlackSvg);
      const vectorFooterBlackImage = await pdfDoc.embedPng(
        vectorFooterBlackPng
      );

      page2.drawImage(vectorFooterBlackImage, {
        x: 54,
        y: page2.getHeight() - 1933,
        width: 199,
        height: 38,
        opacity: 1,
      });
      //page 3 page 4 page 5 page 6
      const sections = [
        {
          title: "Listen",
          score: matchedRecord.listen_score * 20  ?? 0,
          report: matchedRecord.listen_report ?? "",
          areaOfImprovement: matchedRecord.listen_training_developments ?? "",
          details: matchedRecord.listen_details ?? "",
          strengths: matchedRecord.listen_strengths ?? "",
          weaknesses: matchedRecord.listen_weaknesses ?? "",
          page: "03",
        },
        {
          title: "Affirm",
          score: matchedRecord.affirm_score * 20 ?? 0,
          report: matchedRecord.affirm_report ?? "",
          areaOfImprovement: matchedRecord.affirm_training_developments ?? "",
          details: matchedRecord.affirm_details ?? "",
          strengths: matchedRecord.affirm_strengths ?? "",
          weaknesses: matchedRecord.affirm_weaknesses ?? "",
          page: "04",
        },
        {
          title: "Respond",
          score: matchedRecord.respond_score * 20 ?? 0,
          report: matchedRecord.respond_report ?? "",
          areaOfImprovement: matchedRecord.respond_training_developments ?? "",
          details: matchedRecord.respond_details ?? "",
          strengths: matchedRecord.respond_strengths ?? "",
          weaknesses: matchedRecord.respond_weaknesses ?? "",
          page: "05",
        },
        {
          title: "Ask",
          score: matchedRecord.ask_score * 20 ?? 0,
          report: matchedRecord.ask_report ?? "",
          areaOfImprovement: matchedRecord.ask_training_developments ?? "",
          details: matchedRecord.ask_details ?? "",
          strengths: matchedRecord.ask_strengths ?? "",
          weaknesses: matchedRecord.ask_weaknesses ?? "",
          page: "06",
        },
      ];

      for (const [index, section] of sections.entries()) {
        const page = pdfDoc.addPage([1500, 1974]); // Custom size

        //background color width full and height 106

        const svgGradientPng = await svgToPng(svgGradient);
        const svgGradientImage = await pdfDoc.embedPng(svgGradientPng);

        page.drawImage(svgGradientImage, {
          x: 0,
          y: page.getHeight() - 1863,
          width: 340,
          height: 1863,
          opacity: 1,
        });
        // Embed the altercall.ai logo
        page.drawImage(logoImage3, {
          x: 70,
          y: page.getHeight() - 77, // Adjusted for top position
          width: 229,
          height: 44,
        });

        page.drawImage(svgButtonImage, {
          x: 392,
          y: page.getHeight() - 33 - 44,
          width: 192,
          height: 40,
          opacity: 1,
        });

        // Draw coach name
        page.drawText(`${coachName}`, {
          x: 435, // Adjusted to match the left position
          y: page.getHeight() - 33 - 30, // Adjusted for top position
          size: 18,
          font: customFontLexend,
          color: rgb(0, 0, 0),
          width: 136, // Adjusted to match the width
          height: 22.5, // Adjusted to match the height
          lineHeight: 22.5,
          fontWeight: 300,
          textAlign: "left",
          opacity: 1, // Adjusted to match the opacity
          textUnderlinePosition: "from-font",
          textDecorationSkipInk: "none",
        });
        page.drawText(`${dateCustom}`, {
          x: 1273,
          y: page.getHeight() - 45 - 19, // Adjusted for top position
          size: 15,
          color: rgb(0, 0, 0),
          width: 155,
          height: 19,
          lineHeight: 19, // Updated line height
          fontWeight: 300,
          textAlign: "left",
          opacity: 1, // Updated opacity to 1
          font: customFontLexend,
        });

        page.drawRectangle({
          x: 340,
          y: page.getHeight() - 1853,
          width: 1373,
          height: 0,
          borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
          borderWidth: 2,
          opacity: 0,
          rotate: degrees(90),
        });

        // Draw pie chart for the section
        const drawSectionPieChart = async (score) => {
          score = score;
          const radius = 82;
          const textSize = "78px";
          const strokeWidth = 8;

          // Color mapping logic - adjust based on your needs
          const color = getPieChartColor(score);

          const circ = 2 * Math.PI * radius;
          const filledPortion = (score / 100) * circ;
          const strokeDashoffset = circ - filledPortion;

            const pieSvg = `
            <svg width="184" height="184" viewBox="0 0 184 184" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <filter id="shadow" x="-4" y="-4" width="192" height="192" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feDropShadow dx="0" dy="3" stdDeviation="2" flood-opacity="0.05" result="shadow"/>
              <feBlend in="SourceGraphic" in2="shadow" mode="normal"/>
              </filter>
            </defs>
            <g filter="url(#shadow)">
            <style>
          @font-face {
            font-family: 'Lexend';
            src: url('aai-web-app-dev/src/components/Icons/Poppins-Regular.ttf') format('truetype');
          }
              </style>
              <circle 
              cx="92" 
              cy="92" 
              r="${radius}" 
              fill="white" 
              stroke="#EBEBEB" 
              stroke-width="6" 
              stroke-linecap="round"
              />
              <circle 
              cx="92" 
              cy="92" 
              r="${radius}" 
              fill="white" 
              stroke="${color}" 
              stroke-width="${strokeWidth}" 
              stroke-linecap="round"
              stroke-dasharray="${circ}" 
              stroke-dashoffset="${strokeDashoffset}" 
              transform="rotate(-90 92 92)" 
              />
            </g>
            <text 
              x="50%" 
              y="50%" 
              text-anchor="middle"
              text-align="center" 
              font-size="${textSize}" 
              font-family="Lexend, Poppins, sans-serif" 
              opacity="1"
              font-weight="300" 
              fill="black" 
              dy=".3em"
            >
              ${isNaN(score) ? "-" : score === 0 ? "0" : Math.round(score)}
            </text>
            </svg>
          `;

          const svgBlob = new Blob([pieSvg], { type: "image/svg+xml" });
          const url = URL.createObjectURL(svgBlob);
          const img = new Image();
          img.src = url;
          await new Promise((resolve) => (img.onload = resolve));
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          URL.revokeObjectURL(url);
          return canvas.toDataURL("image/png");
        };
        const sectionPieChartPng = await drawSectionPieChart(
          section.score || 0
        );
        const sectionPieChartImage = await pdfDoc.embedPng(sectionPieChartPng);

        page.drawImage(sectionPieChartImage, {
          x: 74,
          y: page.getHeight() - 202 - 148,
          width: 184,
          height: 184,
          opacity: 1,
        });
        // Draw a horizontal line with the specified properties
        // page.drawRectangle({
        //   x: 0,
        //   y: page.getHeight() - 490,
        //   width: 1500,
        //   height: 16,
        //   color: rgb(255 / 255, 94 / 255, 0 / 255),
        //   opacity: 1,
        // });
        // Draw a vertical line with the specified properties
        page.drawRectangle({
          x: 27,
          y: page.getHeight() - 490,
          width: 8,
          height: 44,
          color: rgb(255 / 255, 94 / 255, 0 / 255),
          opacity: 1,
        });
        page.drawText("Your Category Score", {
          x: 64,
          y: page.getHeight() - 380.25,
          size: 20,
          font: customFontLexendBold,
          color: rgb(1, 1, 1),
          width: 200,
          height: 24,
          lineHeight: 24,
          fontWeight: 700,
          textAlign: "center",
          opacity: 1,
          letterSpacing: -0.03,
          textUnderlinePosition: "from-font",
          textDecorationSkipInk: "none",
        });
        const Poppins = await fetch(PoppinsBold).then((res) =>
          res.arrayBuffer()
        );
        const customFontPoppinsBold = await pdfDoc.embedFont(Poppins);
        // Draw the "Key Scoring Criteria" title
        page.drawText("Key Scoring Criteria:", {
          x: 55,
          y: page.getHeight() - 479,
          size: 20,
          font: customFontPoppinsBold,
          color: rgb(1, 1, 1), // Changed to #FFFFFF
          width: 225,
          height: 24,
          lineHeight: 24,
          fontWeight: 700,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        });

        const criteria = [
          {
            title: "Listen",
            content:
              "Active listening, avoiding interruptions, recognizing themes, and understanding non-verbal cues.",
          },
          {
            title: "Affirm",
            content:
              "Offering validation, providing encouragement, acknowledging the feelings and efforts.",
          },
          {
            title: "Respond",
            content:
              "Timeliness, clarity, and relevance of responses; offering feedback that reflects the progress.",
          },
          {
            title: "Ask",
            content:
              "Open-ended questions, depth of inquiry, fostering self-reflection and action planning.",
          },
        ];

        const matchingCriteria = criteria.find(
          (c) => c.title === section.title
        );

        if (matchingCriteria) {
          const criteriaText = matchingCriteria.content; // Get the content
          const criteriaFontSize = 20;
          const criteriaMaxWidth = 225;

          // Wrap text (assuming wrapText is a function that returns an array of wrapped lines)
          const criteriaLines = wrapText(
            criteriaText,
            criteriaMaxWidth,
            criteriaFontSize,
            customFontLexend
          );

          let criteriaCurrentY = page.getHeight() - 540; // Adjusted for top position

          // Draw the text line by line
          criteriaLines.forEach((line, index) => {
            page.drawText(line, {
              x: 55,
              y: criteriaCurrentY - index * criteriaFontSize * 1.2,
              size: criteriaFontSize,
              font: customFont,
              color: rgb(1, 1, 1), // White color
              width: criteriaMaxWidth,
              height: criteriaFontSize * 1.2,
              lineHeight: 24,
              fontWeight: 400,
              textAlign: "left",
              opacity: 1,
              letterSpacing: -0.03,
            });
          });
        } else {
          console.log(`No matching content found for title: ${section.title}`);
        }

        page.drawRectangle({
          x: 27,
          y: page.getHeight() - 790,
          width: 8,
          height: 44,
          color: rgb(255 / 255, 94 / 255, 0 / 255),
          opacity: 1,
        });
        page.drawText("Improvement Tips:", {
          x: 55,
          y: page.getHeight() - 779,
          size: 20,
          font: customFontPoppinsBold,
          color: rgb(1, 1, 1),
          width: 225,
          height: 24,
          lineHeight: 24,
          fontWeight: 700,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
          textUnderlinePosition: "from-font",
          textDecorationSkipInk: "none",
        });
        const criteriaImprovement = [
          {
            title: "Listen",
            content:
              "Practice mindfulness, focus on the speaker without distractions, and summarize key points back to the coachee.",
          },
          {
            title: "Affirm",
            content:
              "Use affirming language, focus on the coachee’s progress, and express appreciation for their insights and contributions.",
          },
          {
            title: "Respond",
            content:
              "Tailor responses to the coachee's unique situation, be clear and concise, and offer solutions that the coachee can apply.",
          },
          {
            title: "Ask",
            content:
              "Use probing questions to dig deeper into the coachee’s thoughts, avoid leading questions, and encourage self-directed problem-solving.",
          },
        ];
        // Check if the section.title matches any title in the criteria array
        const matchCriteria = criteriaImprovement.find(
          (c) => c.title === section.title
        );

        if (matchCriteria) {
          const criteriaText = matchCriteria.content; // Get the content
          const criteriaFontSize = 20;
          const criteriaMaxWidth = 235;

          // Wrap text (assuming wrapText is a function that returns an array of wrapped lines)
          const criteriaLines = wrapText(
            criteriaText,
            criteriaMaxWidth,
            criteriaFontSize,
            customFontLexend // Use the appropriate font here
          );

          let criteriaCurrentY = page.getHeight() - 840; // Adjusted for top position

          // Draw the text line by line
          criteriaLines.forEach((line, index) => {
            page.drawText(line, {
              x: 55,
              y: criteriaCurrentY - index * criteriaFontSize * 1.2,
              size: criteriaFontSize,
              font: customFont,
              color: rgb(1, 1, 1), // White color
              opacity: 1,
            });
          });
        } else {
          console.log(`No matching content found for title: ${section.title}`);
        }

        //report part

        page.drawText(section.title, {
          x: 436,
          y: page.getHeight() - 145,
          size: 34,
          font: customFont,
          color: rgb(0, 0, 0),
          width: 196,
          height: 75,
          opacity: 1,
          fontWeight: 300,
          lineHeight: 75,
          textAlign: "left",
        });

        // Coach session report
        const coachSessionReport =
          section.report ||
          `No coach ${section.title.toLowerCase()} report available.`;
        const coachReportFontSize = 18;
        const coachReportMaxWidth = 1000;
        const coachReportLines = wrapText(
          coachSessionReport,
          coachReportMaxWidth,
          coachReportFontSize,
          customFontLexend
        );

        let coachReportY = page.getHeight() - 190; // Adjusted for top position

        coachReportLines.forEach((line, index) => {
          page.drawText(line, {
            x: 436,
            y: coachReportY - index * coachReportFontSize * 1.2,
            size: coachReportFontSize,
            color: rgb(0, 0, 0),
            width: coachReportMaxWidth,
            height: 42, // Adjusted height
            lineHeight: 42,
            fontWeight: 300,
            textAlign: "left",
            opacity: 1, // Adjusted opacity
            letterSpacing: -0.03,
            font: customFontLexend,
            textUnderlinePosition: "from-font",
            textDecorationSkipInk: "none",
          });
        });
        page.drawRectangle({
          x: 340,
          y: page.getHeight() - 366,
          width: 1160,
          height: 0,
          borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
          borderWidth: 2,
          opacity: 1,
          rotate: degrees(0),
        });

        // Constants
        const sectionGap = 50; // Gap between sections
        const initialYPosition = page.getHeight() - 430; // Starting Y position
        const fontSize = 18;
        const adjustedFontSize = 16;
        const maxContentWidth = 990 - 60; // Maximum width for text content

        // Function to calculate wrapped lines and draw the content dynamically
        const drawSectionContent = async (
          title,
          content,
          iconSvg,
          iconDimensions,
          currentY,
          page
        ) => {
          const { width: iconWidth, height: iconHeight } = iconDimensions;

          // Embed the SVG icon
          const iconPng = await svgToPng(iconSvg);
          const iconImage = await pdfDoc.embedPng(iconPng);

          // Draw the icon
          page.drawImage(iconImage, {
            x: 392, // Icon X position
            y: currentY - iconHeight + 25, // Adjusted Y position based on icon height
            width: iconWidth,
            height: iconHeight,
            opacity: 1,
          });

          // Draw the section title
          page.drawText(title, {
            x: 504,
            y: currentY - 18, // Adjusted Y position based on icon height
            size: 34,
            color: rgb(86 / 255, 143 / 255, 243 / 255), // #568FF3
            width: maxContentWidth,
            font: customFont,
            fontWeight: 400,
            lineHeight: 48,
            letterSpacing: -0.02,
            textAlign: "left",
            textUnderlinePosition: "from-font",
            textDecorationSkipInk: "none",
            opacity: 1,
          });

          // Wrap the content text
          let wrappedLines = wrapText(
            content,
            maxContentWidth,
            fontSize,
            customFontLexend
          );
          let totalHeight = wrappedLines.length * 22;

          // Draw the wrapped lines
          wrappedLines.forEach((line, index) => {
            page.drawText(line, {
              x: 507,
              y: currentY - 60 - index * 22, // Adjusted Y position for lines
              size: 18,
              color: rgb(0, 0, 0), // Black color
              fontWeight: 400,
              font: customFontLexend,
              lineHeight: 22,
              opacity: 1,
              letterSpacing: -0.03,
              textAlign: "left",
              textUnderlinePosition: "from-font",
              textDecorationSkipInk: "none",
            });
          });

          // Return the updated Y position for the next section
          return currentY - iconHeight - totalHeight - 50 - sectionGap; // Adjusted for icon height and content
        };

        // Section Data with Icon Dimensions
        const sections = [
          {
            title: "Strengths",
            content:
              section.strengths ||
              `No ${section.title.toLowerCase()} strengths data available.`,
            icon: arrowUpRightStrengthsSvg,
            iconDimensions: { width: 74, height: 74 }, // Icon dimensions for strengths
          },
          {
            title: "Weaknesses",
            content:
              section.weaknesses ||
              `No ${section.title.toLowerCase()} weaknesses data available.`,
            icon: arrowDownRightWeaknessesSvg,
            iconDimensions: { width: 73, height: 73 }, // Icon dimensions for weaknesses
          },
          {
            title: "Improvements",
            content:
              section.areaOfImprovement ||
              "No areas for improvement available.",
            icon: improvementSvg,
            iconDimensions: { width: 55, height: 55 }, // Icon dimensions for improvements
          },
          {
            title: "Details",
            content: section.details || "No details available.",
            icon: listSvg,
            iconDimensions: { width: 51, height: 51 }, // Icon dimensions for details
          },
        ];

        // Render All Sections
        let currentY = initialYPosition;

        for (const section of sections) {
          currentY = await drawSectionContent(
            section.title,
            section.content,
            section.icon,
            section.iconDimensions,
            currentY,
            page
          );
        }

        // Draw the disclaimer text
        const disclaimerText = "*This web app uses generative AI, which may occasionally produce errors or misinterpretations. If you notice any inaccuracies or unexpected outputs, please report them to help us improve the system.";
        const disclaimerFontSize = 14;
        const disclaimerMaxWidth = 240;
        const disclaimerLines = wrapText(disclaimerText, disclaimerMaxWidth, disclaimerFontSize, customFontLexend);

        let disclaimerY = page.getHeight() - 1709;

        disclaimerLines.forEach((line, index) => {
          page.drawText(line, {
            x: 55,
            y: disclaimerY - index * disclaimerFontSize * 1.28571,
            size: disclaimerFontSize,
            font: customFontLexend,
            color: rgb(1 , 1, 1),
            width: disclaimerMaxWidth,
            height: disclaimerFontSize * 1.28571,
            lineHeight: 18,
            fontWeight: 400,
            textAlign: "left",
            letterSpacing: -0.42,
            opacity: 1,
          });
        });

        // Draw a horizontal line with the specified properties
        page.drawRectangle({
          x: -38,
          y: page.getHeight() - 1863,
          width: 1606,
          height: 0,
          borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
          borderWidth: 2,
          opacity: 1,
          rotate: degrees(0),
        });

        // Draw the text "Learn more about L.A.R.A at www.altercall.com/aai"
        page.drawText("Learn more about at", {
          x: 540,
          y: page.getHeight() - 1933,
          size: 20,
          color: rgb(0, 0, 0),
          font: customFontLexend,
          width: 894,
          height: 41,
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        });

        // Draw the clickable link "www.altercall.com/aai"
        page.drawText("www.altercall.com/aai", {
          x: 750,
          y: page.getHeight() - 1933,
          size: 20,
          color: rgb(0, 118 / 255, 186 / 255),
          font: customFontLexend,
          width: 894,
          height: 41,
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
          underline: true,
        });
        const pageno = section.page;
        page.drawText(pageno, {
          x: 1437,
          y: page.getHeight() - 1933,
          size: 18,
          color: rgb(255 / 255, 94 / 255, 0 / 255),
          width: 18,
          height: 19,
          opacity: 1,
        });

        const vectorFooterBlackPng = await svgToPng(vectorFooterBlackSvg);
        const vectorFooterBlackImage = await pdfDoc.embedPng(
          vectorFooterBlackPng
        );

        page.drawImage(vectorFooterBlackImage, {
          x: 54,
          y: page.getHeight() - 1933,
          width: 199,
          height: 38,
          opacity: 1,
        });
      }

      const sectionEgo = [
        {
          title: "I / We",
          ego_centric_score: ((matchedRecord.ego_centric_score * 20) + (matchedRecord.group_centric_score * 20)) / 2,
          ego_centric: matchedRecord.ego_centric,
          ego_group_polarity: matchedRecord.ego_group_polarity,
          ego_group_report: matchedRecord.ego_group_report,
          ego_group_tendency: matchedRecord.ego_group_tendency,
          group_centric: matchedRecord.group_centric,
          page: "07",
        }
      ]
      for (const [index, section] of sectionEgo.entries()) {
        const page = pdfDoc.addPage([1500, 1974]); // Custom size

        //background color width full and height 106

        const svgGradientPng = await svgToPng(svgGradient);
        const svgGradientImage = await pdfDoc.embedPng(svgGradientPng);

        page.drawImage(svgGradientImage, {
          x: 0,
          y: page.getHeight() - 1863,
          width: 340,
          height: 1863,
          opacity: 1,
        });
        // Embed the altercall.ai logo
        page.drawImage(logoImage3, {
          x: 70,
          y: page.getHeight() - 77, // Adjusted for top position
          width: 229,
          height: 44,
        });

        page.drawImage(svgButtonImage, {
          x: 392,
          y: page.getHeight() - 33 - 44,
          width: 192,
          height: 40,
          opacity: 1,
        });

        // Draw coach name
        page.drawText(`${coachName}`, {
          x: 435, // Adjusted to match the left position
          y: page.getHeight() - 33 - 30, // Adjusted for top position
          size: 18,
          font: customFontLexend,
          color: rgb(0, 0, 0),
          width: 136, // Adjusted to match the width
          height: 22.5, // Adjusted to match the height
          lineHeight: 22.5,
          fontWeight: 300,
          textAlign: "left",
          opacity: 1, // Adjusted to match the opacity
          textUnderlinePosition: "from-font",
          textDecorationSkipInk: "none",
        });
        page.drawText(`${dateCustom}`, {
          x: 1273,
          y: page.getHeight() - 45 - 19, // Adjusted for top position
          size: 15,
          color: rgb(0, 0, 0),
          width: 155,
          height: 19,
          lineHeight: 19, // Updated line height
          fontWeight: 300,
          textAlign: "left",
          opacity: 1, // Updated opacity to 1
          font: customFontLexend,
        });

        page.drawRectangle({
          x: 340,
          y: page.getHeight() - 1853,
          width: 1373,
          height: 0,
          borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
          borderWidth: 2,
          opacity: 0,
          rotate: degrees(90),
        });

        // Draw pie chart for the section
        const drawSectionPieChart = async (score) => {
          score = score;
          const radius = 82;
          const textSize = "78px";
          const strokeWidth = 8;

          // Color mapping logic - adjust based on your needs
          const color = getPieChartColor(score);

          const circ = 2 * Math.PI * radius;
          const filledPortion = (score / 100) * circ;
          const strokeDashoffset = circ - filledPortion;

            const pieSvg = `
            <svg width="184" height="184" viewBox="0 0 184 184" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <filter id="shadow" x="-4" y="-4" width="192" height="192" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feDropShadow dx="0" dy="3" stdDeviation="2" flood-opacity="0.05" result="shadow"/>
              <feBlend in="SourceGraphic" in2="shadow" mode="normal"/>
              </filter>
            </defs>
            <g filter="url(#shadow)">
            <style>
          @font-face {
            font-family: 'Lexend';
            src: url('aai-web-app-dev/src/components/Icons/Poppins-Regular.ttf') format('truetype');
          }
              </style>
              <circle 
              cx="92" 
              cy="92" 
              r="${radius}" 
              fill="white" 
              stroke="#EBEBEB" 
              stroke-width="6" 
              stroke-linecap="round"
              />
              <circle 
              cx="92" 
              cy="92" 
              r="${radius}" 
              fill="white" 
              stroke="${color}" 
              stroke-width="${strokeWidth}" 
              stroke-linecap="round"
              stroke-dasharray="${circ}" 
              stroke-dashoffset="${strokeDashoffset}" 
              transform="rotate(-90 92 92)" 
              />
            </g>
            <text 
              x="50%" 
              y="50%" 
              text-anchor="middle"
              text-align="center" 
              font-size="${textSize}" 
              font-family="Lexend, Poppins, sans-serif" 
              opacity="1"
              font-weight="300" 
              fill="black" 
              dy=".3em"
            >
              ${isNaN(score) ? "-" : score === 0 ? "0" : Math.round(score)}
            </text>
            </svg>
          `;

          const svgBlob = new Blob([pieSvg], { type: "image/svg+xml" });
          const url = URL.createObjectURL(svgBlob);
          const img = new Image();
          img.src = url;
          await new Promise((resolve) => (img.onload = resolve));
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          URL.revokeObjectURL(url);
          return canvas.toDataURL("image/png");
        };
        const sectionPieChartPng = await drawSectionPieChart(
          section.ego_centric_score || 0
        );
        const sectionPieChartImage = await pdfDoc.embedPng(sectionPieChartPng);

        page.drawImage(sectionPieChartImage, {
          x: 74,
          y: page.getHeight() - 202 - 148,
          width: 184,
          height: 184,
          opacity: 1,
        });
        // Draw a horizontal line with the specified properties
        // page.drawRectangle({
        //   x: 0,
        //   y: page.getHeight() - 490,
        //   width: 1500,
        //   height: 16,
        //   color: rgb(255 / 255, 94 / 255, 0 / 255),
        //   opacity: 1,
        // });
        // Draw a vertical line with the specified properties
        page.drawRectangle({
          x: 27,
          y: page.getHeight() - 490,
          width: 8,
          height: 44,
          color: rgb(255 / 255, 94 / 255, 0 / 255),
          opacity: 1,
        });
        page.drawText("Your Category Score", {
          x: 64,
          y: page.getHeight() - 380.25,
          size: 20,
          font: customFontLexendBold,
          color: rgb(1, 1, 1),
          width: 200,
          height: 24,
          lineHeight: 24,
          fontWeight: 700,
          textAlign: "center",
          opacity: 1,
          letterSpacing: -0.03,
          textUnderlinePosition: "from-font",
          textDecorationSkipInk: "none",
        });
        const Poppins = await fetch(PoppinsBold).then((res) =>
          res.arrayBuffer()
        );
        const customFontPoppinsBold = await pdfDoc.embedFont(Poppins);
        // Draw the "Key Scoring Criteria" title
        page.drawText("Key Scoring Criteria:", {
          x: 55,
          y: page.getHeight() - 479,
          size: 20,
          font: customFontPoppinsBold,
          color: rgb(1, 1, 1), // Changed to #FFFFFF
          width: 225,
          height: 24,
          lineHeight: 24,
          fontWeight: 700,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        });

        const criteria = [
          {
            title: "I / We",
            content:
              "I centric communication defines how we use language that is centred on the self ( in negative or positive way ) The We is when we use the language that is centered towards the group that shows that my language was inclusive .",
          },
        ];

        const matchingCriteria = criteria.find(
          (c) => c.title === section.title
        );

        if (matchingCriteria) {
          const criteriaText = matchingCriteria.content; // Get the content
          const criteriaFontSize = 20;
          const criteriaMaxWidth = 225;

          // Wrap text (assuming wrapText is a function that returns an array of wrapped lines)
          const criteriaLines = wrapText(
            criteriaText,
            criteriaMaxWidth,
            criteriaFontSize,
            customFontLexend
          );

          let criteriaCurrentY = page.getHeight() - 540; // Adjusted for top position

          // Draw the text line by line
          criteriaLines.forEach((line, index) => {
            page.drawText(line, {
              x: 55,
              y: criteriaCurrentY - index * criteriaFontSize * 1.2,
              size: criteriaFontSize,
              font: customFont,
              color: rgb(1, 1, 1), // White color
              width: criteriaMaxWidth,
              height: criteriaFontSize * 1.2,
              lineHeight: 24,
              fontWeight: 400,
              textAlign: "left",
              opacity: 1,
              letterSpacing: -0.03,
            });
          });
        } else {
          console.log(`No matching content found for title: ${section.title}`);
        }

        page.drawRectangle({
          x: 27,
          y: page.getHeight() - 911,
          width: 8,
          height: 44,
          color: rgb(255 / 255, 94 / 255, 0 / 255),
          opacity: 1,
        });
        page.drawText("Improvement Tips:", {
          x: 55,
          y: page.getHeight() - 900,
          size: 20,
          font: customFontPoppinsBold,
          color: rgb(1, 1, 1),
          width: 225,
          height: 24,
          lineHeight: 24,
          fontWeight: 700,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
          textUnderlinePosition: "from-font",
          textDecorationSkipInk: "none",
        });
        const criteriaImprovement = [
          {
            title: "I / We",
            content:
              "Use I went discussing self accountability and We when discussing group accountability",
          },
        ];
        // Check if the section.title matches any title in the criteria array
        const matchCriteria = criteriaImprovement.find(
          (c) => c.title === section.title
        );

        if (matchCriteria) {
          const criteriaText = matchCriteria.content; // Get the content
          const criteriaFontSize = 20;
          const criteriaMaxWidth = 235;

          // Wrap text (assuming wrapText is a function that returns an array of wrapped lines)
          const criteriaLines = wrapText(
            criteriaText,
            criteriaMaxWidth,
            criteriaFontSize,
            customFontLexend // Use the appropriate font here
          );

          let criteriaCurrentY = page.getHeight() - 960; // Adjusted for top position

          // Draw the text line by line
          criteriaLines.forEach((line, index) => {
            page.drawText(line, {
              x: 55,
              y: criteriaCurrentY - index * criteriaFontSize * 1.2,
              size: criteriaFontSize,
              font: customFont,
              color: rgb(1, 1, 1), // White color
              opacity: 1,
            });
          });
        } else {
          console.log(`No matching content found for title: ${section.title}`);
        }

        //report part

        page.drawText(section.title, {
          x: 436,
          y: page.getHeight() - 145,
          size: 34,
          font: customFont,
          color: rgb(0, 0, 0),
          width: 196,
          height: 75,
          opacity: 1,
          fontWeight: 300,
          lineHeight: 75,
          textAlign: "left",
        });

        // Coach session report
        const coachSessionReport =
          section.ego_group_report ||
          `No ${section.title.toLowerCase()} report available.`;
        const coachReportFontSize = 18;
        const coachReportMaxWidth = 1000;
        const coachReportLines = wrapText(
          coachSessionReport,
          coachReportMaxWidth,
          coachReportFontSize,
          customFontLexend
        );

        let coachReportY = page.getHeight() - 190; // Adjusted for top position

        coachReportLines.forEach((line, index) => {
          page.drawText(line, {
            x: 436,
            y: coachReportY - index * coachReportFontSize * 1.2,
            size: coachReportFontSize,
            color: rgb(0, 0, 0),
            width: coachReportMaxWidth,
            height: 42, // Adjusted height
            lineHeight: 42,
            fontWeight: 300,
            textAlign: "left",
            opacity: 1, // Adjusted opacity
            letterSpacing: -0.03,
            font: customFontLexend,
            textUnderlinePosition: "from-font",
            textDecorationSkipInk: "none",
          });
        });
        page.drawRectangle({
          x: 340,
          y: page.getHeight() - 366,
          width: 1160,
          height: 0,
          borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
          borderWidth: 2,
          opacity: 1,
          rotate: degrees(0),
        });

        // Constants
        const sectionGap = 50; // Gap between sections
        const initialYPosition = page.getHeight() - 430; // Starting Y position
        const fontSize = 18;
        const adjustedFontSize = 16;
        const maxContentWidth = 990 - 60; // Maximum width for text content

        // Function to calculate wrapped lines and draw the content dynamically
        const drawSectionContent = async (
          title,
          content,
          iconSvg,
          iconDimensions,
          currentY,
          page
        ) => {
          const { width: iconWidth, height: iconHeight } = iconDimensions;

          // Embed the SVG icon
          const iconPng = await svgToPng(iconSvg);
          const iconImage = await pdfDoc.embedPng(iconPng);

          // Draw the icon
          page.drawImage(iconImage, {
            x: 392, // Icon X position
            y: currentY - iconHeight + 25, // Adjusted Y position based on icon height
            width: iconWidth,
            height: iconHeight,
            opacity: 1,
          });

          // Draw the section title
          page.drawText(title, {
            x: 504,
            y: currentY - 18, // Adjusted Y position based on icon height
            size: 34,
            color: rgb(86 / 255, 143 / 255, 243 / 255), // #568FF3
            width: maxContentWidth,
            font: customFont,
            fontWeight: 400,
            lineHeight: 48,
            letterSpacing: -0.02,
            textAlign: "left",
            textUnderlinePosition: "from-font",
            textDecorationSkipInk: "none",
            opacity: 1,
          });

          // Wrap the content text
          let wrappedLines = wrapText(
            content,
            maxContentWidth,
            fontSize,
            customFontLexend
          );
          let totalHeight = wrappedLines.length * 22;

          // Draw the wrapped lines
          wrappedLines.forEach((line, index) => {
            page.drawText(line, {
              x: 507,
              y: currentY - 60 - index * 22, // Adjusted Y position for lines
              size: 18,
              color: rgb(0, 0, 0), // Black color
              fontWeight: 400,
              font: customFontLexend,
              lineHeight: 22,
              opacity: 1,
              letterSpacing: -0.03,
              textAlign: "left",
              textUnderlinePosition: "from-font",
              textDecorationSkipInk: "none",
            });
          });

          // Return the updated Y position for the next section
          return currentY - iconHeight - totalHeight - 50 - sectionGap; // Adjusted for icon height and content
        };

        // Section Data with Icon Dimensions
        const sections = [
          {
            title: "I Centric",
            content:
              section.ego_centric ||
              `No ${section.title.toLowerCase()} ego centric data available.`,
            icon: arrowUpRightStrengthsSvg,
            iconDimensions: { width: 74, height: 74 }, // Icon dimensions for strengths
          },
          {
            title: "We Centric",
            content:
              section.group_centric ||
              `No ${section.title.toLowerCase()} group centric data available.`,
            icon: arrowDownRightWeaknessesSvg,
            iconDimensions: { width: 73, height: 73 }, // Icon dimensions for weaknesses
          },
          {
            title: "I Group Polarity",
            content:
              section.ego_group_polarity ||
              `No ${section.title.toLowerCase()} ego group polarity data available.`,
            icon: improvementSvg,
            iconDimensions: { width: 55, height: 55 }, // Icon dimensions for improvements
          },
          {
            title: "I Group Tendency",
            content: section.ego_group_tendency || `No ${section.title.toLowerCase()} data available.`,
            icon: listSvg,
            iconDimensions: { width: 51, height: 51 }, // Icon dimensions for details
          },
        ];

        // Render All Sections
        let currentY = initialYPosition;

        for (const section of sections) {
          currentY = await drawSectionContent(
            section.title,
            section.content,
            section.icon,
            section.iconDimensions,
            currentY,
            page
          );
        }

        // Draw the disclaimer text
        const disclaimerText = "*This web app uses generative AI, which may occasionally produce errors or misinterpretations. If you notice any inaccuracies or unexpected outputs, please report them to help us improve the system.";
        const disclaimerFontSize = 14;
        const disclaimerMaxWidth = 240;
        const disclaimerLines = wrapText(disclaimerText, disclaimerMaxWidth, disclaimerFontSize, customFontLexend);

        let disclaimerY = page.getHeight() - 1709;

        disclaimerLines.forEach((line, index) => {
          page.drawText(line, {
            x: 55,
            y: disclaimerY - index * disclaimerFontSize * 1.28571,
            size: disclaimerFontSize,
            font: customFontLexend,
            color: rgb(1 , 1, 1),
            width: disclaimerMaxWidth,
            height: disclaimerFontSize * 1.28571,
            lineHeight: 18,
            fontWeight: 400,
            textAlign: "left",
            letterSpacing: -0.42,
            opacity: 1,
          });
        });

        // Draw a horizontal line with the specified properties
        page.drawRectangle({
          x: -38,
          y: page.getHeight() - 1863,
          width: 1606,
          height: 0,
          borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
          borderWidth: 2,
          opacity: 1,
          rotate: degrees(0),
        });

        // Draw the text "Learn more about L.A.R.A at www.altercall.com/aai"
        page.drawText("Learn more about at", {
          x: 540,
          y: page.getHeight() - 1933,
          size: 20,
          color: rgb(0, 0, 0),
          font: customFontLexend,
          width: 894,
          height: 41,
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        });

        // Draw the clickable link "www.altercall.com/aai"
        page.drawText("www.altercall.com/aai", {
          x: 750,
          y: page.getHeight() - 1933,
          size: 20,
          color: rgb(0, 118 / 255, 186 / 255),
          font: customFontLexend,
          width: 894,
          height: 41,
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
          underline: true,
        });
        const pageno = section.page;
        page.drawText(pageno, {
          x: 1437,
          y: page.getHeight() - 1933,
          size: 18,
          color: rgb(255 / 255, 94 / 255, 0 / 255),
          width: 18,
          height: 19,
          opacity: 1,
        });

        const vectorFooterBlackPng = await svgToPng(vectorFooterBlackSvg);
        const vectorFooterBlackImage = await pdfDoc.embedPng(
          vectorFooterBlackPng
        );

        page.drawImage(vectorFooterBlackImage, {
          x: 54,
          y: page.getHeight() - 1933,
          width: 199,
          height: 38,
          opacity: 1,
        });
      }

      //page 6
      const page6 = pdfDoc.addPage([1500, 1974]);

      // Set the background color for page 5
      page6.drawRectangle({
        x: 0,
        y: 0,
        width: page6.getWidth(),
        height: page6.getHeight(),
        color: rgb(1, 1, 1),
        opacity: 1,
      });

      // Draw the gradient overlay

      const gradientPng = await svgToPng(gradientSvg);
      const gradientImage = await pdfDoc.embedPng(gradientPng);

      page6.drawImage(gradientImage, {
        x: 0,
        y: page6.getHeight() - 1863, // Adjusted for top position
        width: page6.getWidth(),
        height: 1863,
        opacity: 1,
      });
      const logoImagePng = await svgToPng(logoSvg);
      const logoImageEmbedded = await pdfDoc.embedPng(logoImagePng);

      page6.drawImage(logoImageEmbedded, {
        x: 612,
        y: page6.getHeight() - 112 - 156,
        width: 275.58,
        height: 156,
        opacity: 1,
      });
      // Draw the first text block

      const text1 = "AlterCall AI is a";
      const text2 = "revolutionary";
      const ltext3 = "Leadership Platform";
      const text4 = "we measure the";
      const text5 = "previously";
      const text6 = "unmeasurable.";

      const pageWidth = page6.getWidth();
      const pageHeight = page6.getHeight();

      // Text Configuration
      const lfontSize = 78;
      const lineHeight = 88;
      const left = 136;
      const top = pageHeight - 987;
      const colorWhite = rgb(1, 1, 1); // White color
      const colorOrange = rgb(1, 94 / 255, 0); // Orange color

      // Draw Text 1
      page6.drawText(text1, {
        x: left,
        y: top,
        size: lfontSize,
        font: customFont,
        color: colorWhite,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 2
      page6.drawText(text2, {
        x: left,
        y: top - lineHeight,
        size: lfontSize,
        font: customFont,
        color: colorWhite,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 3
      page6.drawText(ltext3, {
        x: left,
        y: top - 2 * lineHeight,
        size: lfontSize,
        font: customFont,
        color: colorWhite,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 4
      page6.drawText(text4, {
        x: left,
        y: top - 3 * lineHeight,
        size: lfontSize,
        font: customFont,
        color: colorOrange,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 5
      page6.drawText(text5, {
        x: left,
        y: top - 4 * lineHeight,
        size: lfontSize,
        font: customFont,
        color: colorOrange,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 6
      page6.drawText(text6, {
        x: left,
        y: top - 5 * lineHeight,
        size: lfontSize,
        font: customFont,
        color: colorOrange,
        lineHeight: lineHeight,
        textAlign: "left",
      });
      // Draw the new text block
      const newText1 =
        "We are pioneering the coaching industry with a specialized";
      const newText2 =
        "AI platform that doesn't just speculate about your potential;";
      const newText3 = "it knows it,";
      const newText4 = "and more importantly, it's here to unlock it.";

      const newFontSize = 24;
      const newLineHeight = 35;
      const newLeft = 136;
      const newTop = pageHeight - 1544;
      const newColor = rgb(1, 1, 1); // White color

      // Draw new Text 1
      page6.drawText(newText1, {
        x: newLeft,
        y: newTop,
        size: newFontSize,
        font: customFontLexend,
        color: newColor,
        lineHeight: newLineHeight,
        textAlign: "left",
        fontWeight: 300,
      });

      // Draw new Text 2
      page6.drawText(newText2, {
        x: newLeft,
        y: newTop - newLineHeight,
        size: newFontSize,
        font: customFontLexend,
        color: newColor,
        lineHeight: newLineHeight,
        textAlign: "left",
        fontWeight: 300,
      });

      // Draw new Text 3
      // Draw new Text 3
      page6.drawText(newText3, {
        x: newLeft,
        y: newTop - 2 * newLineHeight,
        size: newFontSize,
        font: customFontLexend,
        color: newColor,
        lineHeight: newLineHeight,
        textAlign: "left",
        fontWeight: 700,
      });

      // Draw new Text 4
      page6.drawText(newText4, {
        x: newLeft + 128,
        y: newTop - 2 * newLineHeight,
        size: newFontSize,
        font: customFontLexendBold,
        color: newColor,
        lineHeight: newLineHeight,
        textAlign: "left",
        fontWeight: 1400,
      });

      // Draw a horizontal line with the specified properties
      page6.drawRectangle({
        x: -38,
        y: page6.getHeight() - 1863,
        width: 1606,
        height: 0,
        borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
        borderWidth: 2,
        opacity: 1,
        rotate: degrees(0),
      });
      page6.drawText("Learn more about at", {
        x: 540,
        y: page6.getHeight() - 1933,
        size: 20,
        font: customFontLexend,
        color: rgb(0, 0, 0),
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
      });

      // Draw the clickable link "www.altercall.com/aai"
      page6.drawText("www.altercall.com/aai", {
        x: 750,
        y: page6.getHeight() - 1933,
        size: 20,
        font: customFontLexend,
        color: rgb(0, 118 / 255, 186 / 255),
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
        underline: true,
      });

      page6.drawText("08", {
        x: 1437,
        y: page6.getHeight() - 1933,
        size: 18,
        color: rgb(255 / 255, 94 / 255, 0 / 255), // #FF5E00
        width: 18,
        height: 19,
        opacity: 1,
      });
      page6.drawImage(vectorFooterBlackImage, {
        x: 54,
        y: page6.getHeight() - 1933,
        width: 199,
        height: 38,
        opacity: 1,
      });

      // Serialize the PDF to Uint8Array and start the download
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      saveAs(blob, `${callName}.pdf`);
    } catch (err) {
      console.error("Error generating the PDF report:", err);
    }
  };

  const handleDownload = async () => {
    setIsLoading(true);
    await downloadReport();
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  // 11) Expand / collapse
  const handleToggle = (section) => {
    setOpenSections((prev) => (prev === section ? null : section));
  };

  // In your component body:

  const participantCount = (localFileData?.participants || []).length;
  console.log(participantCount);
  let overallScore = selectedData?.participant_overall_score || 0;
// Compute the count of unidentified participants.
const unidentifiedCount =
  fileData &&
  fileData.participants &&
  Array.isArray(listparticipants.participants)
    ? fileData.participants.filter((p, index) => {
        if (!p.user_id) {
          // Get the corresponding list participant by the same index:
          const currentListParticipant = listparticipants.participants[index] || {};
          const match_status = currentListParticipant.match_status || null;
          // Check if match_status is neither "guest" nor "matched"
          return match_status !== "guest" && match_status !== "matched";
        }
        return false;
      }).length
    : 0;

console.log("unidentifiedCount total check:", unidentifiedCount);

  

  // If more than 6 participants, force overallScore = 0
  if (participantCount > 7 || unidentifiedCount > 7) {
    overallScore = "N/A";
  }


  return (
    <Box
      sx={{
        width: "100%",
        marginTop: "20px",
        overflowY: "auto",
        // Use flex-column + gap to separate sections
        display: "flex",
        flexDirection: "column",
        gap: "50px",
      }}
    >
      <Frigade.Tour
        flowId="flow_EpzJqK4r"
        defaultOpen={true}
        sequential={true}
        dismissible={true}
        css={{
          ".fr-card": {
            backgroundColor: "#EFF3F6",
          },
          ".fr-button-primary": {
            backgroundColor: "white",
            color: "black",
            borderColor: "black",
            borderRadius: "50px",
            borderWidth: "1px",
            borderStyle: "solid",
            transition: "background-color 0.3s, color 0.3s",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          },
        }}
      />

      {/* SECTION 1: TOP ROW (Score Ring + Participant dropdown + Summary + Download) */}
      <Box
        sx={{
          // No margin-top/bottom here; spacing is controlled by the parent’s gap
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "100px",
            paddingX: "16px",
            marginBottom:"40px"
          }}
          >
          {/* Overall Score Ring */}
            <Box sx={{ minWidth: "200px" }}>
            <OverallScoreRing
              score={
              tempClientData?.ego_centric_score === 0 ||
              tempClientData?.group_centric_score === 0
                ? calculateAverageScore()
                : overallScore
              }
            />
            </Box>

            {/* Right Column */}
          <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            {/* Dropdown + Download row */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "16px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  fontSize: "11px",
                  lineHeight: "18px",
                  letterSpacing: "0.05em",
                  fontWeight: 400,
                  color: "#4A4A4A",
                  marginBottom: "4px",
                }}
              >
                Select participant to score
              </Typography>

              <ParticipantDropdown
                participants={participants}
                selectedParticipant={selectedParticipant}
                setSelectedParticipant={setSelectedParticipant}
              />
            </Box>

            <Button
              onClick={handleDownload}
              variant="outlined"
              sx={{
                width: "210px",
                height: "34px",
                borderRadius: "50px",
                backgroundColor: "white",
                color: "black",
                border: "1px solid black",
                padding: "6px 16px",
                textTransform: "none",
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "18px",
                letterSpacing: "0.05em",
                marginTop: "5px",
                marginRight: "5%",
              }}
            >
              {isLoading ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  style={{ animation: "spin 1s linear infinite" }}
                >
                  <path
                    d="M12 2V6M12 18V22M4.93 4.93L7.76 7.76M16.24 16.24L19.07 19.07M2 12H6M18 12H22M4.93 19.07L7.76 16.24M16.24 7.76L19.07 4.93"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <>
                  <DownloadIcon />
                  <Box sx={{ width: "10px" }} />
                  Download Full Report
                </>
              )}
            </Button>
          </Box>

          {/* Summary text right under dropdown */}
          <Box sx={{ marginTop: "8px" }}>
            <Typography
              sx={{
                color: "#4A4A4A",
                fontFamily: "Lexend",
                fontSize: "11px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "18px",
                maxWidth: "100%",
                marginTop: "4px",
              }}
            >
              {summaryText}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* SECTION 2: LIMIT BOX (only if participantCount > 3 and not dismissed) */}
      {participantCount > 7 && showLimitBox && (
        <Box
          sx={{
            borderRadius: "16px",
            background: "#EFF3F6",
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginX: "16px",
          }}
        >
          <Typography
            sx={{
              color: "#1E1B39",
              fontFamily: "Lexend",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "20px",
              marginBottom: "8px",
            }}
          >
            No group scores data available
          </Typography>

          <Typography
            sx={{
              color: "#828282",
              fontFamily: "Lexend",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "18px",
              marginBottom: "8px",
            }}
          >
            At this time, the participant group quantity is too large to provide
            accurate LARA scores
          </Typography>

          <Button
            onClick={() => setShowLimitBox(false)}
            sx={{
              display: "inline-flex",
              height: "28px",
              width: "100px",
              padding: "10px 15px",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              flexShrink: 0,
              backgroundColor: "transparent",
              border: "1px solid #D9E4EC",
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "18px",
              letterSpacing: "0.6px",
              textTransform: "capitalize",
              borderRadius: "50px",
              "&:hover": {
                backgroundColor: "#E0E0E0",
              },
            }}
          >
            Dismiss
          </Button>
        </Box>
      )}

      {unidentifiedCount > 7 && showBox && (
        <Box
          sx={{
            borderRadius: "16px",
            background: "#EFF3F6",
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginX: "16px",
          }}
        >
          <Typography
            sx={{
              color: "#1E1B39",
              fontFamily: "Lexend",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "20px",
              marginBottom: "8px",
            }}
          >
            Identify the participant to get the scores
          </Typography>

          <Typography
            sx={{
              color: "#828282",
              fontFamily: "Lexend",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "18px",
              marginBottom: "8px",
            }}
          >
            At this time, the participant selected is unable to be linked to an
            account.
          </Typography>

          <Button
            onClick={() => setShowBox(false)}
            sx={{
              display: "inline-flex",
              height: "28px",
              width: "100px",
              padding: "10px 15px",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              flexShrink: 0,
              backgroundColor: "transparent",
              border: "1px solid #D9E4EC",
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "18px",
              letterSpacing: "0.6px",
              textTransform: "capitalize",
              borderRadius: "50px",
              "&:hover": {
                backgroundColor: "#E0E0E0",
              },
            }}
          >
            Dismiss
          </Button>
        </Box>
      )}

      {/* SECTION 3: CATEGORY SECTIONS */}
      <Box sx={{ padding: "1% 6% 8% 2%" }}>
        {categories.map((section) => {
          const score = getCategoryScore(section);
          const report = getCategoryReport(section);
          const displayName =
            section === "ego"
              ? "I / We"
              : section.charAt(0).toUpperCase() + section.slice(1);

          return (
            <Box key={section} sx={{ marginBottom: "30px" }}>
              {/* Top row: Pie + label + expand/collapse */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                  {(participantCount > 7 || unidentifiedCount > 7) ? false :  (
                    <Box sx={{ marginRight: "16px" }}>
                      <Pie score={score} textSize="18px" />
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontFamily: "Lexend",
                        fontWeight: 400,
                        color: "#1E1B39",
                        lineHeight: "24px",
                      }}
                    >
                      {displayName}
                    </Typography>

                    <Typography
                      sx={{
                        color: "#4A4A4A",
                        fontFamily: "Lexend",
                        fontSize: 11,
                        fontWeight: 400,
                        lineHeight: "18px",
                        wordWrap: "break-word",
                        maxWidth: "98%",
                      }}
                    >
                      {report}
                    </Typography>
                  </Box>
                </Box>

                {/* Expand/Collapse icon */}
                <Box
                  onClick={() => handleToggle(section)}
                  sx={{
                    cursor: "pointer",
                    marginTop: "5px",
                    "& svg path": {
                      stroke: "black",
                      transition: "stroke 0.2s ease",
                    },
                    "&:hover svg path": {
                      background: "#EFF3F6",
                      stroke: "black",
                      borderRadius: "50%",
                      transition: "stroke 0.2s ease",
                      width: "18px",
                      height: "18px",
                    },
                  }}
                >
                  {openSections === section ? (
                    // Collapse icon
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M13.5 11.25C12.3172 9.61441 10.9328 8.14769 9.38259 6.88713C9.15775 6.70429 8.84226 6.70429 8.61741 6.88713C7.0672 8.14769 5.68276 9.61441 4.5 11.25"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    // Expand icon
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M4.5 6.75C5.68276 8.38559 7.0672 9.8523 8.61741 11.1129C8.84225 11.2957 9.15775 11.2957 9.38259 11.1129C10.9328 9.85231 12.3172 8.38559 13.5 6.75"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </Box>
              </Box>

              {/* Collapsible content */}
              <Collapse
                in={openSections === section}
                timeout="auto"
                unmountOnExit
                sx={{ marginTop: "8px" }}
              >
                {section === "ego"
                  ? renderEgoSectionContent(updatedLaraData)
                  : renderSectionContent(
                      section,
                      isCoacheeSelected ? "client" : "coach",
                      updatedLaraData
                    )}
              </Collapse>
            </Box>
          );
        })}
      </Box>

      {/* SECTION 4: Disclaimer */}
      <Box
        sx={{
          paddingX: "6%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "7px",
            fontWeight: 400,
            fontFamily: "Poppins",
            color: "#313131",
            lineHeight: "15px",
            marginTop: "24px",
          }}
        >
          *This web app uses generative AI, which may occasionally produce
          errors or misinterpretations. If you notice any inaccuracies or
          unexpected outputs, please report them to help us improve the system.
        </Typography>
      </Box>
    </Box>
  );
};

export default LaraSessionPage;
