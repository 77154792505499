import {
  Modal,
  Box,
  TextField,
  Typography,
  Chip,
  Grid,
  IconButton,
} from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import ConfirmButton from "components/ConfirmButton";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import { generateClient } from "aws-amplify/data";

const resourceClient = generateClient({ authMode: "userPool" });

//FOR VIRTUAL COACH COMPANIES TO ADD USERS TO THEIR TEAM

const VcAddUserModal = ({
  user,
  userData,
  openAddUserModal,
  setOpenAddUserModal,
  fetchCompanyData,
  availableSeats,
  companyInformation,
  companyMembers,
}) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [invites, setInvites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [companyId, setCompanyId] = useState(
    userData?.companyInformation?.company_id
  );
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setCompanyId(userData?.companyInformation?.company_id);
  }, [userData]);

  const handleAddInvite = () => {
    if (!firstName || !lastName) {
      setErrorMessage("Please provide a first and last name");
      return;
    }

    if (email && validateEmail(email)) {
      // Check if the email already exists in the invites
      const emailExists = invites.some(
        (invite) => invite.email === email.trim()
      );
      const emailExistsInCompanyMembers = companyMembers.some(
        (member) => member.email === email.trim()
      );

      if (emailExists) {
        setErrorMessage("Email has already been added");
        return;
      }

      if (emailExistsInCompanyMembers) {
        setErrorMessage("A member already exists with this email");
        return;
      }

      // Add the new invite
      setInvites([
        ...invites,
        {
          email: email.trim(),
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          title: title.trim(),
        },
      ]);

      // Clear input fields
      setEmail("");
      setFirstName("");
      setLastName("");
      setTitle("");
      setErrorMessage("");
    } else {
      setErrorMessage("Please provide a valid email address");
      return;
    }
  };

  const handleCloseAddUserModal = () => {
    setEmail("");
    setFirstName("");
    setLastName("");
    setTitle("");
    setErrorMessage("");
    setInvites([]);
    setOpenAddUserModal(false);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleDeleteInvite = (inviteToDelete) => () => {
    setInvites(
      invites.filter((invite) => invite.email !== inviteToDelete.email)
    );
  };

  const handleInvite = async () => {
    try {
      // Check available seats before proceeding
      if (availableSeats < 1) {
        toast.error(
          "Not enough seats available. Please upgrade your seat count to continue."
        );
        return;
      }

      if (invites.length > availableSeats) {
        toast.error(
          `You are trying to invite ${invites.length} user(s), but only ${availableSeats} seat(s) are available.`
        );
        return;
      }

      setLoading(true);
      const body = {
        user_id: user?.sub,
        invites,
        company_id: companyId,
        is_admin: false,
        company_name: userData?.companyInformation?.company_id,
      };

      // Send invites request to the server
      const response = await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/vc-add-members`,
        body
      );
      const { successfulInvites } = response.data;

      // Increment seat count for successful invites
      for (let i = 0; i < successfulInvites; i++) {
        await resourceClient.mutations.incrementCoacheeSeatCount({
          user_id: companyInformation?.owner_user_id,
        });
      }

      toast.success(
        `${successfulInvites} invitation${
          successfulInvites === 1 ? "" : "s"
        } sent successfully`
      );

      // Fetch updated company data
      await fetchCompanyData();
    } catch (error) {
      console.log("Error inviting users: ", error);
      toast.error("Failed to send user invitations.");
    } finally {
      handleCloseAddUserModal();
      setLoading(false);
    }
  };

  return (
    <Modal
      open={openAddUserModal}
      onClose={handleCloseAddUserModal}
      aria-labelledby="add-user-modal-title"
      aria-describedby="add-user-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "white",
          color: "black",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          maxWidth: "calc(100% - 40px)",
          maxHeight: {
            xs: "calc(100% - 50px)",
            md: "80%",
          },
          overflowY: "hidden",
          boxShadow: 24,
          borderRadius: "30px",
          padding: {
            xs: "24px",
            md: "64px",
          },
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ marginBottom: "16px" }}>
          <Typography
            sx={{
              color: "black",
              fontSize: 36,
              fontFamily: "Lexend",
              fontWeight: "300",
            }}
          >
            Add Users
          </Typography>
          <Typography
            sx={{
              color: "#828282",
              fontSize: 16,
              fontFamily: "Lexend",
              fontWeight: "500",
            }}
          >
            Press + to add the user.
          </Typography>
        </Box>
        <Box
          sx={{
            overflowY: "auto", // Add scrolling to this section
            flexGrow: 1, // Ensure this section grows to fill available space
            marginBottom: "16px", // Ensure some margin before the footer
            paddingRight: "16px", // For scrollbar padding (optional)
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <Typography
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  letterSpacing: "0.05em",
                }}
              >
                First Name
              </Typography>
              <TextField
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                fullWidth
                InputLabelProps={{ style: { color: "#4F4F4F" }, shrink: false }}
                InputProps={{
                  style: {
                    color: "black",
                    borderColor: "1px #C4CAD4 solid",
                    borderRadius: 16,
                  },
                  sx: {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C4CAD4",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C4CAD4",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C4CAD4",
                    },
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <Typography
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  letterSpacing: "0.05em",
                }}
              >
                Last Name
              </Typography>
              <TextField
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                fullWidth
                InputLabelProps={{ style: { color: "#4F4F4F" }, shrink: false }}
                InputProps={{
                  style: {
                    color: "black",
                    borderColor: "1px #C4CAD4 solid",
                    borderRadius: 16,
                  },
                  sx: {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C4CAD4",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C4CAD4",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C4CAD4",
                    },
                  },
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <Typography
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  letterSpacing: "0.05em",
                }}
              >
                Email
              </Typography>
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                InputLabelProps={{ style: { color: "#4F4F4F" }, shrink: false }}
                InputProps={{
                  style: {
                    color: "black",
                    borderColor: "1px #C4CAD4 solid",
                    borderRadius: 16,
                  },
                  sx: {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C4CAD4",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C4CAD4",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C4CAD4",
                    },
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <Typography
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  letterSpacing: "0.05em",
                }}
              >
                Title
              </Typography>
              <TextField
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
                InputLabelProps={{ style: { color: "#4F4F4F" }, shrink: false }}
                InputProps={{
                  style: {
                    color: "black",
                    borderColor: "1px #C4CAD4 solid",
                    borderRadius: 16,
                  },
                  sx: {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C4CAD4",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C4CAD4",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#C4CAD4",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex" }}>
              {/* Plus Icon Button to Add Invite */}
              <Box
                sx={{
                  display: "flex",
                  gap: "15px",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{ color: "red", fontSize: "12px", fontFamily: "Lexend" }}
                >
                  {errorMessage ?? ""}
                </Typography>
                <IconButton
                  color="primary"
                  onClick={handleAddInvite}
                  // disabled={!firstName.trim() || !lastName.trim() || !validateEmail(email)}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>

          {/* Display Invited Users */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "8px",
              marginTop: "16px",
            }}
          >
            {invites.length > 0 &&
              invites.map((invite, index) => (
                <Chip
                  key={index}
                  label={`${invite.firstName} ${invite.lastName} (${invite.email}) - ${invite.title}`}
                  onDelete={handleDeleteInvite(invite)}
                  color="primary"
                  sx={{
                    fontSize: 14,
                    backgroundColor: "#F1F1F1",
                    color: "black",
                    overflowX: "auto",
                  }}
                />
              ))}
          </Box>
        </Box>

        {/* Invite Button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "24px",
          }}
        >
          <ConfirmButton
            onClick={handleInvite}
            label={"Send"}
            disabled={invites.length < 1 || loading || !companyId}
            loading={loading}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default VcAddUserModal;
