import axios from "axios";

/**
 * Fetch assessments data for the given user and company.
 * @param {Object} params - Parameters for the fetch request.
 * @param {Object} params.user - The user object containing user details.
 * @returns {Promise<Object>} - 
 */
export const fetchAutoShareUsers = async ({ user, memberData }) => {
  if (!user || memberData?.length === 0 ) {
    console.warn("Missing user or member data for fetch auto share users");
    return null;
  }

  try {
    let response = [];
        // Fetch data for company context
        response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-auto-share-for-users`,
          {
            params: {
              user_id: user?.sub,
              member_user_ids: memberData?.map((user) => user.user_id).join(","),
            },
          }
        );
        console.log("res fetch auto share: ", response)
        const auto_share_users = response?.data?.recurring_meeting_data;
        console.log("res fetch auto share users: ", auto_share_users)

    return {
        auto_share_users
    };
  } catch (error) {
    console.error("Error fetching auto share users:", error);
    throw error; // Re-throw the error for handling in the calling function
  }
};
