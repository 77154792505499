import axios from "axios";
import { fetchAvatarUrl } from "utils/fetchAvatarUrl";

/**
 * Fetch assessments data for the given user and company.
 * @param {Object} params - Parameters for the fetch request.
 * @param {Object} params.user - The user object containing user details.
 * @param {Object} params.userData - The user data object containing company information.
 * @returns {Promise<Object>} - The fetched assessments data including members, teams, and assessments.
 */
export const fetchAssessments = async ({ user, userData }) => {
  if (!user || !userData) {
    console.warn("Missing user or userData for fetchAssessments");
    return null;
  }

  try {
    const userId = user?.sub;
    const companyId = userData?.companyInformation?.company_id; // Updated to `company_id` as an example
    if (!userId || !companyId) {
      console.warn("Invalid userId or companyId in fetchAssessments");
      return null;
    }

    let response;
    let members = [];
    let teams = [];
    let assessments = [];

    if (userData?.accessTo === "platform") {
      const endpointMap = {
        owner: "/zoom/owner-fetch-company-information",
        super_admin: "/zoom/super-admin-fetch-company-members",
        admin: "/zoom/admin-fetch-company-members",
        user: "/zoom/user-fetch-company-members",
        private: "/zoom/private-user-fetch-assessments",
      };

      const roleMap = {
        owner: "OWNER",
        super_admin: "SUPERADMINS",
        admin: "ADMINS",
        user: "USERS",
        private: "PRIVATE",
      };

      const endpoint = endpointMap[userData?.companyInformation?.my_user_role?.role_name];
      const cognitoRole = roleMap[userData?.companyInformation?.my_user_role?.role_name];

      if (endpoint) {
        response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}${endpoint}`, {
          params: {
            userId,
            userGroup: cognitoRole,
            companyId,
            fetchAssessments: true,
          },
        });

        console.log("Response from fetchAssessments:", response);

        members = response.data.members || [];
        teams = response.data.teams || response.data.myTeams || [];
        assessments = response.data.assessments || [];
      }
    } else {
      response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/executive-user-fetch-assessments`,
        {
          params: { userId, companyId },
        }
      );

      members = response.data.members || [];
      assessments = response.data.assessments || [];
    }

    if (teams.length > 0) {
      teams = teams.sort((a, b) => a.team_name.localeCompare(b.team_name));
    }

    const updateAvatarUrls = async (assessments) => {
      return Promise.all(
        assessments.map(async (assessment) => {
          if (assessment.user_avatar) {
            const avatarUrl = await fetchAvatarUrl(assessment.user_avatar);
            return { ...assessment, user_avatar: avatarUrl };
          }
          return assessment;
        })
      );
    };

    const updatedAssessments = await updateAvatarUrls(assessments);

    return {
      members,
      teams,
      assessments: updatedAssessments,
    };
  } catch (error) {
    console.error("Error fetching assessments data:", error);
    throw error; // Re-throw the error for handling in the calling function
  }
};
