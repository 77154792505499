import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Modal,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Check,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import {
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import avatar from "assets/avatar.png";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import PlaceIcon from "@mui/icons-material/Place";

import MenuIcon from "@mui/icons-material/Menu";
import PositiveActionButton from "components/PositiveActionButton";
import NegativeActionButton from "components/NegativeActionButton";
import DeclineButton from "components/DeclineButton";
import ConfirmButton from "components/ConfirmButton";
import { useDecrementClientCount } from "hooks/useDecrementClientCount";
import { formatDistanceToNow } from "date-fns";
import ClientLimitReached from "components/ClientLimitReachedModal";
import { useIncrementClientCount } from "hooks/useIncrementClientCount";
import MetricsComponent from "components/UserOverview/MetricsComponent";
import LaraClientOverview from "components/UserOverview/LaraClientOverview";
import GoalCard from "components/UserOverview/GoalCard";
import LaraSessionCircle from "components/UserOverview/LaraSessionCircle";
import axios from "axios";
import Assessments from "components/UserOverview/Assessments";
import EditActionIcon from "components/Icons/EditAction.svg";
import CompletedIcon from "components/Icons/Completed.svg";
import * as Frigade from "@frigade/react";
import { getUrl } from "aws-amplify/storage";
import "react-lazy-load-image-component/src/effects/blur.css";
import { fetchAvatarUrl } from "utils/fetchAvatarUrl"
import ActionsTable from "components/Table/CustomTable";
import { actionsColumns } from "components/UserOverview/Actions/ActionsColumns"
import {TodoActionsToolbar, CompletedActionsToolbar} from "components/UserOverview/Actions/ActionsToolbar"
import { useQuery } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      style={{
        height: "100%"
      }}
      {...other}
    >
      {value === index && <Box sx={{height: "100%"}}>{children}</Box>}
    </div>
  );
};

const UserOverview = ({ user, userData }) => {
  const collapsed = useSelector((state) => state.layout.collapsed);
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [clientData, setClientData] = useState([]);
  const [notes, setNotes] = useState([]);
  const [goals, setGoals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [editClientData, setEditClientData] = useState(false);
  const [openAddActionModal, setOpenAddActionModal] = useState(false);
  const [actionDate, setActionDate] = useState("");
  const [actionsSum, setActionsSum] = useState(0);
  const [sessionsSum, setSessionsSum] = useState(0);
  const [todoActionsSum, setTodoActionsSum] = useState(0);
  const [completedActionsSum, setCompletedActionsSum] = useState(0);
  const [overdueActionsSum, setOverdueActionsSum] = useState(0);
  const [noteMenuShow, setNoteMenuShow] = useState([]);
  const [selectedActions, setSelectedActions] = useState([]);
  const [selectedActionObjects, setSelectedActionObjects] = useState([]);
  const [selectedCompletedObjects, setSelectedCompletedObjects] = useState([]);
  const [selectedCompletedActions, setSelectedCompletedActions] = useState([]);
  const [openCompleteActionModal, setOpenCompleteActionModal] = useState(false);
  const [openUndoActionModal, setOpenUndoActionModal] = useState(false);
  const [openEditActionModal, setOpenEditActionModal] = useState(false);
  const [openDeleteActionModal, setOpenDeleteActionModal] = useState(false);
  const [updatedAction, setUpdatedAction] = useState({});
  const [initialAction, setInitialAction] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [openDueDateSetModal, setOpenDueDateSetModal] = useState(false);
  const [clientControlAnchorEl, setClientControlAnchorEl] = useState(null);
  const [activeTab, setActiveTab] = useState("editText");
  const [picture, setPicture] = useState(null);
  const { client_id } = useParams();
  const handleCloseEditAction = () => {
    setOpenEditActionModal(false);
    setInitialAction({});
    setActiveTab("editText");
  };
  const handleCloseCompleteAction = () => {
    setOpenCompleteActionModal(false);
  };
  const handleCloseUndoAction = () => {
    setOpenUndoActionModal(false);
  };
  const handleCloseDeleteAction = () => {
    setOpenDeleteActionModal(false);
  };
  const handleCloseSetDueDate = () => {
    setOpenDueDateSetModal(false);
  };
  const handleClose = () => {
    setOpenAddActionModal(false);
    formik3.resetForm();
    setActionDate("");
  };

  const queryClient = useQueryClient();


  const fetchCallData = async (client_id, coach_id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-coach-test`,
        {
          params: { client_id, coach_id },
        }
      );

      const { data } = response;
  
      // Return the necessary data
      return {
        calls: data?.data?.calls || [],
        averages: data?.data?.averages || {},
        callsByMonth: data?.data?.callsByMonth || [],
      };
    } catch (error) {
      console.error("Error fetching call data:", error);
      throw error; // Ensure React Query knows about the failure
    }
  };
  

  const useCallData = (client_id, coach_id) => {
    return useQuery({
      queryKey: ['callData', client_id, coach_id],
      queryFn: () => fetchCallData(client_id, coach_id),
      staleTime: 60000, // Use stale data for 1 minute
      cacheTime: 300000, // Cache data for 5 minutes
      enabled: !!client_id && !!coach_id, // Fetch only if parameters are valid
      onError: (error) => console.error("Error in useCallData:", error),
    });
  };

  const { data , isLoading: loadingLara } = useCallData(client_id, user?.sub);

  // Destructure the fetched data
  const { calls = [], averages: laraData = {}, callsByMonth = [] } = data || {};

  const deleteActionModal = () => {
    return (
      <Modal
        open={openDeleteActionModal}
        onClose={() => setOpenDeleteActionModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="black" fontWeight="400">
              {`Are you sure you want to delete selected actions?`}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              gap: { xs: "8px", md: "15px" },
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <DeclineButton onClick={handleCloseDeleteAction} label={"No"} />
            <ConfirmButton onClick={deleteActions} label={"Yes"} />
          </Box>
        </Box>
      </Modal>
    );
  };

  const deleteActions = async () => {
    try {
      setOpenDeleteActionModal(false);
  
      // Map through selected actions and delete each one
      const promises = selectedActions.map((action) =>
        axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/delete-action`, {
          action_id: action.action_id,
        })
      );
  
      // Wait for all delete requests to finish
      const responses = await Promise.allSettled(promises);
  
      // Filter successful deletions
      const successfulDeletions = selectedActions.filter(
        (action, index) => responses[index].status === "fulfilled"
      );
  
      // Display a single toast notification for the number of successful deletions
      if (successfulDeletions.length > 0) {
        // Optimistically update the cache
        queryClient.setQueryData(['actionsUserOverview', client_id, user?.sub], (oldData) => {
          if (!oldData) return oldData;
          return oldData.filter(
            (prevRow) =>
              !successfulDeletions.some(
                (deletedAction) => deletedAction.action_id === prevRow.action_id
              )
          );
        });
  
        // Show a success toast
        toast(`${successfulDeletions.length} actions deleted successfully`, {
          hideProgressBar: true,
          style: {
            background: "white",
            boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
            borderRadius: "8px",
            padding: "16px 28px",
            color: "#160042",
          },
          icon: () => <Check color="success" />,
        });
      }
  
      // Reset selections
      setSelectedActions([]);
      setSelectedCompletedActions([]);
      setSelectedCompletedObjects([]);
    } catch (error) {
      console.error("Error deleting actions:", error);
      toast.error("Error deleting actions");
    }
  };
  

  const [profilePicture, setProfilePicture] = useState(null);

  const fetchClientData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-client-data`,
        {
          params: {
            client_id: client_id,
            coach_id: user?.sub,
            company_id: userData?.companyInformation?.company_id,
          },
        }
      );
  
      if (!response.data) {
        setLoading(false);
        return;
      }
  
      const data = response.data.client_data;
  
      // Dynamically fetch avatar URL using fetchAvatarUrl
      const avatarUrl = data.avatar ? await fetchAvatarUrl(data.avatar) : null;
  
      const updatedData = {
        ...data,
        avatar: avatarUrl, // Update avatar with the fetched URL
      };
  
      setClientData(updatedData);
      setProfilePicture(avatarUrl || ""); // Set profile picture to the fetched URL or an empty string
      setLoading(false);
    } catch (error) {
      console.error("Error fetching client data:", error);
      setLoading(false);
    }
  };
  

  const fetchClientGoalsData = async () => {
    // setLoading(true);
    try {
      let response;

      response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-member-goals`,
        {
          params: {
            created_for: client_id,
            created_by: user?.sub,
          },
        }
      );

      if (!response.data.goals_data) {
        // setLoading(false);
        return;
      }

      const data = response.data.goals_data;

      setGoals(data);
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const fetchActionData = async (created_for, created_by) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-actions`,
        {
          params: { created_by, created_for },
        }
      );

      let idCounter = 0;

      const generateUniqueId = () => `${Date.now()}-${idCounter++}`;
  
      const data = Array.isArray(response.data.data) ? response.data.data : [];
      const updatedData = data.map((row) => ({
        id: generateUniqueId(),
        ...row,
      }));
  
      // Sort the data
      const sortedData = updatedData.sort(
        (a, b) => new Date(b.call_date) - new Date(a.call_date)
      );
  
      return sortedData; // Return sorted data
    } catch (error) {
      console.error("Error fetching action data:", error);
      throw error; // Throw error to let React Query handle retries/logging
    }
  };
  
  const useActions = (client_id, coach_id) => {
    return useQuery({
      queryKey: ['actionsUserOverview', client_id, coach_id],
      queryFn: () => fetchActionData(client_id, coach_id),
      staleTime: 60000, // Cache data for 1 minute
      cacheTime: 300000, // Keep data in cache for 5 minutes
      enabled: !!client_id && !!coach_id, // Only fetch if IDs are valid
      retry: 1, // Retry once on failure
      onError: (error) => console.error("Error in useActions:", error),
    });
  };
  
  const { data: actions = [], isLoading: loadingActions, refetch: refetchActions } = useActions(client_id, user?.sub);
  
  // Compute filtered actions directly
  const todoActions = useMemo(() => actions.filter(action => action.status === "incomplete"), [actions]);
  const completedActions = useMemo(() => actions.filter(action => action.status === "complete"), [actions]);
  
  

  const fetchClientNotes = async () => {
    // setLoading(true);
    try {
      const { data: response } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-member-notes`,
        {
          params: {
            client_id: client_id,
          },
        }
      );

      if (!response.data) {
        // setLoading(false);
        return;
      }

      const sortedData = Array.isArray(response.data)
        ? response.data.sort((a, b) => new Date(b.date) - new Date(a.date))
        : [];

      setNotes(sortedData);
      setNoteMenuShow([]);
      sortedData.map((item) => {
        setNoteMenuShow((prev) => [...prev, false]);
      });
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching action data:", error);
    }
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

  const a11yProps = (index) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  };

  const handleActionTabChange = (event, newValue) => {
    setTabValue(newValue);
    setSelectedActions([]);
    setSelectedActionObjects([]);
    setSelectedCompletedActions([]);
    setSelectedCompletedObjects([]);
  };

  const formik3 = useFormik({
    initialValues: {
      action_title: "",
      action: "",
      date: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      action: Yup.string().required("required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const body = {
          created_for: clientData.user_id,
          title: values.action_title,
          action: values.action,
          due_date: actionDate || null,
          created_by: user?.sub,
          client_name: clientData.full_name,
          coach_name: `${user?.given_name} ${user?.family_name}`,
          add: true,
        };
    
        // Close modal and show loading spinner
        setOpenAddActionModal(false);
        setLoading(true);
    
        // Make API call
        const response = await axios.put(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/add-action`,
          body
        );

        if(response.status === 200){
          toast.success("Action added successfully.")
        }

        refetchActions();
    
        // Clear the form and state
        setLoading(false);
        setActionDate("");
        resetForm(); // Reset the form values
      } catch (err) {
        console.error("Error in update:", err.message);
        toast.error(err.message);
        setLoading(false);
      }
    }
    
  });

  const completeActionModal = () => {
    return (
      <Modal
        open={openCompleteActionModal}
        onClose={() => setOpenCompleteActionModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="black" fontWeight="400">
              {`Are you sure you want to mark selected actions as completed?`}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              gap: { xs: "8px", md: "15px" },
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <DeclineButton onClick={handleCloseCompleteAction} label={"No"} />
            <ConfirmButton onClick={completeActions} label={"Yes"} />
          </Box>
        </Box>
      </Modal>
    );
  };

  const undoActionModal = () => {
    return (
      <Modal
        open={openUndoActionModal}
        onClose={() => setOpenUndoActionModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="black" fontWeight="400">
              {`Are you sure you want to mark selected actions as to-do?`}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              gap: { xs: "8px", md: "15px" },
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <DeclineButton onClick={handleCloseUndoAction} label="No" />
            <ConfirmButton onClick={undoActions} label={"Yes"} />
          </Box>
        </Box>
      </Modal>
    );
  };

  const ClientComponent = () => {
    return (
      <Box
        sx={{
          maxHeight: "100%",
        }}
        padding="24px"
      >
        <Box display="flex" gap="20px" alignItems="center">
          <Box
            sx={{
              background: "#0076BA",
              padding: "3px",
              borderRadius: "50%",
              width: {
                xs: "60px",
                lg: "80px",
                xl: "100px",
              },
              height: {
                xs: "60px",
                lg: "80px",
                xl: "100px",
              },
            }}
          >
            <Box
              sx={{
                background: "white",
                padding: "4px",
                borderRadius: "50%",
                width: {
                  xs: "54px",
                  lg: "74px",
                  xl: "94px",
                },
                height: {
                  xs: "54px",
                  lg: "74px",
                  xl: "94px",
                },
              }}
            >
              <Avatar
                sx={{
                  width: {
                    xs: "46px",
                    lg: "66px",
                    xl: "86px",
                  },
                  height: {
                    xs: "46px",
                    lg: "66px",
                    xl: "86px",
                  },
                }}
                src={clientData.avatar || avatar}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap="8px" width="100%">
            <Typography
              sx={{
                fontSize: { xs: "20px", md: "28px" },
                fontWeight: "bold",
                lineHeight: "1.2",
                color: "black",
              }}
            >
              {clientData.full_name}
            </Typography>
            {!editClientData && (
              <>
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontSize: "18px",
                    lineHeight: "1.35",
                    color: "black",
                  }}
                >
                  {clientData.occupation}
                </Typography>
              </>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: {
              xs: "center",
              xl: "unset",
            },
            flexDirection: {
              xs: "column",
              xl: "row",
            },
            gap: {
              xs: "24px",
              lg: "0",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              padding: {
                xs: "16px",
                lg: "20px 35px 0",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <EmailIcon
                sx={{
                  color: "#9DB2CE",
                }}
              />
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  fontSize: "18px",
                  lineHeight: "1.35",
                  color: "black",
                }}
              >
                {clientData.email}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <PhoneIphoneIcon
                sx={{
                  color: "#9DB2CE",
                }}
              />
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  fontSize: "18px",
                  lineHeight: "1.35",
                  color: "black",
                }}
              >
                {clientData.phone_number}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <PlaceIcon
                sx={{
                  color: "#9DB2CE",
                }}
              />
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  fontSize: "18px",
                  lineHeight: "1.35",
                  color: "black",
                }}
              >
                {clientData.city}, {clientData.state} {clientData.zip_code}
              </Typography>
            </Box>
            {/* <Typography
                            sx={{
                              fontFamily: "Lexend",
                              fontSize: "18px",
                              color: "#202226",
                              lineHeight: "1.35",
                            }}
                          >
                            last login: Feb 12, 2024
                          </Typography> */}
          </Box>
        </Box>
      </Box>
    );
  };

  const addActionModal = (
    <Modal
      open={openAddActionModal}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "white",
          color: "black",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 1000,
          boxShadow: 24,
          padding: {
            xs: "24px",
            lg: "64px",
          },
          gap: {
            xs: "24px",
            lg: "unset",
          },
          borderRadius: "30px",
          display: "flex",
          flexDirection: {
            xs: "column",
            lg: "row",
          },
          justifyContent: "space-between",
          maxWidth: "calc(100% - 40px)",
          margin: "20px auto",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            width: {
              xs: "100%",
              lg: "50%",
            },
            display: "flex",
            flexDirection: "column",
            gap: {
              xs: "8px",
              lg: "16px",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Lexend",
              maxWidth: {
                md: "350px",
              },
              fontSize: "36px",
              color: "black",
            }}
          >
            Create a new action for {clientData.full_name}
          </Typography>
          <Typography
            sx={{
              maxWidth: {
                lg: "280px",
              },
              fontSize: "14px",
              lineHeight: "1.5",
              letterSpacing: "0.3px",
              color: "black",
            }}
          >
            Once posted, the recipient will be notified of the action.
          </Typography>
        </Box>
        <Box
          sx={{
            width: {
              xs: "100%",
              lg: "50%",
            },
            maxWidth: {
              xs: "100%",
              lg: "340px",
            },
          }}
        >
          <form onSubmit={formik3.handleSubmit}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "22px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontSize: "12px",
                    lineHeight: "1.5",
                    letterSpacing: "2px",
                    fontWeight: "bold",
                    color: "black",
                    textTransform: "capitalize",
                  }}
                >
                  Action
                </Typography>
                <TextField
                  required
                  fullWidth
                  multiline
                  rows={4}
                  error={
                    formik3.touched.action && Boolean(formik3.errors.action)
                  }
                  {...formik3.getFieldProps("action")}
                  InputProps={{
                    style: {
                      color: "black",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "black",
                    },
                  }}
                  sx={{
                    color: "#22272F",
                    background: "white",
                    borderRadius: "16px",
                    width: "100%",
                    border: "1px solid #C4CAD4",
                    "&:before, &:after": {
                      display: "none",
                    },
                    "& fieldset": {
                      border: "none !important",
                    },
                  }}
                  focused={formik3.touched.action}
                />
                {formik3.touched.action && formik3.errors.action && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#BE0808",
                      letterSpacing: "2px",
                      fontSize: "8px",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    {formik3.touched.action && formik3.errors.action}
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontSize: "12px",
                    lineHeight: "1.5",
                    letterSpacing: "2px",
                    fontWeight: "bold",
                    color: "black",
                    textTransform: "capitalize",
                  }}
                >
                  Due Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="YYYY-MM-DD"
                    onChange={(date) => {
                      if (date) {
                        const formattedDate = dayjs(date)
                          .utc()
                          .format("YYYY-MM-DD");
                        setActionDate(formattedDate);
                      }
                    }}
                    sx={{
                      borderRadius: "16px",
                      border: "1px solid #C4CAD4",
                      "& svg": {
                        color: "#22272F",
                      },
                      "& input": {
                        color: "#22272F",
                      },
                      "& fieldset": {
                        border: "none !important",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box
              marginTop="42px"
              display="flex"
              justifyContent="flex-end"
              gap="15px"
            >
              <NegativeActionButton onClick={handleClose} label={"Cancel"} />
              <PositiveActionButton
                onClick={formik3.handleSubmit}
                label={"Create Action"}
                disabled={!actionDate || !formik3.values.action}
              />
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );

  const dueDateSetModal = () => {
    return (
      <Modal
        open={openDueDateSetModal}
        onClose={handleCloseSetDueDate}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box>
            <Typography color="black" fontWeight="400">
            {`Are you sure you want to set due date to ${dayjs(selectedDate).format("YYYY-MM-DD")}?`}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              gap: { xs: "8px", md: "15px" },
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <DeclineButton onClick={handleCloseSetDueDate} label={"No"} />
            <ConfirmButton onClick={setDueDate} label="Yes" />
          </Box>
        </Box>
      </Modal>
    );
  };

  const setDueDate = async () => {
    try {
      setOpenDueDateSetModal(false);
      const promises = selectedActions.map((row) =>
        axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/update-action-due-date`, {
          action_id: row.action_id,
          due_date: selectedDate,
        })
      );
  
      await Promise.all(promises);
  
    // Optimistically update the cache
    queryClient.setQueryData(['actionsUserOverview', client_id, user?.sub], (oldData) => {
      if (!oldData) return;
      return oldData.map((prevRow) =>
        selectedActions.some((action) => action.action_id === prevRow.action_id)
          ? { ...prevRow, due_date: selectedDate }
          : prevRow
      );
    });
  
      toast.success(`Due date set successfully`, { /* toast config */ });
      setSelectedActions([]);
    } catch (error) {
      console.error("Error setting due date:", error);
    } finally{
      handleCloseSetDueDate()
    }
  };
  

  const completeActions = async () => {
    try {
      const promises = selectedActions.map((action) =>
        axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/set-action-status`, {
          new_status: "complete",
          action_id: action.action_id,
        })
      );
  
      await Promise.all(promises);
  
    // Optimistically update the cache
    queryClient.setQueryData(['actionsUserOverview', client_id, user?.sub], (oldData) => {
      if (!oldData) return;
      return oldData.map((prevRow) =>
        selectedActions.some((action) => action.action_id === prevRow.action_id)
          ? { ...prevRow, status: "complete", completed_at: new Date() }
          : prevRow
      );
    });
  
      toast.success(`Actions marked as completed`, { /* toast config */ });
      setSelectedActions([]);
    } catch (err) {
      console.error("Error marking actions as complete:", err.message);
      toast.error(err.message);
    }finally{
      setOpenCompleteActionModal(false)
    }
  };
  

  const undoActions = async () => {
    try {
      // Make API calls to update the backend
      const promises = selectedActions.map((action) =>
        axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/set-action-status`, {
          new_status: "incomplete",
          action_id: action.action_id,
        })
      );
  
      await Promise.all(promises);
  
    // Optimistically update the cache
    queryClient.setQueryData(['actionsUserOverview', client_id, user?.sub], (oldData) => {
      if (!oldData) return;
      return oldData.map((prevRow) =>
        selectedActions.some((action) => action.action_id === prevRow.action_id)
          ? { ...prevRow, status: "incomplete" }
          : prevRow
      );
    });
  
      // Clear selected actions
      setSelectedCompletedActions([]);
      setSelectedCompletedObjects([]);
  
      toast(`Action marked as to-do`, {
        hideProgressBar: true,
        style: {
          background: "white",
          boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
          borderRadius: "8px",
          padding: "16px 28px",
          color: "#160042",
        },
        icon: () => <Check color="success" />,
      });
    } catch (err) {
      console.error("Error in update", err.message);
      toast.error(err.message);
    } finally {
      setOpenUndoActionModal(false);
    }
  };

  const NoteComponent = () => {
    const [openCreateNoteModal, setOpenCreateNoteModal] = useState(false);

    const handleOpenCreateNoteModal = () => {
      setOpenCreateNoteModal(true);
    };

    const handleCloseCreateNoteModal = () => {
      setOpenCreateNoteModal(false);
    };

    const formik4 = useFormik({
      initialValues: {
        note_title: "",
        note_description: "",
      },
      enableReinitialize: true,
      onSubmit: async (values) => {
        try {
          if (values.note_title === "" || values.note_description === "") {
            return;
          }

          await axios.put(
            `${process.env.REACT_APP_API_ENDPOINT}/zoom/save-client-note`,
            {
              note_id: uuid(),
              coach_id: user?.sub,
              client_id: client_id,
              client_name: clientData.full_name,
              title: values.note_title,
              note: values.note_description,
              date: new Date(Date.now()),
              status: "incomplete",
              add: true,
            }
          );

          toast(`Note was added successfully`, {
            hideProgressBar: true,
            style: {
              background: "white",
              boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
              borderRadius: "8px",
              padding: "16px 28px",
              color: "#160042",
            },
            icon: () => <Check color="success" />,
          });

          await fetchClientNotes();
          handleCloseCreateNoteModal();
        } catch (err) {
          console.log("Error in update", err.message);
          toast.error(err.message);
          setLoading(false);
        }
      },
    });

    const handleComplteNote = async (noteId) => {
      const res = await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/mark-note-as-complete`,
        {
          note_id: noteId,
        }
      );

      if (res.status === "success") {
        toast(`Note status updated successfully`, {
          hideProgressBar: true,
          style: {
            background: "white",
            boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
            borderRadius: "8px",
            padding: "16px 28px",
            color: "#160042",
          },
          icon: () => <Check color="success" />,
        });
      } else {
        toast.error("Error");
      }

      await fetchClientNotes();
    };

    const handleDeleteNote = async (noteId) => {
      const res = await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/delete-note`,
        {
          note_id: noteId,
        }
      );

      if (res.status === "success") {
        toast(`Note deleted successfully`, {
          hideProgressBar: true,
          style: {
            background: "white",
            boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
            borderRadius: "8px",
            padding: "16px 28px",
            color: "#160042",
          },
          icon: () => <Check color="success" />,
        });
      } else {
        toast.error("Error");
      }

      await fetchClientNotes();
    };

    return (
      <Box paddingTop="20px" display="flex" flexDirection="column" gap="36px">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Button
              sx={{
                paddingX: "30px",
                paddingY: "10px",
                background: "white",
                border: "1px solid black",
                color: "black",
                borderRadius: "43px",
                display: "flex",
                alignItems: "center",
                transition: "all 0.3s",
                "&:hover": {
                  cursor: "pointer",
                  background: "black",
                  borderColor: "black",
                },
                "&:hover *": {
                  color: "white",
                },
                "&:hover svg *": {
                  stroke: "white",
                },
              }}
              onClick={handleOpenCreateNoteModal}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  lineHeight: "18px",
                  fontWeight: 500,
                  letterSpacing: "0.05em",
                  textTransform: "none",
                }}
              >
                Add a Note
              </Typography>
            </Button>
          </Box>

          <Modal
            open={openCreateNoteModal}
            onClose={handleCloseCreateNoteModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                background: "white",
                border: "2px solid white",
                boxShadow: 24,
                paddingX: "20px",
                paddingY: "30px",
                borderRadius: "30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "8px",
                margin: "0 auto",
                maxWidth: "calc(100% - 40px)",
              }}
            >
              <Box
                sx={{
                  padding: "30px",
                  borderRadius: "30px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    maxWidth: {
                      md: "350px",
                    },
                    fontSize: "36px",
                    color: "black",
                    marginBottom: "16px",
                  }}
                >
                  Create a new note
                </Typography>
                <form onSubmit={formik4.handleSubmit}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "22px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Lexend",
                          fontSize: "12px",
                          lineHeight: "1.5",
                          letterSpacing: "2px",
                          fontWeight: "bold",
                          color: "black",
                          textTransform: "capitalize",
                        }}
                      >
                        Note Subject
                      </Typography>
                      <TextField
                        name="note_title"
                        {...formik4.getFieldProps("note_title")}
                        fullWidth
                        InputProps={{
                          placeholder: "Add note subject",
                          style: {
                            color: "black",
                          },
                        }}
                        sx={{
                          color: "#22272F",
                          background: "white",
                          borderRadius: "16px",
                          width: "100%",
                          border: "1px solid #C4CAD4",
                          "&:before, &:after": {
                            display: "none",
                          },
                          "& fieldset": {
                            border: "none !important",
                          },
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Lexend",
                          fontSize: "12px",
                          lineHeight: "1.5",
                          letterSpacing: "2px",
                          fontWeight: "bold",
                          color: "black",
                          textTransform: "capitalize",
                        }}
                      >
                        Add your note
                      </Typography>
                      <TextField
                        name="note_description"
                        {...formik4.getFieldProps("note_description")}
                        fullWidth
                        multiline
                        rows={4}
                        InputProps={{
                          style: {
                            color: "black",
                          },
                        }}
                        sx={{
                          color: "#22272F",
                          background: "white",
                          borderRadius: "16px",
                          width: "100%",
                          border: "1px solid #C4CAD4",
                          "&:before, &:after": {
                            display: "none",
                          },
                          "& fieldset": {
                            border: "none !important",
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    marginTop="42px"
                    display="flex"
                    justifyContent="flex-end"
                    gap="15px"
                  >
                    <NegativeActionButton
                      onClick={handleCloseCreateNoteModal}
                      label={"Cancel"}
                    />
                    <PositiveActionButton
                      onClick={formik4.handleSubmit}
                      label={"Save Note"}
                    />
                  </Box>
                </form>
              </Box>
            </Box>
          </Modal>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              height: "480px",
              overflowY: "auto",
            }}
          >
            {notes.map((note, index) => (
              <Box
                sx={{
                  padding: "24px",
                  background: "#F6F6F6",
                  borderRadius: "40px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Lexend",
                      fontSize: "22px",
                      lineHeight: "1.35",
                      color: "black",
                      fontWeight: "500",
                    }}
                  >
                    {note.title}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "16px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        padding: "0 26px",
                        background: "white",
                        borderRadius: "48px",
                        fontFamily: "Lexend",
                        fontSize: "10px",
                        lineHeight: "2.6",
                        fontWeight: "500",
                        color: "#202226",
                      }}
                    >
                      {note.date !== null &&
                        note.date !== "" &&
                        dayjs(note.date).utc().format("MM.DD.YY")}
                    </Typography>
                    <Box
                      sx={{
                        color: "#C4C4C4",
                        width: "30px",
                        height: "30px",
                        cursor: "pointer",
                        padding: "10px",
                        border: "1px solid #C4C4C4",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        const newStates = [...noteMenuShow];
                        newStates[index] = !newStates[index];
                        setNoteMenuShow(newStates);
                      }}
                    >
                      <MenuIcon
                        sx={{
                          color: "#C4C4C4",
                        }}
                      />
                    </Box>
                    {noteMenuShow[index] && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          position: "absolute",
                          paddingY: "6px",
                          top: "60px",
                          right: "24px",
                          width: "80px",
                          borderRadius: "12pt",
                          background: "#fff",
                          boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                          zIndex: "999",
                        }}
                      >
                        {/* <Button
                        sx={{
                          color: "black",
                          fontWeight: "500",
                          fontSize: "16px",
                          fontFamily: "Montserrat",
                          lineHeight: "1.4",
                          padding: "8px",
                          textAlign: "center",
                          "&:hover": {
                            background: "#F7F7F7",
                            cursor: "pointer",
                          },
                          "&:disabled": {
                            background: "grey",
                            color: "#F7F7F7",
                          }
                        }}
                        disabled={clientData.status === "1"}
                        onClick={() => {
                          // setInfo("");
                          setSelectedClient(row);
                          inviteClient();
                          // setOpenInviteModal(true);
                        }}
                      >
                        Invite
                      </Button> */}
                        <Button
                          sx={{
                            color: "black",
                            fontSize: "14px",
                            lineHeight: "1.4",
                            padding: "8px",
                            textAlign: "center",
                            textTransform: "none",
                            fontWeight: "200",
                            "&:hover": {
                              background: "#F5F5F5",
                              cursor: "pointer",
                            },
                            "&:disabled": {
                              color: "#d3d3d3",
                              cursor: "not-allowed",
                            },
                          }}
                          disabled={note.status === "complete"}
                          onClick={() => {
                            handleComplteNote(note.note_id);
                          }}
                        >
                          Done
                        </Button>
                        <Button
                          sx={{
                            color: "black",
                            fontSize: "14px",
                            lineHeight: "1.4",
                            padding: "8px",
                            textAlign: "center",
                            textTransform: "none",
                            fontWeight: "200",
                            "&:hover": {
                              background: "#F5F5F5",
                              cursor: "pointer",
                            },
                            "&:disabled": {
                              color: "#d3d3d3",
                              cursor: "not-allowed",
                            },
                          }}
                          onClick={() => {
                            handleDeleteNote(note.note_id);
                          }}
                        >
                          Delete
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Typography
                  sx={{
                    fontFamily: "Lexend",
                    fontSize: "14px",
                    lineHeight: "1.4",
                    color: "#202226",
                    fontWeight: "300",
                  }}
                >
                  {note.note}
                </Typography>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "16px",
                    right: "24px",
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      backgroundColor:
                        note.status === "complete" ? "#83BF6E" : "#FF6A55",
                    }}
                  />
                  <Typography sx={textStyle}>
                    {note.status === "complete" ? "Done" : "Not done"}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    );
  };


  const fetchSumData = useCallback(async () => {
    try {
      const { data: response } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-client-sum-by-coach`,
        {
          params: { created_for: client_id, created_by: user?.sub },
        }
      );

      if (response?.data) {
        setActionsSum(response.data.actions_sum);
        setSessionsSum(Number(response.data.session_sum || 0));
        setTodoActionsSum(response.data.todo_actions_sum);
        setCompletedActionsSum(response.data.completed_actions_sum);
        setOverdueActionsSum(response.data.overdue_actions_sum);
      }
    } catch (error) {
      console.error("Error fetching sum data:", error);
    }
  }, [client_id, user?.sub]);
  


  const editActionModal = () => {
    const handleTabChange = (tab) => {
      setActiveTab(tab);
    };
    let originalAction = "";
    if (initialAction && initialAction.edits && initialAction.edits[0]) {
      originalAction = initialAction.edits[0].previous_value;
    }
    return (
      <Modal open={openEditActionModal} onClose={handleCloseEditAction}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            background: "white",
            border: "2px solid white",
            boxShadow: 24,
            paddingX: "50px",
            paddingY: "30px",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "20px",
            margin: "0 auto",
            maxWidth: "calc(100% - 40px)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: {
                xs: "8px",
                lg: "32px",
              },
            }}
          >
            <Box display="flex" gap="10px">
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 600,
                  padding: "4px",
                  borderBottom:
                    activeTab === "editText" ? "1px solid black" : "none",
                  textTransform: "capitalize",
                  boxShadow: "none",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => handleTabChange("editText")}
              >
                Edit Action
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 600,
                  padding: "4px",
                  borderBottom:
                    activeTab === "editHistory" ? "1px solid black" : "none",
                  textTransform: "capitalize",
                  boxShadow: "none",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => handleTabChange("editHistory")}
              >
                View History
              </Typography>
            </Box>
            {activeTab === "editHistory" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  height: "220px",
                  overflowY: "auto",
                }}
              >
                {initialAction.edits &&
                  initialAction.edits
                    .slice()
                    .reverse()
                    .map((edit, index) => (
                      <Box key={index} sx={{ gap: "2px" }}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "black",
                          }}
                        >
                          {`${edit.new_value}`}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#2D2D2D",
                            fontSize: "12px",
                            marginLeft: "10px",
                            marginBottom: "6px",
                          }}
                        >
                          edited by{" "}
                          {edit.editor_name || "Unknown"}{" "}
                          {formatDistanceToNow(new Date(edit.edit_timestamp))}{" "}
                          ago
                        </Typography>
                        <Divider color="#d3d3d3" />
                      </Box>
                    ))}
                {initialAction.edits && initialAction.edits[0] && (
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "black",
                      }}
                    >
                      {originalAction}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#2D2D2D",
                        fontSize: "12px",
                        marginLeft: "10px",
                      }}
                    >
                      orginal action
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
            {activeTab === "editText" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Lexend",
                      fontSize: "12px",
                      lineHeight: "150%",
                      letterSpacing: "2px",
                      color: "black",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                  >
                    Action
                  </Typography>
                  <TextField
                    required
                    fullWidth
                    autoFocus
                    multiline
                    rows={4}
                    value={updatedAction?.action || ""}
                    InputProps={{
                      inputProps: {
                        style: {
                          color: "black",
                        },
                      },
                      style: {
                        borderRadius: "16px",
                        border: "1px solid #C4CAD4",
                        backgroundColor: "transparent",
                      },
                    }}
                    onChange={(event) => {
                      setUpdatedAction((prev) => ({
                        ...prev,
                        action: event.target.value, // Update the action text
                      }));
                    }}
                  />
                </Box>
                <Box display="flex" justifyContent="flex-end" gap="15px">
                  <NegativeActionButton
                    onClick={handleCloseEditAction}
                    label={"Cancel"}
                  />
                  <PositiveActionButton
                    onClick={saveEditAction}
                    label={"Save Action"}
                    disabled={updatedAction.action === initialAction.action}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Modal>
    );
  };

  const saveEditAction = async () => {
    try {
      setOpenEditActionModal(false);
      await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/update-action`, {
        action_id: updatedAction.action_id,
        action: updatedAction.action,
        initial_action: initialAction.action,
        edited_by_userID: user?.sub,
      });
  
      // Optimistically update the cache
      queryClient.setQueryData(['actionsUserOverview', client_id, user?.sub], (oldData) => {
        if (!oldData) return oldData;
        return oldData.map((prevRow) =>
          prevRow.action_id === updatedAction.action_id
            ? { ...prevRow, action: updatedAction.action }
            : prevRow
        );
      });
      
      toast.success(`Updated action successfully`, { /* toast config */ });
    } catch (error) {
      console.error("Error updating action:", error);
    }
  };

  useEffect(() => {
    fetchClientData();
    fetchClientGoalsData();
    fetchClientNotes();
  }, []);

  useEffect(() => {
    fetchSumData();
  }, [undoActions, completeActions, deleteActions, setDueDate]);

  const encodeFilePath = (path) => {
    const fileName = path.split('/').pop();  // Get the file name
    return encodeURIComponent(fileName).replace(/%20/g, ' '); // Replace %20 with space if necessary
  };

  const fetchClientProfilePicture = async () => {
    if (!clientData || !clientData.avatar || clientData.avatar === "") {
      setPicture(avatar);
      setLoading(false);
      return;
    }

    try {
      const encodedFileName = encodeFilePath(clientData.avatar);
      const filePath = `public/avatar/${encodedFileName}`;

      const { url } = await getUrl({
        path: filePath,
        expires: 900, // URL valid for 15 minutes
        validateObjectExistence: true, 
      });

      if (url) {
        setPicture(url.href); 
      } else {
        setPicture(avatar);
      }
    } catch (error) {
      console.error("Error fetching profile picture:", error);
      setPicture(avatar); // 
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClientProfilePicture();
  }, [clientData]);


  return loading ? (
    <GlobalPageComponent user={user} userData={userData}>
      <Frigade.Tour
        flowId="flow_FnM7KnhQ"
        defaultOpen={true}
        sequential={true}
        dismissible={true}
        css={{
          ".fr-card": {
            backgroundColor: "#EFF3F6",
          },
          ".fr-button-primary": {
            backgroundColor: "white",
            color: "black",
            borderColor: "black",
            borderRadius: "50px",
            borderWidth: "1px",
            borderStyle: "solid",
            transition: "background-color 0.3s, color 0.3s",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          },
        }}
      />
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    </GlobalPageComponent>
  ) : (
    <GlobalPageComponent user={user} userData={userData}>
      <ClientLimitReached user={user} userData={userData} />
      {editActionModal()}
      {completeActionModal()}
      {deleteActionModal()}
      {dueDateSetModal()}
      {undoActionModal()}
      <Box
        sx={{
          backgroundColor: "#EFF3F6",
          width: "100%",
          height: "100%",
          padding: "16px",
          display: {
            xs: !collapsed ? "none" : "block",
          },
          overflow: "auto",
        }}
      >
          <Box sx={{display: "flex", height: "100%", width: "100%", flexDirection: "column", gap: "8px", overflow: "hidden" }}>
            <LaraClientOverview
              clientName={clientData.full_name}
              client_id={clientData.user_id}
              picture={clientData?.avatar}
              avatar={avatar}
              laraData={laraData}
              loadingClientInfo={loading}
              loadingLara={loadingLara}
              setPicture={setPicture}
            />
            <Box sx = {{display: "flex", flexDirection: {xs: "column", md: "row"}, gap: "8px", width: "100%", height: "100%", overflow: "hidden"}}>
              {/* Left Side - Actions */}
              <Box
                sx={{
                  width: {xs: "100%", md: "50%"},
                  height: "100%",
                }}
              >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "24px",
                      borderRadius: "40px",
                      gap: "20px",
                      height: "100%",
                      background: "white"
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Tabs
                        value={tabValue}
                        onChange={handleActionTabChange}
                        TabIndicatorProps={{ style: { backgroundColor: "black" } }}
                        sx={{
                          overflow: "visible",
                          "& .MuiTab-textColorPrimary": {
                            color: "black",
                            fontFamily: "Montserrat",
                          },
                          "& .MuiTab-root.MuiButtonBase-root": {
                            minHeight: { xs: "16px", lg: "32px" },
                            fontSize: { xs: "12px", lg: "14px" },
                            fontWeight: 500,
                            lineHeight: "18px",
                            fontFamily: "Poppins",
                            letterSpacing: "0.05em",
                          },
                          "& .MuiTabs-flexContainer": {
                            display: "flex",
                            // justifyContent: {
                            //   xs: "space-between!important",
                            //   lg: "flex-start!important",
                            // },
                          },
                          "& .MuiTabs-scroller": {
                            overflowX: {
                              xs: "auto !important",
                              lg: "visible !important",
                            },
                            overflowY: {
                              xs: "visible !important",
                            },
                          },
                        }}
                      >
                        <Tab
                          icon={<EditActionIcon />}
                          iconPosition="start"
                          label="To-Do"
                          sx={{
                            letterSpacing: "0.05em",
                            fontSize: 12,
                            fontWeight: 500,
                            lineHeight: "18px",
                            fontFamily: "Poppins",
                            textTransform: "none"
                          }}
                        />
                        <Tab
                          icon={<CompletedIcon/>}
                          iconPosition="start"
                          label="Completed"
                          sx={{
                            letterSpacing: "0.05em",
                            lineHeight: "18px",
                            fontSize: 12,
                            fontWeight: 500,
                            fontFamily: "Poppins",
                            textTransform: "none"
                          }}
                        />
                      </Tabs>
                      <Button
                        onClick={() => setOpenAddActionModal(true)}
                        sx={{
                          paddingX: "30px",
                          paddingY: "10px",
                          background: "white",
                          border: "1px solid black",
                          color: "black",
                          borderRadius: "43px",
                          display: "flex",
                          alignItems: "center",
                          transition: "all 0.3s",
                          "&:hover": {
                              cursor: "pointer",
                              background: "black",
                              borderColor: "black",
                            },
                            "&:hover *": {
                              color: "white",
                            },
                            "&:hover svg *": {
                              stroke: "white",
                            },
                        }}
                        data-cy="add-action-button"
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "12px",
                            lineHeight: "18px",
                            fontWeight: 500,
                            letterSpacing: "0.05em",
                            textTransform: "none",
                          }}
                        >
                          <span id="hint-anchor-2">Add an Action</span>
                        </Typography>
                      </Button>
                    </Box>
                    <CustomTabPanel value={tabValue} index={0}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          height: "100%",
                          overflow: "auto",
                          gap: "8px"
                        }}
                      >
                      <TodoActionsToolbar
                        selectedActions={selectedActions}
                        selectedActionObjects={selectedActions} // Use `selectedActions` directly
                        setInitialAction={setInitialAction}
                        setUpdatedAction={setUpdatedAction}
                        setOpenEditActionModal={setOpenEditActionModal}
                        setOpenCompleteActionModal={setOpenCompleteActionModal}
                        setOpenDeleteActionModal={setOpenDeleteActionModal}
                        setOpenDueDateSetModal={setOpenDueDateSetModal}
                        selectedDate={selectedDate} // Pass selectedDate as a prop
                        setSelectedDate={setSelectedDate} // Pass setSelectedDate as a prop
                      />
                      <ActionsTable
                        rows={todoActions}
                        loading={loadingActions}
                        columns={actionsColumns}
                        checkboxSelection={true}
                        pageSize={50}
                        rowHeight={90}
                        selectionModel={selectedActions.map((row) => row.id)} // Bind the selection to selectedActions
                        onSelectionModelChange={(ids) => {
                          const selectedRows = todoActions.filter((row) => ids.includes(row.id));
                          setSelectedActions(selectedRows); // Update selected action objects
                        }}
                        getRowClassName={(params) =>
                          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                        }
                        localeText={todoActions?.length ? "" : "No actions yet."}
                      />

                    </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={1}>
                    <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          height: "100%",
                          overflow: "auto",
                          gap: "8px"
                        }}
                    >
                      <CompletedActionsToolbar 
                        selectedCompletedActions={selectedActions}
                        selectedActionObjects={selectedActions} // Use `selectedActions` directly
                        setInitialAction={setInitialAction}
                        setUpdatedAction={setUpdatedAction}
                        setOpenEditActionModal={setOpenEditActionModal}
                        setOpenUndoActionModal={setOpenUndoActionModal}
                        setOpenDeleteActionModal={setOpenDeleteActionModal}
                        setOpenDueDateSetModal={setOpenDueDateSetModal}
                        selectedDate={selectedDate} // Pass selectedDate as a prop
                        setSelectedDate={setSelectedDate} // Pass setSelectedDate as a prop
                      />
                      <ActionsTable
                        rows={completedActions}
                        loading={loadingActions}
                        columns={actionsColumns}
                        checkboxSelection={true}
                        pageSize={50}
                        rowHeight={90}
                        selectionModel={selectedActions.map((row) => row.id)} // Bind the selection to selectedActions
                        onSelectionModelChange={(ids) => {
                          const selectedRows = completedActions.filter((row) => ids.includes(row.id));
                          setSelectedActions(selectedRows); // Update selected action objects
                        }}
                        getRowClassName={(params) =>
                          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                        }
                        localeText={completedActions?.length ? "" : "No actions yet."}
                      />
                    </Box>
                    </CustomTabPanel>
                  </Box>
              </Box>

              {/* Right Side - Tabs */}
              <Box
                sx={{
                  width: {xs: "100%", md: "50%"},
                  height: "100%"
                }}
              >
                <Box 
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "24px",
                      borderRadius: "40px",
                      gap: "20px",
                      height: "100%",
                      background: "white"
                    }}
                >
                  <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    sx={{
                      "& .MuiTabs-scroller": {
                        overflowX: "auto !important",
                      },
                    }}
                  >
                    {/* <Tab sx={tabStyle} disabled label="Activity" {...a11yProps(0)}
                    style={{
                      "&.Mui-disabled": {
                        color: "gray"
                      }
                    }}
                  /> */}
                    <Tab
                      sx={{ ...tabStyle , textTransform: "none"}}
                      label="Metrics"
                      {...a11yProps(0)}
                      data-cy="view-metrics"
                    />
                    <Tab
                      sx={{ ...tabStyle , textTransform: "none" }}
                      label="Sessions"
                      {...a11yProps(0)}
                      data-cy="view-sessions"
                    />

                    <Tab
                      sx={{ ...tabStyle , textTransform: "none" }}
                      label="Goals"
                      {...a11yProps(0)}
                      data-cy="view-goals"
                      id="hint-anchor-3"
                    />

                    <Tab
                      sx={{ ...tabStyle , textTransform: "none" }}
                      label="Assessments"
                      {...a11yProps(0)}
                      data-cy="view-assessment"
                    />
                    <Tab
                      sx={{ ...tabStyle , textTransform: "none" }}
                      label="Notes"
                      {...a11yProps(0)}
                      data-cy="view-notes"
                    />
                    <Tab
                      sx={{ ...tabStyle , textTransform: "none" }}
                      label="Info"
                      {...a11yProps(0)}
                      data-cy="view-client-info"
                    />
                  </Tabs>
                  <TabPanel value={tabIndex} index={0} className="tab-panel-scrollable">
                    <MetricsComponent
                      actions={actions}
                      completedActionsSum={completedActionsSum}
                      overdueActionsSum={overdueActionsSum}
                      todoActionsSum={todoActionsSum}
                      sessionsSum={sessionsSum}
                    />
                  </TabPanel>

                  <TabPanel sx={{height: "100%"}} value={tabIndex} index={1}>
                    <Box
                      sx={{
                        ...tabPanelStyle,
                        maxHeight: "535px",
                        overflowY: {
                          xs: "auto",
                          "&::-webkit-scrollbar": {
                            width: "8px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "rgb(207, 207, 207)",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: "rgb(207, 207, 207)",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                          },
                        },
                        paddingRight: {
                          lg: "8px",
                        },
                      }}
                    >
                      {Object.keys(callsByMonth).map((index) => (
                        <>
                          <Typography
                            sx={{
                              ...tabTextStyle,
                              marginLeft: "24px",
                            }}
                          >
                            {index}
                          </Typography>
                          {callsByMonth[index].map((row) => (
                            <Box
                              sx={{
                                paddingX: "24px",
                                paddingY: "16px",
                                borderRadius: "40px",
                                display: "flex",
                                flexDirection: "column",
                                gap: "16px",
                                background: "#F6F6F6",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (row.file_name && row.file_name !== "null")
                                  navigate(
                                    `/platform/coach/session/${row.file_name}`
                                  );
                              }}
                            >
                              <Box
                                display="flex"
                                flexDirection="column"
                                gap="8px"
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "10px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontFamily: "Lexend",
                                        fontSize: "12px",
                                        lineHeight: "2.6",
                                        letterSpacing: "2",
                                        color: "#202226",
                                      }}
                                    >
                                      {row.zoom_date !== null &&
                                        row.zoom_date !== "" &&
                                        dayjs(row.zoom_date)
                                          .utc()
                                          .format("MM.DD.YY")}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontFamily: "Lexend",
                                        fontSize: "12px",
                                        lineHeight: "2.6",
                                        letterSpacing: "2",
                                        color: "#202226",
                                      }}
                                    >
                                      {row.duration_minutes} Mins
                                    </Typography>
                                    {!isNaN(row.clientLaraData?.overall_score) && (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "8px",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontFamily: "Lexend",
                                            fontSize: "12px",
                                            lineHeight: "1.4",
                                            color: "#202226",
                                            fontWeight: "300",
                                          }}
                                        >
                                          Coachee Score:
                                        </Typography>
                                        <LaraSessionCircle
                                          laraOverallScore={
                                            row.clientLaraData?.overall_score
                                          }
                                        />
                                        <Typography
                                          sx={{
                                            fontFamily: "Lexend",
                                            fontSize: "12px",
                                            lineHeight: "1.4",
                                            color: "#202226",
                                            fontWeight: "300",
                                          }}
                                        >
                                          Coach Score:
                                        </Typography>
                                        <LaraSessionCircle
                                          laraOverallScore={row.coachLaraData?.overall_score}
                                        />
                                      </Box>
                                    )}
                                  </Box>
                                  {clientData.status === "ReadyToPublish" ? (
                                    <Box position="relative" width="150px">
                                      <Typography
                                        sx={{
                                          background: "white",
                                          border: "1px solid #116D1F",
                                          borderRadius: "52px",
                                          width: "130px",
                                          letterSpacing: "0.05em",
                                          fontSize: "10px",
                                          textAlign: "center",
                                          fontFamily: "Lexend",
                                          fontWeight: "500",
                                          padding: "0",
                                          lineHeight: "18px",
                                          color: "#636467",
                                          textTransform: "capitalize",
                                          cursor: "pointer",
                                          transition: "all 0.3s",
                                          "&:hover": {
                                            background: "#10B02A",
                                            color: "white",
                                            border: "#10B02A",
                                          },
                                        }}
                                      >
                                        Processed
                                      </Typography>
                                    </Box>
                                  ) : clientData.status === "processing" ? (
                                    <Typography
                                      sx={{
                                        background: "white",
                                        border: "1px solid #116D1F",
                                        borderRadius: "52px",
                                        width: "130px",
                                        letterSpacing: "0.05em",
                                        fontSize: "10px",
                                        textAlign: "center",
                                        fontFamily: "Lexend",
                                        fontWeight: "500",
                                        padding: "0",
                                        lineHeight: "18px",
                                        color: "#636467",
                                        textTransform: "capitalize",
                                        cursor: "pointer",
                                        transition: "all 0.3s",
                                        "&:hover": {
                                          background: "#EDFAED",
                                          color: "#116D1F",
                                          border: "#EDFAED",
                                        },
                                      }}
                                    >
                                      Processing
                                    </Typography>
                                  ) : clientData.status === "incomplete" ? (
                                    <Typography
                                      sx={{
                                        background: "white",
                                        border: "1px solid #B52100",
                                        borderRadius: "52px",
                                        width: "130px",
                                        letterSpacing: "0.05em",
                                        fontSize: "10px",
                                        textAlign: "center",
                                        textTransform: "capitalize",
                                        cursor: "pointer",
                                        fontFamily: "Lexend",
                                        fontWeight: "500",
                                        padding: "0",
                                        lineHeight: "18px",
                                        color: "#636467",
                                        transition: "all 0.3s",
                                        "&:hover": {
                                          background: "#CE3F3F",
                                          color: "#FFD9D9",
                                          borderColor: "#B52100",
                                        },
                                      }}
                                    >
                                      Unprocessed
                                    </Typography>
                                  ) : (
                                    <Box
                                      sx={{
                                        background: "white",
                                        position: "relative",
                                        border: "1px solid #50B500",
                                        borderRadius: "43px",
                                        width: "auto",
                                        textAlign: "center",
                                        padding: "0",
                                        textTransform: "capitalize",
                                        cursor: "pointer",
                                        transition: "all 0.3s",
                                        "&:hover": {
                                          background: "rgba(80, 181, 0, 0.4)",
                                          backgroundOrigin: "border-box",
                                          border: "rgba(80, 181, 0, 0.4)",
                                        },
                                        "&:hover p": {
                                          color: "white",
                                        },
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontFamily: "Poppins",
                                          paddingX: "30px",
                                          paddingY: "4px",
                                          fontWeight: "bold",
                                          color: "#50B500",
                                          lineHeight: "18px",
                                          letterSpacing: "0.05em",
                                          fontSize: "10px",
                                          transition: "all 0.3s",
                                        }}
                                      >
                                        Session
                                      </Typography>
                                    </Box>
                                  )}
                                </Box>
                                <Typography
                                  sx={{
                                    fontFamily: "Lexend",
                                    fontSize: "22px",
                                    lineHeight: "1.35",
                                    color: "black",
                                    fontWeight: "500",
                                  }}
                                >
                                  {row.call_name}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontFamily: "Lexend",
                                    fontSize: "14px",
                                    lineHeight: "1.4",
                                    color: "#202226",
                                    fontWeight: "300",
                                  }}
                                >
                                  {row.quick_recap}
                                </Typography>
                              </Box>
                            </Box>
                          ))}
                        </>
                      ))}
                    </Box>
                  </TabPanel>
                  <TabPanel value={tabIndex} index={2}>
                    <GoalCard
                      user={user}
                      clientData={clientData}
                      loading={loading}
                      setLoading={setLoading}
                      fetchClientGoalsData={fetchClientGoalsData}
                      goals={goals}
                      setGoals={setGoals}
                    />
                  </TabPanel>
                  <TabPanel value={tabIndex} index={3}>
                    <Assessments
                      user={user}
                      userData={userData}
                      clientData={clientData}
                      loading={loading}
                      setLoading={setLoading}
                    />
                  </TabPanel>
                  <TabPanel value={tabIndex} index={4}>
                    <NoteComponent />
                  </TabPanel>
                  <TabPanel value={tabIndex} index={5}>
                    <ClientComponent />
                  </TabPanel>
                </Box>
              </Box>
            </Box>
          </Box>
      </Box>
      {addActionModal}
    </GlobalPageComponent>
  );
};

const boxStyle = {
  display: "flex",
  flexDirection: "column",
  border: "1px solid #F3F3F3",
  borderRadius: "40px",
  backgroundColor: "white",
  height: "100%",
};

const subHeadingStyle = {
  color: "black",
  fontWeight: "500",
  fontSize: "18px",
  fontFamily: "Montserrat",
  lineHeight: "135%",
};

const tableHeadingStyle = {
  color: "black",
  fontWeight: "400",
  fontSize: {
    xs: "12px",
    lg: "14px",
  },
  fontFamily: "Montserrat",
  lineHeight: "135%",
};

const textStyle = {
  color: "#202226",
  fontWeight: "400",
  fontSize: "12px",
  fontFamily: "Montserrat",
};

const tabTextStyle = {
  color: "black",
  fontWeight: "600",
  fontSize: "18px",
  fontFamily: "Montserrat",
};

const tabStyle = {
  color: "black",
  fontSize: 15,
  fontWeight: 500,
  letterSpacing: "0.05em",
  fontFamily: "Poppins",
  borderBottom: "1px solid #E8E8E8",
  "&.Mui-selected": {
    borderBottom: "1px solid #000",
  },
};

const tabPanelStyle = {
  marginTop: "20px",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
};

export default UserOverview;
