// SessionActions.jsx
import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
    Box,
    Button,
    TextField,
    Typography,
    Modal,
    Avatar,
    MenuItem,
    Divider,
    Select,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { formatDistanceToNow } from "date-fns";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFormik } from "formik";
import * as Yup from "yup";

import ActionsTable from "components/Table/CustomTable";
import avatar from "assets/avatar.png";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PositiveActionButton from "components/PositiveActionButton";
import NegativeActionButton from "components/NegativeActionButton";
import ConfirmButton from "components/ConfirmButton";
import DeclineButton from "components/DeclineButton";
import { useGetCoachesForUser } from "hooks/useGetCoachesForUser";
import {
    TodoActionsToolbar,
    CompletedActionsToolbar,
} from "components/SessionPage/SessionToolBar";
import PlusIcon from "components/Icons/Plus.svg";

export default function SessionActions({
    user,
    fileData,
    setCallActions,
    actionsArray = [],
    labels,
    coachName,
    clientShared,
    userData,
    clientHasInactiveCoach,
    listparticipants
}) {
    const [tabValue, setTabValue] = useState(0);
    const dispatch = useDispatch();
    const collapsed = useSelector((state) => state.layout.collapsed);
    const [loading, setLoading] = useState(true);

    // Selection states
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedCompleted, setSelectedCompleted] = useState([]);
    const [selectedCompletedIds, setSelectedCompletedIds] = useState([]);
    const [selected, setSelected] = useState([]);
    const [actionIds, setActionIds] = useState([]);

    // Modal states
    const [openCompleteActionModal, setOpenCompleteActionModal] = useState(false);
    const [openUndoActionModal, setOpenUndoActionModal] = useState(false);
    const [openEditActionModal, setOpenEditActionModal] = useState(false);
    const [openDeleteActionModal, setOpenDeleteActionModal] = useState(false);
    const [openAddActionsToGoalModal, setOpenAddActionsToGoalModal] =
        useState(false);
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [openDueDateSetModal, setOpenDueDateSetModal] = useState(false);
    const [openAddActionModal, setOpenAddActionModal] = useState(false);
    const [actionDate, setActionDate] = useState("");

    // Filter states
    const [filterClient, setFilterClient] = useState("none");
    const [filterClientItems, setFilterClientItems] = useState([
        { key: "Coachee", value: "none" },
    ]);
    const [filterCoach, setFilterCoach] = useState("none");
    const [filterCoachItems, setFilterCoachItems] = useState([
        { key: "Coach", value: "none" },
    ]);
    const [filterStartDate, setFilterStartDate] = useState("");
    const [filterEndDate, setFilterEndDate] = useState("");
    const [filteredActionData, setFilteredActionData] = useState([]);

    // Editing states
    const [updatedAction, setUpdatedAction] = useState({});
    const [initialAction, setInitialAction] = useState({});
    const [activeTab, setActiveTab] = useState("editText");

    // Goals
    const [selectedGoalForActions, setSelectedGoalForActions] = useState(null);
    const [loadingAssignActionsToGoal, setLoadingAssignActionsToGoal] =
        useState(false);

    // Additional data
    const [todoActions, setTodoActions] = useState([]);
    const [completedActions, setCompletedActions] = useState([]);

    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { membersWithoutUser } = useGetCoachesForUser(
        user?.sub,
        userData,
        user
    );
// const hasNonGuestParticipants = useMemo(() => {
//     return fileData?.guest_shares?.some((guest) =>
//         listparticipants?.participants?.some(
//             (participant) => participant.user_id === guest.user_id
//         )
//     );
// }, [fileData, listparticipants]);
// const guestCount = useMemo(() => {
//     return fileData?.guest_shares?.length || 0;
// }, [fileData]);
const hasNonGuestParticipants = useMemo(() => {
    return fileData?.guest_shares?.some((guest) =>
        listparticipants?.participants?.some(
            (participant) => user?.sub === guest.user_id
        )
    );
}, [fileData, listparticipants]);

const guestCount = useMemo(() => {
    return fileData?.guest_shares?.length || 0;
}, [fileData]);

const disableCheckboxes = hasNonGuestParticipants;



    // ──────────────────────────────────────────────────────────────────────────
    //  Fetch Goals + Summaries
    // ──────────────────────────────────────────────────────────────────────────

    const useGoalsData = (userId) => {
        return useQuery({
            queryKey: ["clientGoals", userId],
            queryFn: async () => {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-my-goals`,
                    { params: { client_id: userId } }
                );
                if (!response.data.goals_data) {
                    return [];
                }
                return response.data.goals_data;
            },
            enabled: !!userId,
            staleTime: 60 * 1000,
            cacheTime: 5 * 60 * 1000,
            retry: 1,
        });
    };
    console.log("actionsArray", actionsArray);
    const { data: goals = [], isLoading: loadingGoals } = useGoalsData(user?.sub);

    const useActionsSummary = (userId) => {
        return useQuery({
            queryKey: ["actionsSummary", userId],
            queryFn: async () => {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-actions-sum-by-user-id`,
                    { params: { user_id: userId } }
                );
                return data.data;
            },
            staleTime: 300000,
            enabled: !!userId,
        });
    };
    const { data: summaryData } = useActionsSummary(user?.sub);

    const {
        last_action_count = 0,
        last_file_name = "",
        completed_actions_sum = 0,
        todo_actions_sum = 0,
        overdue_actions_sum = 0,
    } = summaryData || {};

    useEffect(() => {
        setLoading(false);

        // We have "actionsArray" from parent. Just set "filteredActionData" from it, applying filters:
        let filteredData = [...actionsArray];

        if (filterClient !== "none") {
            filteredData = filteredData.filter(
                (row) => row.client_name === filterClient
            );
        }
        if (filterCoach !== "none") {
            filteredData = filteredData.filter(
                (row) => row.coach_name === filterCoach
            );
        }
        if (filterStartDate) {
            filteredData = filteredData.filter(
                (row) =>
                    new Date(row.date).toISOString().split("T")[0] >= filterStartDate
            );
        }
        if (filterEndDate) {
            filteredData = filteredData.filter(
                (row) =>
                    new Date(row.due_date).toISOString().split("T")[0] <= filterEndDate
            );
        }
        setFilteredActionData(filteredData);
    }, [actionsArray, filterClient, filterCoach, filterStartDate, filterEndDate]);

    useEffect(() => {
        if (actionsArray && actionsArray.length > 0) {
            const uniqueClients = Array.from(
                new Set(actionsArray.map((a) => a.client_name))
            ).sort();
            setFilterClientItems([
                { key: "Coachee", value: "none" },
                ...uniqueClients.map((n) => ({ key: n, value: n })),
            ]);

            const uniqueCoaches = Array.from(
                new Set(actionsArray.map((a) => a.coach_name))
            ).sort();
            setFilterCoachItems([
                { key: "Coach", value: "none" },
                ...uniqueCoaches.map((n) => ({ key: n, value: n })),
            ]);
        }
    }, [actionsArray]);

    const handleResetFilters = () => {
        setFilterClient("none");
        setFilterCoach("none");
        setFilterStartDate("");
        setFilterEndDate("");
    };

    const newActions = useMemo(() => {
        const today = new Date();
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - today.getDay());
        const formattedToday = today.toLocaleDateString("en-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
        const formattedStartOfWeek = startOfWeek.toLocaleDateString("en-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
        const incomplete = Array.isArray(actionsArray) ? actionsArray.filter((a) => a.status === "incomplete") : [];
        return incomplete.filter((row) => {
            const callDate = new Date(row.date).toLocaleDateString("en-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
            return callDate >= formattedStartOfWeek && callDate <= formattedToday;
        });
    }, [actionsArray]);

    const overdueActions = useMemo(() => {
        const today = new Date();
        const formattedToday = today.toLocaleDateString("en-us");
        const incomplete = Array.isArray(actionsArray) ? actionsArray.filter((a) => a.status === "incomplete") : [];
        return incomplete.filter((row) => {
            const dueDate = new Date(row.due_date).toLocaleDateString("en-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
            return dueDate <= formattedToday;
        });
    }, [actionsArray]);

    useEffect(() => {
        setLoading(false);
        setFilteredActionData(actionsArray);
    }, [actionsArray]);

    const generateUUID = () => {
        return typeof crypto !== "undefined" && crypto.randomUUID
            ? crypto.randomUUID()
            : Math.random().toString(36).substr(2, 9);
    };

    const addActionMutation = useMutation({
        mutationFn: async (newAction) => {
            const response = await axios.put(
                `${process.env.REACT_APP_API_ENDPOINT}/zoom/add-action-with-call`,
                newAction
            );
            return response.data;
        },
        onMutate: async (newAction) => {
            await queryClient.cancelQueries(["actions", user?.sub]);
            const previousActions = queryClient.getQueryData(["actions", user?.sub]);

            const tempActionId = generateUUID();
            const nowIso = new Date().toISOString();

            const optimisticAction = {
                action_id: tempActionId,
                action: newAction.action,
                call_id: fileData?.id || null,
                coach_id: user?.sub,
                client_name: newAction.client_name,
                client_id: newAction.client_id || null,
                status: "incomplete",
                coach_name: newAction.coach_name,
                title: newAction.title || null,
                call_date: fileData?.zoom_call_date || null,
                created_at: fileData?.created_at || nowIso,
                participant_name: fileData?.client || null,
                due_date: newAction.due_date || null,
                completed_at: null,
                action_for: "third-party",
                edits: [
                    {
                        edit_id: Math.random().toString(36).substr(2, 9),
                        action_id: tempActionId,
                        previous_value: null,
                        new_value: null,
                        edited_by_userid: user?.sub,
                        edit_timestamp: nowIso,
                    },
                ],
                date: nowIso,
            };

            queryClient.setQueryData(["actions", user?.sub], (old = []) => [
                ...old,
                optimisticAction,
            ]);
            setCallActions((prev) => [...prev, optimisticAction]);

            return { previousActions, tempActionId };
        },
        onError: (error, newAction, context) => {
            queryClient.setQueryData(["actions", user?.sub], context.previousActions);
            toast.error("Failed to add action. Please try again.");
        },
        onSuccess: (serverResponse, newAction, context) => {
            const finalActionId = generateUUID();
            const nowIso = new Date().toISOString();

            const finalAction = {
                action_id: finalActionId,
                action: newAction.action,
                call_id: fileData?.id || null,
                coach_id: user?.sub,
                client_name: newAction.client_name,
                client_id: newAction.client_id || null,
                status: "incomplete",
                coach_name: newAction.coach_name,
                title: newAction.title || null,
                call_date: fileData?.zoom_call_date || null,
                created_at: fileData?.created_at || nowIso,
                participant_name: fileData?.client || null,
                due_date: newAction.due_date || null,
                completed_at: null,
                action_for: "third-party",
                edits: [
                    {
                        edit_id: Math.random().toString(36).substr(2, 9),
                        action_id: finalActionId,
                        previous_value: null,
                        new_value: null,
                        edited_by_userid: user?.sub,
                        edit_timestamp: nowIso,
                    },
                ],
                date: nowIso,
            };

            queryClient.setQueryData(["actions", user?.sub], (old = []) =>
                old.map((act) =>
                    act.action_id === context.tempActionId ? finalAction : act
                )
            );
            setCallActions((prev) =>
                prev.map((act) =>
                    act.action_id === context.tempActionId ? finalAction : act
                )
            );
            toast.success("Action added successfully!");
        },
        onSettled: () => {
            queryClient.invalidateQueries(["actions", user?.sub]);
            queryClient.invalidateQueries(["counts", user?.sub]);
        },
    });

    const formik3 = useFormik({
        initialValues: {
            action_title: "",
            action: "",
            coach_id: "",
            coach_name: "",
        },
        validationSchema: Yup.object().shape({
            action: Yup.string().required("Action is required"),
            coach_id: Yup.string().required("Coach selection is required"),
        }),
        onSubmit: async (values) => {
            const newAction = {
                action: values.action,
                created_for: values.coach_id,
                created_by: user?.sub,
                due_date: actionDate || null,
                call_id: fileData?.call_id || null,
                client_name: values.coach_name,
                coach_name: `${user?.given_name} ${user?.family_name}`,
            };
            addActionMutation.mutate(newAction);
            handleClose();
        },
    });

    const handleClose = () => {
        setOpenAddActionModal(false);
        formik3.resetForm();
        setActionDate("");
    };

    // ──────────────────────────────────────────────────────────────────────────
    //  Additional CRUD calls (delete, complete, undo, etc.)
    // ──────────────────────────────────────────────────────────────────────────
    const handleCloseUndoAction = () => setOpenUndoActionModal(false);
    const handleCloseCompleteAction = () => setOpenCompleteActionModal(false);
    const handleCloseEditAction = () => {
        setOpenEditActionModal(false);
        setInitialAction({});
        setActiveTab("editText");
    };
    const handleCloseSetDueDate = () => setOpenDueDateSetModal(false);
    const handleCloseDeleteAction = () => setOpenDeleteActionModal(false);
    const handleCloseAddActionsToGoal = () => {
        setSelectedGoalForActions(null);
        setOpenAddActionsToGoalModal(false);
    };

    const deleteActions = async () => {
        const selectedIdsToDelete = [...selected, ...selectedCompleted];
        try {
            // Optimistic removal
            setCallActions((prev) =>
                prev.filter(
                    (item) =>
                        !selectedIdsToDelete.some((sel) => sel.action_id === item.action_id)
                )
            );
            queryClient.setQueryData(["actions", user?.sub], (oldData = []) =>
                oldData.filter(
                    (item) =>
                        !selectedIdsToDelete.some((sel) => sel.action_id === item.action_id)
                )
            );

            await Promise.all(
                selectedIdsToDelete.map(async (row) => {
                    await axios.put(
                        `${process.env.REACT_APP_API_ENDPOINT}/zoom/delete-action`,
                        {
                            action_id: row.action_id,
                        }
                    );
                })
            );

            queryClient.invalidateQueries(["actionsSummary", user?.sub]);
            toast.success("Actions deleted successfully");
        } catch (error) {
            console.error("Error deleting actions:", error);
        } finally {
            setSelectedIds([]);
            setSelected([]);
            setSelectedCompletedIds([]);
            setSelectedCompleted([]);
            setOpenDeleteActionModal(false);
            queryClient.invalidateQueries(["clientGoals", user?.sub]);
        }
    };

    const completeActions = async () => {
        try {
            setCallActions((prev) =>
                prev.map((item) =>
                    selected.some((sel) => sel.action_id === item.action_id)
                        ? { ...item, status: "complete", completed_at: new Date() }
                        : item
                )
            );
            queryClient.setQueryData(["actions", user?.sub], (oldData = []) =>
                oldData.map((item) =>
                    selected.some((sel) => sel.action_id === item.action_id)
                        ? { ...item, status: "complete", completed_at: new Date() }
                        : item
                )
            );
    
            await Promise.all(
                selected.map(async (action) => {
                    return axios.put(
                        `${process.env.REACT_APP_API_ENDPOINT}/zoom/set-action-status`,
                        {
                            new_status: "complete",
                            action_id: action.action_id,
                        }
                    );
                })
            );
    
            // Invalidate summary and goals – add the actions list invalidation here:
            queryClient.invalidateQueries(["actions", user?.sub]);
            queryClient.invalidateQueries(["actionsSummary", user?.sub]);
            queryClient.invalidateQueries(["clientGoals", user?.sub]);
    
            toast.success("Actions marked as complete");
            setSelectedIds([]);
            setSelected([]);
            setActionIds([]);
            setOpenCompleteActionModal(false);
        } catch (error) {
            console.error("Error completing actions:", error);
            toast.error("Failed to complete actions. Please try again.");
        }
    };
    
    const undoActions = async () => {
        try {
            setCallActions((prev) =>
                prev.map((item) =>
                    selectedCompleted.some((sel) => sel.action_id === item.action_id)
                        ? { ...item, status: "incomplete" }
                        : item
                )
            );
            queryClient.setQueryData(["actions", user?.sub], (oldData = []) =>
                oldData.map((item) =>
                    selectedCompleted.some((sel) => sel.action_id === item.action_id)
                        ? { ...item, status: "incomplete" }
                        : item
                )
            );

            await Promise.all(
                selectedCompleted.map((row) =>
                    axios.put(
                        `${process.env.REACT_APP_API_ENDPOINT}/zoom/set-action-status`,
                        {
                            action_id: row.action_id,
                            completed: "complete",
                        }
                    )
                )
            );

            queryClient.invalidateQueries(["actionsSummary", user?.sub]);
            toast.success("Actions marked as to-do");
        } catch (error) {
            console.error("Error undoing actions:", error);
        } finally {
            setSelectedCompletedIds([]);
            setSelectedCompleted([]);
            setActionIds([]);
            setOpenUndoActionModal(false);
        }
    };

    const setDueDate = async () => {
        // Combine IDs from selected and selectedCompleted if needed:
        const combinedIds = [...selected, ...selectedCompleted];
    
        // Optimistically update local state
        setCallActions((prev) =>
            prev.map((item) =>
                combinedIds.some((sel) => sel.action_id === item.action_id)
                    ? { ...item, due_date: selectedDate }
                    : item
            )
        );
        queryClient.setQueryData(["actions", user?.sub], (oldData = []) =>
            oldData.map((item) =>
                combinedIds.some((sel) => sel.action_id === item.action_id)
                    ? { ...item, due_date: selectedDate }
                    : item
            )
        );
    
        try {
            await Promise.all(
                combinedIds.map(async (row) => {
                    await axios.put(
                        `${process.env.REACT_APP_API_ENDPOINT}/zoom/update-action-due-date`,
                        {
                            action_id: row.action_id,
                            due_date: selectedDate,
                        }
                    );
                })
            );
    
            // Invalidate the actions query to ensure server updates are reflected globally
            queryClient.invalidateQueries(["actions", user?.sub]);
            queryClient.invalidateQueries(["actionsSummary", user?.sub]);
            queryClient.invalidateQueries(["clientGoals", user?.sub]);
    
            toast.success("Due date set successfully!");
        } catch (error) {
            console.error("Error setting due date:", error);
            toast.error("Failed to set due date. Please try again.");
        } finally {
            setSelected([]);
            setSelectedIds([]);
            setSelectedCompleted([]);
            setSelectedCompletedIds([]);
            setActionIds([]);
            setOpenDueDateSetModal(false);
        }
    };
    
    const saveEditAction = async () => {
        try {
            // Optimistic update
            setCallActions((prev) =>
                prev.map((item) =>
                    item.action_id === updatedAction.action_id
                        ? { ...item, action: updatedAction.action }
                        : item
                )
            );
            queryClient.setQueryData(["actions", user?.sub], (oldData = []) =>
                oldData.map((item) =>
                    item.action_id === updatedAction.action_id
                        ? { ...item, action: updatedAction.action }
                        : item
                )
            );

            await axios.put(
                `${process.env.REACT_APP_API_ENDPOINT}/zoom/update-action`,
                {
                    action_id: updatedAction.action_id,
                    action: updatedAction.action,
                    initial_action: initialAction.action,
                    edited_by_userID: user?.sub,
                }
            );

            toast.success("Action updated successfully!");
        } catch (error) {
            console.error("Error saving edit action:", error);
            // Even if there's an error, the UI is updated optimistically
            // so we won't revert to old state unless you want to handle that
            toast.error("Could not save the updated action.");
        } finally {
            setUpdatedAction(null);
            setSelected([]);
            setSelectedIds([]);
            setOpenEditActionModal(false);
        }
    };

    const addActionsToGoal = useCallback(async () => {
        try {
            setLoadingAssignActionsToGoal(true);
            const selectedToAssignToGoal = [...selected, ...selectedCompleted];
            let successCount = 0;
            let duplicateCount = 0;

            await Promise.all(
                selectedToAssignToGoal.map(async (row) => {
                    const response = await axios.put(
                        `${process.env.REACT_APP_API_ENDPOINT}/zoom/assign-actions-to-goal`,
                        {
                            action_id: row.action_id,
                            goal_id: selectedGoalForActions.goal_id,
                        }
                    );
                    if (response.data.status === "success") {
                        successCount++;
                    } else if (response.data.status === "duplicate") {
                        duplicateCount++;
                    }
                })
            );

            if (successCount > 0) {
                toast(
                    `Actions assigned to goal successfully: ${successCount}${duplicateCount > 0 ? `, already assigned: ${duplicateCount}` : ""
                    }`
                );
            } else {
                toast.error("The selected action(s) are already assigned to the goal");
            }

            setSelected([]);
            setSelectedIds([]);
            setSelectedCompleted([]);
            setSelectedCompletedIds([]);
            setActionIds([]);
            queryClient.invalidateQueries(["clientGoals", user?.sub]);
        } catch (error) {
            console.error("Error assigning actions to goal:", error);
        } finally {
            handleCloseAddActionsToGoal();
            setLoadingAssignActionsToGoal(false);
        }
    }, [
        selected,
        selectedCompleted,
        selectedGoalForActions,
        queryClient,
        user?.sub,
    ]);

    // ──────────────────────────────────────────────────────────────────────────
    //  Modals
    // ──────────────────────────────────────────────────────────────────────────
    const CustomIcon = (props) => (
        <KeyboardArrowDown {...props} style={{ color: "black" }} />
    );

    const editActionModal = () => {
        const handleTabChange = (tab) => setActiveTab(tab);

        let originalAction = "";
        let sortedEdits = [];

        if (initialAction && initialAction.edits && initialAction.edits[0]) {
            sortedEdits = [...initialAction.edits].sort(
                (a, b) => new Date(a.edit_timestamp) - new Date(b.edit_timestamp)
            );
            originalAction = sortedEdits[0].previous_value;
        }

        return (
            <Modal open={openEditActionModal} onClose={handleCloseEditAction}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 500,
                        background: "white",
                        border: "2px solid white",
                        boxShadow: 24,
                        paddingX: "50px",
                        paddingY: "30px",
                        borderRadius: "30px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        gap: "20px",
                        margin: "0 auto",
                        maxWidth: "calc(100% - 40px)",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: { xs: "8px", lg: "32px" },
                        }}
                    >
                        <Box display="flex" gap="10px">
                            <Typography
                                sx={{
                                    fontFamily: "Lexend",
                                    color: "black",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    padding: "4px",
                                    borderBottom:
                                        activeTab === "editText" ? "1px solid black" : "none",
                                    textTransform: "capitalize",
                                    boxShadow: "none",
                                    "&:hover": { cursor: "pointer" },
                                }}
                                onClick={() => handleTabChange("editText")}
                            >
                                Edit Action
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Lexend",
                                    color: "black",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    padding: "4px",
                                    borderBottom:
                                        activeTab === "editHistory" ? "1px solid black" : "none",
                                    textTransform: "capitalize",
                                    boxShadow: "none",
                                    "&:hover": { cursor: "pointer" },
                                }}
                                onClick={() => handleTabChange("editHistory")}
                            >
                                View History
                            </Typography>
                        </Box>
                        {activeTab === "editHistory" && (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "4px",
                                    height: "220px",
                                    overflowY: "auto",
                                }}
                            >
                                {sortedEdits
                                    .slice()
                                    .reverse()
                                    .map((edit, index) => (
                                        <Box key={index} sx={{ gap: "2px" }}>
                                            <Typography sx={{ fontSize: "14px", color: "black" }}>
                                                {edit.new_value}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: "#2D2D2D",
                                                    fontSize: "12px",
                                                    marginLeft: "10px",
                                                    marginBottom: "6px",
                                                }}
                                            >
                                                edited by {edit.editor_name || "Unknown"}{" "}
                                                {formatDistanceToNow(new Date(edit.edit_timestamp))} ago
                                            </Typography>
                                            <Divider color="#d3d3d3" />
                                        </Box>
                                    ))}
                                {initialAction.edits && originalAction && (
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: 500,
                                                color: "black",
                                            }}
                                        >
                                            {originalAction}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: "#2D2D2D",
                                                fontSize: "12px",
                                                marginLeft: "10px",
                                            }}
                                        >
                                            orginal action
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        )}
                        {activeTab === "editText" && (
                            <>
                                <Box
                                    sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "Lexend",
                                            fontSize: "12px",
                                            lineHeight: "150%",
                                            letterSpacing: "2px",
                                            color: "black",
                                            textTransform: "uppercase",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Action
                                    </Typography>
                                    <TextField
                                        required
                                        fullWidth
                                        autoFocus
                                        multiline
                                        rows={4}
                                        value={updatedAction?.action || ""}
                                        InputProps={{
                                            inputProps: { style: { color: "black" } },
                                            style: {
                                                borderRadius: "16px",
                                                border: "1px solid #C4CAD4",
                                                backgroundColor: "transparent",
                                            },
                                        }}
                                        onChange={(event) => {
                                            setUpdatedAction((prev) => ({
                                                ...prev,
                                                action: event.target.value,
                                            }));
                                        }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="flex-end" gap="15px">
                                    <NegativeActionButton
                                        onClick={handleCloseEditAction}
                                        label="Cancel"
                                    />
                                    <PositiveActionButton
                                        onClick={saveEditAction}
                                        label="Save Action"
                                        disabled={updatedAction?.action === initialAction?.action}
                                    />
                                </Box>
                            </>
                        )}
                    </Box>
                </Box>
            </Modal>
        );
    };

    const addActionsToGoalModal = () => (
        <Modal
            open={openAddActionsToGoalModal}
            onClose={handleCloseAddActionsToGoal}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 500,
                    background: "white",
                    border: "2px solid white",
                    boxShadow: 24,
                    paddingX: "50px",
                    paddingY: "30px",
                    borderRadius: "30px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "20px",
                    margin: "0 auto",
                    maxWidth: "calc(100% - 40px)",
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            color: "black",
                            fontWeight: 500,
                            fontFamily: "Lexend",
                            fontSize: { xs: "24px", md: "36px" },
                        }}
                    >
                        {`Link Action${selected?.length > 1 ? "s" : ""} to Goal`}
                    </Typography>
                </Box>
                <Box>
                    <Typography color="#828282" fontWeight={500} fontFamily="Lexend">
                        {selected?.length > 0
                            ? `Select a goal for ${selected?.length} action${selected?.length > 1 ? "s" : ""
                            }`
                            : selectedCompleted?.length > 0
                                ? `Select a goal for ${selectedCompleted?.length} action${selectedCompleted?.length > 1 ? "s" : ""
                                }`
                                : "No actions selected"}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        width: "100%",
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "Lexend",
                            fontSize: "12px",
                            lineHeight: "1.5",
                            letterSpacing: "2px",
                            fontWeight: "bold",
                            color: "black",
                            textTransform: "uppercase",
                        }}
                    >
                        Select Goal
                    </Typography>
                    <Select
                        fullWidth
                        required
                        value={selectedGoalForActions?.goal_id || ""}
                        onChange={(event) => {
                            const found = goals.find((g) => g.goal_id === event.target.value);
                            setSelectedGoalForActions(found || null);
                        }}
                        IconComponent={CustomIcon}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    backgroundColor: "white",
                                    maxHeight: 300,
                                    overflow: "auto",
                                    maxWidth: "20%",
                                    width: "20%",
                                },
                            },
                        }}
                        sx={{
                            color: "black",
                            background: "white",
                            borderRadius: "16px",
                            border: "1px solid #C4CAD4",
                            "&:before, &:after": {
                                display: "none",
                            },
                            "& fieldset": {
                                border: "none !important",
                            },
                        }}
                    >
                        {goals?.length > 0 &&
                            goals.map((goal) => (
                                <MenuItem
                                    key={goal.goal_id}
                                    value={goal.goal_id}
                                    sx={{
                                        background: "white",
                                        color: "black",
                                        "&:hover": { background: "#F7F7F7", cursor: "pointer" },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "8px",
                                            width: "100%",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: "black",
                                                fontSize: "12px",
                                                whiteSpace: "normal",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                wordBreak: "break-word",
                                            }}
                                        >
                                            {goal.title}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: "#202226",
                                                fontSize: "10px",
                                                paddingLeft: "10px",
                                                whiteSpace: "normal",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                wordBreak: "break-word",
                                            }}
                                        >
                                            {goal.description?.length > 100
                                                ? `${goal?.description.substring(0, 100)}...`
                                                : goal.description}
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            ))}
                    </Select>
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    sx={{
                        gap: { xs: "8px", md: "15px" },
                        flexDirection: { xs: "column-reverse", md: "row" },
                    }}
                >
                    <DeclineButton onClick={handleCloseAddActionsToGoal} label="Cancel" />
                    <ConfirmButton
                        onClick={addActionsToGoal}
                        disabled={
                            (selected?.length === 0 && selectedCompleted?.length === 0) ||
                            !selectedGoalForActions ||
                            loadingAssignActionsToGoal
                        }
                        label="Confirm"
                    />
                </Box>
            </Box>
        </Modal>
    );

    const deleteActionModal = () => (
        <Modal open={openDeleteActionModal} onClose={handleCloseDeleteAction}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 500,
                    background: "white",
                    border: "2px solid white",
                    boxShadow: 24,
                    paddingX: "50px",
                    paddingY: "30px",
                    borderRadius: "30px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "20px",
                    margin: "0 auto",
                    maxWidth: "calc(100% - 40px)",
                }}
            >
                <Box>
                    <Typography color="black" fontWeight="400">
                        Are you sure you want to delete selected actions?
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    sx={{
                        gap: { xs: "8px", md: "15px" },
                        flexDirection: { xs: "column-reverse", md: "row" },
                    }}
                >
                    <DeclineButton onClick={handleCloseDeleteAction} label="No" />
                    <ConfirmButton onClick={deleteActions} label="Yes" />
                </Box>
            </Box>
        </Modal>
    );

    const completeActionModal = () => (
        <Modal
            open={openCompleteActionModal}
            onClose={() => setOpenCompleteActionModal(false)}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 500,
                    background: "white",
                    border: "2px solid white",
                    boxShadow: 24,
                    paddingX: "50px",
                    paddingY: "30px",
                    borderRadius: "30px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "20px",
                    margin: "0 auto",
                    maxWidth: "calc(100% - 40px)",
                }}
            >
                <Box>
                    <Typography color="black" fontWeight="400">
                        Are you sure you want to mark selected actions as completed?
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    sx={{
                        gap: { xs: "8px", md: "15px" },
                        flexDirection: { xs: "column-reverse", md: "row" },
                    }}
                >
                    <DeclineButton onClick={handleCloseCompleteAction} label="No" />
                    <ConfirmButton onClick={completeActions} label="Yes" />
                </Box>
            </Box>
        </Modal>
    );

    const undoActionModal = () => (
        <Modal
            open={openUndoActionModal}
            onClose={() => setOpenUndoActionModal(false)}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 500,
                    background: "white",
                    border: "2px solid white",
                    boxShadow: 24,
                    paddingX: "50px",
                    paddingY: "30px",
                    borderRadius: "30px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "20px",
                    margin: "0 auto",
                    maxWidth: "calc(100% - 40px)",
                }}
            >
                <Box>
                    <Typography color="black" fontWeight="400">
                        Are you sure you want to mark selected actions as to-do?
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    sx={{
                        gap: { xs: "8px", md: "15px" },
                        flexDirection: { xs: "column-reverse", md: "row" },
                    }}
                >
                    <DeclineButton onClick={handleCloseUndoAction} label="No" />
                    <ConfirmButton onClick={undoActions} label="Yes" />
                </Box>
            </Box>
        </Modal>
    );

    // “Create new action” modal
    const addActionModal = (
        <Modal
            open={openAddActionModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    backgroundColor: "white",
                    color: "black",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 1000,
                    boxShadow: 24,
                    padding: {
                        xs: "24px",
                        lg: "64px",
                    },
                    gap: {
                        xs: "24px",
                        lg: "unset",
                    },
                    borderRadius: "30px",
                    display: "flex",
                    flexDirection: {
                        xs: "column",
                        lg: "row",
                    },
                    justifyContent: "space-between",
                    maxWidth: "calc(100% - 40px)",
                    margin: "20px auto",
                    overflowY: "auto",
                }}
            >
                <Box
                    sx={{
                        width: {
                            xs: "100%",
                            lg: "50%",
                        },
                        display: "flex",
                        flexDirection: "column",
                        gap: {
                            xs: "8px",
                            lg: "16px",
                        },
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "Lexend",
                            maxWidth: {
                                md: "350px",
                            },
                            fontSize: "36px",
                            color: "black",
                        }}
                    >
                        Create a new action
                    </Typography>
                </Box>

                <Box
                    sx={{
                        width: {
                            xs: "100%",
                            lg: "50%",
                        },
                        maxWidth: {
                            xs: "100%",
                            lg: "340px",
                        },
                    }}
                >
                    <form onSubmit={formik3.handleSubmit}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "22px",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "8px",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Lexend",
                                        fontSize: "12px",
                                        lineHeight: "1.5",
                                        letterSpacing: "2px",
                                        fontWeight: "bold",
                                        color: "black",
                                        textTransform: "capitalize",
                                    }}
                                >
                                    Action
                                </Typography>
                                <TextField
                                    required
                                    fullWidth
                                    multiline
                                    rows={4}
                                    error={
                                        formik3.touched.action && Boolean(formik3.errors.action)
                                    }
                                    {...formik3.getFieldProps("action")}
                                    InputProps={{
                                        style: {
                                            color: "black",
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: "black",
                                        },
                                    }}
                                    sx={{
                                        color: "#22272F",
                                        background: "white",
                                        borderRadius: "16px",
                                        width: "100%",
                                        border: "1px solid #C4CAD4",
                                        "&:before, &:after": {
                                            display: "none",
                                        },
                                        "& fieldset": {
                                            border: "none !important",
                                        },
                                    }}
                                    focused={formik3.touched.action}
                                />
                                {formik3.touched.action && formik3.errors.action && (
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            color: "#BE0808",
                                            letterSpacing: "2px",
                                            fontSize: "8px",
                                            fontWeight: "bold",
                                            textTransform: "capitalize",
                                        }}
                                    >
                                        {formik3.errors.action}
                                    </Typography>
                                )}
                            </Box>

                            <Box
                                sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Lexend",
                                        fontSize: "12px",
                                        lineHeight: "1.5",
                                        letterSpacing: "2px",
                                        fontWeight: "bold",
                                        color: "black",
                                        textTransform: "capitalize",
                                    }}
                                >
                                    Due Date
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        format="YYYY-MM-DD"
                                        onChange={(date) => {
                                            if (date) {
                                                const formattedDate = dayjs(date)
                                                    .utc()
                                                    .format("YYYY-MM-DD");
                                                setActionDate(formattedDate);
                                            }
                                        }}
                                        sx={{
                                            borderRadius: "16px",
                                            border: "1px solid #C4CAD4",
                                            "& svg": { color: "#22272F" },
                                            "& input": { color: "#22272F" },
                                            "& fieldset": { border: "none !important" },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Box>

                            <Box
                                sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Lexend",
                                        fontSize: "12px",
                                        lineHeight: "1.5",
                                        letterSpacing: "2px",
                                        fontWeight: "bold",
                                        color: "black",
                                        textTransform: "capitalize",
                                    }}
                                >
                                    Action For
                                </Typography>
                                <Select
                                    fullWidth
                                    required
                                    value={formik3.values.coach_id}
                                    onChange={(event) => {
                                        const selectedCoach = membersWithoutUser.find(
                                            (coach) => coach.user_id === event.target.value
                                        );
                                        formik3.setFieldValue("coach_id", event.target.value);
                                        formik3.setFieldValue(
                                            "coach_name",
                                            selectedCoach?.full_name || ""
                                        );
                                    }}
                                    IconComponent={CustomIcon}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                backgroundColor: "white",
                                            },
                                        },
                                    }}
                                    sx={{
                                        color: "black",
                                        background: "white",
                                        borderRadius: "16px",
                                        border: "1px solid #C4CAD4",
                                        "&:before, &:after": { display: "none" },
                                        "& fieldset": { border: "none !important" },
                                    }}
                                >
                                    {userData?.accessTo === "platform" &&
                                        membersWithoutUser &&
                                        membersWithoutUser.map((coach) => (
                                            <MenuItem
                                                key={coach.user_id}
                                                value={coach.user_id}
                                                sx={{ background: "white", color: "black" }}
                                            >
                                                {coach.full_name}
                                            </MenuItem>
                                        ))}

                                    {userData?.accessTo === "platform_professional" && (
                                        <MenuItem
                                            key={user?.sub}
                                            value={user?.sub}
                                            sx={{ background: "white", color: "black" }}
                                        >
                                            Personal Goal
                                        </MenuItem>
                                    )}
                                </Select>
                                {formik3.touched.coach_id && formik3.errors.coach_id && (
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            color: "#BE0808",
                                            letterSpacing: "2px",
                                            fontSize: "8px",
                                            fontWeight: "bold",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        *{formik3.errors.coach_id}
                                    </Typography>
                                )}
                            </Box>
                        </Box>

                        <Box
                            marginTop="42px"
                            display="flex"
                            justifyContent="flex-end"
                            gap="15px"
                        >
                            <NegativeActionButton onClick={handleClose} label="Cancel" />
                            <PositiveActionButton
                                onClick={formik3.handleSubmit}
                                label="Create Action"
                                disabled={
                                    !actionDate ||
                                    !formik3.values.action ||
                                    !formik3.values.coach_id
                                }
                            />
                        </Box>
                    </form>
                </Box>
            </Box>
        </Modal>
    );

    const dueDateSetModal = () => {
        const handleSetDueDate = async () => {
            await setDueDate([...selectedIds, ...selectedCompletedIds]);
            setSelected([]);
            setSelectedIds([]);
            setSelectedCompleted([]);
            setSelectedCompletedIds([]);
        };

        return (
            <Modal
                open={openDueDateSetModal}
                onClose={() => setOpenDueDateSetModal(false)}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 500,
                        background: "white",
                        border: "2px solid white",
                        boxShadow: 24,
                        paddingX: "50px",
                        paddingY: "30px",
                        borderRadius: "30px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        gap: "20px",
                        margin: "0 auto",
                        maxWidth: "calc(100% - 40px)",
                    }}
                >
                    <Box>
                        <Typography color="black" fontWeight="400">
                            {`Are you sure you want to set due date to ${dayjs(
                                selectedDate
                            ).format("YYYY-MM-DD")}?`}
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="center"
                        sx={{
                            gap: { xs: "8px", md: "15px" },
                            flexDirection: { xs: "column-reverse", md: "row" },
                        }}
                    >
                        <DeclineButton onClick={handleCloseSetDueDate} label="No" />
                        <ConfirmButton onClick={handleSetDueDate} label="Yes" />
                    </Box>
                </Box>
            </Modal>
        );
    };

    // ──────────────────────────────────────────────────────────────────────────
    //  Table Columns
    // ──────────────────────────────────────────────────────────────────────────

    // We locate the matching participant by comparing:
    // row.action_for_participant_id === participant.participant_id
    const toDoColumns = [
        {
          field: "action",
          headerName: "Action",
          flex: 1,
          headerClassName: "table-header",
          cellClassName: "table-cell",
          sortable: false,
          renderCell: (params) => (
            <Box
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                display: "flex",
                alignItems: "center",
                whiteSpace: "pre-wrap",
                textOverflow: "break-word",
              }}
            >
              <Box
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  display: "flex",
                  alignItems: "center",
                  whiteSpace: "pre-wrap",
                  textOverflow: "break-word",
                  textTransform: "none",
                }}
              >
                {params.value}
              </Box>
            </Box>
          ),
        },
        {
          field: "participant_display",
          headerName: "Participant(s)",
          flex: 0.5,
          headerClassName: "table-header",
          cellClassName: "table-cell",
          sortable: true,
          renderCell: (params) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
                overflowX: "auto",
                scrollbarWidth: "none", // Hides the scrollbar in Firefox
                "-ms-overflow-style": "none", // Hides the scrollbar in IE/Edge
                "&::-webkit-scrollbar": {
                  display: "none", // Hides the scrollbar in WebKit browsers
                },
              }}
            >
              {
                (params?.row?.created_for_name ? params.row.created_for_name.trim() : "") || 
                (params?.row?.participant_zoom_name ? params.row.participant_zoom_name.trim() : "") || 
                ""
            }
            </Box>
          ),
        },
        {
          field: "due_date",
          headerName: "Due Date",
          flex: 0.2,
          sortable: true,
          renderCell: (params) => (
            <Box sx={{ fontSize: "12px", fontWeight: 400 }}>
              {params.value && dayjs(params.value).utc().format("MM/DD/YY")}
            </Box>
          ),
        },
      ];
      
    // ──────────────────────────────────────────────────────────────────────────
    //  Render
    // ──────────────────────────────────────────────────────────────────────────

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: "8px",
                width: "100%",
                height: "100%",
                overflow: "hidden",
                display: { xs: !collapsed ? "none" : "block" },
            }}
        >
            <Box
                sx={{
                    height: "calc(100% - 2px)",
                    background: "white",
                    borderRadius: "40px",
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: { md: 1 },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                        width: "100%",
                        overflow: "auto",
                        height: "100%",
                    }}
                >
                    {!hasNonGuestParticipants && (
 
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            width: "100%",
                            gap: "12px",
                        }}
                    >
                        {/* "Create New" Action Button */}
                        <Button
                            onClick={() => setOpenAddActionModal(true)}
                            sx={{
                                padding: "10px 15px",
                                background: "white",
                                border: "1px solid #D9E4EC",
                                color: "black",
                                borderRadius: "50px",
                                height: "34px",
                                display: "flex",
                                alignItems: "center",
                                transition: "all 0.3s",
                                "&:hover": {
                                    cursor: "pointer",
                                    background: "black",
                                    borderColor: "black",
                                },
                                "&:hover *": { color: "white" },
                                "&:hover svg *": { stroke: "white" },
                            }}
                        >
                            <PlusIcon />
                            <Box sx={{ width: "10px" }} />
                            <Typography
                                sx={{
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                    lineHeight: "18px",
                                    fontWeight: 500,
                                    letterSpacing: "0.6px",
                                    textTransform: "capitalize",
                                }}
                            >
                                Create New
                            </Typography>
                        </Button>

                        {/* Actions toolbar for incomplete actions */}
                        <TodoActionsToolbar
                            user={user}
                            selected={selected}
                            selectedIds={selectedIds}
                            setUpdatedAction={setUpdatedAction}
                            setInitialAction={setInitialAction}
                            setOpenEditActionModal={setOpenEditActionModal}
                            setOpenAddActionsToGoalModal={setOpenAddActionsToGoalModal}
                            setOpenCompleteActionModal={setOpenCompleteActionModal}
                            setOpenDeleteActionModal={setOpenDeleteActionModal}
                            setSelectedDate={setSelectedDate}
                            selectedDate={selectedDate}
                            setOpenDueDateSetModal={setOpenDueDateSetModal}
                            navigate={navigate}
                        />
                    </Box>
                 )}


                    {/* Table of incomplete actions */}
                    <ActionsTable
                        rows={filteredActionData.filter((a) => a.status === "incomplete")}
                        loading={false}
                        columns={toDoColumns}
                        checkboxSelection={true}
                        selectionModel={selectedIds}
                        rowHeight={70}
                        getRowId={(row) => row.action_id}
                        isRowSelectable={() => !disableCheckboxes}
                        onRowClick={(row, event) => {
                            event.stopPropagation();
                            setModalContent(row.row.action);
                            setOpenDetailModal(true);
                        }}
                        onSelectionModelChange={(ids) => {
                            const selectedRows = filteredActionData.filter(
                                (r) => r.status === "incomplete" && ids.includes(r.action_id)
                            );
                            setSelected(selectedRows);
                            setSelectedIds(ids);
                        }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                        }
                    />
                </Box>

                {/* Action detail modal */}
                <Modal open={openDetailModal} onClose={() => setOpenDetailModal(false)}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 500,
                            background: "white",
                            border: "2px solid white",
                            boxShadow: 24,
                            padding: "20px 20px 10px",
                            borderRadius: "16px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            gap: "20px",
                            margin: "0 auto",
                            maxWidth: "calc(100% - 40px)",
                        }}
                    >
                        <Box>
                            <Typography color="black" fontWeight="400">
                                {modalContent}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-around">
                            <Button
                                sx={{
                                    width: "150px",
                                    borderRadius: "50px",
                                    background: "transparent",
                                    color: "black",
                                    border: "1px solid black",
                                    fontWeight: "bold",
                                    "&:hover": {
                                        cursor: "pointer",
                                        background: "black",
                                        borderColor: "black",
                                        color: "white",
                                    },
                                    textTransform: "capitalize",
                                }}
                                onClick={() => setOpenDetailModal(false)}
                            >
                                Close
                            </Button>
                        </Box>
                    </Box>
                </Modal>

                {/* All modals */}
                {addActionModal}
                {undoActionModal()}
                {completeActionModal()}
                {dueDateSetModal()}
                {editActionModal()}
                {deleteActionModal()}
                {addActionsToGoalModal()}
            </Box>
        </Box>
    );
}
