import React, { useRef } from "react";
import { InputLabel, FormControl } from "@mui/material";

export default function InputFieldOcean({ label, placeholder, state, setState }) {
  const inputRef = useRef(null);

  const inputStyles = {
    border: "2px solid #FFFFFF",
    borderRadius: "12px",
    padding: "6px 16px",
    backgroundColor: "transparent",
    width: "100%",
    height: "64px",
    fontFamily: "Lexend",
    fontSize: "18px",
    lineHeight: "27px",
    fontWeight: "400",
    color: "#ffffff",
    outline: "none",
  };

  const handlePaste = () => {
    if (inputRef.current) {
      // Add the force class immediately on paste
      inputRef.current.classList.add("force-transparent");
      // Remove it after a short duration
      setTimeout(() => {
        inputRef.current.classList.remove("force-transparent");
      }, 100);
    }
  };

  return (
    <FormControl fullWidth variant="standard">
      {label && (
        <InputLabel
          shrink
          style={{
            color: "#ffffff",
            fontFamily: "Lexend",
            fontSize: "18px",
          }}
        >
          {label}
        </InputLabel>
      )}
      <style>
        {`
          .input-ocean::placeholder {
            color: #ffffff;
            opacity: 1;
          }

          .input-ocean,
          .input-ocean:focus,
          .input-ocean:hover,
          .input-ocean:active {
            background-color: transparent;
            border-color: #ffffff;
          }

          .input-ocean.force-transparent {
            background-color: transparent !important;
        }
        `}
      </style>
      <input
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        value={state}
        onChange={(e) => setState(e.target.value)}
        onPaste={handlePaste}
        className="input-ocean"
        style={inputStyles}
      />
    </FormControl>
  );
}
