/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, } from "@mui/material";
import { v4 as uuid } from "uuid";
import PdfModal from './Pdf/PdfModal';
import ThreadArea from './ThreadComponents/ThreadArea';
import TopFloatingButtons from './TopFloatingButtons';
import ContentLibrary from './ContentLibraryComponents/ContentLibrary';
import ConvoArea from './ConvoAreaComponents/ConvoArea';

const RetuneIframe = ({ user, userData }) => {
    const userId = userData?.oldUserId || user?.sub;
    const baseApiUrl = process.env.REACT_APP_VC_BASE_API_URL;
    // const baseApiUrl = 'http://localhost:8000';


    const [threadNames, setThreadNames] = useState([]);
    const [selectedThread, setSelectedThread] = useState(null);
    const [selectedThreadMessages, setSelectedThreadMessages] = useState([]);
    const [sidebarOpen, setSidebarOpen] = useState(false);


    const [openPdfModal, setOpenPdfModal] = useState(false)
    const [selectedPdfUrl, setSelectedPdfUrl] = useState('');

    const [promptFromContentLibraryAsInput, setPromptFromContentLibraryAsInput] = useState('');
    const [contentLibraryOpen, setContentLibraryOpen] = useState(false);

    useEffect(() => {
        if (sidebarOpen) {
            setContentLibraryOpen(false);
        }
    }, [sidebarOpen])

    useEffect(() => {
        if (contentLibraryOpen) {
            setSidebarOpen(false);
        }
    }, [contentLibraryOpen])

    const handleOpenPdfModal = () => setOpenPdfModal(true);
    const handleClosePdfModal = () => setOpenPdfModal(false);

    const getCurrentTimeInUTC = () => {
        const now = new Date();
        const formatted = now.toISOString().replace('T', ' ').replace('Z', '');
        return formatted;
    }

    const handleClickNewThread = () => {
        if (selectedThreadMessages?.length === 1 && selectedThreadMessages[0]?.newThread) return;
        const newThreadId = `new-thread${uuid()}`;

        const newThreadMessage = {
            id: uuid(),
            role: 'assistant',
            content: 'What would you like to learn?',
            newThread: true
        }

        setSelectedThread(newThreadId)
        setSelectedThreadMessages([newThreadMessage])
        setThreadNames((prevState) => [{ id: newThreadId, name: " Start learning", lastMessageAt: getCurrentTimeInUTC() }, ...prevState])
    }



    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                maxWidth: "100%",
                display: 'flex',
                boxSizing: 'border-box',
                fontFamily: "Lexend",
                position: 'relative',

            }}>


            {/* Floating buttons for sidebar toggle, new thread creation, userguide download */}
            <TopFloatingButtons
                contentLibraryOpen={contentLibraryOpen}
                handleClickNewThread={handleClickNewThread}
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
            />


            {/* The thread area */}
            <ThreadArea
                sidebarOpen={sidebarOpen}
                selectedThread={selectedThread}
                setSelectedThread={setSelectedThread}
                userId={userId}
                baseApiUrl={baseApiUrl}
                threadNames={threadNames}
                setThreadNames={setThreadNames}
                handleClickNewThread={handleClickNewThread}
            />


            {/* PDF Modal */}
            <PdfModal openPdfModal={openPdfModal} selectedPdfUrl={selectedPdfUrl} handleClosePdfModal={handleClosePdfModal} />


            {/* The Convo area */}
            <ConvoArea
                contentLibraryOpen={contentLibraryOpen}
                sidebarOpen={sidebarOpen}
                promptFromContentLibraryAsInput={promptFromContentLibraryAsInput}
                setPromptFromContentLibraryAsInput={setPromptFromContentLibraryAsInput}
                selectedThread={selectedThread}
                selectedThreadMessages={selectedThreadMessages}
                setSelectedThreadMessages={setSelectedThreadMessages}
                baseApiUrl={baseApiUrl}
                userId={userId}
                setSelectedPdfUrl={setSelectedPdfUrl}
                handleOpenPdfModal={handleOpenPdfModal}
                threadNames={threadNames}
                setThreadNames={setThreadNames}
                setSelectedThread={setSelectedThread}
            />



            {/* The content library (right) */}
            <ContentLibrary
                contentLibraryOpen={contentLibraryOpen}
                setPromptFromContentLibraryAsInput={setPromptFromContentLibraryAsInput}
                setContentLibraryOpen={setContentLibraryOpen}
                handleOpenPdfModal={handleOpenPdfModal}
                setSelectedPdfUrl={setSelectedPdfUrl}
            />



        </Box >


    )
}
export default RetuneIframe;
